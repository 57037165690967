import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { MAP_MODE_CHANGED } from '../services/analytics';

export default Controller.extend({
  analytics: service(),
  tenant: service(),
  isAerial: false,
  queryParams: ['page', 'page_size', 'lat', 'lng'],
  page: 1,
  page_size: 500,
  selectedSites: computed('model.sites.[]', 'siteFilter.[]', function() {
    if (this.siteFilter) {
      return this.siteFilter
    } else {
      return this.model.sites
    }
  }),
  actions: {
    siteSelected(site) {
      if (site) {
        this.set('siteFilter', [site]);
      } else {
        this.set('siteFilter', null);
      }
    },
    togglePublicCameras() {
      this.toggleProperty('displayPublicCameras');
    },
    toggleAerialView() {
      this.toggleProperty('isAerial');

      this.analytics.trackMultiple(MAP_MODE_CHANGED, {
        satellite: this.get('isAerial'),
        tenant: this.tenant.subdomain,
      });
    },
  }
});
