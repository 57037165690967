import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  session: service(),
  tenant: service(),
  currentUser: service(),
  intl: service(),
  featureFlags: service(),

  /**
   * Wait for the tenant and current user to be validated
   * before loading the model
   **/
  beforeModel(transition) {
    this.set('session.attemptedTransition', transition);
    let superPromise = this._super(...arguments);
    if (!this.get('session.isAuthenticated')) {
      this.transitionTo('login');
      return superPromise;
    }

    let currentPromise = new Promise((resolve, reject) => {
      this.tenant.load()
        .then(() => {
          this.featureFlags.load()
        })
        .then(() => {
          this.currentUser.load()
            .then(() => {
              this.afterUserLoaded(...arguments);
              resolve();
            })
            .catch((e) => {
              if (!localStorage.getItem('login-error')) {
                if (e.string === this.intl.t('errors.user-inactive').string) {
                  localStorage.setItem('login-error', e.message ? e.message : JSON.stringify(e));
                } else {
                  localStorage.setItem('login-error', this.intl.t('errors.not-authenticated-vhi'));
                }
              }
              this.session.invalidate();
              this.transitionTo('login');
              reject(e);
            });
        })
        .catch((e) => {
          this.session.invalidate();
          this.transitionTo('login');
          reject(e);
        });
    }, 'Load Tenant and Current User');
    if (superPromise && superPromise.then) {
      return superPromise
        .then(() => {
          return currentPromise;
        });
    } else {
      return currentPromise;
    }
  },

  /**
   * NOTE: if you need to run some logic after the vhiUser is loaded,
   * then override this function to put the logic you need in here.
   *
   * The arguments of this function will be the beforeModel() ones
   * (aka the transition object, etc.)
    */
  afterUserLoaded() {
    // does nothing unless overridden
  }
});
