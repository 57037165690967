import ApplicationSerializer from './application';

let currentId = 0;
export default ApplicationSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (!payload) {
      payload = {};
    }
    let newPayload = payload;
    if (!payload.data) {
      newPayload = {
        data: [],
        included: []
      };
      for (var index in payload) {
        payload[index].forEach(item => {
          let newItem = {
            id: currentId++,
            type: payload._type ? payload._type : primaryModelClass.modelName,
            attributes: {
              timestamp: item[0],
              value: item[1]
            },
            relationships: {
              register: { data: { type: 'register', id: index } }
            }
          };

          newPayload.data.push(newItem);
        });
      }
    }
    return this._super(store, primaryModelClass, newPayload, id, requestType);
  }
});
