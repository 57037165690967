import Mixin from '@ember/object/mixin';
import { ALERT_STATE_UPDATED } from '../services/analytics';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default Mixin.create({
  intl: service(),
  analytics: service(),
  store: service(),
  report: service(),
  subscription: service(),
  init() {
    this._super(...arguments);
  },

  viewEvent() {
    if (!(this.subscription.viewedState && this.subscription.newState)) {
      //wait until the props are loaded
      if (!this.isDestroyed && !this.isDetroying) {
        later(this, this.viewEvent, 1000);
      }
      return;
    }
    let status = this.get('model.currentStatus');
    if (status.get('status.id') === this.subscription.newState.id) {
      this.trackStateFilter('viewed');
      status.set('status', this.subscription.viewedState);
      status.save();
      this.removeFromList(this.model);
    }
  },

  trackStateFilter(state) {
    this.analytics.trackMultiple(ALERT_STATE_UPDATED, {
      state: state,
      subscriptionId: this.get('model.subscription.id'),
      subscriptionName: this.get('model.subscription.name'),
    });
  },

  sendStatus(msg, state) {
    this.status(msg, state);
  },

  actions: {
    addToReport() {
      this.viewEvent();
      let items = this.get('model.items');
      if (items.get('length') <= 0) {
        items = this.eventItems;
      }
      const item_contents = [];
      items.forEach(item => {
        item_contents.push(item.content);
      })
      this.report.addImagesToReport(item_contents)
        .then(() => {
          this.sendStatus(this.intl.t('alerts.add-to-report-success'), 'success')
        })
        .catch(() => {
          this.sendStatus(this.intl.t('alerts.failed-report'), 'error');
        });
    },

    flagEvent() {
      if (!this.subscription.flaggedState) {
        throw 'Flagged Event State not loaded while trying to flag event.';
      }
      let status = this.get('model.currentStatus');
      this.trackStateFilter('flagged');
      status.set('status', this.subscription.flaggedState);
      status.save();
      this.removeFromList(this.model);
    },
    archiveEvent() {
      if (!this.subscription.archivedState) {
        throw 'Archive Event State not loaded while trying to flag event.';
      }
      let status = this.get('model.currentStatus');
      this.trackStateFilter('archived');
      status.set('status', this.subscription.archivedState);
      status.save();
      this.removeFromList(this.model);
    },
    undo() {
      if (!this.subscription.viewedState) {
        throw 'Flagged Event State not loaded while trying to flag event.';
      }
      let status = this.get('model.currentStatus');
      this.trackStateFilter('viewed');
      status.set('status', this.subscription.viewedState);
      status.save();
      this.removeFromList(this.model);
    },
    openInReviewStream() {
      let endDatetime = moment(this.get('model.lastTimestamp'))
        .add(1, 'minute');
      let startDatetime = moment(this.get('model.firstTimestamp'))
        .subtract(1, 'minute');
      this.viewEvent();

      if (this.get('model.eventContentType') !== 'datapointvalue') {
        // We might have images or errors, but no datapointvalues
        const channels = [];
        let siteId = '';
        const eventObjItems = this.get('model.items');
        const eventItems =
          eventObjItems.length === 0
            ? this.get('eventItems')
            : eventObjItems;

        eventItems.forEach(it => {
          //images are weird
          if (it.get('content.channelId')) {
            channels.push(it.get('content.channelId'));
          } else if (it.get('content.channel.id')) {
            channels.push(it.get('content.channel.id'));
          }
        });
        if (this.model.get('site.id')) {
          siteId = this.model.get('site.id');
        }
        var uniqueChannels = [...new Set(channels)];

        this.openImageInReviewStream(
          siteId,
          endDatetime.format(),
          uniqueChannels
        );
      } else {
        endDatetime = endDatetime.add(4, 'hours');
        startDatetime = startDatetime.subtract(4, 'hours');

        this.openDataLoggerInReviewStream(
          this.get('model.items.firstObject.content.register.data_logger.site.id'),
          this.get('model.items.firstObject.content.register.data_logger.id'),
          endDatetime.format(),
          startDatetime.format()
        );
      }

    }
  }
});
