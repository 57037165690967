import MaterialCard from '../common/md-card';
import { computed } from '@ember/object';
import groupBy from 'ember-group-by';

export default MaterialCard.extend({
  classNames: ['site-picker_site-card'],
  classNameBindings: ['isActive:is-selected:'],
  site: null,
  selectedChannelIDs: null,
  siteFiltersByChannel: groupBy('selectedChannelIDs.[]', 'channel.id'),
  selectedChannelLength: computed('siteFiltersByChannel.[]', function() {
    return this.siteFiltersByChannel.length;
  }),
  hasSelection: computed('selectedChannelIDs.[]', function() {
    return this.selectedChannelIDs ? this.selectedChannelIDs.length > 0 : false;
  }),
  isActive: false,
  click() {
    this.onClick();
  }
});
