import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['inspections-board-stepper'],
  index: -1,

  isMinimum: computed('index', function() {
    return this.get('index') <= 0;
  }),
  total: computed('model', function() {
    return this.get('model.total');
  }),
  isMaximum: computed('index', function() {
    return this.get('index') === this.get('total') - 1;
  }),

  actions: {
    clickPrevious() {
      if (this.index < 1) return;
      this.selectIndex(this.get('index') - 1);
    },

    clickNext() {
      if (this.index >= this.model.length - 1) return;
      this.selectIndex(this.get('index') + 1);
    },
  }
});
