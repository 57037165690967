import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  email: DS.attr(),
  firstName: DS.attr(),
  lastName: DS.attr(),
  username: DS.attr(),
  displayName: computed('firstName', 'username', function() {
    return this.firstName ? this.firstName : this.username;
  }),
  fullDisplayName: computed('firstName', 'lastName', 'username', function() {
    return `${this.firstName} ${this.lastName} (${this.username})`;
  }),
});
