import Component from '@ember/component';

export default Component.extend({
  tagName: 'div',
  prefix: 'fas',
  model: null,
  onDownload: null,
  enabled: false,
  iconCls: 'download-pdf-icon',
  classNames: ['download-status-button'],
  actions: {
    onDownload() {
      this.onDownload();
    }
  }
});
