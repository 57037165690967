import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';

// NOTE: the table content can fit 11 rows on the display with appropriate margin-bottom
// However, due to using infinite scroll we need 11 + 1 rows (aka 12 rows loaded) so that we\
// do not immediately trigger another fetch to the backend after the page is loaded.
const PAGE_SIZE = 12;

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    infinity: service(),
    featureFlags: service(),
    tenant: service(),

    titleToken: t('navbar.inspections'),

    async model() {
      if (!(this.featureFlags.isInspectionsEnabled && this.tenant.isInspectionsEnabled)) {
        this.transitionTo('index');
        return;
      }

      return this.infinity.model('subscription/subscription',
        {
          perPage: PAGE_SIZE,
          perPageParam: 'page_size',
          pageParam: 'page_number',
          totalPagesParam: 'meta.pagination.pages',
          context: 'inspection',
        });
    },

    setupController() {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.inspections'),
          link: 'inspections'
        },
        {
          name: this.intl.t('inspections.manage')
        }
      ]);

      this._super(...arguments);
    },
  },
);
