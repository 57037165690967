import Component from '@ember/component';
import {inject as service} from '@ember/service';
import {computed} from '@ember/object';
import { filter } from '@ember/object/computed';
import moment from 'moment';

export default Component.extend({
  store: service(),
  channels: null,
  sites: null,
  cloudbridges: null,
  currentTimezone: moment.tz.guess(),
  listDateFormat: 'MMM DD, YYYY - hh:mm A z',
  offlineCameras: filter('channels', function(camera) {
    return camera.isOffline;
  }),
  alertCameras: filter('channels', function(camera) {
    return camera.deployState === 'ALERT';
  }),
  alertsPresent: computed('alertCameras', function() {
    return this.alertCameras && this.alertCameras.length > 0;
  }),
  offlineCloudbridges: filter('cloudbridges', function(cb) {
    return !cb.active
  }),
  alertsCloudbridgePresent: computed('offlineCloudbridges', function() {
    return this.offlineCloudbridges && this.offlineCloudbridges.length > 0;
  }),
});
