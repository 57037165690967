import Service, { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';
import { hash } from 'rsvp';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

export default Service.extend({
  store: service(),
  site: null,
  channels: null,
  tagTypeGroups: null,
  tagTypes: null,
  currentPage: DEFAULT_PAGE,
  currentPageSize: DEFAULT_PAGE_SIZE,

  initializeSite(site) {
    this.set('currentPage', DEFAULT_PAGE);
    this.set('currentPageSize', DEFAULT_PAGE_SIZE);
    this.set('channels', site.channels);
    this.set('site', site);
    this.set('channelsLoaded', true);
  },

  loadSite(siteId, page, pageSize, query, afterLoadFn) {
    if (page) {
      this.set('currentPage', page);
    }
    if (pageSize) {
      this.set('currentPageSize', pageSize)
    }
    return new Promise((resolve, reject) => {

      try {

        hash({
          site: this.store.findRecord('site', siteId),
          tagTypeGroups: this.store.findAll('tag-type-group'),
          tagTypes: this.store.findAll('tag-type'),
          channels: this.store.query('channel', Object.assign({
            page: this.currentPage,
            site: siteId,
            page_size: this.currentPageSize,
            include: 'latest_image'
          }, this.prepareQuery(query)))
        })
          .then((hash) => {
            this.set('channels', hash.channels);
            this.set('site', hash.site);
            this.set('tagTypes', hash.tagTypes);
            this.set('tagTypeGroups', hash.tagTypeGroups);
            this.set('channelsLoaded', true);
            if (typeOf(afterLoadFn) === 'function') {
              afterLoadFn.apply(this)
            }
            resolve(hash.site);
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  loadChannels(model, page, pageSize, query, afterLoadFn) {
    if (page) {
      this.set('currentPage', page);
    }
    if (pageSize) {
      this.set('currentPageSize', pageSize)
    }
    return new Promise((resolve, reject) => {
      try {
        return this.store.query('channel', Object.assign({
          page: this.currentPage,
          site: model.id,
          page_size: this.currentPageSize,
          include: 'latest_image', // we need to also include ptzPresets.latestImage
          order: 'name'
        }, this.prepareQuery(query)))
          .then((query) => {
            this.set('channels', query);
            this.set('site', model);
            this.set('channelsLoaded', true);
            if (typeOf(afterLoadFn) === 'function') {
              afterLoadFn.apply(this)
            }
            resolve(model);
          })
          .catch(error => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * Checks if query is object and return otherwise
   * return empty object
   * @method prepareQuery
   * @param {(Object|none)} query params
   * @return {Object} query params object or empty object
   */
  prepareQuery(query) {
    return typeOf(query) === 'object' ? query : {};
  }
});
