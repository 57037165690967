import {Factory, faker} from 'ember-cli-mirage';

export default Factory.extend({
  name() {
    return faker.random.word();
  },

  lsd: 'x/y',
  centroid() {
    return {
      lat: faker.address.latitude(),
      lng: faker.address.longitude()
    };
  },
  locationPolygons: null,
  siteType() {
    return [4];
  },
  channels() {
    return [
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid()
    ];
  },
  dataloggers() {
    return [];
  },
  cloudbridges() {
    return [];
  },
  mute: false,
  maintenanceMode: false
});
