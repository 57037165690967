import ApplicationAdapter from './application';
import ENV from '../config/environment';
import Ember from 'ember';
import { inject as service } from '@ember/service';

const VIDEO_ENDPOINT = 'videos';
const CLOUDBRIDGE_ENDPOINT = 'cloudbridges';
const CLOUDBRIDGE_FETCH_VIDEO = 'fetch-video';

export default ApplicationAdapter.extend({
  websockets: service(),
  session: service(),
  vhiProtocol: ENV.vhiProtocol,
  vhiWsProtocol: ENV.vhiWsProtocol,

  pathForType() {
    return VIDEO_ENDPOINT;
  },
  buildURL(modelName, id, snapshot, requestType, query, socket) {
    let path;
    let url = [];
    let host = this.host;
    let prefix = this.urlPrefix();
    if (socket) {
      prefix = prefix.replace(this.vhiProtocol, this.vhiWsProtocol);
      prefix = prefix.replace('/api', '');
    } else {
      if (query.cloudbridge) {
        path = CLOUDBRIDGE_ENDPOINT;
        url.push(path);
        url.push(query.id);
        url.push(CLOUDBRIDGE_FETCH_VIDEO);
      } else {
        path = VIDEO_ENDPOINT;
        url.push(path);
      }
    }

    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    return url + '/';
  },

  query(store, type, query) {
    //remove null values from query
    Object.keys(query).forEach((key) => (query[key] == null) && delete query[key]);
    let url = this.buildURL(type.modelName, null, null, 'query', query);

    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }
    if (query.cloudbridge) {
      delete query.cloudbridge;
      return this.ajax(url, 'POST', { data: query, traditional: true })
        .then((cbResponse) => {
          //we can't mock websockets from mirage
          if (Ember.testing) {
            return cbResponse;
          }
          return this.loadFromWebsocket(cbResponse.socket, query);
        });
    } else {
      return this.ajax(url, 'GET', { data: query, traditional: true });
    }
  },

  loadFromWebsocket(socketPath, query) {
    return new Promise ((resolve, reject) => {
      function onMessage(message) {
        const messageData = JSON.parse(message.data);
        if (messageData.status) {
          if (messageData.status === 'failed') {
            reject(messageData.message);
          }
        } else {
          socket.close();
          resolve(messageData);
        }
      }

      if (!socketPath) {
        return null;
      }
      if (socketPath.startsWith('/')) {
        socketPath = socketPath.substr(1);
      }
      let url = this.buildURL('video', null, null, 'query', query, true);

      let { access_token } = this.get('session.data.authenticated');
      url += `${socketPath}?access_token=${access_token}`;

      const socket = this.websockets.socketFor(url);
      socket.on('message', onMessage, this);
    });
  },

});
