/**
 * Commonly used UI constants that are used throughout the front-end are gathered here.
 */
export default {
  // general date format
  UI_DATE_FORMAT: 'MMM DD, YYYY - hh:mm A z',

  // For camera times, we need to include the seconds
  CAMERA_DATE_FORMAT: 'MMM DD, YYYY - hh:mm:ss A z',
  CAMERA_DATE_ONLY_FORMAT: 'MMM DD, YYYY',
  CAMERA_TIME_ONLY_FORMAT: 'hh:mm:ss A z',

  // Required for mobile JS behaviors to be triggered when in mobile mode (mobile mode as determined by angular material)
  REACH_MEDIA_BREAKPOINT_DOWN_XS_THRESHOLD: '(max-width: 599px)',
  LANDSCAPE_AND_LOW_HEIGHT: '(orientation: landscape) and (max-height: 450px)',

  // required for reach-snapshot-dialog (which is still a bootstrap dialog).
  // when going into mobile mode, the CSS for reach-snapshot-dialog doesn't look good. So we disable this dialog for now.
  BOOTSTRAP_MOBILE_BREAKPOINT_DOWN: '(max-width: 991.98px)',

  /**
   * Ember Paper responsive dialog uses angular-material breakpoints to determine when to become fullscreen
   * see sass variable ($layout-breakpoint-sm -1) defined here: node_modules/angular-material-styles/src/core/services/layout/layout-attributes.scss
   * see breakpoint definition here: node_modules/angular-material-styles/src/components/dialog/dialog.scss
   */
  PAPER_DIALOG_FULLSCREEN_BREAKPOINT: '(max-width: 959px)',

  // for consistency all date pickers should use the same date format (which is same as query string format)
  DATE_FORMAT_DATEPICKER: 'yyyy-mm-dd'
};
