import Component from '@ember/component';
import { computed } from '@ember/object';
import groupBy from 'ember-group-by';

export default Component.extend({
  classNames: ['site-filter-card_sites-filter-list'],
  subscriptionFilters: null,
  highlightedFilters: null,
  showDeleteDialog: false,
  filtersBySite: groupBy('subscriptionFilters', 'site.id'),
  lengthOfCurrentlyHighlightedFilters: computed(
    'highlightedFilters.[]',
    function() {
      return this.highlightedFilters.length;
    }
  ),
  isAnyChannelsSelected: computed(
    'lengthOfCurrentlyHighlightedFilters',
    function() {
      return this.lengthOfCurrentlyHighlightedFilters > 0;
    }
  ),
  isAllChannelsSelected: computed(
    'highlightedFilters.[]',
    'subscriptionFilters.[]',
    function() {
      return (
        this.lengthOfCurrentlyHighlightedFilters ===
        this.subscriptionFilters.length
      );
    }
  ),
  selectAllText: computed(
    'subscriptionFilters.[]',
    'highlightedFilters.[]',
    function() {
      return this.isAnyChannelsSelected
        ? `(${this.lengthOfCurrentlyHighlightedFilters}/${
          this.subscriptionFilters.length
        })`
        : `All (${this.subscriptionFilters.length})`;
    }
  ),
  init() {
    this._super(...arguments);
    this.subscriptionFilters = this.subscriptionFilters || [];
    this.highlightedFilters = this.highlightedFilters || [];
  },
  actions: {
    toggleChannelFilterDeleteDialog() {
      this.set('showDeleteDialog', !this.showDeleteDialog);
    },
    removeFilters(filters) {
      this.removeFilters(filters);
    },
    onAddSitesClick() {
      this.onAddSitesClick();
    },
    toggleFilterTag(filter, tag) {
      this.toggleFilterTag(filter, tag);
    },
    highlightFilter(filter, addToList) {
      this.highlightFilter(filter, addToList);
    },
    bulkHighlightFilters(filters, addToList) {
      this.bulkHighlightFilters(filters, addToList);
    },
    highlightAllFiltersOnSite() {
      const addToList = this.highlightedFilters.length === 0;
      let sitesToAdd = [];
      if (Array.isArray(this.subscriptionFilters)) {
        sitesToAdd = this.subscriptionFilters;
      } else if (
        this.subscriptionFilters &&
        Array.isArray(this.subscriptionFilters._objects)
      ) {
        sitesToAdd = this.subscriptionFilters._objects;
      }
      this.bulkHighlightFilters(sitesToAdd, addToList);
    }
  }
});
