import Mixin from '@ember/object/mixin';

export default Mixin.create({
  setupController() {
    this._super(...arguments);
    this.controllerFor('application').set('breadcrumbs', this.breadcrumbs);
    this.controllerFor('application').set('breadcrumbButtons', this.breadcrumbButtons);
  },
  activate() {
    this._super(...arguments);
    this.controllerFor('application').set('breadcrumbs', this.breadcrumbs);
    this.controllerFor('application').set('breadcrumbButtons', this.breadcrumbButtons);
  },
  deactivate() {
    this._super(...arguments);
    this.controllerFor('application').set('breadcrumbs', null);
    this.controllerFor('application').set('breadcrumbButtons', null);
  },
});
