import {BaseSubscriptionSerializer} from '../../mixins/subscription/serializer';

export default BaseSubscriptionSerializer.extend({
  serializeAttribute(snapshot, json, key) {
    if (key === 'rawQueryDoc') {
      return;
    }
    if (snapshot.attr(key) != null) {
      this._super(...arguments);
    }
  }
});
