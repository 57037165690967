import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['ptz-preview'],
  store: service(),
  reloadPreview() {
    if (this.view.channel) {
      this.store
        .query('image', {
          channel: this.get('view.channel.id'),
          preset: this.view.id,
          limit: 1,
          page_size: 10
        })
        .then(result => this.set('ptzPreview', result.objectAt(0)))
        .catch(() => this.set('ptzPreview', null));
    }
  },
  didInsertElement() {
    this._super(...arguments);
    this.reloadPreview();
  },
  watchPreview: observer('view', function() {
    this.reloadPreview();
  }),
  click() {
    this.selectPTZ(this.view);
  }
});
