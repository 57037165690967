import EmberRouter from '@ember/routing/router';
import config from './config/environment';
import MpPageView from './mixins/reach-mixpanel';
import RouterScroll from 'ember-router-scroll';

const Router = EmberRouter.extend(MpPageView, RouterScroll, {
  location: config.locationType,
  rootURL: config.rootURL
});

Router.map(function() {
  this.route('sites');
  this.route('site', { path: '/site/:site_id'}, function() {
    this.route('review');
    this.route('events');
    this.route('dataloggers');
    this.route('datalogger', {path: '/datalogger/:datalogger_id'});
    this.route('error', {path: '/error/:id'});
  });
  this.route('login');
  this.route('freestyle');
  this.route('map');
  this.route('reports');
  this.route('alerts');
  this.route('feedback');
  this.route('contact');
  this.route('logout');
  this.route('profile');
  this.route('forgot-password');
  this.route('loading');

  this.route('error', {path: '/error'}, function() {
    this.route('random', {path: ':id'});
  });

  this.route('report',  function() {
    this.route('index', { path: '/:report_id'});
    this.route('create');
    this.route('edit', { path: '/edit/:report_id'});
  });

  this.route('alert', function() {
    this.route('index', { path: '/:alert_id'});
    this.route('manage');
    this.route('edit', { path: '/:subscription_id/edit'});
    this.route('create');
  });

  this.route('legacy_alert', { 'path': '/alerts/:subscription_id/detail/:alert_id'});
  this.route('alert.error');
  this.route('404', { path: '/*path' });
  this.route('alerts-loading');
  this.route('site-error');
  this.route('report-error');
  this.route('cognito-redirect');
  this.route('inspections');

  this.route('inspection', function() {
    this.route('index', { path: '/:inspection_id'});
    this.route('edit', { path: '/:inspection_id/edit'});
    this.route('create');
    this.route('manage');
    this.route('loading');
  });

  this.route('customer-settings');
  this.route('operations');
  this.route('admin-dashboard');
  this.route('user-admin');
  this.route('user-sites-edit', { path: '/user-admin/:user_id'});
  this.route('dashboard');
  this.route('site-admin');
});

export default Router;
