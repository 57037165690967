import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';
import Ember from 'ember';

const hasDirty = item => item.hasDirtyAttributes;
export default Route.extend(AuthenticatedRouteMixin,
  TenantRouteMixin,
  ConfirmationMixin,
  {
    currentUser: service(),
    can: service(),
    isSaving: false,
    confirmationMessage() {
      return this.intl.t('user-sites-admin.confirm-discard-changes');
    },
    isPageDirty(model) {
      if (Ember.testing) {
        return false;
      }
      return model.hasDirtyAttributes || model.sites.any(hasDirty);
    },
    allUsers: service(),
    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', false);
    },
    model(params) {
      //if the user does not have permissions to edit user permission, send them away
      if (this.can.cannot('siteAccess user', this.currentUser)) {
        this.transitionTo('index');
        return;
      }
      return this.store.findRecord(
        'site-user',
        params.user_id
      );
    }
  });
