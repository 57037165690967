import DS from 'ember-data';
//https://echo.ospreystaging.com/api/channels/180112-ch-886e/actions/?page_size=1&verb=enabled+maintenance+mode
export default DS.Model.extend({
  actionObject: DS.attr(), //{displayName: "Thermal Test @ Nakusp", id: 14, objectType: "channel"}
  actor: DS.attr(), //{displayName: "rbezemer", id: 9, objectType: "user"}
  description: DS.attr(),
  target: DS.attr(),
  timestamp: DS.attr(),
  dataDuration: DS.attr(),
  verb: DS.attr(),//"enabled maintenance mode"
});
