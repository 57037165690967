import ApplicationSerializer from './application';
import { CustomRelationshipKeyMixin } from '../mixins/subscription/serializer';

const RELATIONSHIP_ALIASES = {
  tag_types: 'tag-types'
};

export default ApplicationSerializer.extend(CustomRelationshipKeyMixin, {
  relationship_key_aliases: RELATIONSHIP_ALIASES,
});
