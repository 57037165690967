import Component from '@ember/component';

export default Component.extend({
  classNames: ['site-picker-pagination-button_container'],
  paginationText: null,
  actions: {
    getPrevPage() {
      this.getPrevPage()
    },
    getNextPage() {
      this.getNextPage()
    }
  }
});
