import { inject as service } from '@ember/service';
import InfinityModel from 'ember-infinity/lib/infinity-model';

/**
 * NOTE: we don't use hideOnInfinity=true because https://github.com/ember-infinity/ember-infinity/issues/395
 * Instead wrap your infinity loader in an #unless model.reachedInfinity as per issue 395
 * example:
 * {{#unless model.reachedInfinity}}
 *   {{#infinity-loader infinityModel=model}}
 *   {{/infinity-loader}}
 * {{/unless}}
 */

/**
 * This function will make a new class of ExtendedInfinityModel.
 * @param dataStoreType a string representing the model data type of what to scroll (example: 'subscription/subscription')
 * @param props an options object containing properties to be merged into the new class (example: { extra: 'my-prop' })
 * @returns {*} a new class (generated from calling EmberObject.extend())
 */
export default function(dataStoreType, props) {
  const options = Object.assign({}, { dataStoreType }, props);

  return InfinityModel.extend({
    store: service(),
    infinity: service(),

    total: 0,
    theModel: undefined,

    afterInfinityModel(records, infinityModel) {
      this.set('total', records.meta && records.meta.pagination ? records.meta.pagination.count : records.length);

      /**
       * NOTE: we need to use this hook ('afterInfinityModel') so that we can capture a reference to the model object
       * loaded.  This is necessary because the replace() function called in loadAll() must have the model reference
       * in order to replace the model with a new listing.
       */
      this.set('theModel', infinityModel);
    },

    async loadAll({ queryParams = {}} = {}) {
      // Lets use the total as the page_size and read everything at once
      const allRecords = await this.store.query(this.get('dataStoreType'), {
        page_number: 1,
        page_size: this.get('total'),
        ...queryParams
      });

      // use the infinity-service to replace the model with a list of everything.
      this.get('infinity')
        .replace(this.get('theModel'), allRecords);

      // nothing else to load
      this.set('canLoadMore', false);
      // don't show loader anymore
      this.set('reachedInfinity', true);
    },
  }, options);
}
