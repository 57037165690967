import { Factory, faker, association } from 'ember-cli-mirage';

export default Factory.extend({
  name() {
    return faker.random.word();
  },
  timezone() {
    faker.random.locale();
  },
  latestImage: association({
    resizableImageUrl: '/assets/images/osprey-icon.png'
  }),
  lsd: 'x/y',
  centroid() {
    return {
      lat: faker.address.latitude(),
      lng: faker.address.longitude()
    };
  },
  locationPolygons: null,
  siteType() {
    return [4];
  },
  channels() {
    return [
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid(),
      faker.random.uuid()
    ];
  },
  dataloggers() {
    return [];
  },
  cloudbridges() {
    return [];
  },
  mute: false,
  maintenanceMode: false
});
