import Component from '@ember/component';

export default Component.extend({
  classNames: ['form-page_top-bar'],
  actions: {
    saveForm() {
      this.saveForm()
    },
    resetDirtyForm() {
      this.resetDirtyForm()
    },
  }
});
