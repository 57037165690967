import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import moment from 'moment';
import constants from '../utils/ui-constants';
import { INSPECTION_LIST_PAGE_FILTERED } from '../services/analytics';

const { DATE_FORMAT_DATEPICKER } = constants;
const MINUTES_PER_DAY_MINUS_ONE = 1439;

export default Controller.extend({
  analytics: service(),
  featureFlags: service(),
  store: service(),
  intl: service(),
  errors: null,
  showFilters: false,
  queryParams: ['subscription', 'before_date'],
  subscription: null,
  _subscription: null,
  before_date: null,
  subscriptions: null,
  datePickerFormat: DATE_FORMAT_DATEPICKER,
  hasSubscriptionFilter: computed('_subscription', function() {
    return this._subscription !== null;
  }),
  hasDateFilter: computed('before_date', function() {
    return this.before_date !== null;
  }),
  filterCount: computed('hasSubscriptionFilter', 'hasDateFilter', function() {
    return (this.hasSubscriptionFilter ? 1 : 0)
      + (this.hasDateFilter ? 1 : 0);
  }),
  hasFilters: computed('filterCount', function() {
    return this.filterCount > 0;
  }),
  watch_subscription: observer('_subscription', function() {
    this.set('subscription', this.get('_subscription.id'));
  }),
  init() {
    this._super(...arguments);
    this.errors = this.errors || [];
  },
  makeUnknownError() {
    return {
      title: this.intl.t('errors.unknown-error-title'),
      values: null,
      resolve: this.intl.t('errors.unknown-error-resolve')
    };
  },
  actions: {
    assignInspection() {
      this.get('errors')
        .pushObject(this.makeUnknownError());

    },
    unassignInspection() {
      this.get('errors')
        .pushObject(this.makeUnknownError());
    },
    clearErrors() {
      this.set('errors', []);
    },
    openFilters() {
      this.toggleProperty('showFilters');
    },
    hidePopup() {
      this.set('showFilters', false);
    },
    clearFilters() {
      this.set('_subscription', null);
      this.set('before_date', null);
    },
    subscriptionChanged(value) {
      this.set('_subscription', value);

      if (value) {
        this.analytics.trackMultiple(INSPECTION_LIST_PAGE_FILTERED, {
          filterValue: value.id,
          by: 'subscription'
        });
      }
    },
    dateChanged(date) {
      let isoDate = moment(date)
        .add(MINUTES_PER_DAY_MINUS_ONE, 'minutes')
        .toISOString();
      this.set('before_date', isoDate);

      if (date) {
        this.analytics.trackMultiple(INSPECTION_LIST_PAGE_FILTERED, {
          filterValue: isoDate,
          by: 'date'
        });
      }
    },
    customParser (value) {
      return value ? moment(value).toDate() : undefined;
    }
  }
});
