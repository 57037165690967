import Route from '@ember/routing/route';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';

export default Route.extend(UnauthenticatedRouteMixin, {
  activate() {
    this._super(...arguments);
    this.controllerFor('application').set('hideNavBarRoute', true);
  },

  deactivate() {
    this._super(...arguments);
    this.controllerFor('application').set('hideNavBarRoute', false);
  },
});
