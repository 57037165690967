import { helper } from '@ember/component/helper';

export function arrayContainsString(params /*, hash*/) {
  if (!params[0] || !params[1]) {
    return false;
  }
  const index = params[0].indexOf(params[1]);
  return index !== -1;
}

export default helper(arrayContainsString);
