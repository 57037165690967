import ApplicationSerializer from '../application';

const ALERT_RELATIONSHIPS = [
  'subscription',
  'event',
]
export default ApplicationSerializer.extend({
  getRelationships() {
    return ALERT_RELATIONSHIPS;
  },
});
