import Component from '@ember/component';

export default Component.extend({
  classNames: ['manage-subscriptions-list_header-row-item'],
  actions: {
    doSomething () {
      if (this.enableSorting && this.get('actionName')) {
        this.get('actionName')();
      }
    }
  }
});
