import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  currentUser: service(),
  navState: 'show-title',
  showSearchBarMobile: false,
  actions: {
    navigateToSite(site) {
      this.transitionToRoute('site', site);
    },
    
  }

});
