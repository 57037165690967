import moment from 'moment';
import Ember from 'ember';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';

const DEFAULT_ALERT_RECURRENCE = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
const DEFAULT_ALERT_START = '06:00 pm';
const DEFAULT_ALERT_END = '11:59 pm';

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  ConfirmationMixin,
  {
    intl: service(),
    allUsers: service(),
    confirmationMessage() {
      return this.intl.t('alerts.confirm-discard-changes');
    },
    isPageDirty(model) {
      if (Ember.testing || this.controller.cancelClicked) {
        return false;
      }
      return model.hasDirtyRelationships(model);
    },
    beforeModel() {
      this.get('allUsers').loadAllUsers();
      return this._super(...arguments);
    },
    model() {
      const newSubscription = this.store.createRecord(
        'subscription/subscription',
        {
          enabled: true,
          creationInProgress: false
        }
      );
      const creator = this.store.createRecord('subscription/subscriber', {
        user: this.currentUser.vhiUser,
        allowEdit: true,
        subscription: newSubscription,
        creator: true
      });
      newSubscription.subscribers.addObject(creator);
      const newSchedule = this.store.createRecord('subscription/schedule', {
        recurrence: DEFAULT_ALERT_RECURRENCE,
        start: DEFAULT_ALERT_START,
        end: DEFAULT_ALERT_END,
        timezone: moment.tz.guess(),
        subscription: newSubscription,
      });
      newSubscription.schedules.pushObject(newSchedule);
      this.store.findAll('subscription/notification-method-type')
        .then(types => {
          types.forEach(type => {
            if (type.visible) {
              let method = this.store.createRecord(
                'subscription/notification-method',
                {
                  enabled: true,
                  subscriber: creator,
                  notificationMethodType: type
                }
              );
              creator.notificationMethods.pushObject(method);
            }
          });
        });
      return new Promise(resolve => {
        resolve(newSubscription);
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set(
        'notificationTypes',
        this.store.findAll('subscription/notification-method-type')
      );
    },

    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controller.set('showSitePicker', false);
      this.controller.set('errors', []);
      this.controller.set('isSaving', false);
      this.controllerFor('application')
        .set('hideNavBarRoute', false);
    },

    resetController(controller, isExiting) {
      this.controller.cancelClicked = false;
      if (!isExiting) return;
      // WARNING: rollbackAttributes() will remove the model from the store,
      // which will mutate relationships as we loop over them.
      // To avoid looping on a mutating list, make a copy of the array first,
      // and then loop over the copy to delete the records.
      const model = controller.get('model');
      let toDelete = model.subscriptionFilters.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.subscribers.toArray();
      toDelete.forEach(sub => {
        const notificationMethods = sub.notificationMethods.toArray();
        notificationMethods.forEach(nm => nm.rollbackAttributes())
      });
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.schedules.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      model.rollbackAttributes();
    },
  }
);
