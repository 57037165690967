define("reach-web-client/components/ember-scrollbar", ["exports", "ember-scrollable/components/ember-scrollbar"], function (_exports, _emberScrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberScrollbar.default;
    }
  });
});
