import LocalStorageSerializer from './local-storage';

export default LocalStorageSerializer.extend({
  /**
   * If saving to local storage it serialize it as jsonapi format
   * otherwise it will convert it to the format needed by the legacy vhi api
   **/
  serialize(snapshot, options) {
    if ((snapshot.adapterOptions && snapshot.adapterOptions.localStorage) || (options && options.localStorage)) {
      return this._super(...arguments);
    }
    let json = {
      id: snapshot.id,
      type: snapshot.reportType,
      segments: []
    }
    let includeId = options ? options.includeId : null;
    snapshot.eachAttribute((key, attribute) => {
      this.serializeAttribute(snapshot, json, key, attribute);
    });

    snapshot.record.get('segments').forEach(seg => {
      let segmentJson = seg.serialize({localStorage: false});
      segmentJson.id = includeId ? segmentJson.id : null;
      json.segments.push(segmentJson);
    });
    let reportTypeId = snapshot.record.get('reportType.id');
    json.type = reportTypeId;

    delete json.local_storage;

    return json;
  },
  normalizeResponse(modelName, item, payload, id, requestType) {
    let newPayload = payload;
    if (newPayload.data && newPayload.included) {
      newPayload.included.forEach(item => {
        if (item) {
          item.attributes = {
            images: item.images,
            start_time: item.start_time,
            notes: item.notes,
          }
          delete item.images;
          delete item.start_time;
          delete item.notes;
        }
      })
    }
    return this._super(modelName, item, newPayload, id, requestType);
  },
  /**
   * as of ember 3.8 upgrade ember data will not serialize any segments that are
   * not previously individually saved, which of course makes sense, except VHI doesn't
   * support that workflow, so overriding the default hasMany serialization behavior
   **/
  serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
    var key = relationship.key;

    if (this.shouldSerializeHasMany(snapshot, key, relationship)) {
      var hasMany = snapshot.hasMany(key);

      if (hasMany !== undefined) {
        json.relationships = json.relationships || {};

        var payloadKey = this._getMappedKey(key, snapshot.type);

        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
        }


        var data = new Array(hasMany.length);

        for (var i = 0; i < hasMany.length; i++) {
          var item = hasMany[i];
          var payloadType = this.payloadKeyFromModelName(item.modelName);
          data[i] = {
            type: payloadType,
            id: item.id
          };
        }

        json.relationships[payloadKey] = {
          data: data
        };
      }
    }
  }

});
