import Component from '@ember/component';

export default Component.extend({
  classNames: ['inspections_inspections-board-empty'],
  disabled: false,
  actions: {
    toggleShowSitePicker: function() {
      if (!this.disabled) {
        this.toggleShowSitePicker()
      }
    }
  }
});
