define("reach-web-client/macros/group-by-path", ["exports", "ember-cli-group-by/macros/group-by-path"], function (_exports, _groupByPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _groupByPath.default;
    }
  });
});
