import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import constants from '../../../utils/ui-constants';

const { CAMERA_DATE_FORMAT } = constants;

export default Component.extend({
  dateFormat: CAMERA_DATE_FORMAT,
  classNames: 'report-image-card',
  intl: service(),

  contentType: computed('model.content', function() {
    return this.get('model.content._internalModel.modelName');
  }),

  image: computed('contentType', function() {
    return this.get('contentType') === 'image' ? this.get('model.content') : undefined;
  }),

  error: computed('contentType', function() {
    return this.get('contentType') === 'subscription/event-item-error' ? this.get('model.content') : undefined;
  }),

  site: computed('model.content.channel.site', function() {
    return this.get('image') ? this.get('model.content.peekSite') : this.get('model.content.channel.site');
  }),

  channel: computed('model.content.channel', function() {
    return this.get('image') ? this.get('model.content.peekChannel') : this.get('model.content.channel');
  }),

  ptzPreset: computed('model.content.ptzPreset', function() {
    return this.get('model.content.ptzPreset');
  }),

  actions: {
    download() {
      const image = this.get('image');
      return Promise.resolve(
        image.get('download') ? image : this.get('image')
      )
        .then(image => image.download())
        .catch(error => {
          this.setErrors([{ title: this.intl.t('errors.image-download'), values: error.message }]);
        });
    },

    clickImage() {
      this.selectImage(this.get('image'));
    }
  }
});
