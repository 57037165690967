import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
const format = 'hh:mm a';

const isStartOfDay = time => time.includes('12:00') && time.includes('am');
const isEndOfDay = time => time.includes('11:59') && time.includes('pm');

export default Component.extend({
  intl: service(),
  allowEdit: false,
  showDialog: false,
  selectedWeekDays: null,
  weekDays: null,
  init() {
    this._super(...arguments);
    this.weekDays = [
      { day: 'MO', displayText: this.intl.t('weekday.monday-single') },
      { day: 'TU', displayText: this.intl.t('weekday.tuesday-single') },
      { day: 'WE', displayText: this.intl.t('weekday.wednesday-single') },
      { day: 'TH', displayText: this.intl.t('weekday.thursday-single') },
      { day: 'FR', displayText: this.intl.t('weekday.friday-single') },
      { day: 'SA', displayText: this.intl.t('weekday.saturday-single') },
      { day: 'SU', displayText: this.intl.t('weekday.sunday-single') }
    ];
  },
  fromTime: moment()
    .startOf('day')
    .add(18, 'hour')
    .format(format),
  toTime: moment()
    .endOf('day')
    .format('hh:mm:s a'),
  allDay: computed('fromTime', 'toTime', {
    get() {
      if (isStartOfDay(this.fromTime) && isEndOfDay(this.toTime)) {
        return true;
      }
      return false;
    },
    set() {
      this.set(
        'fromTime',
        moment()
          .startOf('day')
          .format(format)
      );
      this.set(
        'toTime',
        moment()
          .endOf('day')
          .format(format)
      );
      return true;
    }

  }),

  fromTimeFormatted: computed('fromTime', function() {
    if (this.fromTime) {
      return moment(this.fromTime, format).format(format);
    }
  }),
  toTimeFormatted: computed('toTime', function() {
    if (this.toTime) {
      return moment(this.toTime, format).format(format);
    }
  }),

  actions: {
    changeFromTime(time) {
      this.set('fromTime', time);
    },
    changeToTime(time) {
      this.set('toTime', time);
    },
    toggleWeekDay(day) {
      const index = this.selectedWeekDays.indexOf(day);
      if (index === -1) {
        this.set('selectedWeekDays', [...this.selectedWeekDays, day]);
      } else {
        const newDays = this.selectedWeekDays.filter(
          weekDay => weekDay !== day
        );
        this.set('selectedWeekDays', newDays);
      }
    },

    removeSchedule(schedule, color) {
      this.removeSchedule(schedule, color);
    }
  }
});
