import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    titleToken: t('navbar.profile'),
    currentUser: service(),
    model() {
      return hash({
        dailyReport: this.store.findAll('subscription/dailyReport').then(result =>{
          if (result.length > 0) {
            return result.get('firstObject')
          } else {
            let daily = this.store.createRecord('subscription/dailyReport', {
              scheduledTime: moment().hours(7)
                .minutes(0)
                .seconds(0)
                .format('hh:mm A'),
              timezone: moment.tz.guess(),
              user: this.currentUser.vhiUser
            })
            daily.save();
            return daily;

          }
        })
      });
    },
    setupController() {
      this.set('breadcrumbs', [{
        name: this.intl.t('navbar.profile')
      }]);

      this._super(...arguments);
    },
  });
