import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { debounce } from '@ember/runloop';
import Ember from 'ember';

const { not } = computed;

const DEFAULT_ZOOM_PERCENTAGE = 100;

const isImageLoaded = (img) => img && img.complete &&
  (typeof img.naturalWidth === 'undefined' || img.naturalWidth !== 0);

const createImageStyle = (width, height, left, top) =>
  htmlSafe(
    `width: ${Math.round(width)}px;` +
    `height: ${Math.round(height)}px;` +
    `left: ${Math.round(left)}px;` +
    `top: ${Math.round(top)}px;`
  );

export default Component.extend({
  classNames: 'image-zoom-card',
  attributeBindings: 'zoomPercentage:zoom',
  zoomPercentage: DEFAULT_ZOOM_PERCENTAGE,
  _scrollLeft: 0,
  _scrollTop: 0,
  dimensions: null,

  // default left value when image is horizontally centered inside the container
  baseLeft: computed('dimensions', 'imgWidth', 'zoomPercentage', function() {
    const dim = this.getDimensions();
    if (!dim) {
      return 0;
    }
    const multiplier = this.get('zoomPercentage') / 100;
    const width = this.get('imgWidth') * multiplier;
    const left = (dim.width - width) / 2;
    this.updateScrollLimit({ left });
    return left;
  }),

  // default top value when image is vertically centered inside the container
  baseTop: computed('dimensions', 'imgHeight', 'zoomPercentage', function() {
    const dim = this.getDimensions();
    if (!dim) {
      return 0;
    }
    const multiplier = this.get('zoomPercentage') / 100;
    const height = this.get('imgHeight') * multiplier;
    const top = (dim.height - height) / 2;
    this.updateScrollLimit({ top });
    return top;
  }),

  // dont allow scrolling to exceed visible part of image
  scrollLeft: computed('baseLeft', 'zoomPercentage', {
    get() {
      if (!this.get('baseLeft') || this.get('baseLeft') >= 0) {
        return 0;
      }
      const value = this.get('_scrollLeft')
      const sign = value < 0 ? -1 : 1;
      const max = Math.abs(this.get('baseLeft'));
      if (Math.abs(value) > max) {
        return sign * max;
      }
      return value;
    },
    set(key, value) {
      if (!this.get('baseLeft') || this.get('baseLeft') >= 0) {
        // set scroll to default if image position unknown or no scrollable space
        this.set('_scrollLeft', 0);
        return 0;
      }

      const sign = value < 0 ? -1 : 1;
      const max = Math.abs(this.get('baseLeft'));
      if (Math.abs(value) > max) {
        this.set('_scrollLeft', sign * max);
        return sign * max;
      }
      this.set('_scrollLeft', value);
      return value;
    }
  }),

  // dont allow scrolling to exceed visible part of image
  scrollTop: computed('baseTop', 'zoomPercentage', {
    get() {
      if (!this.get('baseTop') || this.get('baseTop') >= 0) {
        return 0;
      }
      const value = this.get('_scrollTop')
      const sign = value < 0 ? -1 : 1;
      const max = Math.abs(this.get('baseTop'));
      if (Math.abs(value) > max) {
        return sign * max;
      }
      return value;
    },
    set(key, value) {
      if (!this.get('baseTop') || this.get('baseTop') >= 0) {
        // set scroll to default if image position unknown or no scrollable space
        this.set('_scrollTop', 0);
        return 0;
      }
      const sign = value < 0 ? -1 : 1;
      const max = Math.abs(this.get('baseTop'));
      if (Math.abs(value) > max) {
        this.set('_scrollTop', sign * max);
        return sign * max;
      }
      this.set('_scrollTop', value);
      return value;
    }
  }),

  hasImage: computed('model', function() {
    return this.get('model') && !this.get('model.event_item_error_type.name');
  }),

  modelWatcher: observer('model', function() {
    if (this.get('hasImage')) {
      // re-center new images
      this.resetImage();
    }
  }),

  noImage: not('hasImage'),

  style: computed(
    'imgWidth', 'imgHeight',
    'scrollLeft', 'scrollTop',
    'baseLeft', 'baseTop',
    'zoomPercentage',
    function() {
      const multiplier = this.get('zoomPercentage') / 100;
      const width = this.get('imgWidth') * multiplier;
      const height = this.get('imgHeight') * multiplier;
      const left = this.get('scrollLeft') + this.get('baseLeft');
      const top = this.get('scrollTop') + this.get('baseTop');
      return createImageStyle(width, height, left, top);
    }),

  updateScrollLimit({ left, top }) {
    if (this.scrollLimit) {
      // only if left (or top) is offscreen (aka negative) do we have a scroll limit to apply
      const leftLimit = left < 0 ? Math.abs(left) : 0;
      const topLimit = top < 0 ? Math.abs(top) : 0;

      this.scrollLimit({ left: leftLimit, top: topLimit });
    }
  },

  async getImageSize() {
    const img = this.element.querySelector('img');
    if (isImageLoaded(img)) {
      return { width: img.naturalWidth, height: img.naturalHeight };
    } else {
      return { width: 0, height: 0 };
    }
  },

  getDimensions() {
    if (!this.get('element')) {
      return {width: 0, height: 0};
    }
    const style = window.getComputedStyle(this.get('element'));
    return {
      width: parseInt(style.width, 10),
      height: parseInt(style.height, 10),
    };
  },

  async resetImage() {
    const img = this.element.querySelector('img');
    if (!isImageLoaded(img)) {
      if (Ember.testing) {
        return;
      }
      debounce(this, this.resetImage, 100);
      return;
    }
    const dim = this.getDimensions();
    let { width, height } = await this.getImageSize();
    const ratio = width / height;

    const imgWidth = (width > height && dim.height >= dim.width) || ratio * dim.height > dim.width
      ? dim.width
      : ratio * dim.height;

    const imgHeight = imgWidth / ratio;

    // set the image to fill the container
    this.set('dimensions', dim);
    this.set('imgWidth', Math.round(imgWidth));
    this.set('imgHeight', Math.round(imgHeight));

    // remove any zoom and scroll
    this.set('zoomPercentage', DEFAULT_ZOOM_PERCENTAGE);
    this.set('scrollLeft', 0);
    this.set('scrollTop', 0);
  },

  actions: {
    onDragStart(e) {
      // required for firefox, see: https://stackoverflow.com/questions/26356877/html5-draggable-false-not-working-in-firefox-browser
      // lint exception also required, see: https://github.com/ember-template-lint/ember-template-lint/issues/118
      e.preventDefault();
    },
    imageLoaded() {
      this.resetImage();
    },
    resetImage() {
      this.resetImage();
    },
  }
});
