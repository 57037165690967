import Component from '@ember/component';
import { inject as service } from '@ember/service';
import {
  MAINTENANCE_MODE_TOGGLED,
  MUTE_TOGGLED } from '../services/analytics';
import UserPermCheck from '../mixins/user-permission-check';
import { observer, computed } from '@ember/object';
import moment from 'moment';
import LiveVideoMixin from '../mixins/live-video-mixin';

import { run, later } from '@ember/runloop';


export default Component.extend(UserPermCheck, LiveVideoMixin, {
  intl: service(),
  report: service(),
  analytics: service(),
  store: service(),
  currentUser: service(),
  session: service(),
  tenant: service(),
  classNames: ['channel-preview'],
  channel: null,
  refreshing: false,
  error: '',
  maintenanceAction: '',
  muteAction: '',
  showEventModal: false,
  imageDataUri: '',
  currentTimezone: moment.tz.guess(),


  init() {
    this._super(...arguments);
    this.updateMaintenanceAction();
    this.updateMuteAction();
  },

  videoTooltip: computed('streaming', function() {
    if (this.streaming) {
      return this.intl.t('tooltips.stop-video');
    } else {
      return this.intl.t('tooltips.play-live-video');
    }
  }),

  muteUntilTimestamp: computed('muteAction.timestamp', function() {
    let timestamp = moment(this.get('muteAction.timestamp'));
    timestamp = timestamp.add(this.get('muteAction.dataDuration'), 's');
    return timestamp.tz(this.currentTimezone).format('ll LTS z');
  }),

  watchMaintenanceMode: observer('channel.maintenanceMode', function() {
    if (!this.get('channel.hasDirtyAttributes')) {
      this.updateMaintenanceAction();
    }
  }),

  watchMuteChannel: observer('channel.mute', function() {
    if (!this.get('channel.hasDirtyAttributes')) {
      this.updateMuteAction();
    }
  }),

  updateMaintenanceAction() {
    if (this.get('channel.maintenanceMode')) {
      this.store.query('action', {
        slug: this.get('channel.slug'),
        verb: 'enabled maintenance mode'
      })
        .then(result => {
          if (this.isDestroyed || this.isDetroying) {
            return;
          }
          this.set('maintenanceAction', result.objectAt(0));
        });
    }
  },

  updateMuteAction() {
    if (this.get('channel.mute')) {
      this.store.query('action', {
        slug: this.get('channel.slug'),
        verb: 'muted'
      })
        .then(result => {
          if (this.isDestroyed || this.isDetroying) {
            return;
          }
          if (this.updateMuteRun) {
            run.cancel(this.updateMuteRun);
          }
          this.set('muteAction', result.objectAt(0));
          let timestamp = moment(this.get('muteAction.timestamp'));
          timestamp = timestamp.add(this.get('muteAction.dataDuration'), 's');
          let msUntilExpiry = moment.duration(timestamp.diff(moment())).asMilliseconds();
          const updateMuteRun = later(this, function() {
            if (this.reloadData) {
              this.reloadData();
            }
          }, msUntilExpiry);
          this.set('updateMuteRun', updateMuteRun);
        });
    }
  },
  actions: {
    closeError() {
      this.set('error', '');
      this.set('channel.error', '');
    },
    closeWarning() {
      this.set('warning', '');
    },
    playVideo() {
      this.toggleLiveVideo('streaming');
    },
    enableMaintenanceMode() {
      this.toggleProperty('channel.maintenanceMode');
      const analyticsAction = this.get('channel.maintenanceMode') ? 'Turned On' : 'Turned Off';

      this.analytics.trackMultiple(MAINTENANCE_MODE_TOGGLED, {
        siteToggle: false,
        channelToggle: true,
        siteName: this.get('site.name'),
        siteId: this.get('site.id'),
        channelId: this.get('channel.id'),
        channelSlug: this.get('channel.slug'),
        action: analyticsAction
      });
      this.channel.save()
        .then(() => {
          this.updateMaintenanceAction('channel.mute');
          this.reloadData();
        })
        .catch(() => {
          this.set('error', this.intl.t('site.details.maintenance-mode-failed'))
        });
    },
    muteAlerts() {
      this.set('showEventModal', true);
    },
    updateMuteStatus(duration) {
      this.toggleProperty('channel.mute');
      this.set('showEventModal', false);

      const analyticsAction = this.get('channel.mute') ? 'Turned On' : 'Turned Off';

      this.analytics.trackMultiple(MUTE_TOGGLED, {
        siteToggle: false,
        channelToggle: true,
        siteName: this.get('site.name'),
        siteId: this.get('site.id'),
        channelId: this.get('channel.id'),
        channelSlug: this.get('channel.slug'),
        action: analyticsAction
      });
      this.channel.save({adapterOptions: { mute: true, duration: duration * 3600}})
        .then(() => {
          this.updateMuteAction('channel.mute');
          this.reloadData();
        })
        .catch(() => {
          this.set('error', this.intl.t('site.details.mute-failed'));
        });
    },
    streamingStopped(warning) {
      this.set('warning', warning);
      this.set('streaming', false);
      this.set('streamingUrl', '');
      this.$('.alert-stream-stopped').alert();
    },
    download() {
      this.get('channel.latestImage').then((image) => {
        image.download()
          .catch(() => {
            if (image.mediaType === 'video/mp4') {
              this.set('error', this.intl.t('video.error-loading'));
            } else {
              this.set('error', this.intl.t('errors.image-download'));
            }
          })});
    },
    addImageToReport(image) {
      this.set('reportSuccess', false);
      this.set('reportFailed', false);
      this.report.addImageToReport(image)
        .then(() => {
          this.set('reportSuccess', this.intl.t('report.image-added'));
        })
        .catch(() => {
          this.set('reportFailed', this.intl.t('report.image-add-failed'));
        });
    },
    repositionCamera() {
      this.showRepositionCamera(this.channel);
    },
    clickImage() {
      this.selectImage(this.get('channel.latestImage'));
    },
  }
});
