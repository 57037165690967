import Mixin from '@ember/object/mixin';

const ACTIONS = ['updateRecord', 'createRecord'];

export const AdapterIncludeParamMixin = Mixin.create({
  includeActions: ACTIONS,
  addQueryParam(url, param, value) {
    const data = [`${param}=${value}`];
    const parts = url.split('?');

    if (parts.length === 2) {
      data.push(parts[1]);
    }

    url = [parts[0], [data.join('&')]].join('?');

    return url
  },
  buildURL(modelName, id, snapshot, requestType, query) {
    let url = this._super(modelName, id, snapshot, requestType, query);

    if (this.includeActions.find(action => action === requestType) && snapshot.include) {
      url = this.addQueryParam(url, 'include', encodeURIComponent(snapshot.include));
    }

    if (snapshot && snapshot.adapterOptions && snapshot.adapterOptions.queryParams) {
      for (let [key, value] of Object.entries(snapshot.adapterOptions.queryParams)) {
        url = this.addQueryParam(url, key, encodeURIComponent(value));
      }

    }

    return url;
  }
});

