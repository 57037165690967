import JsonApiBaseAdapter from './json-api-base';

export default JsonApiBaseAdapter.extend({
  pathForType() {
    return 'camerashadow';
  },
  buildEventItemsQuery(url, query) {
    let queryDetails = [];

    if (query.channel_id) {
      queryDetails.push(`filter[channel__slug]=${query.channel_id}`)
    }

    return url + '?' + queryDetails.join('&');
  },
  query(store, type, query) {
    let url = this.buildURL(type, null, null);
    const endOfPath = url.lastIndexOf('/') + 1;
    url = url.slice(0, endOfPath);
    url = this.buildEventItemsQuery(url, query);
    const options = {headers: this.headers};
    return this.ajax(url, 'GET', options);
  }
});
