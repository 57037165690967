import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { v1 } from 'ember-uuid';
import { later, run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import bowser from 'ember-bowser';
import fetch from 'fetch';
import retry from 'ember-retry/retry'

const MAX_404_RETRIES = 5;
const MAX_RETRIES_STARTOVER = 3;
const INITIAL_RETRY_DELAY = 500;

const head = (url) =>
  fetch(url, {
    method: 'HEAD', headers: {
      'Cache-Control': 'no-cache',
      Accept: 'application/vnd.apple.mpegurl, application/text, application/x-ospplaylistconn, */*'
    }
  })
    .then(res => {
      if (res.ok) {
        return res;
      }
      throw res;
    });

const retryHead = (url, retries = MAX_404_RETRIES) => retry(
  () => head(url),
  retries,
  INITIAL_RETRY_DELAY,
  (res) => res.status === 404,
);

export default Component.extend({
  intl: service(),
  tenant: service(),
  store: service(),
  classNames: ['video-container clearfix'],
  errorMessage: '',
  loadingStream: false,
  streaming: false,
  videoUrl: '',
  videoUuid: '',
  errorRetryCount: 0,
  errorRetryOffset: 1000,
  maxErrorRetries: 6,
  bufferWaitTime: 3000,
  offsetTime: 0,
  player: null,
  selectedPlaylistIndex: 0,
  pause: false,
  showControls: true,
  lastInteraction: Date.now(),
  site: null,


  init() {
    this.set('videoUuid', v1());
    this._super(...arguments);
    this.store.findAll('site-type');
  },
  lastInteractionUpdated: observer('lastInteraction', function() {
    this.restartLiveVideoTimeout();
  }),

  createMaxRetriesErrorMsg(url) {
    return this.get('intl')
      .t('video.max-retries', { url })
  },

  createVideoConnectionErrorMsg(url, code) {
    return this.get('intl')
      .t('video.connection-error', { url, code });
  },

  didInsertElement() {
    this.startVideo();
  },

  willDestroyElement() {
    if (this.player) {
      this.stopVideo();

      // NOTE: JWPlayer has a bug where if a user enters fullscreen mode and then video is stopped,
      // the page vertical scrollbar is lost.  Exit fullscreen before removing the player to avoid this bug.
      this.player.setFullscreen(false);

      this.player.remove();
    }
  },

  downloadMediaListFile(url) {
    // Set headers to the request
    const headers = {
      'Cache-Control': 'no-cache',
      Accept: 'application/vnd.apple.mpegurl, application/x-ospdlmedialist, */*'
    };
    const requestOptions = {
      method: 'GET',
      cache: 'no-cache',
      headers,
      responseType: 'text'
    };
    return fetch(url, requestOptions)
      .then((data) => {
        if (!data.ok) {
          throw { message: `Error downloading media file from: ${url}`, status: data.status };
        } else {
          return data.text();
        }
      })
  },

  /**
   * Examines the contents of a chunk list file from Wowza. Returns false if it doesn't look valid.
   *
   * @param contents
   * @returns {*}
   */
  chunkListFileIsValid(contents) {
    const MAXIMUM_VIDEO_CHUNK_DURATION = 20.0;  // No video chunk should be anywhere near this for live video
    const MINIMUM_VIDEO_CHUNK_DURATION = 0.01;  // At least one frame

    // We have the chunk list file. Check the durations - sometimes Wowza sends durations of thousands of seconds,
    // which is wrong.
    const durationsRegexPattern = /#EXTINF:([0-9\\.]+),/gm;
    let match = null;

    while ((match = durationsRegexPattern.exec(contents)) != null) {
      const duration = parseFloat(match[1]);

      if (Number.isNaN(duration) || ((duration > MAXIMUM_VIDEO_CHUNK_DURATION) || (duration <= MINIMUM_VIDEO_CHUNK_DURATION))) {
        return false;
      }
      // If we've got this far, the duration is valid so we don't need to do anything else.
    }
    return true;
  },

  /**
   * Returns true if the URL looks like it points to a playlist file.
   * We actually check the validity of the playlist file separately.
   *
   * @param url
   * @returns {*}
   */
  isAPlayListFile(url) {
    return url.match(/playlist\.m3u8$/);
  },

  /**
   * Checks whether the playList is valid. This includes checking the associated chunk list file, which
   * sometimes gets returns with incorrect (and huge) segment durations.
   *
   * If this happens, returns false and lets the code try again.
   *
   * N.B. The playlist file name must match that in the VHI and Wowza.
   * @param playListUrl
   * @param playListContents
   * @returns {*}
   */
  getChunkListUrlFromPlayList(playListUrl, playListContents) {
    const PLAYLIST_FILENAME = 'playlist.m3u8';  // HLS format playlist name.

    // Get the chunk list file name from the playlist - using .* as char class matching proved unreliable.
    const chunkListFileName = playListContents.match(/^chunklist_.*$/m);

    if (!chunkListFileName) {
      throw new Error('No matching chunklist found in the playlist file');
    }
    // The chunk list file should be in the same URL folder as the playlist in Wowza.
    return playListUrl.replace(PLAYLIST_FILENAME, chunkListFileName);
  },

  jwPlayerId: computed('videoUuid', function() {
    return 'video-player-' + this.videoUuid;
  }),

  hideControls: observer('showControls', function() {
    if (this.player) {
      this.player.setControls(this.showControls);
    }
  }),

  watchUrl: observer('videoUrl', 'playlist.[]', function() {
    if (this.player) {
      this.stopVideo();
    }
    this.startVideo();
  }),

  pauseVideo: observer('pause', function() {
    if (this.player) {
      if (this.pause) {
        this.player.stop();
      } else {
        this.player.play();
      }
    }
  }),

  /**
   * Function to start streaming video. This will display a JWPlayer instance
   * with the provided URL.
   */
  startVideo() {
    let usingPlaylist = false;
    let url = this.videoUrl;
    if (!url && this.playlist) {
      url = this.playlist.objectAt(0)
        .get('hlsStream');
      if (!url) {
        url = this.playlist.objectAt(0)
          .get('hlsUrl');
      }

      usingPlaylist = true;
    }

    if (!this.streaming) {
      this.set('streaming', true);
      this.set('loadingStream', true);
      this.checkPlaylist(url)
        .then(() => {
          // check that the user hasn't cancelled the request
          if (this.streaming) {
            if (usingPlaylist) {
              const playlist = this.playlist.map(item => {
                if (item.get('hlsStream')) {
                  return { file: item.get('hlsStream') };
                } else {
                  return { file: item.get('hlsUrl') };
                }
              });
              this.setupPlayer(playlist);
            } else {
              this.setupPlayer([{ file: url }]);
            }
            this.set('loadingStream', false);
          }
        })
        .catch(() => {
          this.set('error', `This camera is experiencing degraded communications to run Live Video at the moment,
          please try again in a few minutes.  Should this issue persist please contact support@osperity.com.`);
          this.stopVideo();
        });
    }
  },

  /**
   * Function to remove the live stream and stop it from playing
   */
  stopVideo() {
    this.set('loadingStream', false);
    this.set('streaming', false);

    if (this.playTimeout) {
      run.cancel(this.playTimeout);
    }
    if (this.bufferTimeout) {
      run.cancel(this.bufferTimeout);
    }
    if (this.player) {
      this.player.stop();
    }
  },

  resetRetries() {
    this.set('retry', false);
    this.set('errorRetryCount', 0);
    this.set('errorRetryOffset', 1000);
  },

  retryPlayback() {
    const offset = this.errorRetryOffset;
    this.set('loadingStream', true);
    this.set('retry', true);
    const playTimeout = later(
      this,
      function() {
        this.stopVideo();
        this.player.remove();
        this.startVideo(this.url);
      },
      offset
    );
    this.set('playTimeout', playTimeout);
    this.set('errorRetryOffset', offset * 2);
  },

  /**
   * Function to configure JWPlayer instance
   */
  setupPlayer(playlist) {
    this.set('streaming', true);
    const player = window.jwplayer(this.jwPlayerId)
      .setup({
        // Media Settings
        playlist: playlist,
        mediaid: this.jwPlayerId,
        // Appearance Settings
        stretching: 'uniform',
        width: '100%',
        height: '100%',
        aspectratio: '16:9',
        image: '/assets/images/osprey-icon.png',
        playbackRateControls: true,
        playbackRates: [0.5, 1, 2, 4],
        icons: false,
        // Rendering and loading Settings
        autostart: true,
        // using preload: 'auto' causes stream to think it's in dvr mode,
        // with -36 hours of seek, and the livestream at the beginning.
        // https://support.jwplayer.com/articles/video-preload-reference
        preload: 'metadata',
        nextUpDisplay: false,
        visualplaylist: false
      });
    this.set('player', player);

    player.on('error', error => {
      this.handlePlayerError(error);
    });
    player.on('setupError', error => {
      this.handlePlayerError(error);
    });
    player.on('ready', () => {
      this.handlePlayerReady();
    });
    player.on('buffer', bufferData => {
      this.handlePlayerBuffer(bufferData);
    });
    player.on('play', () => {
      this.handlePlayerPlay();
    });
    player.on('stop', () => {
      this.handlePlayerStop();
    });
    player.on('playlistItem', evt => {
      this.set('selectedPlaylistIndex', evt.index);
    });
    this.player.setControls(this.showControls);
  },

  handlePlayerError(error) {
    let errorSquashed = false;
    if (error.message.includes('Casting failed to load')) {
      errorSquashed = true;
    } else if (
      (error.message.includes('M3U8') ||
        error.message.includes('404') ||
        error.message.includes('cannot be played')) &&
      this.errorRetryCount < this.maxErrorRetries
    ) {
      this.incrementProperty('errorRetryCount');
      errorSquashed = true;
      this.retryPlayback();
    } else if (error.message.includes('404')) {
      // max retries exceeded for this error
      this.set('error', this.intl.t('video.unavailable'));
    } else {
      this.set('error', error.message);
    }

    if (!errorSquashed) {
      this.stopVideo();
    }
  },

  handlePlayerReady() {
    this.set('loadingStream', false);
    this.set('error', null)
    this.player.started = false;
    this.player.play();
  },

  handlePlayerBuffer(bufferData) {
    if (bowser.ios && bufferData.reason === 'idle') {
      this.checkBufferBug();
    }
  },

  checkBufferBug() {
    if (this.bufferTimeout) {
      run.cancel(this.bufferTimeout);
    }

    if (this.iniOSBufferBugState()) {
      this.retryPlayback();
    } else if (this.player.getState() === 'buffering') {
      let bufferTimeout = later(this, this.checkBufferBug, this.bufferWaitTime);
      this.set('bufferTimeout', bufferTimeout);
    }
  },

  iniOSBufferBugState() {
    let jwPlayerId = this.jwPlayerId;
    const liveId = `#${jwPlayerId} .jw-controls .jw-controlbar .jw-button-container .jw-icon-inline.jw-text-live`;
    return (
      this.player.getState() === 'buffering' && this.$(liveId)
        .is(':visible')
    );
  },
  restartLiveVideoTimeout() {
    if (this.playTimeout) {
      run.cancel(this.playTimeout);
    }
    // Live video is stopped automatically after a certain interval, to avoid excessive data usage.
    // If the site has fibre internet, have the timeout at 1 hour
    let videoTimeout = this.tenant.currentTenant.liveVideoStreamTimeLimit
    if (this.site) {
      this.get('site.siteType').forEach(siteType => {
        if (siteType.name.toLowerCase() === 'fibre internet') {
          videoTimeout += 3530;
        }
      });
    }

    if (this.live) {
      const playTimeout = later(
        this,
        function () {
          // Notify the user why the video stopped
          if (this.streaming) {
            this.streamingStopped(
              this.intl.t('video.live-stream-stopped', {
                stopTime: videoTimeout
              })
            );
          }
          this.stopVideo(true);
        },
        videoTimeout * 1000
      );
      this.set('playTimeout', playTimeout);
    }
  },
  handlePlayerPlay() {
    if (this.bufferTimeout) {
      run.cancel(this.bufferTimeout);
    }
    this.resetRetries();
    if (this.live) {
      this.restartLiveVideoTimeout()
    } else {
      const offsetTime = this.offsetTime;
      if (!this.player.started && offsetTime) {
        const offset = offsetTime >= 1 ? offsetTime - 1 : offsetTime;
        this.player.seek(offset);
      }
    }
    this.set('error', null)
    this.player.started = true;
  },

  handlePlayerStop() {
    if (this.playTimeout) {
      run.cancel(this.playTimeout);
    }
    this.player.started = false;
    this.set('loadingStream', false);
    this.set('streaming', false);
  },

  // throws an exception only if 5 attempts of HEAD(url) fail with 404 or any non-200, non-404 status response.
  async checkUrl(url) {
    try {
      await retryHead(url, this.maxErrorRetries);
    } catch (res) {
      if (res.status === 404) {
        throw new Error(this.createMaxRetriesErrorMsg(url));
      }
      throw new Error(this.createVideoConnectionErrorMsg(url, res.status));
    }
  },

  /**
   * Helper function to attempt a HEAD request to a provided endpoint
   * a fixed number of times. If the request ever returns successfully,
   * the returned promise resolves. If every request returns 404s or
   * if a request ever returns a non-404 or non-200 status code, the
   * promise is rejected.
   * This method checks for and validates playlist files.
   *
   * @param url the playlist url to fetch and validate
   * @param numRetries the maximum number of times to start over fetching the playlist
   * @param currentRetry the current retry being attempted
   * @returns resolved promise with url, otherwise a rejected promise with error
   */
  async checkPlaylist(url, numRetries = MAX_RETRIES_STARTOVER, currentRetry = 0) {
    if (currentRetry > numRetries) {
      throw new Error(this.createMaxRetriesErrorMsg(url));
    }

    // attempt to get playlist, retry only if response is 404
    await this.checkUrl(url);

    /* Not a playlist, don't bother with further validation. */
    if (!this.isAPlayListFile(url)) {
      return url;
    }

    // if we have the playlist, then try to get chunklist url
    let chunkListUrl;
    try {
      const contents = await this.downloadMediaListFile(url);
      chunkListUrl = this.getChunkListUrlFromPlayList(url, contents);
    } catch (err) {
      // retry from the beginning if GET'ing the playlist failed
      return this.checkPlaylist(url, numRetries, currentRetry++);
    }

    // now try to get chunklist contents and determine if valid
    await this.checkUrl(chunkListUrl);

    try {
      const contents = await this.downloadMediaListFile(chunkListUrl);
      if (!this.chunkListFileIsValid(contents)) {
        throw new Error('Invalid chunklist contents');
      }
    } catch (err) {
      // retry from the beginning if GET'ing the chunklist failed
      return this.checkPlaylist(url, numRetries, currentRetry++);
    }

    // both playlist and chunklist are available and good
    return url;
  },

  actions: {
    setVideo(index) {
      this.player.playlistItem(index);
    }
  }
});
