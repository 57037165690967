import ApplicationAdapter from './application';
import CacheBuster from '../mixins/cachebuster-adapter';
import moment from 'moment';
const MINUTE = 'minute';

const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export default ApplicationAdapter.extend(CacheBuster, {
  query(store, type, query) {
    const reloadParam = '&reload=' + moment()
      .startOf(MINUTE)
      .format('X');
    if (query.slug && query.verb) {
      const url = this.host + '/api/channels/' + query.slug + '/actions/?page_size=1&verb-' + query.verb + reloadParam;
      const options = {headers: JSON_HEADERS};
      return this.ajax(url, 'GET', options);
    } else if (query.siteId && query.verb) {
      const url = this.host + '/api/sites/' + query.siteId + '/actions/?page_size=1&verb-' + query.verb + reloadParam;
      const options = {headers: JSON_HEADERS};
      return this.ajax(url, 'GET', options);
    }

    return this._super(...arguments);

  }
});
