import Component from '@ember/component';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template'
import PeekChannelSitePropertiesWorkaroundMixin from '../mixins/site-channel-image-properties-workaround-mixin';

export default Component.extend(PeekChannelSitePropertiesWorkaroundMixin,{
  classNames: ['reach-snapshot','col-xs-12', 'col-sm-12', 'col-md-4'],
  report: service(),
  intl: service(),
  image: null,
  imageDataUri: '',
  highlighted: false,
  currentTimezone: moment.tz.guess(),
  animationPageSize: 1,
  error: '',

  animationDelay: computed('animationIndex', function() {
    return (this.animationIndex % this.animationPageSize) * 0.1 + 's';
  }),
  animationStyle: computed('animationDelay', function() {
    return htmlSafe('animation-delay: ' + this.animationDelay);
  }),

  isVideo: computed('image', function() {
    if (this.get('image.video')) {
      return true;
    } else {
      return false;
    }
  }),

  init() {
    this._super(...arguments);
  },

  actions: {
    streamingStopped() {

    },
    download() {
      this.set('error', null);
      const image = this.get('image');
      return Promise.resolve(
        image.download ? image
          : this.get('image')
      )
        .then(image => image.download())
        .catch(() => {
          if (this.isVideo) {
            this.set('error', this.intl.t('video.error-loading'));
          } else {
            this.set('error', this.intl.t('errors.image-download'));
          }
        });

    },
    clickImage() {
      this.selectImage(this.image);
    },
    addImageToReport(image) {
      this.set('reportSuccess', false);
      this.set('reportFailed', false);
      this.report.addImageToReport(image)
        .then(() => {
          this.set('reportSuccess', this.intl.t('report.image-added'));
        })
        .catch(() => {
          this.set('reportFailed', this.intl.t('report.image-add-failed'));
        });
    }
  }
});
