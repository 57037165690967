import DS from 'ember-data';
import EventItemContent from './subscription/event-item-content';
import { computed } from '@ember/object'

export default EventItemContent.extend({
  value: DS.attr(),
  register: DS.belongsTo('register'),
  truncatedValue: computed('value', function() {
    return Math.round(Number(this.value) * 100) / 100;
  })
});
