import DS from 'ember-data';
import ENV from '../config/environment';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
  tenant: service(),
  session: service(),
  namespace: 'api',
  headers: JSON_HEADERS,
  host: computed('tenant.subdomain', function() {
    let subdomain = this.get('tenant.subdomain')
    return ENV.vhiProtocol + '://' + subdomain + '.' + ENV.vhiServer;
  }),
  authorize(xhr) {
    let { access_token } = this.get('session.data.authenticated');
    xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
  },
  buildURL() {
    const url = this._super(...arguments);
    return url + '/';
  }

});
