import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include: function() {
    return [
      'content',
      'event',
      'image',
      'error'
    ];
  }
});
