import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { Ability } from 'ember-can';

export default Ability.extend({
  currentUser: service(),
  vhiUser: readOnly('currentUser.vhiUser'),
  permissions: readOnly('vhiUser.permissions'),
  canCreate: computed('permissions', function () {
    return !!this.permissions && this.permissions.includes('subscriptions.add_inspectionfilter');
  }),
  canEdit: computed('permissions', function () {
    return !!this.model && !!this.model.permissions && !!this.model.permissions.can_edit
  }),
});
