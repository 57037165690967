import DS from 'ember-data';

export default DS.Model.extend({
  activationDate: DS.attr(),
  createdBy: DS.attr(),
  createdDate: DS.attr(),
  expirationDate: DS.attr(),
  message: DS.attr(),
  readBy: DS.attr(),
  subject: DS.attr(),
  updatedBy: DS.attr(),
  updatedDate: DS.attr()
});
