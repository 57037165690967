import Component from '@ember/component';
import moment from 'moment';
import { inject as service } from '@ember/service';

import { computed } from '@ember/object';
const CHART_OPTIONS = {
  chart: {
    type: 'column',
    height: '200',
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: ['Last Reading']
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    },

  },
  legend: {
    useHTML: true,
    align: 'left',
    verticalAlign: 'top',
    layout: 'vertical'
  },
  tooltip: {
    formatter: function () {
      return this.point.series.name;
    }
  },
};
export default Component.extend({
  store: service(),
  classNames: ['datalogger-summary', 'col-md-4'],
  currentTimezone: moment.tz.guess(),
  chartOptions: CHART_OPTIONS,
  chartData: computed('logger.dataloggerdata', function() {
    let updatedChartData = {};
    const item = this.logger.dataloggerdata.lastObject;
    if (!item) {
      return null;
    }
    let name = item.data.values.zone_id;
    if (!name) {
      name = '0';
    }

    if (!updatedChartData[name + '-avg']) {
      updatedChartData[name + '-avg'] = [];
      updatedChartData[name + '-min'] = [];
      updatedChartData[name + '-max'] = [];
    }
    updatedChartData[name + '-avg'].push([parseFloat(item.data.values.avg)]);
    updatedChartData[name + '-min'].push([parseFloat(item.data.values.min)]);
    updatedChartData[name + '-max'].push([parseFloat(item.data.values.max)]);
    let data = []
    for (let key in updatedChartData) {
      data.push({
        name: 'Zone ' + key,
        data: updatedChartData[key],
        lineWidth: 2,
        boostThreshold: 1,
        turboThreshold: 1
      });
    }

    return data;
  })
});

