import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  intl: service(),

  makeNameError() {
    return {
      title: this.intl.t('inspections.errors.no-name-title'),
      values: null,
      resolve: this.intl.t('inspections.errors.no-name-resolve')
    };
  },
  makeNoScheduleError() {
    return {
      title: this.intl.t('inspections.errors.no-schedule-title'),
      values: null,
      resolve: this.intl.t('inspections.errors.no-schedule-resolve')
    };
  },
  makeNoScheduleRecurrenceError() {
    return {
      title: this.intl.t('inspections.errors.no-schedule-recurrence'),
      values: null,
      resolve: this.intl.t('inspections.errors.no-schedule-recurrence-resolve')
    };
  },
  makeNoFilterError() {
    return {
      title: this.intl.t('inspections.errors.no-filter-title'),
      values: null,
      resolve: this.intl.t('inspections.errors.no-filter-title')
    };
  },
  makeUnknownErrorOnSave() {
    return {
      title: this.intl.t('errors.unknown-error-title'),
      values: null,
      resolve: this.intl.t('errors.unknown-error-resolve')
    };
  },
  getErrorsOnInspection(inspection) {
    const errors = [];

    // NOTE: deleted items don't count for error checking before saving.
    const subscriptionFilters = inspection.subscriptionFilters.filterBy('isDeleted', false);
    const doesInspectionHaveFilters = subscriptionFilters && subscriptionFilters.length > 0;
    if (!doesInspectionHaveFilters) {
      errors.push(this.makeNoFilterError());
    }

    const schedules = inspection.schedules.filterBy('isDeleted', false);
    const scheduled = inspection.scheduled
    const doesInspectionHaveSchedules = schedules && schedules.length > 0 && inspection.scheduled;

    if (!doesInspectionHaveSchedules && scheduled) {
      errors.push(this.makeNoScheduleError());
    }

    const doSchedulesHaveRecurrence = schedules.reduce(
      (hasRecurrence, schedule) => {
        hasRecurrence = hasRecurrence && schedule.recurrence &&
          schedule.recurrence.length > 0 &&
          schedule.recurrence.every(recurrence => recurrence !== '');
        return hasRecurrence;
      },
      true
    );
    if (!doSchedulesHaveRecurrence) {
      errors.push(this.makeNoScheduleRecurrenceError());
    }

    const doesInspectionHaveAName = typeof inspection.name === 'string' && inspection.name !== '';
    if (!doesInspectionHaveAName) {
      errors.push(this.makeNameError());
    }
    return errors;
  },
  serverErrorToClientError(error) {
    if (!error) {
      return this.makeUnknownErrorOnSave();
    }
    // todo make the server give codes so we can go code -> make client error function
    if (error.code === '') {
      //this will actually match to something
      return this.makeUnknownErrorOnSave();
    } else {
      return this.makeUnknownErrorOnSave();
    }
  },
  translateServerErrorsToClient(response) {
    const errors = [];
    if (!response || !response.errors || !Array.isArray(response.errors)) {
      errors.push(this.makeUnknownErrorOnSave());
      return errors;
    }

    response.errors.forEach(error => {
      const newError = this.serverErrorToClientError(error);
      errors.push(newError);
    });
    return errors;
  }
});
