import { helper } from '@ember/component/helper';

export function cleanRouteName(params/*, hash*/) {
  if (!params[0]) {
    return 'unknown';
  }
  const targetName = params[0]
    .replace(/\//g, '-')
    .replace(/\./g, '-');
  return targetName;
}

export default helper(cleanRouteName);
