import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    titleToken: function (model) {
      return model.get('name');
    },
    intl: service(),
    report: service(),
    breadcrumbs: null,

    model(params) {
      return this.store.find('report', params.report_id);
    },

    setupController(controller, model) {
      this.store.query('report-segment', {
        id: model.get('id'),
        page: 1,
        page_size: 400
      })
        .then(segments => {
          model.set('segments', segments);
        });
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.reports'),
          link: 'reports'
        },
        {
          name: model.get('name')
        }
      ]);
      this.set('navTitleText', this.intl.t('navbar.' + this.navTitle, {reportName: model.get('name'), siteId: model.get('id')}));

      this._super(...arguments);
    }
  }
);
