import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  report: service(),
  errors: null,
  init() {
    this._super(...arguments);
    this.set('errors', {
      creation: null,
    });
  },
  actions: {
    addMoreImages() {

    },
    createReport() {
      this.model.save()
        .then(()=>{
          this.transitionToRoute('report.index', this.model);
        })
        .catch(() => {
          this.set('errors.creation', true);
        });
    },
    cancelReport() {
      let segments = this.get('model.segments');
      if (segments) {
        segments.forEach(seg => {
          seg.rollbackAttributes();
        });
      }
      this.model.rollbackAttributes();
      this.transitionToRoute('report.index', this.model);
    }
  }
});
