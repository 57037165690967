import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { HELP_URL_CLICKED } from '../services/analytics';

const SUPPORT_URL = 'https://help.ospreyreach.com';
export default Controller.extend({
  analytics: service(),
  actions: {
    clickSupportLink() {
      this.analytics.track(HELP_URL_CLICKED, 'click', SUPPORT_URL);
      window.open(SUPPORT_URL);
    }
  }
});
