import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export function subscriptionsSetBackgroundColor(params /*, hash*/) {
  const [color] = params;
  
  return htmlSafe(`background-color: ${color};`);
}

export default helper(subscriptionsSetBackgroundColor);
