define("reach-web-client/components/tt-picker-item", ["exports", "ember-time-tools/components/tt-picker-item"], function (_exports, _ttPickerItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttPickerItem.default;
    }
  });
});
