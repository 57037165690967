import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'i',
  classNameBindings: ['showOutlined:material-icons-outlined:material-icons'],
  isOutline: false,
  isBrowserIE: document.body.className.includes('internet-explorer'),
  showOutlined: computed('isOutline', 'isBrowserIE', function() {
    return !this.isBrowserIE && this.isOutline;
  })
});
