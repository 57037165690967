import Component from '@ember/component';
import {inject as service} from '@ember/service';
import { computed } from '@ember/object';
import { filter } from '@ember/object/computed';

export default Component.extend({

  store: service(),
  channels: null,
  validCameras: filter('channels', function(camera) {
    return camera.deployState !== 'RETIRED';
  }),
  offlineCameras: filter('channels', function(camera) {
    return camera.isOffline;
  }),
  alertCameras: filter('channels', function(camera) {
    return camera.deployState === 'ALERT';
  }),
  alertsPresent: computed('alertCameras', function() {
    return this.alertCameras && this.alertCameras.length > 0;
  }),
});
