import Component from '@ember/component';
import { computed } from '@ember/object';
import { isMobile } from '../../utils/responsive';

export default Component.extend({
  classNames: ['inspections-board-selector-item'],
  classNameBindings: ['selected:is-selected'],
  selectedItem: null,

  // NOTE: we need to track if forcing scroll on this item is necessary.
  // If you click on it, there is no need to scroll the view.
  // However, using the arrow keys or stepper control may
  // need to scroll to keep the current item in view
  hasToScroll: false,

  selected: computed('model', 'selectedItem', function() {
    if (this.selectedItem === this.model) {
      if (this.hasToScroll) {
        this.element.scrollIntoView(false);
      }
      return true;
    }
    return false;
  }),

  contentType: computed('model.content', function() {
    return this.model.get('content._internalModel.modelName');
  }),

  image: computed('contentType', function() {
    return this.get('contentType') === 'image' ? this.model.get('content') : undefined;
  }),

  site: computed('model.content.channel.site', function() {
    return this.get('image') ? this.model.get('content.peekSite') : this.model.get('content.channel.site');
  }),

  channel: computed('model.content.channel', function() {
    return this.get('image') ? this.model.get('content.peekChannel') : this.model.get('content.channel');
  }),

  ptzPreset: computed('model.content.ptzPreset', function() {
    return this.model.get('content.ptzPreset');
  }),

  click() {
    this.selectItem(this.model);
    if (isMobile()) {
      this.enlargeImage(this.get('image'));
    }
  },
});
