import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { ResetControllerMixin } from 'ember-site-tour/mixins/route-tour';
import { later } from '@ember/runloop'

export default Mixin.create(ResetControllerMixin, {
  intl: service(),
  tenant: service(),
  currentUser: service(),
  reachTour: service(),
  tourManager: service(),
  storage: service(),

  beforeModel(transition) {
    this.tourManager.set('includeStepCount', false);
    this.set('navTitle', transition.targetName);
    return this._super(...arguments);

  },

  setupController(controller) {
    this._super(...arguments);
    if (!this.navTitleText) {
      this.set('navTitleText', this.intl.t('navbar.' + this.navTitle));
    }
    this.controllerFor('application').set('navTitle', this.navTitleText);

    this.store.query('message', {
      expired: false,
      active: true
    }).then(result => {
      let steps = [];
      let subdomain = this.get('tenant.subdomain')
      let userId = `${subdomain}:${this.currentUser.vhiUser.id}`;
      this.set('messages', result)
      let msgId = '';


      result.forEach((message) => {
        if (!message.readBy || !message.readBy.includes(userId)) {
          steps.push({
            id: message.id,
            title: message.subject,
            content: message.message,
            target: '.navbar',
            placement: 'bottom'
          });
          msgId = msgId + '-' + message.id;
        }
      });
      if (steps.length <= 0) {
        return;
      }
      let tour = this.reachTour.setupManualTour('messages' + msgId, steps, result);
      this.set('tour', tour);
      controller.set('tour', tour);

      tour.set('calloutOptions', {
        id: msgId,
        target: '.navbar',
        placement: 'bottom',
      });
      tour.on('tour.end', () => {
        this.messages.forEach(msg => {
          if (msg.id !== 'new-FE') {
            msg.set('read', true);
            msg.save();
          }
        })
      })
      if (result.length > 0) {
        later(this, () => tour.start(), 1000);
      }
    });
  },
  willTransition () {
    if (this.tour) {
      this.tour.stop();
    }
    return this.super(...arguments);
  }
});
