import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['material-pagination-bar'],
  meta: null,
  paginatedRoute: 'sites',
  paginatedData: null,
  totalItems: computed('meta.{count,page}', function() {
    let meta = this.meta;
    return Number(meta.count) * Number(meta.pages);
  }),
  currentItems: computed('meta.{pages,page}', function() {
    let meta = this.meta;
    let end = Number(meta.page) * Number(meta.count);
    let start = end - Number(meta.count) + 1;
    return start.toString() + ' - ' + end.toString();

  }),
  nextIndex: computed('meta.{page,pages}', function() {
    if (Number(this.meta.page) === Number(this.meta.pages)) {
      return this.meta.pages;
    }
    return Number(this.meta.page) + 1;
  }),
  previousIndex: computed('meta.{page,pages}', function() {
    if (Number(this.meta.page) === 1) {
      return this.meta.page;
    }
    return Number(this.meta.page) - 1;
  })
});
