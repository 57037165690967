import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['webhooks-list'],
  webhookSubscribers: null,
  allowEdit: false,
  sortingParams: Object.freeze(['isActive:desc', 'url:desc']),
  sortedWebhooks: computed.sort('webhookSubscribers', 'sortingParams'),
  init() {
    this._super(...arguments);
    this.webhookSubscribers = this.webhookSubscribers || [];
  },
  actions: {
    removeWebhookFromList(webhook) {
      this.removeWebhookFromList(webhook);
    },
    toggleWebhooksStatus(user, type, shouldToggleOn) {
      this.toggleWebhooksStatus(user, type, shouldToggleOn);
    }
  }
});
