import DS from 'ember-data';
import moment from 'moment';
import constants from '../utils/ui-constants';
const { UI_DATE_FORMAT } = constants;


export default DS.Transform.extend({
  deserialize(serialized) {
    return moment(serialized)
      .tz(moment.tz.guess())
      .format(UI_DATE_FORMAT)
  },

  serialize(deserialized) {
    return deserialized.format(UI_DATE_FORMAT)
  },
});
