import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';


export default Route.extend(AuthenticatedRouteMixin, {
  activate() {
    this._super(...arguments);
    this.controllerFor('application').set('onFreestyleRoute', true);
  },

  deactivate() {
    this._super(...arguments);
    this.controllerFor('application').set('onFreestyleRoute', false);
  },
});
