import ApplicationAdapter from './application';
import CacheBuster from '../mixins/cachebuster-adapter';
import ENV from '../config/environment';


export default ApplicationAdapter.extend(CacheBuster, {
  alwaysReload: true,

  /**
   * Override method to set json-api related headers
   * If have a bug related to ember-api-actions or json-api adapter
   * It always set rest/json headers even if we use json-api adapter
   * @method ajaxOptions
   * @private
   * @param {String} url
   * @param {String} type The request type GET, POST, PUT, DELETE etc.
   * @param {Object} options
   * @return {Object}
   */
  ajaxOptions: function ajaxOptions(url/*, type, options*/) {
    var hash = this._super.apply(this, arguments);

    if (hash.contentType) {
      hash.contentType = 'application/vnd.api+json';
    }

    var beforeSend = hash.beforeSend;
    hash.beforeSend = function (xhr) {


      if (beforeSend) {
        beforeSend(xhr);
      }

      xhr.setRequestHeader('Accept', '*/*');
      if (!url.includes('legacy/channels/mute')) {
        xhr.setRequestHeader('Content-Type', 'application/vnd.api+json; application/json');
      }
    };

    return hash;
  },

  urlForUpdateRecord(id, modelName, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.mute) {
      return `${this.host}/${this.namespace}/legacy/channels/mute`;
    }
    return `${this.host}/${this.namespace}/channels/${
      snapshot._attributes.slug
    }`;
  },

  urlForRelativeMoveRecord(id) {
    return `${this.host}/${this.namespace}/channels/ptz/move/relative/${id}/`;
  },

  urlForContinuesMoveRecord(id) {
    return `${this.host}/${this.namespace}/channels/ptz/move/continuous/${id}/`;
  },

  /**
   * Add custom request types to resolve zoom, move actions urls
   * @method buildURL
   * @param {String} modelName
   * @param {(String|Array|Object)} id single id or array of ids or query
   * @param {(DS.Snapshot|Array)} snapshot single snapshot or array of snapshots
   * @param {String} requestType
   * @param {Object} query object of query parameters to send for query requests.
   * @return {String} url
   */
  buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
    switch (requestType) {
      case 'zoomRecord':
        return this.urlForRelativeMoveRecord(id, modelName, snapshot);

      case 'moveRecord':
        return this.urlForContinuesMoveRecord(id, modelName, snapshot);

      default:
        if (query && query.new_api) {
          let url = this._super(...arguments);
          return url.replace('channels', 'v1/cameras');
        }
        else if (query && query.tenant) {
          let url = this._super(...arguments);
          return ENV.vhiProtocol + '://' + url.replace(/^[^.]*/, query.tenant);
        } else {
          return this._super(...arguments);
        }
    }
  },

  updateRecord(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot);

    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
    if (snapshot.adapterOptions && snapshot.adapterOptions.mute) {
      return this.ajax(url, 'POST', {data: data});
    }
    return this.ajax(url, 'PUT', {data: data});
  }
});
