export default [
  {
    id: 0,
    name: 'Immediate Email',
    visible: true
  },
  {
    id: 2,
    name: 'Daily',
    visible: true
  },
  {
    id: 3,
    name: 'Post-Event Email With Report',
    visible: true
  }
]
