import MaterialCard from '../common/md-card';
import groupBy from 'ember-group-by';
import { computed } from '@ember/object';

export default MaterialCard.extend({
  classNames: ['site-picker_channel-with-ptz-card', 'channel-picker-item'],
  channel: null,
  ptzAsOneFilter: false,
  filtersByChannelId: groupBy('filtersOnChannel', 'channel.id'),
  filtersByPtz: groupBy('filtersOnChannel', 'ptzPreset.id'),
  toggleSelectAllPtz() {
    if (this.isAnyPtzSelected) {
      this.selectChannel(this.channel, false);
    } else {
      this.selectChannel(this.channel, true);
    }
  },
  isAllPtzSelected: computed(
    'filtersOnChannel.[]',
    'channel.ptzPresets.[]',
    function() {
      return this.ptzAsOneFilter ? this.filtersByPtz.length > 0
        : this.filtersByPtz.length === this.channel.ptzPresets.length;
    }
  ),
  isAnyPtzSelected: computed('filtersOnChannel.[]', function() {
    return this.filtersByPtz.length > 0;
  }),
  click() {
    this.toggleSelectAllPtz();
  },
  actions: {
    toggleSelectAllPtz() {
      this.toggleSelectAllPtz();
    },
    selectPtz(channel, ptz) {
      if (this.ptzAsOneFilter) {
        this.toggleSelectAllPtz();
      } else {
        this.selectPtz(channel, ptz);
      }
      return false;
    }
  }
});
