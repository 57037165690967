export default {
  "login-form-example-1--usage.hbs": "        {{login-form}}",
  "navbar--example-1--usage.hbs": "        {{reach-navbar}}",
  "navbar-button-example-1--usage.hbs": "        <div class=\"reach-navbar\">\n          <ul>\n            {{#reach-navbar-button destination='sites'}}\n              Sites\n            {{/reach-navbar-button}}\n          </ul>\n        </div>",
  "reach-icon--example-1--usage.hbs": "        {{reach-icon name='user'}}",
  "reach-icon--example-2--usage.hbs": "        {{reach-icon name='user-secret'}}",
  "reach-icon--example-3--usage.hbs": "        {{reach-icon name='edit'}}",
  "reach-pagination-bar--example-1--usage.hbs": "        {{reach-pagination-bar pages='{\"first\":{\"page\":1,\"start\":true,\"end\":false},\"last\":{\"page\":10,\"start\":false,\"end\":true},\"prev\":{\"page\":4,\"start\":false,\"end\":false},\"next\":{\"page\":6,\"start\":false,\"end\":false},\"self\":{\"page\":5,\"start\":false,\"end\":false} }' }}",
  "reach-search-bar-1--usage.hbs": "        {{site-search-bar}}",
  "reach-toggle--example-1--usage.hbs": "        {{reach-toggle enabled=true }}",
  "reach-toggle--example-2--usage.hbs": "        {{reach-toggle enabled=false }}"
};