import EmberObject, { computed } from '@ember/object';
import Service from '@ember/service';
import { A } from '@ember/array';

const MultiChannelAdapter = EmberObject.extend({
  selectedChannels: null,
  computedChannel: null,
  sortedCVTags: computed('selectedChannelIDs', 'channels', function() {
    return this.get('computedChannel').sortedCVTags;
  }),
  CVTagsConfig: computed('selectedChannelIDs', 'channels', function() {
    return this.get('computedChannel').CVTagsConfig;
  }),

  init: function() {
    this._super(arguments)
    let computedChannel = {
      sortedCVTags: [],
      CVTagsConfig: {}
    };
    let cvTags = A()

    if (this.get('selectedChannelIDs')) {
      this.set('selectedChannels', this.channels.filter(channel => this.selectedChannelIDs.indexOf(channel.id) !== -1));
      this.get('selectedChannels').forEach(channel => {
        if (channel.sortedCVFeatures) {
          channel.sortedCVFeatures.forEach(cvFeature => {
            let featureTags = [];
            let tagTypes = cvFeature.tagTypes;
            /**
             * Filtering TagTypes that have the same name as tagType group.
             * We keep this workaround as leave tagType.parent property for backward compatibility.
             * It will be removed as soon as we remove parent property from tag types and remove parent tags as well.
             */
            tagTypes = tagTypes.length > 1 ? tagTypes.filter(cvtag => cvtag.name != cvFeature.name) : tagTypes;
            tagTypes.forEach(cvTag => featureTags.push(cvTag.name));
            cvTags.push(...featureTags.sort());
          });
        }

        for (const tagConfig in channel.CVTagsConfig) {
          computedChannel.CVTagsConfig[tagConfig] = channel.CVTagsConfig[tagConfig];
        }
      });

      cvTags = cvTags.uniq();
      computedChannel.sortedCVTags = cvTags.sort();
    }


    this.set('computedChannel', computedChannel);
  }
});

export default Service.extend({
  tags: null,
  channels: null,
  selectedChannelIDs: null,
  channel: computed('selectedChannelIDs', 'channels', function() {
    return MultiChannelAdapter.create({
      selectedChannelIDs: this.selectedChannelIDs,
      channels: this.channels
    })
  }),

  filter: computed('selectedChannelIDs', 'channels', 'tags', function() {
    return EmberObject.create({tags: A(this.get('tags'))});
  }),

  init() {
    this._super(...arguments);
    this.set('tags', []);
  }
});
