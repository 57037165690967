import Component from '@ember/component';
import DatepickerSupport from 'ember-cli-bootstrap-datepicker/components/datepicker-support';
import moment from 'moment'

export default Component.extend(DatepickerSupport, {
  tagName: 'div',
  classNames: ['input-group', 'date', 'reach-datepicker'],
  instrumentDisplay: '{{input type="text"}}',
  customParser (value) {
    if (!value) {
      return;
    }
    let date = moment(value);
    return date.toDate();
  }
});
