import DS from 'ember-data';
import EmberObject, { computed } from '@ember/object';
import { Copyable } from 'ember-copy'

export default DS.Model.extend(Copyable, {
  disabled: DS.attr('boolean', { defaultValue: false }),
  orderDetails: DS.attr('string'),
  channel: DS.belongsTo('channel'),
  site: computed('channel.site', function() {
    return this.get('channel.site');
  }),
  copy() {
    return EmberObject.create({
      disabled: this.disabled,
      orderDetails: this.orderDetails,
      channel: this.channel,
      site: this.get('channel.site'),
    });
  }
});
