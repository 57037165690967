import Component from '@ember/component';
import ENV from '../config/environment';
import { computed } from '@ember/object';
import { getLatLngCenter } from '../utils/geo';
/* global L */

const DEFAULT_LOCATION = { lat: 51.048615, lng: -114.07084 };

export default Component.extend({
  classNames: ['reach-map'],
  zoom: 8,
  init() {
    if (ENV.leaflet) {
      window.L.Icon.Default.imagePath =
        ENV.leaflet.imagePath + '/assets/images/';
    }
    this._super(...arguments);
  },
  siteLocations: computed('sites', function() {
    if (!this.sites) {
      return [DEFAULT_LOCATION];
    }
    return this.sites
      .map(item => {
        const hasCoordinates =
          item.centroid && item.centroid.lat && item.centroid.lng;
        if (hasCoordinates) return item.centroid;
        return null;
      })
      .filter(val => val); // filter sites with no coords
  }),
  centerLocation: computed('siteLocations','sites', function() {
    if (this.lat && this.lng) {
      // this is for specifically passed in lat and lng for centering the map
      return { lat: this.lat, lng: this.lng };
    }

    if (!this.sites) {
      //if no data just return calgary
      return DEFAULT_LOCATION;
    }

    return getLatLngCenter(this.get('siteLocations'));
  }),
  createClusterIcon(cluster) {
    const n = cluster.getChildCount();
    return L.divIcon({
      html: `<div class="map-cluster-icon"><div class='map-cluster-text'>${n}</text></div>`,
      className: 'mycluster',
      iconSize: L.point(40, 40)
    });
  },
  actions: {
    toggleAerialView() {
      this.toggleAerialView();
    },
    onLoad(e) {
      if (this.siteLocations.length > 0) {
        const map = e.target;
        map.fitBounds(this.siteLocations);
      }
    }
  }
});
