import Controller from '@ember/controller';

export default Controller.extend({
  actions: {
    generateCSV() {
      let csv = 'data:text/csv;charset=utf-8,';
      let rows = [['site id', 'site name', 'username', 'email', 'first name', 'last name']]
      this.model.sites.forEach(site => {
        this.model.users.forEach(user => {
          if (!user.isStaff && user.sites.find(item => item.id === site.id)) {
            rows.push([site.id, site.name, user.username, user.email, user.firstName, user.lastName])
          }
        });
      });
      csv = csv + rows.map(e => e.join(',')).join('\n');
      const encodedUri = encodeURI(csv);
      window.open(encodedUri);
    }
  }
});
