import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';

const logger = console;
export const INSPECTIONS_FLAG = 'enable_inspections';
export const VIEW_INSPECTION_REPORTS_FLAG = 'enable_viewinspectionreports';
export const DOWNLOAD_PDF_FLAG = 'enable_downloadpdfinspection';
export const PTZ_CONTROLS_FLAG = 'live_ptz_controls';
export const VIEW_USER_ADMIN_OPTION = 'enable_useradmin';

export default Service.extend({
  store: service(),
  enabledFlags: null,
  isInspectionsEnabled: computed('enabledFlags.[]', function() {
    return this.enabledFlags.isAny('name', INSPECTIONS_FLAG);
  }),
  // this feature flag corresponds to Inspections Phase 2 part 1
  // (view list of reports and click to see details, no workflow yet)
  isViewInspectionReportsEnabled: computed('enabledFlags.[]', function() {
    return this.enabledFlags.isAny('name', VIEW_INSPECTION_REPORTS_FLAG);
  }),
  isDownloadPdfEnabled: computed('enabledFlags.[]', function() {
    return this.enabledFlags.isAny('name', DOWNLOAD_PDF_FLAG);
  }),
  isLivePTZControlsEnabled: computed('enabledFlags.[]', function() {
    return this.enabledFlags.isAny('name', PTZ_CONTROLS_FLAG);
  }),
  isUserAdminEnabled: computed('enabledFlags.[]', function() {
    return this.enabledFlags.isAny('name', VIEW_USER_ADMIN_OPTION);
  }),
  init() {
    this.enabledFlags = this.enabledFlags || [];
    this._super(...arguments);
    this.load();
  },
  load() {
    return this.store
      .findAll('feature-flag')
      .then(result => {
        const filteredFlags = result.filter(flag => flag.enabled);
        this.set('enabledFlags', filteredFlags);
      })
      .catch(() => logger.log('There was an error retrieving feature flags'));
  }
});
