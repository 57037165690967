import Component from '@ember/component';
import { observer } from '@ember/object';
export default Component.extend({
  classNames: ['change-ptz-modal'],
  visible: false,
  watchVisible: observer('visible', function() {
    if (this.visible) {
      this.$('.change-ptz-modal').modal('show');
      this.$('.change-ptz-modal').on('hidden.bs.modal', () => {
        this.set('visible', false);
      });
    } else {
      this.$('.change-ptz-modal').modal('hide');
    }
  }),
  actions: {
    selectPTZ(view) {
      this.set('visible', false);
      this.selectPTZ(view);
    },
    close() {
      this.set('visible', false);
    }
  }
});
