import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['camera-panel'],
  site: null,
  cameras: null,
  selectedCamera: null,
  store: service(),
  init() {
    this._super(...arguments);
    if (this.site) {
      this.loadCameras(this.site);
    }
  },
  siteChanged: observer('site', function() {
    if (this.site) {
      this.loadCameras(this.site)
    } else {
      this.set('cameras', null);
    }
  }),
  loadCameras(site) {
    return this.store.query('channel', {
      page: 1,
      page_size: 1000,
      site: site.get('id'),
      include: 'latest_image'
    })
      .then(result => {
        this.set('cameras', result);
      });
  },
  actions: {
    cameraSelected(camera) {
      this.set('selectedCamera', camera);
    },
    reloadData() {
      this.loadCameras(this.site);
    }
  }
});
