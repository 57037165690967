import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['manage-list_container'],
  showDeleteDialog: false,
  analytics: service(),
  infinity: service(),
  totalCount: computed('this.model.meta.pagination.count', function() {
    if (
      !this.model.meta ||
      !this.model.meta.pagination ||
      !this.model.meta.pagination.count
    ) {
      return this.model.filter(
        subscription =>
          subscription.get('id') !== null && !subscription.isDeleted
      ).length;
    }
    return this.model.meta.pagination.count;
  }),
  selectedItems: computed.filterBy('model', 'selected', true),
  selectedCount: computed('selectedItems.[]', 'model.length', function() {
    return this.selectedItems.length;
  }),
  allSelected: computed('selectedCount', 'totalCount', function() {
    return this.totalCount === this.selectedCount;
  }),
  someSelected: computed('selectedItems.[]', 'selectedCount', function() {
    return this.selectedCount > 0;
  }),
  selectAllLabelText: computed('selectedCount', 'totalCount', function() {
    if (this.selectedCount > 0) {
      return `(${this.selectedCount}/${this.totalCount})`;
    }
    return `All (${this.totalCount})`;
  }),
  actions: {
    toggleAll() {
      const toggleState = !this.someSelected;
      this.model
        .filter(sub => sub.currentUserCanEdit)
        .forEach(sub => sub.set('selected', toggleState));
    },
    toggleShowDeleteDialog() {
      this.toggleProperty('showDeleteDialog');
    },
    deleteSelected() {
      this.deleteSelectedSubscriptions();
    }
  }
});
