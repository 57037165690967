import moment from 'moment';
import Component from '@ember/component';
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy'
import move from 'ember-animated/motions/move';
import ErrorHandler from '../../mixins/inspection-error-handler';

const DEFAULT_INSPECTION_RECURRENCE = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
const DEFAULT_INSPECTION_START = '07:00 am';
const CHANNEL_FILTERS = { inspections: true };

export default Component.extend(ErrorHandler, {
  classNames: ['inspection_edit-page'],
  showSitePicker: false,
  showImmediateReportDialog: false,
  store: service(),
  inspection: null,
  channelFilters: CHANNEL_FILTERS,
  activeFilters: filterBy('inspection.subscriptionFilters', 'isDeleted', false),
  activeSchedules: filterBy('inspection.schedules', 'isDeleted', false),
  activeSubscribers: filterBy('inspection.subscribers', 'isDeleted', false),
  allUsers: null,
  notificationTypes: null,
  frozenFilters: null,
  errors: null,
  iSaving: false,
  init() {
    this._super(...arguments);
    this.selectedChannelIDs = this.selectedChannelIDs || [];
    this.allUsers = this.allUsers || [];
    this.notificationTypes = this.notificationTypes || [];
    this.errors = this.errors || [];
  },
  transition: function({ insertedSprites, keptSprites, removedSprites }) {
    for (let sprite of insertedSprites) {
      sprite.startAtPixel({ y: -750 });
      move(sprite, { duration: 750 });
    }

    for (let sprite of keptSprites) {
      move(sprite, { duration: 750 });
    }

    for (let sprite of removedSprites) {
      sprite.endAtPixel({ y: -750 });
      move(sprite, { duration: 750 });
    }
  },

  actions: {
    saveSubscription() {
      const clientErrors = this.getErrorsOnInspection(this.model);
      if (clientErrors && clientErrors.length > 0) {
        this.errors.pushObjects(clientErrors);
        return;
      }
    },
    addSiteSelection(filters) {
      let i = 0;
      filters.forEach(filter => {
        // NOTE: if a filter is deleted, we cannot set order on it
        if (!filter.isDeleted) {
          filter.set('order', i++);
        }
      });
      this.set('inspection.subscriptionFilters', filters);
      return Promise.resolve();
    },
    addUserToSubscription(user) {
      const alreadySubscribed = this.inspection.subscribers.find(inspector => inspector.user.get('id') === user.get('id'));
      if (alreadySubscribed) {
        if (alreadySubscribed.isDeleted) alreadySubscribed.rollbackAttributes();
        return;
      }
      const subscriber = this.store.createRecord('subscription/subscriber', {
        user: user,
        allowEdit: false,
        subscription: this.inspection
      });
      this.notificationTypes.forEach(type => {
        if (type.visible) {
          let method = this.store.createRecord('subscription/notification-method', {
            enabled: true,
            subscriber,
            // TODO: remove this hack when we get notificationType records correctly
            //  from API, instead of using a fake object
            notificationMethodType: type.realType
          });
          subscriber.notificationMethods.pushObject(method);
        }
      });
      this.inspection.subscribers.pushObject(subscriber);
    },
    removeUserFromSubscription(inspector) {
      if (
        this.inspection.get('creator.id') !== inspector.user.get('id') ||
        this.currentUser.vhiUser.get('id') !== inspector.user.get('id')
      ) {
        inspector.deleteRecord();
      }
    },
    toggleNotificationMethod(inspector, type, shouldToggleOn) {
      const found = inspector.notificationMethods.find(
        notificationMethod =>
          notificationMethod.get('notificationMethodType.id') === type.get('id')
      );
      if (found) {
        found.set('enabled', shouldToggleOn);
      } else {
        if (shouldToggleOn) {
          this.store.createRecord('subscription/notification-method', {
            enabled: shouldToggleOn,
            subscriber: inspector,
            notificationMethodType: type
          });
        }
      }
    },
    createInspectionFilter(channel, ptz) {
      return this.store.createRecord('subscription/inspection-filter', {
        channel: channel,
        ptzPreset: ptz,
        inspection: this.inspection,
      });
    },
    createInspectionSchedule() {
      const newSchedule = this.store.createRecord('subscription/schedule', {
        recurrence: DEFAULT_INSPECTION_RECURRENCE,
        start: DEFAULT_INSPECTION_START,
        timezone: moment.tz.guess(),
        subscription: this.inspection,
        editMode: true
      });
      this.inspection.schedules.pushObject(newSchedule);
    },

    toggleInspectionScheduled() {
      this.inspection.set('scheduled', !this.inspection.scheduled)
    },

    toggleImmediateReportDialog() {
      // TODO: re-enable this when the controller.generateInspectionReport() method does something.
      // this.toggleProperty('showImmediateReportDialog');
    },
    toggleShowSitePicker() {
      this.toggleProperty('showSitePicker');
      this.set('frozenFilters', this.showSitePicker ?
        this.get('activeFilters')
          .map(item => copy(item))
        : null);
    },
    initiateSaveInspection() {
      const clientErrors = this.getErrorsOnInspection(this.inspection);
      if (clientErrors && clientErrors.length > 0) {
        this.errors.pushObjects(clientErrors);
        return;
      }
      // TODO: remove saveInspection() (aka next line) when report dialog is working.
      this.saveInspection();
      // TODO: re-enable this when the controller.generateInspectionReport() method does something.
      // this.toggleProperty('showImmediateReportDialog');
    },
    saveInspection() {
      this.saveInspection();
    },
    generateInspectionReport() {
      this.generateInspectionReport();
    },
    resetDirtyInspection() {
      this.resetDirtyInspection();
    },
    clearErrors() {
      this.set('errors', []);
    }
  }
});
