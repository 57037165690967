import { Ability } from 'ember-can';
import {inject as service} from '@ember/service';
import {readOnly} from '@ember/object/computed';
import {computed} from '@ember/object';

const USER_MGMT_SETTING = 'SPA User Management'

export default Ability.extend({
  currentUser: service(),
  vhiUser: readOnly('currentUser.vhiUser'),
  permissions: readOnly('vhiUser.permissions'),
  groups: readOnly('vhiUser.groups'),
  canSiteAccess: computed('vhiUser.{isStaff,groups}', function () {
    if (!this.vhiUser) {
      return false;
    }
    if ((this.groups && this.groups.find(element => element.name === USER_MGMT_SETTING)) || this.vhiUser.isStaff || this.vhiUser.isSuperuser) {
      return true;
    }
    return false;
  }),
});
