import { cancel, later } from '@ember/runloop';
import Service from './base-site-manager'
import Ember from 'ember';


export default Service.extend({
  initializeSite(site) {
    this._super(site);
    this.scheduleDataUpdate();
  },


  loadSite(siteId, page, pageSize, query) {
    return this._super(siteId, page, pageSize, query, this.scheduleDataUpdate);

  },
  loadChannels(siteId, page, pageSize, query) {
    return this._super(siteId, page, pageSize, query, this.scheduleDataUpdate)
  },

  /**
   * every minute check for updates to the cameras
   **/
  scheduleDataUpdate() {
    //you can't do continuous runloops with ember testing!
    if (Ember.testing) {
      return;
    }
    if (this.delayedFunc) {
      cancel(this.delayedFunc);
    }
    let delayedFunc = later(this, function () {

      // NOTE: we dont need to catch exception in the background here, because it will be picked up and logged by Sentry
      this.loadSite(this.site.id)
        .then(() => {
          this.scheduleDataUpdate();
        })
    }, 60000);
    this.set('delayedFunc', delayedFunc);
  },

  clearScheduledUpdates() {
    if (this.delayedFunc) {
      cancel(this.delayedFunc);
    }
  }
});
