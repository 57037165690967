import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { LOGIN_ATTEMPTED } from '../services/analytics';
import ResponsiveBootstrap from '../mixins/responsive-bootstrap';

const DEFAULT = 1;
const CP_SUBMIT_USERNAME = 2;
const CP_ENTER_CHANGE_PASSWORD_CODE = 3;

const NOT_AUTHORIZED_EXCEPTION_CODE = 'NotAuthorizedException';
const INVALID_PARAMETER_EXCEPTION_CODE = 'InvalidParameterException';

const ACCOUNT_HAS_EXPIRED_MESSAGE = 'account has expired';
const MISSING_PARAMETER_USERNAME_MESSAGE = 'Missing required parameter USERNAME';
const INCORRECT_USERNAME_OR_PASSWORD_MESSAGE = 'Incorrect username or password';
const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException';
const USER_DOES_NOT_EXIST = 'User does not exist.';

/* Regex pattern matches HTML5 standard. Non-Latin domains will require changes to this. */
const EMAIL_VALIDATION_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const COGNITO_OAUTH2_PROVIDER = 'cognito-oauth2'

export default Component.extend(ResponsiveBootstrap, {
  session: service(),
  tenant: service(),
  intl: service(),
  analytics: service(),
  currentUser: service(),
  torii: service(),
  raven: service(),
  classNames: ['login-form'],

  tenantName: computed('tenant.currentTenant.name', function() {
    const tenantName = this.get('tenant.currentTenant.name');
    return `${tenantName}`;
  }),

  errorMessage: '',
  successMessage: '',
  username: '',
  password: '',
  newPassword: '',
  confirmPassword: '',
  cognitoCode: '',
  mode: DEFAULT,
  authenticated: false,

  init() {
    let error = localStorage.getItem('login-error');
    if (error) {
      this.set('errorMessage', error);
    }
    this._super(...arguments);
  },

  authenticate(params, provider) {
    this.clearMessages();
    this.analytics.trackMultiple(LOGIN_ATTEMPTED, {
      clientIp: this.get('analytics.clientIp'),
      $username: params.username
    });

    // We use torii for cognito oauth2. cognito further can delegate to any IDP
    // that is configured for the user pool
    if (provider === COGNITO_OAUTH2_PROVIDER) {
      this.get('session').authenticate('authenticator:torii', provider, {
        ...params,
        site: this.tenant.currentTenant.subdomain})
        .then(() => {
          this.set('authenticated', true);
        },
        (error) => {
          this.set('errorMessage', this.intl.t('login-form.federated-error'))
          this.get('raven').captureMessage('Federated login error : ' + error.message || error);
        });
      return;
    }

    // if provider arg is not set, we use the cognito vanilla (username/password) authenticator
    this.session
      .authenticate('authenticator:cognito', params)
      .then(() => {
        this.set('authenticated', true);
      })
      .catch(err => {
        if (err.state && err.state.name === 'newPasswordRequired') {
          this.set('errorMessage', '');
          this.set('state', err.state);
        } else if (
          err.code &&
          err.code === INVALID_PARAMETER_EXCEPTION_CODE &&
          err.message &&
          err.message.indexOf(MISSING_PARAMETER_USERNAME_MESSAGE) >= 0
        ) {
          this.set(
            'errorMessage',
            this.intl.t('login-form.username-error')
          );
        } else if (
          err.code &&
          err.code === NOT_AUTHORIZED_EXCEPTION_CODE &&
          err.message &&
          err.message.indexOf(ACCOUNT_HAS_EXPIRED_MESSAGE) >= 0
        ) {
          this.set('errorMessage', this.intl.t('login-form.temporary-password-expired-error'));
        } else if (
          err.code &&
          err.code === NOT_AUTHORIZED_EXCEPTION_CODE &&
          err.message &&
          err.message.indexOf(INCORRECT_USERNAME_OR_PASSWORD_MESSAGE) >= 0
        ) {
          this.set('errorMessage', this.intl.t('login-form.credentials-error'));
        } else if (
          err.code &&
          err.code === USER_NOT_FOUND_EXCEPTION &&
          err.message &&
          err.message.indexOf(USER_DOES_NOT_EXIST) >= 0
        ) {
          this.set('errorMessage', this.intl.t('login-form.credentials-error'));
        } else {
          this.set('errorMessage', err.message || err);
        }
      });
  },

  newPasswordRequired: equal('state.name', 'newPasswordRequired'),

  clearMessages() {
    this.set('errorMessage', '');
    this.set('successMessage', '');
    this.set('currentUser.vhiError', '');
    localStorage.removeItem('login-error');
  },

  actions: {
    federatedLogin(identityProvider) {
      this.authenticate({'identityProvider': identityProvider}, COGNITO_OAUTH2_PROVIDER);
      return false;
    },
    login(provider) {
      let params = this.getProperties('username', 'password');
      this.authenticate(params, provider);
    },
    logout() {
      this.session.invalidate();
    },
    newPassword() {
      let { newPassword, confirmPassword, state } = this;
      if (newPassword === confirmPassword) {
        if (!state.userAttributes.locale) {
          state.userAttributes.locale = navigator.language;
        }
        if (!state.userAttributes.given_name) {
          state.userAttributes.given_name = 'Osprey';
        }
        if (!state.userAttributes.family_name) {
          state.userAttributes.family_name = 'User';
        }
        if (!state.userAttributes.locale) {
          state.userAttributes.locale = navigator.language;
        }
        this.authenticate({ password: newPassword, state });
      } else {
        this.set('errorMessage', this.intl.t('login-form.passwords-no-match'));
      }
    },
    resetPassword() {
      this.clearMessages();
      this.set('mode', CP_SUBMIT_USERNAME);
    },
    submitUsername() {
      this.clearMessages();

      const userEmail = this.username;
      if (!userEmail) {
        this.set('errorMessage', this.intl.t('login-form.email-empty-error'));
        return;
      }
      if (!EMAIL_VALIDATION_REGEX.test(userEmail)) {
        this.set('errorMessage', this.intl.t('login-form.email-invalid-error'));
        return;
      }
      this.currentUser
        .forgotPassword(userEmail)
        .then(() => {
          this.set('successMessage', this.intl.t('login-form.password-sent'));
          this.set('mode', CP_ENTER_CHANGE_PASSWORD_CODE);
        })
        .catch(error => {
          this.set('errorMessage', error);
        });
    },
    changePassword() {
      this.clearMessages();

      let { newPassword, confirmPassword, cognitoCode } = this;
      if (newPassword !== confirmPassword) {
        this.set('errorMessage', this.intl.t('login-form.passwords-no-match'));
        return;
      }
      if (!cognitoCode) {
        this.set(
          'errorMessage',
          this.intl.t('login-form.validation-code-error')
        );
        return;
      }
      this.currentUser
        .verifyResetPasswordCode(cognitoCode, newPassword)
        .then(() => {
          this.set('mode', DEFAULT);
          this.set(
            'successMessage',
            this.intl.t('login-form.password-changed')
          );
        })
        .catch(error => {
          this.set('errorMessage', error);
        });
    },
    cancel() {
      this.clearMessages();
      this.set('mode', DEFAULT);
    }
  }
});
