import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  allowEdit: true,
  combinedCvFeatures: service(),

  selectedChanged: function(values) {
    this.onSelectTags(values);
  },

  channel: computed('selectedChannelIDs', 'channels', function() {
    this.combinedCvFeatures.set('selectedChannelIDs', this.get('selectedChannelIDs'));
    this.combinedCvFeatures.set('channels', this.get('channels'));

    return this.combinedCvFeatures.channel;
  }),

  filter: computed('selectedChannelIDs', 'tags', function() {
    this.combinedCvFeatures.set('tags', this.get('tags'));
    return this.combinedCvFeatures.filter;
  })
});
