define("reach-web-client/helpers/compare-json", ["exports", "ember-time-tools/helpers/compare-json"], function (_exports, _compareJson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "compareJson", {
    enumerable: true,
    get: function get() {
      return _compareJson.compareJson;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _compareJson.default;
    }
  });
});
