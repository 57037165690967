import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
export default Controller.extend({
  currentUser: service(),
  queryParams: ['page', 'page_size'],
  page: 1,
  page_size: 10,
  actions: {
    navigateToSite(site) {
      this.transitionToRoute('site', site);
    }
  }
});
