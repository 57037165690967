import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import SearchBarMixin from '../mixins/search-bar-scroll-fixer-mixin';

export default Component.extend(SearchBarMixin, {
  store: service(),
  classNames: 'site-search-bar mobile-bounce-left',
  selected: null,
  selectedChanged: observer('selected', function() {
    this.resetScrolling();
    this.navigateToSite(this.selected);
  }),
  sites: null,
  initSiteId: null,
  init() {
    this._super(...arguments);
    this.set('sites', this.loadAllSites());
  },
  willRender() {
    this.set('animation', 'mobile-bounce-left');
    this._super(...arguments);
  },
  willDestroyElement() {
    var clone = this.$().clone()[0];
    clone.id = 'temp-search-bar';
    clone.classList.toggle('mobile-bounce-left');
    this.$(clone).insertBefore(this.$().parent()
      .children('.reach-icon'));
    clone.addEventListener('animationend',
      () => {
        clone.remove();
      }, false);
    clone.classList.toggle('mobile-bounce-right');
    this._super(...arguments);
  },
  loadAllSites() {
    return this.store.query('site', {
      page: 1,
      page_size: 1000
    })
      .then(result=>{
        result.forEach(item=>{
          if (item.get('id') === this.initSiteId) {
            this.set('selected', item);
          }
        });
        return result;
      });
  }
});
