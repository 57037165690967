import SearchBar from './site-search-bar';
import moment from 'moment';
import SearchBarMixin from '../mixins/search-bar-scroll-fixer-mixin';

export default SearchBar.extend(SearchBarMixin, {
  initSiteId: null,
  loadAllSites() {
    return this.store.query('subscription/subscription', {
      page: 1,
      page_size: 1000,
      context: 'alert',
      reload: moment().format('X')
    })
      .then(result=>{
        result.forEach(item => {
          if (item.get('id') === this.initSiteId) {
            this.set('selected', item);
          }
        });
        return result;
      });
  }
});
