import { computed } from '@ember/object';
import { debounce } from '@ember/runloop';
import {
  drawArrowhead,
  snapToAngle,
  findLengthOfLineSegment,
  drawCenterCircle
} from 'reach-web-client/utils/drawing';

import PIXI from 'pixi';
import PixiCanvas from 'ember-cli-pixijs/components/pixi-canvas';
import Ember from 'ember';
//Axis camera's this is 100, but that's way to fast
const MAX_PTZ_SPEED = 50;
const ONE_THIRD_SPEED = 15;

export default PixiCanvas.extend({
  classNames: ['ptz-video-container'],
  mousedown: false,
  channel: null,
  lastX: null,
  lastY: null,
  lastZoom: null,
  onReady(element, param) {
    if (param) {
      let component = param[0];
      window.addEventListener('wheel', component.zoom.bind(component));
      document.body.onmousedown = function() {
        component.set('mousedown', true);
      };
      document.body.onmouseup = function() {
        component.set('mousedown', false);
      };
    }
  },
  willDestroy(element, param) {
    if (param) {
      let component = param[0];
      //stop all movement
      component.movePtz({dx: 0, dy: 0, length: 0});
      window.removeEventListener('wheel', component.zoom.bind(component));
      document.body.onmousedown = null;
      document.body.onmouseup = null;
    }
  },
  /**
   * Overrides the built in function as we need to get the renderer as transparent to
   * have a transparent background
   */
  pixiRenderer: computed(function() {
    this.width = this.element.clientWidth;
    this.height = this.element.clientHeight;
    let { width, height } = this.getProperties('width', 'height');
    return PIXI.autoDetectRenderer(width, height, {transparent: true});
  }),
  movePtz(length) {
    if (this.channel) {
      //shortcut the stop case
      if (length.dx === 0 && length.dy === 0) {
        if (this.lastX != length.dx || this.lastY != length.dy) {
          this.set('lastX', 0);
          this.set('lastY', 0);
          this.get('channel').then(channel => {
            channel.movePTZ({pan: 0, tilt: 0});
          });
        }
      } else {
        let {width, height} = this.getProperties('width', 'height');
        const halfWidth = width / 2;
        const halfHeight = height / 2;
        const percentX = Math.min(halfWidth, length.dx / halfWidth * MAX_PTZ_SPEED);
        const percentY = Math.min(halfHeight, length.dy / halfHeight * MAX_PTZ_SPEED);
        let dx = ONE_THIRD_SPEED;
        let dy = ONE_THIRD_SPEED;
        if (length.dx < ONE_THIRD_SPEED && length.dx > -ONE_THIRD_SPEED) {
          dx = 0;
        } else if (length.dx > 0) {
          dx = Math.max(ONE_THIRD_SPEED, Math.round(percentX / ONE_THIRD_SPEED) * ONE_THIRD_SPEED);
        } else {
          dx = Math.min(-ONE_THIRD_SPEED, Math.round(percentX / ONE_THIRD_SPEED) * ONE_THIRD_SPEED);
        }
        if (length.dy < ONE_THIRD_SPEED && length.dy > -ONE_THIRD_SPEED) {
          dy = 0;
        } else if (length.dy > 0) {
          dy = Math.max(ONE_THIRD_SPEED, Math.round(percentY / ONE_THIRD_SPEED) * ONE_THIRD_SPEED);
        } else {
          dy = Math.min(-ONE_THIRD_SPEED, Math.round(percentY / ONE_THIRD_SPEED) * ONE_THIRD_SPEED);
        }

        if (this.lastX != dx || this.lastY != dy) {
          this.set('lastX', dx);
          this.set('lastY', dy);
          this.get('channel').then(channel => {
            channel.movePTZ({pan: dx, tilt: -dy});
          });
        }
      }
    }
  },

  zoom(event) {
    this.ptzedit();
    const lastZoom = this.lastZoom || 0
    this.lastZoom = lastZoom + event.deltaY
    debounce(this, this.onZoom, 500);
    event.stopPropagation();

  },

  onZoom() {
    if (!this.get('channel')) {
      return;
    }
    this.get('channel').then(channel => {
      channel.zoom({zoom: -1 * this.lastZoom});
      this.lastZoom = null;
    });
  },


  draw() {
    if (Ember.testing) {
      return;
    }
    const renderer = this.get('pixiRenderer');
    const stage = new PIXI.Container();
    stage.interactive = true;

    const centerCircle = new PIXI.Graphics();
    const background = new PIXI.Graphics();
    const arrow = new PIXI.Graphics();

    const stopRadius = 40

    let { width, height } = this.getProperties('width', 'height');

    background.lineStyle(0);
    background.beginFill(0xFFFFFF, 0.01);
    background.drawRect(0,0, width, height);
    stage.addChild(background);

    drawCenterCircle(centerCircle, {x: width / 2, y: height / 2}, stopRadius, false);

    stage.addChild(centerCircle);
    stage.addChild(arrow);


    let movePtz = function() {};
    if (this.get('movePtz')) {
      movePtz = this.get('movePtz').bind(this);
    }
    //this stops the autostop of the video
    let ptzedit = function() {};
    if (this.get('ptzedit')) {
      ptzedit = this.get('ptzedit').bind(this);
    }

    let component = this;

    renderer.render(stage);
    function animate() {
      requestAnimationFrame(animate);

      let mousePosition = {...renderer.plugins.interaction.mouse.global};
      const fromPoint = {x: width / 2, y: height / 2};
      const arrowEnd = snapToAngle(45, fromPoint, mousePosition);
      const length = findLengthOfLineSegment(fromPoint, arrowEnd);
      let highlightCircle = false
      if (length.length <= stopRadius) {
        highlightCircle = true;
      }
      drawCenterCircle(centerCircle, {x: width / 2, y: height / 2}, stopRadius, highlightCircle);
      if (component.mousedown) {
        if (mousePosition.x > 0 && mousePosition.x < width && mousePosition.y > 0 && mousePosition.y < height) {
          arrow.clear()
          ptzedit()
          if (length.length <= stopRadius) {
            movePtz({dx: 0, dy: 0, length: 0});
          } else {
            drawArrowhead(fromPoint, arrowEnd, arrow);
            movePtz(length);
          }
        }
      } else {
        arrow.clear()
        movePtz({dx: 0, dy: 0, length: 0});
      }

      renderer.render(stage);

    }
    animate();
  },



});
