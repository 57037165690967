import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
/**
 * This mixin adds works with peek properties and real properties.
 * This is just workaround to get properties form different places
 * It should be removed as soon as we migrate these models to jsonApi in VHI
 */
export default Mixin.create({
  imageChannelName: computed('image.', function() {
    return this.getEntityProperty('channel', 'name') ||
      this.getEntityProperty('peekChannel', 'name')
  }),
  imageTimezone: computed('image.', function() {
    return this.getEntityProperty('site', 'timezone') ||
      this.getEntityProperty('peekSite', 'timezone')
  }),

  getEntityProperty(entityName, propertyName) {
    const image = this.get('image');
    if (!image) {
      return ''
    }
    const entity = image.get(entityName);
    if (!entity) {
      return ''
    }
    return entity.get(propertyName);
  },
});
