import SubscriptionAdapter from './subscription';

export default SubscriptionAdapter.extend({
  pathForType(type, subscriptionUserQuery) {
    if (subscriptionUserQuery) {
      return 'v1/subscriptions/users';
    }
    return 'users';
  },
  query(store, type, query) {
    if (!query.subscription_id) {
      return this._super(...arguments);
    }
    let url = this.buildURL();
    const endOfPath = url.lastIndexOf('/');
    const path = this.pathForType(type, true);
    url = [url.slice(0, endOfPath), `/${path}/${query.subscription_id}`, url.slice(endOfPath)].join('');
    url += `&page_size=${query.page_size}&page=${query.page}`;
    const options = {headers: this.headers, redirect: 'follow'};
    return this.ajax(url, 'GET', options);
  }
});
