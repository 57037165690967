import DS from 'ember-data';
import {computed} from '@ember/object';
import moment from 'moment';

export default DS.Model.extend({
  name: DS.attr(),
  datalogger: DS.belongsTo('datalogger'),
  timestamp: DS.attr(),
  values: DS.attr(),
  isoTimestamp: computed('timestamp', function() {
    return moment(this.timestamp)
      .toISOString();
  }),
  momenttimestamp: computed('timestamp', function() {
    return moment(this.timestamp);
  })

});
