import Controller from '@ember/controller';

export default Controller.extend({
  isSaving: false,
  actions: {
    saveComplete() {
      this.transitionToRoute('user-admin');
      this.set('isSaving', true);
    }
  }
});
