import Component from '@ember/component';

export default Component.extend({
  classNames: 'alerts_filter-menu',
  state: null,
  newAlerts: null,
  subscription: null,
  actions: {
    setState(state) {
      this.setState(state)
    },
    dateChanged(date) {
      this.dateChanged(date)
    },
    clearDate() {
      this.clearDate()
    },
    siteSelected(site) {
      this.siteSelected(site)
    }
  }
});
