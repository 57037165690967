import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { PAGE_VIEWED } from '../services/analytics';

export default Mixin.create({
  analytics: service(),
  init() {
    this._super(...arguments);
    this.on('routeDidChange', () => {
      var eventValue = this.url;
      this.analytics.track(PAGE_VIEWED, 'page', eventValue);
      return this._super(...arguments);
    });
  }
});
