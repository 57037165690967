import Component from '@ember/component';
import { scheduleOnce } from '@ember/runloop';

export default Component.extend({
  tagName: '',
  actions: {
    saveScroll() {
      this.set('scrollPosition', window.pageYOffset);

      /**
       * to prevent the <body> from appearing to shift underneath the modal scrim
       * (caused by applying "position: fixed" to <body>),
       * appear to scroll the body content to the same position by applying inline style
       * see: https://bugs.webkit.org/show_bug.cgi?id=153852
       * (scroll to bottom of bug report): by Kareem Darkazanli 2020-04-01 19:37:31 PDT
       */
      document.body.style.top = `${-this.scrollPosition}px`;
    },
    restoreScroll() {
      scheduleOnce('afterRender', this, () => {
        document.body.style.removeProperty('top');
        window.scrollTo(0, this.scrollPosition || 0);
      });
    },
  }
});
