import Component from '@ember/component';
import { computed } from '@ember/object';
import groupBy from 'ember-group-by';
import { findValueInGroupBy } from '../../../helpers/find-value-in-group-by';

export default Component.extend({
  classNameBindings: ['isAnyChannelsOnSiteSelected:is-highlighted'],
  classNames: ['site-filter-site-list_sites-list-item'],
  channelsBySite: null,
  site: null,
  highlightedChannelFilters: null,
  siteFilters: null,
  allowEdit: false,
  highlightedFiltersByChannel: groupBy('highlightedFilters', 'channel.id'),
  highlightedFiltersBySite: groupBy('highlightedFilters', 'site.id'),
  highlightedFiltersOnThisSite: computed(
    'highlightedFiltersBySite.[]',
    'site',
    function() {
      return findValueInGroupBy([
        this.highlightedFiltersBySite,
        this.site.get('id')
      ]);
    }
  ),
  filtersByChannel: groupBy('siteFilters', 'channel.id'),
  isAllChannelsOnSiteSelected: computed(
    'highlightedFiltersOnThisSite.[]',
    'siteFilters.[]',
    function() {
      return (
        this.siteFilters.length === this.highlightedFiltersOnThisSite.length
      );
    }
  ),
  isAnyChannelsOnSiteSelected: computed(
    'highlightedFiltersOnThisSite.[]',
    function() {
      return this.highlightedFiltersOnThisSite.length > 0;
    }
  ),
  init() {
    this._super(...arguments);
    this.highlightedChannelFilters = this.highlightedChannelFilters || [];
    this.siteFilters = this.siteFilters || [];
  },
  actions: {
    toggleFilterTag(filter, tag) {
      this.toggleFilterTag(filter, tag);
    },
    highlightFilter(filter, addToList) {
      this.highlightFilter(filter, addToList);
    },
    highlightAllFiltersOnSite() {
      let sitesToAdd = [];
      if (Array.isArray(this.siteFilters)) {
        sitesToAdd = this.siteFilters;
      } else if (this.siteFilters && Array.isArray(this.siteFilters._objects)) {
        sitesToAdd = this.siteFilters._objects;
      }
      this.bulkHighlightFilters(sitesToAdd, !this.isAnyChannelsOnSiteSelected);
    },
    bulkHighlightFilters(filters, addToList) {
      this.bulkHighlightFilters(filters, addToList);
    }
  }
});
