import DS from 'ember-data';
import { computed } from '@ember/object';
import SubscriptionFilter from './subscription-filter';

export default SubscriptionFilter.extend({
  queryDoc: DS.attr(),
  rawQueryDoc: DS.attr(),
  site: DS.belongsTo('site'),
  notification_items_threshold: DS.attr(),
  tags: computed('queryDoc.[]', {
    get() {
      return this.queryDoc || [];
    },
    set(key, value) {
      this.set('queryDoc', value);
      return value;
    }
  })
});
