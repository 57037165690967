import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  state: null,
  subscription: service(),
  actions: {
    setState(state) {
      this.setState(state)
    }
  }
});
