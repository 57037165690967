define("reach-web-client/components/date-picker", ["exports", "ember-time-tools/components/tt-date-picker"], function (_exports, _ttDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttDatePicker.default;
    }
  });
});
