import Component from '@ember/component';
import {inject as service} from '@ember/service';
import {USER_PROPERTY_EDIT_SAVED} from '../services/analytics';

export default Component.extend({
  store: service(),
  user: null,
  sites: null,
  currentUser: service(),
  analytics: service(),
  intl: service(),
  isSaving: false,
  didInsertElement() {
    this.set('sites', this.store.query('access-site', {
      page: 1,
      page_size: 1000
    }));
  },
  actions: {

    save(user) {
      user.save()
        .then(() => {
          //alert('Site access selections for this User are saved.');
          //alert.intl.t('user-sites-admin.save-user');
          //this.alert.intl.t('user-sites-admin.save-user');
          this.get('isSaving');
          this.analytics.trackMultiple(USER_PROPERTY_EDIT_SAVED, {
            userId: this.user.id
          });
          this.transitionToRoute('user-admin');
        });
    },
    sitesChanged(sites) {
      this.set('user.sites', sites);
      if (this.user.send) {
        this.user.send('becomeDirty');
      }
    },
    resetDirtyForm() {
      this.resetDirtyForm();
    },
    goBack() {
      this.transitionToRoute('user-admin');
    }
  }

});
