import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import constants from '../../../utils/ui-constants';

const { UI_DATE_FORMAT } = constants;

export default Component.extend({
  can: service(),
  inspection: null,
  classNames: ['manage-list-item'],
  classNameBindings: ['isSelected:selected', 'isDisabled:disabled'],
  isSelected: computed('inspection.selected', function() {
    if (this.inspection) {
      return this.inspection.selected;
    }
    return false;
  }),
  isDisabled: computed('inspection', function() {
    return this.can.cannot('edit inspection', this.inspection)
  }),
  listDateFormat: UI_DATE_FORMAT,
  actions: {
    selectInspection() {
      this.inspection.toggleProperty('selected');
    },
    toggleEnabled() {
      this.inspection.toggleProperty('enabled');
      this.inspection.save({adapterOptions: {saveRelationships: false, queryParams: {context: 'inspection'}}});
    }
  }
});
