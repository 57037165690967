import { helper } from '@ember/component/helper';

export function findValueInGroupBy(params /*, hash*/) {
  const [array, value] = params;
  const found = array.findBy('value', value);

  return found ? found.items : [];
}

export default helper(findValueInGroupBy);
