import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import { t } from 'ember-intl';

export default Route.extend(AuthenticatedRouteMixin, NavBarTitleMixin, TenantRouteMixin, BreadcrumbRoute, {
  titleToken: t('navbar.contact'),
  setupController() {
    this.set('breadcrumbs', [{
      name: this.intl.t('navbar.contact')
    }]);

    this._super(...arguments);
  },
});
