import { helper } from '@ember/component/helper';

export function addMoreImagesClass(params/*, hash*/) {
  const value = Number(params[0]) + 1;
  if (value % 3  === 0) {
    return 'right-hand-side'
  }
  return '';

}

export default helper(addMoreImagesClass);
