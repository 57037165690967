import Route from '@ember/routing/route';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import { t } from 'ember-intl';

export default Route.extend(BreadcrumbRoute, TenantRouteMixin, NavBarTitleMixin, {
  titleToken: t('navbar.sites'),
  queryParams: {
    page: {
      refreshModel: true
    },
    site: {
      refreshModel: true
    }
  },

  intl: service(),
  siteManager: service(),
  rootBreadcrumbName: '',
  breadcrumbs: null,
  subBreadcrumbName: '',

  beforeModel() {
    return this._super(...arguments);
  },

  model(params) {
    return this.siteManager.loadSite(params.site_id, params.page, params.page_size)
      .then((site) => {
        this.set('navTitleText', this.intl.t('navbar.' + this.navTitle, {
          siteName: site.get('name'),
          siteId: site.get('id')
        }));
        return site;
      });
  },

  afterModel(model) {
    if (!this.siteManager.site || this.siteManager.site.id !== model.id) {
      if (!model.channels) {
        return this.siteManager.loadChannels(model)
          .then(result => {
            this.set('navTitleText', this.intl.t('navbar.site.index', { siteName: this.siteManager.site.name }));
            return result;
          })
      } else {
        this.siteManager.initializeSite(model);
      }
    }
  },

  setupController() {
    this.set('breadcrumbs', [
      {
        name: this.intl.t('navbar.sites'),
        link: 'sites'
      },
      {
        name: this.siteManager.site ? this.siteManager.site.get('name') : ''
      }
    ]);
    this.controllerFor('application')
      .set('showSearchBarMobile', false);
    this.set('navTitleText', this.intl.t('navbar.site.index', { siteName: this.siteManager.site.name }));
    this._super(...arguments);
  },

  activate() {
    if (!this.siteManager.site) {
      return this._super(...arguments);
    }
    this.set('breadcrumbs', [
      {
        name: this.intl.t('navbar.sites'),
        link: 'sites'
      },
      {
        name: this.siteManager.site.name
      }
    ]);
    this.controllerFor('application')
      .set('showSearchBarMobile', false);
    this._super(...arguments);
  },

  deactivate() {
    this.siteManager.set('channelsLoaded', false);
    this.siteManager.clearScheduledUpdates();
  },

  actions: {
    routeReloadData() {
      if (Ember.testing) {
        return;
      }
      this.siteManager.loadSite(this.siteManager.site.id);
    }
  }
});
