import DS from 'ember-data';
import {singularize} from 'ember-inflector';
import {underscore} from '@ember/string';

export default DS.JSONAPISerializer.extend({
  modelNameFromPayloadKey(key) {
    if (key === 'User') {
      return 'site-user';
    } else if (key === 'Site') {
      return 'access-site';
    }
    return singularize(key)
  },
  payloadKeyFromModelName(modelName) {
    if (modelName == 'site-user') {
      return 'User';
    } else if (modelName == 'access-site') {
      return 'Site';
    }
    return modelName;
  },
  keyForAttribute(attr) {
    return underscore(attr);
  },
});
