import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['user-management-list'],
  users: null,
  allowEdit: false,
  sortingParams: Object.freeze(['creator:desc', 'user.email:asc']),
  sortedUsers: computed.sort('users', 'sortingParams'),
  isInspection: false,
  init() {
    this._super(...arguments);
    this.users = this.users || [];
  },
  actions: {
    removeUserFromList(user) {
      this.removeUserFromList(user);
    },
    toggleAllNotificationMethod(methodType) {
      this.toggleAllNotificationMethod(methodType);
    },
    toggleNotificationMethod(user, type, shouldToggleOn) {
      this.toggleNotificationMethod(user, type, shouldToggleOn);
    }
  }
});
