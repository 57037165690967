import DS from 'ember-data';
import { dasherize } from '@ember/string';

//import {AliasSerializerMixin, PrefixSerializerModelMixin} from '../mixins/subscription/serializer';
//const sub_types = []

export default DS.JSONAPISerializer.extend({
  /**
   * Need to remove the singularize method from here as it changes data to datum
   * @param key
   * @returns {String|*}
   */
  modelNameFromPayloadKey(key) {
    return dasherize(key);
  },
  //payloadTypeFromModelName(key) {

  //}
  keyForAttribute(attr) {
    if (attr === 'values') {
      return 'data';
    }
    return attr;
  }
});
