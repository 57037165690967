import Component from '@ember/component';

export default Component.extend({
  classNames: ['d-none d-lg-block breadcrumb-container fixed-top'],
  actions: {
    callCallback(callback) {
      callback();
    }
  }
});
