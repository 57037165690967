import DS from 'ember-data';

export const STATE_NEW = 1;//'new';
export const STATE_FLAGGED = 2;//'flagged';
export const STATE_VIEWED = 3;//'new';
export const STATE_ARCHIVED = 4;//'archived';

export default DS.Model.extend({
  name: DS.attr(),
  description: DS.attr()
});
