import Component from '@ember/component';

export default Component.extend({
  classNames: ['no-subscriptions-content-card'],
  canCreateSubscription: false,
  actions: {
    createSubscription() {
      this.createSubscription()
    }
  }
});
