import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

import $ from 'jquery'
/**
 *  This mixin is heavily inspired by he Responsive Bootstrap Toolkit
 * it adds hidden divs for each size and updates based on which size the
 * browser is at
 **/
export default Mixin.create({
  windowResize: service(),
  currentScreenSize: '',
  isMobile: computed('currentScreenSize', function() {
    const size = this.currentScreenSize;
    if (size === 'xs' || size === 'sm') {
      return true;
    }
    return false
  }),
  didInsertElement() {
    this._super();
    this.listenForSizeChange(this.$());
  },
  listenForSizeChange(jq) {
    jq.append('<div id="users-device-size"><div id="xs" class="d-block d-sm-none"></div><div id="sm" class="d-none d-sm-block d-md-none"></div><div id="md" class="d-none d-md-block d-lg-none"></div><div id="lg" class="d-none d-lg-block d-xl-none"></div><div id="xl" class="d-none d-xl-block"></div></div>');
    this.windowResize.on('didResize', () => {
      this.detectScreens();
    })
    this.detectScreens();
  },
  detectScreens() {
    if (this.isDestroyed || this.isDestroying) {
      this.windowResize.off('didResize');
      return;
    }
    this.set('currentScreenSize', $('#users-device-size')
      .find('div:visible')
      .first()
      .attr('id'));
  },
});
