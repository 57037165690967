import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';


export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    titleToken: t('navbar.reports'),
    intl: service(),
    report: service(),
    breadcrumbs: null,

    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      site: {
        refreshModel: true
      }
    },

    model(params) {
      return this.store.query('report', {
        page: params.page,
        page_size: params.page_size,
        order: params.order,
        direction: params.direction,
        ghost: params.ghost,
        name: params.name,
        site: params.site,
        context_note: params.context_note,
        creator: params.creator,
        date_time: params.date_time,
      });
    },

    setupController(controller) {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.reports'),
        }
      ]);
      this.set('breadcrumbButtons', [
        {
          route: 'report.create',
          icon: this.get('report.isReportInProgress') ? 'check' : 'plus',
          title: this.get('report.isReportInProgress') ? this.intl.t('report.complete') : this.intl.t('report.create')
        },
        {
          action: 'callCallback',
          callback: controller.openFilters.bind(controller),
          icon: 'filter',
          title: this.intl.t('report.filters')
        }
      ]);

      this._super(...arguments);
    }
  });
