import { Ability } from 'ember-can';
import {inject as service} from '@ember/service';
import {readOnly} from '@ember/object/computed';
import {computed} from '@ember/object';

const PTZ_CONTROL = 'Allow PTZ Control';
const ALLOWED_CHANNEL_TYPES = ['axis channel', 'onvif channel']

export default Ability.extend({
  currentUser: service(),
  featureFlags: service(),
  vhiUser: readOnly('currentUser.vhiUser'),
  permissions: readOnly('vhiUser.permissions'),
  channel: null,
  liveVideo: null,
  canControl: computed('vhiUser', function () {
    if (!this.vhiUser) {
      return false;
    }
    if (!this.channel || !this.channel.get('ptzEnabled')) {
      return false;
    }
    if (this.channel.get('legacyCloudbridge')) {
      return false;
    }
    if (ALLOWED_CHANNEL_TYPES.indexOf(this.channel.get('channelType')) < 0) {
      return false;
    }
    if (!this.liveVideo) {
      return false
    }
    if (!this.featureFlags.isLivePTZControlsEnabled) {
      return false
    }
    if (this.vhiUser.isSuperuser) {
      return true;
    }
    const groups = this.vhiUser.groups;
    if (!groups) {
      return false;
    }

    if (!groups.find(group => group.name == PTZ_CONTROL)) {
      return false;
    }

    return true;
  }),
});
