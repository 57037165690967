import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import TourMixin from 'ember-site-tour/mixins/route-tour';

import { inject as service } from '@ember/service';
import { t } from 'ember-intl';

export default Route.extend(TourMixin, AuthenticatedRouteMixin, TenantRouteMixin, NavBarTitleMixin, BreadcrumbRoute, {
  titleToken: t('navbar.sites'),
  intl: service(),
  breadcrumbs: null,
  queryParams: {
    page: {
      refreshModel: true
    },
    page_size: {
      refreshModel: true
    }
  },
  setupController() {
    this.set('breadcrumbs', [
      {
        name: this.intl.t('navbar.sites')
      }
    ]);

    this._super(...arguments);
  },

  activate() {
    this.controllerFor('application').set('showSearchBarMobile', true);
  },

  deactivate() {
    this.controllerFor('application').set('showSearchBarMobile', false);
  },

  model(params) {
    return this.store.query('site', {
      page: params.page,
      page_size: params.page_size,
      include: 'latest_image'
    })
      .catch(() => {
        return;
      });
  },
});
