import Route from '@ember/routing/route';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import { inject as service } from '@ember/service';

export default Route.extend(UnauthenticatedRouteMixin, {
  tenant: service(),
  beforeModel() {
    let superPromise = this._super(...arguments);
    let currentPromise = this.tenant.load()
      .catch(() => {
        this.transitionTo('error');
      });

    if (superPromise && superPromise.then) {
      return superPromise
        .then(() => {
          return currentPromise;
        });
    } else {
      return currentPromise;
    }
  },
  afterModel() {
    if (!this.tenant.currentTenant) {
      this.transitionTo('error');
    } else {
      return this._super(...arguments);
    }
  },
  activate() {
    this._super(...arguments);
    this.controllerFor('application').set('hideNavBarRoute', true);
    document.body.classList.add('login-body-bg-color');
  },

  deactivate() {
    this._super(...arguments);
    this.controllerFor('application').set('hideNavBarRoute', false);
    document.body.classList.remove('login-body-bg-color');
  },

});
