import { Factory, trait, faker } from 'ember-cli-mirage';

export default Factory.extend({
  dataloggerTest: trait({
    bitToRead: faker.random.number(),
    dataType: faker.random.word(),
    lastReading: faker.random.number(),
    name: faker.hacker.noun(),
    registerAddress: faker.random.number(),
    units: faker.company.bsNoun()
  })
});
