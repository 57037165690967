import Service from '@ember/service';

/**
 * This service is used to remember the infinite-scroll model used on inspections route.
 * When the user is done viewing a report, we restore the list back to the same spot.
 * NOTE: method to save and restore scroll position inspired by ember-router-scroll
 * see: https://github.com/DockYard/ember-router-scroll/blob/master/addon/index.js
 */
export default Service.extend({
  model: null,
  scrollPosition: null,

  init() {
    this._super(...arguments);
    this.scrollPosition = { x: 0, y: 0 };
  },

  update(infinityModel, scrollPosition) {
    this.set('model', infinityModel);
    this.set('scrollPosition', scrollPosition)
  },

  flush() {
    this.set('model', null);
    this.scrollPosition = { x: 0, y: 0 };
  }
});
