import Component from '@ember/component';

export default Component.extend({
  color: null,
  schedule: null,
  actions: {
    toggleShowDialog() {
      this.toggleShowDialog()
    },
    removeSchedule(schedule, color) {
      this.removeSchedule(schedule, color)
    }
  }
});
