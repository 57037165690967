import Component from '@ember/component';

export default Component.extend({
  actions: {
    onToDateChanged(date) {
      this.toDateChanged(date);
    },
    onFromDateChanged(date) {
      this.fromDateChanged(date);
    }
  }
});
