import { JSONAPISerializer } from 'ember-cli-mirage';
import { underscore, dasherize } from '@ember/string';
import { pluralize } from 'ember-inflector';

export default JSONAPISerializer.extend({
  alwaysIncludeLinkageData: true,
  keyForAttribute(attr) {
    return underscore(attr);
  },
  typeKeyForModel(model) {
    let typeKey = model.typeKey || model.modelName
    return dasherize(pluralize(typeKey))
  },
  serialize(object, request) {
    let json = JSONAPISerializer.prototype.serialize.apply(this, arguments);
    if (request.queryParams['page'] && request.queryParams['page_size'] && request.queryParams['page_size'] !== 1000) {
      const page = parseInt(request.queryParams['page']);
      const size = parseInt(request.queryParams['page_size']);

      json = this.paginate(json, page, size);
    }
    return json;
  },
  paginate(res, page, size) {
    if (!size) {
      //If this gets hit, page size was not defined properly on the
      //adapter query. adding this execption here is after debugging
      //through the mirage internels for 2 hours because
      //the data attribute on my model in tests was empty... Richard Aug 2019
      throw 'invalid page size passed to mirage paginate serializer'
    }
    const slicedResults = (results) => {
      const start = (page - 1) * size;
      const end = start + size;
      if (!Array.isArray(results)) {
        results = [results];
      }
      return results.slice(start, end);


    };
    const isFirstPage = (currentPage) => ((currentPage) === 1)
    const hasNextPage = (currentPageRes, pageSize) => (currentPageRes.length === pageSize)

    const paginatedResults = slicedResults(res.data)

    res.previous = isFirstPage(page) ? null : `https://test.com/api/channels/?include=latest_image&order=name&page=${page - 1}&page_size=${size}`;
    res.next = hasNextPage(paginatedResults, size) ? `https://test.com/api/channels/?include=latest_image&order=name&page=${page + 1}&page_size=${size}` : null;
    res.count = res.data.length;
    res.data = paginatedResults;
    return res;
  },
});
