import DS from 'ember-data';
import { computed } from '@ember/object';
import moment from 'moment';

export default DS.Model.extend({
  firstTimestamp: DS.attr(),
  lastTimestamp: DS.attr(),
  state: DS.attr(),
  subscription: DS.belongsTo('subscription/subscription'),
  event: DS.belongsTo('subscription/event'),
  subscribableType: DS.attr(),
  day: computed('firstTimestamp', function() {
    let timestamp = moment(this.firstTimestamp);
    if (timestamp.isSame(moment(), 'day')) return 'Today';
    if (timestamp.isSame(moment().subtract(1, 'day'), 'day')) return 'Yesterday';
    return timestamp.format('MMMM D, YYYY');
  })
});
