import DS from 'ember-data';

export default DS.Model.extend({
  scheduledTime: DS.attr('reach-time'),
  timezone: DS.attr(),
  user: DS.belongsTo('basic-user'),
  created: DS.attr(),
  modified: DS.attr(),


});
