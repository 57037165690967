import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  keyForRelationship (key) {
    if (key === 'ptzPreset') {
      return 'ptz_preset';
    }
    return this._super(...arguments);
  }
});
