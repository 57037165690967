import Mixin from '@ember/object/mixin';
const MODAL_OPEN = 'modal-open';

export default Mixin.create({
  resetScrolling() {
    document.body.classList.remove(MODAL_OPEN);
  },
  actions: {
    openSelect () {
      document.body.classList.add(MODAL_OPEN);
    },
    closeSelect() {
      this.resetScrolling();
    }
  }
});
