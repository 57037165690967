import constants from './ui-constants';

const {
  REACH_MEDIA_BREAKPOINT_DOWN_XS_THRESHOLD,
  LANDSCAPE_AND_LOW_HEIGHT,
  BOOTSTRAP_MOBILE_BREAKPOINT_DOWN,
  PAPER_DIALOG_FULLSCREEN_BREAKPOINT,
} = constants;

export const isMobile = (isBootstrapComponent) =>
  isBootstrapComponent
    ? window.matchMedia(BOOTSTRAP_MOBILE_BREAKPOINT_DOWN).matches
    : window.matchMedia(REACH_MEDIA_BREAKPOINT_DOWN_XS_THRESHOLD).matches
    || window.matchMedia(LANDSCAPE_AND_LOW_HEIGHT).matches;

/**
 * Determine if a Paper dialog is full screen.
 * If so, in the enlarged image view, we display a back arrow icon instead of an 'X'.
 * @returns {boolean} true if a paper dialog is full screen
 */
export const isPaperDialogFullscreen = () => window.matchMedia(PAPER_DIALOG_FULLSCREEN_BREAKPOINT).matches;

/**
 * Determine if device is iOS.
 * WARNING: DO NOT use this function unless you must support special behavior that only applies to mobile Safari
 * iOS detection from: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 */
export const isIos = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};
