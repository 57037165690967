define("reach-web-client/components/tt-date-navigation", ["exports", "ember-time-tools/components/tt-date-navigation"], function (_exports, _ttDateNavigation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttDateNavigation.default;
    }
  });
});
