import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  bitToRead: DS.attr(''),
  datatype: DS.attr(''),
  flag: DS.attr(''),
  last_reading: DS.attr(''),
  name: DS.attr(''),
  registerAddress: DS.attr(''),
  units: DS.attr(''),
  data_logger: DS.belongsTo('datalogger'),
  datapoints: DS.hasMany('datapointvalue'),
  truncatedLastReading: computed('last_reading', function() {
    return isNaN(this.last_reading)
      ? this.last_reading
      : Math.round(Number(this.last_reading) * 100) / 100;
  })
});
