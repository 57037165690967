import Service from '@ember/service';
import ENV from '../config/environment';
import { computed } from '@ember/object';
import Ember from 'ember';

import { inject as service } from '@ember/service';
import { INSPECTION_REPORT_DOWNLOADED } from './analytics';

const EVENT_REPORT = 'sockets/subscription-report';

export default Service.extend({
  websockets: service(),
  session: service(),
  tenant: service(),
  store: service(),
  pendingReports: null,
  socket: null,
  flirType: null,
  analytics: service(),
  host: computed('tenant.subdomain', function() {
    let subdomain = this.get('tenant.subdomain');
    return ENV.vhiWsProtocol + '://' + subdomain + '.' + ENV.vhiServer;
  }),

  init() {
    this._super(...arguments);
    this.pendingReports = [];
    if (!Ember.testing) {
      this.connect();
    }
  },
  buildReportURL() {
    let path;
    let url = [];
    let host = this.host;
    url.push(host);
    path = EVENT_REPORT;
    url.push(path);

    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    url = url + '/';
    let access_token = this.get('session.data.authenticated.access_token');
    url = `${url}?access_token=${access_token}`;
    return url;

  },

  isPending(eventId) {
    return this.pendingReports.find(id => id === eventId)
  },

  getReportUrl(eventId) {
    const tenant = this.tenant.subdomain;
    this.analytics.trackMultiple(INSPECTION_REPORT_DOWNLOADED, {
      eventId: eventId,
      tenant: tenant
    });

    if (!this.pendingReports.includes(eventId)) {
      this.pendingReports.push(eventId);
      this.notifyPropertyChange('pendingReports')
    }

    if (this.socket.readyState()) {
      this.socket.send({event_id: eventId, tenant: tenant}, true);
    }
  },

  connect() {
    let url = this.buildReportURL();
    const socket = this.websockets.socketFor(url);
    socket.on('message', this.onMessage, this);
    socket.on('close', this.onClose, this);
    socket.on('open', this.onOpen, this);
    this.set('socket', socket);
  },
  onMessage(message) {
    const messageData = JSON.parse(message.data);
    const eventId = messageData['event_id'] && messageData['event_id'].toString();
    if (messageData.status) {
      if (messageData.status === 'failed') {
        this.socket.close();
      }

    } else if (eventId && this.pendingReports.includes(eventId)) {
      this.pendingReports.splice(this.pendingReports.indexOf(messageData['event_id']));
      this.notifyPropertyChange('pendingReports');
      if (!(this.isDestroyed || this.isDestroying)) {
        let pdf = this.store.createRecord('media');
        pdf.userFriendlyDownloadName = messageData['name'] + '.pdf';
        pdf.mediaType = 'application/pdf';
        pdf.downloadUrl = messageData['url'];
        pdf.download()
      }
    }
  },

  onClose() {
    let url = this.buildReportURL();
    const socket = this.websockets.socketFor(url);
    socket.reconnect();
    this.set('socket', socket);
  },

  onOpen() {
    if (this.pendingReports) {
      this.pendingReports.forEach(eventId => this.getReportUrl(eventId))
    }
  },

  disconnect() {
    const socket = this.socket;
    socket.off('close', this.onClose);
    socket.off('message', this.onMessage);
    socket.off('open', this.onOpen)
  }
});
