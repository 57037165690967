import Mixin from '@ember/object/mixin';

import moment from 'moment';
const MINUTE = 'minute';

export default Mixin.create({
  buildURL(modelName, id, snapshot, action) {
    let url = this._super(...arguments) || '';
    if (action === 'updateRecord') {
      return url;
    }
    if (url.indexOf('?') >= 0) {
      url += '&';
    } else {
      url += '?';
    }
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11 && this.alwaysReload) {
      return url + 'reload=' + moment()
        .format('X');
    }
    return url + 'reload=' + moment()
      .startOf(MINUTE)
      .format('X');
  }
});
