import PaperButton from 'ember-paper/components/paper-button';

export default PaperButton.extend({
  classNames: ['reach-icon-btn'],
  iconButton: true,
  noink: true,
  disabled: false,
  attributeBindings: ['type'],
  type: 'button'
});
