import ApplicationSerializer from './application';
import { underscore } from '@ember/string';
import { CustomRelationshipKeyMixin } from '../mixins/subscription/serializer';


const PERSISTABLE_KEYS = [
  'mute',
  'maintenanceMode'
];

const CHANNEL_RELATIONSHIP_ALIASES = {
  latest_image: 'latest-image',
  cv_features: 'cv-features'
};

export default ApplicationSerializer.extend(CustomRelationshipKeyMixin, {
  relationship_key_aliases: CHANNEL_RELATIONSHIP_ALIASES,

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (requestType === 'updateRecord' && Array.isArray(payload)) {
      payload[0].id = Number(id);
    }
    return this._super(...arguments);
  },
  serialize(snapshot) {
    let json = {
      id: parseInt(snapshot.id, 10)
    };

    if (snapshot.adapterOptions && snapshot.adapterOptions.mute) {
      json.slugs = snapshot._attributes.slug;
      json.duration = snapshot.adapterOptions.duration;
    } else {
      snapshot.eachAttribute((key) => {
        if (PERSISTABLE_KEYS.includes(key)) {
          json[underscore(key)] = snapshot.attr(key);
        }
      });
    }
    return json;
  }
});
