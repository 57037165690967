import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

const format = 'h:mm a';

export default Component.extend({
  intl: service(),
  disabled: false,
  scheduleStart: computed('schedule.start', function() {
    // translate creator time (stored in db) to users timezone (as detected by browser)
    const creatorTime = moment.tz(this.schedule.start, format, this.schedule.timezone);
    return creatorTime.tz(moment.tz.guess()).format(format);
  }),
  init() {
    this._super(...arguments);
    this.weekDayMap = {
      MO: this.intl.t('weekday.monday-triple'),
      TU: this.intl.t('weekday.tuesday-triple'),
      WE: this.intl.t('weekday.wednesday-triple'),
      TH: this.intl.t('weekday.thursday-triple'),
      FR: this.intl.t('weekday.friday-triple'),
      SA: this.intl.t('weekday.saturday-triple'),
      SU: this.intl.t('weekday.sunday-triple')
    };
  },
  classNames: ['inspections-schedule_schedule-card-container'],
  daysAsList: computed('schedule.recurrence', function() {
    const isRecurrenceAllWeek =
      this.schedule.recurrence && this.schedule.recurrence.length === 7;
    if (isRecurrenceAllWeek) {
      return this.intl.t('inspections.schedule-picker.every-day');
    }
    return this.schedule.recurrence ? this.schedule.recurrence.map(day => this.weekDayMap[day]).join(', ') : '';
  }),
  actions: {
    updateTime(time) {
      this.set('schedule.start', moment.utc(time, format).format(format));
    },
    toggleEditMode() {
      this.toggleProperty('schedule.editMode');
    },
    deleteSchedule() {
      this.deleteSchedule(this.schedule);
    },
    saveSchedule() {
      this.saveSchedule(this.schedule);
      this.toggleProperty('schedule.editMode');
    },
    toggleWeekDay(day) {
      const index = this.schedule.recurrence.indexOf(day);
      if (index === -1) {
        this.set('schedule.recurrence', [...this.schedule.recurrence, day]);
      } else {
        const newDays = this.schedule.recurrence.filter(
          weekDay => weekDay !== day
        );
        this.set('schedule.recurrence', newDays);
      }
    }
  }
});
