import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { CHANNEL_REFRESHED } from '../services/analytics';

export default Component.extend({
  intl: service(),
  analytics: service(),
  store: service(),
  classNames: ['btn', 'btn-reach', 'capture-image'],
  tagName: 'button',
  attributeBindings: ['type', 'data-toggle', 'data-placement', 'title'],
  type: 'button',
  'data-toggle': 'tooltip',
  'data-placement': 'bottom',
  title: '',

  init() {
    this._super(...arguments);
    this.set('title', this.intl.t('tooltips.capture-image'));
  },

  click() {
    this.analytics.trackMultiple(CHANNEL_REFRESHED, {
      channelId: this.get('channel.id'),
      channelSlug: this.get('channel.slug'),
      channelName: this.get('channel.name'),
      siteName: this.get('site.name'),
      siteId: this.get('site.id')
    });

    this.set('error', null);
    this.set('refreshing', true);
    this.set('streaming', false);
    return this.store
      .queryRecord('media', { channelSlug: this.get('channel.slug') })
      .then(result => {
        this.set('channel.latestImage', result);
        this.set('image', result);
        this.set('refreshing', false);
      })
      .catch(() => {
        //do something with failed to update
        this.set('error', this.intl.t('site.details.camera-update-failed'));
        this.set('refreshing', false);
      });
  }
});
