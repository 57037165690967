import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import apiConstants from '../../utils/api-constants';
import makeExtendedInfinityModel from '../../utils/MakeExtendedInfinityModel';
import { INSPECTION_REPORT_VIEWED } from '../../services/analytics';

const { DEFAULT_EVENT_ITEMS_PAGE_SIZE } = apiConstants;

export default Route.extend(
  AuthenticatedRouteMixin,
  TenantRouteMixin,
  {
    analytics: service(),
    featureFlags: service(),
    tenant: service(),
    eventReport: service(),
    infinity: service(),
    inspectionScroll: service(),

    async model(params) {
      if (!(this.featureFlags.isInspectionsEnabled && this.tenant.isInspectionsEnabled)) {
        this.transitionTo('index');
      }

      return RSVP.hash({
        event: this.store.findRecord(
          'subscription/event',
          params.inspection_id, {
            reload: true
          }
        ),
        items: this.infinity.model('subscription/event-item-reference', {
          pageParam: 'page_number',
          event_id: params.inspection_id,
          perPage: DEFAULT_EVENT_ITEMS_PAGE_SIZE,
          countParam: 'meta.pagination.count',
          perPageParam: 'page_size',
          totalPagesParam: 'meta.pagination.pages',
          include: ['content.image', 'content.eventitemerror', 'content.channel.site', 'content.image.channel', 'content.ptz_preset'].join(','),
          sort: 'report_ordinal'
        }, makeExtendedInfinityModel('subscription/event-item-reference'))
      });
    },

    setupController(controller, model) {
      const { event, items } = model;

      this._super(...arguments);
      controller.set('model', event);
      controller.set('items', items);

      const subscription = event.get('subscription');
      this.analytics.trackMultiple(INSPECTION_REPORT_VIEWED, {
        tenant: this.tenant.subdomain,
        subscriptionId: subscription.get('id'),
        inspectionId: event.get('id'),
        subscriptionName: subscription.get('name')
      });

      // mark this event as viewed if user has opened the inspection details page
      controller.viewEvent();

      controller.set('selected', items.objectAt(0));
    },

    /**
      * NOTE: if a user has zoomed into an image and then clicks back button, we want to ensure the controller
      *       is reset to the default state, so the next inspection opened starts in a fresh state.
      * @param controller
     **/
    resetController(controller) {
      controller.set('errors', null);
      controller.set('selected', null);
      controller.set('showZoomedImage', false);
      controller.set('hasToScroll', false);
    },

    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', true);

      // wire up key handlers for controller
      this.controllerFor('inspection/index')
        .set('keyboardActivated', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', false);

      // unhook key handlers for controller
      this.controllerFor('inspection/index')
        .set('keyboardActivated', false);
    },

    actions: {
      willTransition(transition) {
        const isReturningToList = transition.to.name === 'inspections';
        if (!isReturningToList) {
          // zap the cached infinite scroll if we are not going back to the list we scrolled down in
          this.get('inspectionScroll').flush();
        }
      }
    }
  });
