import Component from '@ember/component';
import moment from 'moment';
import {observer} from '@ember/object';
import {inject as service} from '@ember/service';
import { debounce } from '@ember/runloop';

const CHART_OPTIONS = {
  chart: {
    type: 'column',
    height: '430',
    width: '707'
  },
  title: false,
  xAxis: {
    title: {
      text: 'Date'
    },
    type: 'category',
    tickInterval: 1,
    gridLineWidth: 1,
    reversed: true,
    gridLineColor: 'transparent',
    labels: {
      enabled: true
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: 'Number of Alerts'
    },
    tickInterval: 1,
    gridLineWidth: 1
  },
  legend: {
    enabled: true
  },
  styledMode: {
    enabled: true
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      depth: 20,
      pointPadding: 0.05,
      borderWidth: 0,
      groupPadding: 0.05,
      relativeXValue: true
    }
  },
}

export default Component.extend({
  classNames: ['dashboard-alert-activity'],
  chartOptions: CHART_OPTIONS,
  store: service(),
  alerts: null,
  chartData: null,
  noAlertsAvailable: true,
  init() {
    this._super(...arguments);
    if (this.alerts) {
      this.generateChartData();
    }
  },
  watchAlerts: observer('alerts', 'alerts.@each.totalEventItems', function() {
    debounce(this, this.generateChartData, 2500);
  }),
  generateChartData() {
    let dateBucket = {};
    let availTags = [];
    this.alerts.forEach((event) => {
      let date = moment(event.firstTimestamp)
        .format('DD MMM YYYY');
      if (event.tags) {
        event.tags.forEach(tag => {
          if (!dateBucket.hasOwnProperty(date)) {
            dateBucket[date] = {};
          }
          if (!dateBucket[date].hasOwnProperty(tag)) {
            dateBucket[date][tag] = 1;
          } else {
            dateBucket[date][tag] += 1;
          }
          if (availTags.indexOf(tag) < 0) {
            availTags.push(tag);
          }
        })
      }
    });

    let hcSeries = []
    this.set('noAlertsAvailable', (availTags.length <= 0));
    for (let tag in availTags) {
      const tagName = availTags[tag]
      let hcTagData = {
        'name': tagName,
        'data': [],
        'stack': [],
        'pointStart': moment.utc(),
      };
      for (let key in dateBucket) {
        if (dateBucket[key].hasOwnProperty(tagName)) {
          hcTagData['data'].push([(key), dateBucket[key][tagName]]);
          hcTagData['stack'].push(key);
        } else {
          hcTagData['data'].push(null);
          hcTagData['stack'].push(null);
        }
      }
      if (hcTagData.name.length >= 2)
        hcSeries.push(hcTagData);
    }
    this.set('chartData', hcSeries);
  }
});
