define("reach-web-client/helpers/alert", ["exports", "ember-ui-components/helpers/alert"], function (_exports, _alert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "alert", {
    enumerable: true,
    get: function get() {
      return _alert.alert;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _alert.default;
    }
  });
});
