import DS from 'ember-data';
import moment from 'moment';

export default DS.Transform.extend({
  deserialize(serialized) {
    const time = moment(serialized, 'HH:mm:ss')
    return time.format('h:mm:ss a');
  },

  serialize(deserialized) {
    const time = moment(deserialized, 'h:mm:ss a')
    return time.format('HH:mm:ss');
  }
});
