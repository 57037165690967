import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
  visible: false,
  classNames: ['pdf-modal'],
  init() {
    this._super(...arguments);
  },
  didRender() {
    this.$('.modal').on('hide.bs.modal', () => {
      this.set('visible', false);
    })
  },
  watchVisible: observer('visible', function() {
    if (this.visible) {
      this.$('.modal').modal('show');
    } else {
      this.$('.modal').modal('hide');
    }
  }),
  actions: {
    closePdfModal() {
      this.set('visible', false);
    },
    durationUpdated(duration) {
      this.set('selectedDuration', duration);
    }

  }
});
