import Component from '@ember/component';

export default Component.extend({
  classNames: ['user-management-list-item_access-toggle-button'],
  allowEdit: false,
  disabled: false,
  click() {
    if (this.allowEdit && !this.disabled) {
      this.onClick();
    }
  }
});
