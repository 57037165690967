import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { t } from 'ember-intl';
import moment from 'moment';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import apiConstants from '../utils/api-constants';
import { INSPECTION_LIST_PAGE_VIEWED } from '../services/analytics';
import { isMobile } from '../utils/responsive';

const { DEFAULT_INSPECTIONS_PAGE_SIZE } = apiConstants;
const LIST_CONTENT = '.inspection-reports_list-container .manage-list_list-content';

const getScrollPosition = (selector) => {
  const element = document.querySelector(selector);
  return element ? { x: element.scrollLeft, y: element.scrollTop } : { x: 0, y: 0 };
}

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    analytics: service(),
    infinity: service(),
    featureFlags: service(),
    tenant: service(),
    subscription: service(),
    inspectionScroll: service(),

    titleToken: t('navbar.inspections'),

    queryParams: {
      subscription: {
        refreshModel: true,
      },
      before_date: {
        refreshModel: true,
      }
    },

    beforeModel() {
      return this._super(...arguments)
        .then(() => { return this.subscription.ready });
    },

    async model(params) {
      this.set('modelQueryParams', params);
      if (!(this.featureFlags.isInspectionsEnabled && this.tenant.isInspectionsEnabled)) {
        this.transitionTo('index');
        return;
      }
      if (!this.featureFlags.isViewInspectionReportsEnabled) {
        this.transitionTo('inspection.manage');
        return;
      }

      // when the user scrolls down and clicks on an inspection to see a report,
      // and then the user closes the report,
      // this page must restore the list back to the same scroll location
      const cachedInfinityModel = this.get('inspectionScroll.model');
      if (cachedInfinityModel) {
        return cachedInfinityModel;
      }

      const model = await this.infinity.model(
        'subscription/event',
        {
          subscription: params.subscription,
          before_date: params.before_date,
          perPage: DEFAULT_INSPECTIONS_PAGE_SIZE,
          perPageParam: 'page_size',
          pageParam: 'page_number',
          totalPagesParam: 'meta.pagination.pages',
          context: 'inspection',
          'filter[subscription.type]': this.get('subscription.inspectionType.id'),
        });

      // store ref to infinityModel without a proxy wrapper
      this.set('infinityModel', model);

      return model;
    },

    setupController(controller) {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.inspections')
        }
      ]);

      this.set('breadcrumbButtons', [
        {
          route: 'inspection.manage',
          graphic: 'settings-icon',
          title: this.intl.t('inspections.manage')
        }
      ]);

      this._super(...arguments);

      // populate choices for inspection report titles dropdown
      this.store.query('subscription/subscription', {
        page: 1,
        page_size: 1000,
        context: 'inspection',
        reload: moment().format('X')
      }).then(result => {
        controller.set('subscriptions', result);
        const params = this.get('modelQueryParams');
        if (params.subscription) {
          const selected = result.find(sub => sub.id === params.subscription);
          controller.set('_subscription', selected);
        }
      });

      this.analytics.trackMultiple(INSPECTION_LIST_PAGE_VIEWED, {
        tenant: this.tenant.subdomain
      });
    },

    // when in mobile mode, the entire window can scroll
    // when in desktop mode, only the manage-list can scroll
    getScrollingListPos() {
      return isMobile(false)
        ? { x: window.pageXOffset, y: window.pageYOffset }
        : getScrollPosition(LIST_CONTENT);
    },

    setScrollingListPos({ x, y }) {
      if (isMobile(false)) {
        window.scrollTo(x, y);
      } else {
        const element = document.querySelector(LIST_CONTENT);
        if (element) {
          element.scrollLeft = x;
          element.scrollTop = y;
        }
      }
    },

    actions: {
      willTransition(transition) {
        // when leaving this route, remember the scroll position if opening a report
        const isViewingReport = transition.to.name === 'inspection.index';
        if (isViewingReport) {
          this.get('inspectionScroll')
            .update(this.get('infinityModel'), this.getScrollingListPos());
        }
      },
      didTransition() {
        // once we have loaded this route, we need to restore scroll position
        scheduleOnce('afterRender', this, (scrollPosition) => {
          // clear cached scrollPosition, once it is consumed.
          this.get('inspectionScroll').flush();
          this.setScrollingListPos(scrollPosition);
        }, this.get('inspectionScroll.scrollPosition'));
      },
    }
  }
);
