import Component from '@ember/component';
import { inject as service } from '@ember/service';
import moment from 'moment';
export default Component.extend({
  classNames: ['reports-filter', 'popup-sidebar'],
  store: service(),
  siteFilter: null,
  titleFilter: null,
  notesFilter: null,
  authorFilter: null,
  authors: null,
  dateFilter: null,
  init() {
    this._super(...arguments);
    this.set('selectedDate', moment(this.dateFilter));
  },
  actions: {
    dateChanged(date) {
      let isoDate = moment(date).toISOString();
      this.set('dateFilter', isoDate);
    },
    siteSelected(site) {
      if (site) {
        this.set('siteFilter', site.get('id'));
      } else {
        this.set('siteFilter', null);
      }
    },
    authorSelected(author) {
      if (author) {
        this.set('authorFilter', author.get('id'));
      } else {
        this.set('authorFilter', null);
      }
    },
    hidePopup(event) {
      if (event  &&
        event.target.parentElement &&
        event.target.parentElement.className.indexOf('ember-power-select') < 0) {
        this.hidePopup();
      }
    },
    clearDate() {
      this.set('dateFilter', null);
    }

  }
});
