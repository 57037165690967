import Component from '@ember/component';
import { inject as service } from '@ember/service';
import Ember from 'ember';

export default Component.extend({
  intl: service(),
  classNames: ['report-header', 'col-12'],
  reportData: null,
  store: service(),
  errors: null,

  init() {
    this._super(...arguments);
    this.errors = {};
  },

  actions: {
    onCreate() {
      this.set('errors', {});
      if (!this.get('reportData.name')) {
        this.set('errors.reportName', true);
        return;
      }
      this.createReport();
    },
    onContinue() {
      this.addMoreImages();
    },
    onCancel() {
      if (Ember.testing || window.confirm(this.intl.t('report.create-report.cancel-warning'))) {
        //clear everything
        this.cancelReport();
      }
    }
  }
});
