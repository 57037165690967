import TileLayer from 'ember-leaflet/components/tile-layer';
import leafletHelper from '../../utils/leaflet-helper';

export default TileLayer.extend({
  createLayer() {
    // if bing, ignore url property and create bing layer using bing plugin
    if (this.get('isBing')) {
      return leafletHelper.createBingLayer();
    }

    // create this layer in the default manner if not bing

    // NOTE: didInsertParent() will call addToContainer() below. This will swap the mini-map layer
    return this._super(...arguments);
  },

  addToContainer() {
    const miniMap = this.get('parentComponent._miniMap');
    if (miniMap) {
      miniMap.changeLayer(this._layer);
    }
  },

  removeFromContainer() {
    // override to do nothing
    // for the mini-map all we need is to call changeLayer(), removeLayer() is not needed.
  }
});
