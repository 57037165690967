import MaterialCard from '../common/md-card';
import { inject as service } from '@ember/service';
import { observer } from '@ember/object';
import { computed } from '@ember/object';

export default MaterialCard.extend({
  classNames: ['site-picker_ptz-position-card'],
  classNameBindings: ['isSelected:is-selected'],
  selectedFilters: null,
  ptz: null,
  store: service(),
  // TODO: once backend supports loading latest image for PTZ, remove watchPreview(), and reloadPreview()
  watchPreview: observer('ptz', function() {
    this.reloadPreview();
  }),
  isSelected: computed('selectedFilters.[]', function() {
    return this.selectedFilters.length > 0;
  }),
  init() {
    this._super(...arguments);
    this.selectedFilters = this.selectedFilters || [];
  },
  didInsertElement() {
    this._super(...arguments);
    this.reloadPreview();
  },
  reloadPreview() {
    if (this.ptz.channel) {
      this.store
        .query('image', {
          channel: this.get('ptz.channel.id'),
          preset: this.ptz.id,
          limit: 1,
          page_size: 10
        })
        .then(result => this.set('ptzPreview', result.objectAt(0)))
        .catch(() => this.set('ptzPreview', null));
    }
  },
  click() {
    this.selectPtz(this.channel, this.ptz);
    return false;
  },
  actions: {
    selectPtz() {
      this.selectPtz(this.channel, this.ptz);
    }
  }
});
