import DS from 'ember-data';
import { computed } from '@ember/object';
import moment from 'moment';

export default DS.Model.extend({
  timestamp: DS.attr(),
  isoTimestamp: computed('timestamp', function() {
    return moment(this.timestamp).toISOString();
  }),
  momenttimestamp: computed('timestamp', function() {
    return moment(this.timestamp);
  })
});
