define("reach-web-client/components/freestyle-dynamic-input", ["exports", "ember-freestyle/components/freestyle-dynamic-input"], function (_exports, _freestyleDynamicInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _freestyleDynamicInput.default;
    }
  });
});
