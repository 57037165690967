import Component from '@ember/component';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { isArray } from '@ember/array';
import { filter } from '@ember/object/computed';

const DISTRIBUTION = 'even';
const LIMIT = 3;

export default Component.extend({
  selectedChannel: null,
  classNames: ['reach-add-more-images'],
  loading: false,
  intl: service(),
  store: service(),

  filteredChannels: filter('channels', function(item) {
    const activeChannels = this.activeChannels;
    if (isArray(this.activeChannels)) {
      return activeChannels.indexOf(item.get('id')) >= 0;
    }
    //this can be a single string...query params are weird
    return activeChannels == item.get('id');
  }),

  getImages () {
    this.set('loading', true);
    let startTime = null;
    let endTime = null;
    const image = this.image;
    let nextImage = null;
    const allImages = this.allImages;
    if (allImages) {
      allImages.some((item, ci) => {
        if (item.get('id') === image.get('id')) {
          nextImage = allImages.objectAt(ci + 1);
        }
      });
    }

    // determine start and end time
    //get new images at start
    if (this.index === -1) {
      startTime = moment(image.timestamp);
      endTime = startTime.clone();
      endTime.add(10, 'minutes');
    } else {
      endTime = moment(image.get('timestamp'));
      if (nextImage) {
        startTime = moment(nextImage.get('timestamp'));
      } else {
        // loading the last item in the list
        startTime = endTime.clone();
        startTime.subtract(10, 'minutes');
      }
    }
    // constrict the time window by .1 second on each end to avoid returning images
    // that are already represented in the list.
    startTime.add(0.1, 'second');
    endTime.subtract(0.1, 'second');
    this.store.query('media', {
      page_size: 10,
      channel: this.get('selectedChannel.slug'),
      to: endTime.toISOString(),
      from: startTime.toISOString(),
      limit: LIMIT,
      distribution: DISTRIBUTION,
      images: true
    })
      .then(results => {
        if (results.get('length') === 0) {
          //no new images available, request from cloudbridge
          if (!this.get('selectedChannel.cloudbridge')) {
            this.set('error', this.intl.t('review-stream.no-more-images'));
            this.set('loading', false);
            return;
          }
          this.store.query('media', {
            id: this.get('selectedChannel.cloudbridge'),
            channel: this.get('selectedChannel.slug'),
            to_date: endTime.toISOString(),
            from_date: startTime.toISOString(),
            count: LIMIT,
            cloudbridge: true
          })
            .then(imageData => {
              if (imageData.length === 0) {
                this.set('error', this.intl.t('review-stream.no-more-images'));
              } else {
                this.addMoreImages(imageData);
              }
              this.set('loading', false);
              this.set('selectedChannel', null);
            })
            .catch(() => {
              this.set('error', this.intl.t('review-stream.error-getting-images'));
              this.set('loading', false);
              this.set('selectedChannel', null);
            })

        } else {
          this.addMoreImages(this.index, results)
          this.set('loading', false);
          this.set('selectedChannel', null);
        }
      })
      .catch(() => {
        this.set('error', this.intl.t('review-stream.error-getting-images'));
        this.set('loading', false);
        this.set('selectedChannel', null);
      })
  },

  actions: {
    hideError() {
      this.set('error', null);
    },
    hidePopup(event) {
      if (event.currentTarget.activeElement.classList.contains('ember-power-select-trigger')) {
        return;
      }
      this.set('loading', false);
      this.set('showChannelPicker', false);

    },
    channelSelected(channel) {
      this.set('selectedChannel', channel);
    },
    loadMoreImages() {
      if (this.selectedChannel) {
        this.set('showChannelPicker', false);
        this.getImages();
      }
    },
    plusButtonClicked() {
      this.set('error', null);
      const channels = this.filteredChannels;
      if (channels.length === 1) {
        this.set('selectedChannel', channels.objectAt(0));
      }
      if (this.selectedChannel) {
        this.getImages();
      } else {
        this.set('showChannelPicker', true);
      }
    },
    closeError() {
      this.set('error', null);
    }
  }
});
