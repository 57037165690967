import MaterialCard from '../common/md-card';
import { computed } from '@ember/object';

export default MaterialCard.extend({
  classNames: ['site-picker_channel-card', 'channel-picker-item'],
  classNameBindings: ['isSelected:is-selected'],
  selectedChannelIDs: null,
  channel: null,
  filtersOnChannel: null,
  init() {
    this._super(...arguments);
    this.filtersOnChannel = this.filtersOnChannel || [];
  },
  isSelected: computed('filtersOnChannel.[]', 'channel', function() {
    const filters = this.filtersOnChannel;
    return filters ? filters.length > 0 : false;
  }),
  click() {
    this.selectChannel(this.channel);
  },
  actions: {
    selectChannel(channel) {
      this.selectChannel(channel);
    }
  }
});
