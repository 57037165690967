import Component from '@ember/component';

export default Component.extend({
  actions: {
    saveInspectionWithoutReport() {
      this.saveInspection();
      this.toggleImmediateReportDialog();
    },
    generateInspectionReport() {
      this.generateInspectionReport();
      this.toggleImmediateReportDialog();
    }
  }
});
