define("reach-web-client/helpers/open-context-menu", ["exports", "ember-ui-components/helpers/open-context-menu"], function (_exports, _openContextMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _openContextMenu.default;
    }
  });
  Object.defineProperty(_exports, "openContextMenu", {
    enumerable: true,
    get: function get() {
      return _openContextMenu.openContextMenu;
    }
  });
});
