import Ember from 'ember';
import Route from '@ember/routing/route';
import EmberObject from '@ember/object'
import { inject as service } from '@ember/service';
import moment from 'moment';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import ConfirmationMixin from 'ember-onbeforeunload/mixins/confirmation';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';

const DEFAULT_INSPECTION_RECURRENCE = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
const DEFAULT_INSPECTION_START = '07:00 am';

export default Route.extend(
  AuthenticatedRouteMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  ConfirmationMixin,
  {
    featureFlags: service(),
    tenant: service(),
    intl: service(),
    allUsers: service(),
    confirmationMessage() {
      return this.intl.t('inspections.confirm-discard-changes');
    },
    isPageDirty(model) {
      if (Ember.testing || this.controller.cancelClicked) {
        return false;
      }
      return model.hasDirtyRelationships(model);
    },
    beforeModel() {
      this.get('allUsers').loadAllUsers();
      return this._super(...arguments);
    },
    async model() {
      if (!(this.featureFlags.isInspectionsEnabled && this.tenant.isInspectionsEnabled)) {
        this.transitionTo('index');
      }
      return this.store.findAll('subscription/notification-method-type')
        .then(notificationTypes => {
          const notificationMethodType = notificationTypes.findBy('name', 'Post-Event Email With Report');
          const newInspection = this.store.createRecord(
            'subscription/subscription',
            {
              enabled: true,
              scheduled: true,
              creationInProgress: false,
              permissions: { can_edit: true }
            }
          );
          const newSchedule = this.store.createRecord('subscription/schedule', {
            recurrence: DEFAULT_INSPECTION_RECURRENCE,
            start: DEFAULT_INSPECTION_START,
            timezone: moment.tz.guess(),
            subscription: newInspection,
            editMode: false,
          });
          newInspection.schedules.pushObject(newSchedule);

          const creator = this.store.createRecord('subscription/subscriber', {
            user: this.currentUser.vhiUser,
            allowEdit: true,
            subscription: newInspection,
            creator: true
          });
          newInspection.subscribers.addObject(creator);

          const method = this.store.createRecord('subscription/notification-method', {
            enabled: true,
            subscriber: creator,
            notificationMethodType
          });

          creator.notificationMethods.pushObject(method);

          return new Promise(resolve => resolve(newInspection));
        });
    },

    setupController(controller) {
      this._super(...arguments);

      //TODO: in the future we should consider adding a parameter of subscription-type
      // to the notification_method_types endpoint, so we can fetch only the method types
      // that apply (and visible) to any particular type of subscription.
      // For now I will fake this out.
      return this.store.findAll('subscription/notification-method-type')
        .then(notificationTypes => {
          const realType = notificationTypes.findBy('name', 'Post-Event Email With Report');
          const fakeType = EmberObject.create({
            id: realType.get('id'),
            name: realType.get('name'),
            description: realType.get('description'),
            visible: true,
            created: realType.get('created'),
            modified: realType.get('modified'),
            realType
          });
          // pass down the list of available notification types that was already cached in model()
          controller.set('notificationTypes', [fakeType]);
        });
    },

    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideNavBarRoute', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controller.set('showSitePicker', false);
      this.controller.set('errors', []);
      this.controller.set('isSaving', false);
      this.controllerFor('application')
        .set('hideNavBarRoute', false);
    },

    resetController(controller, isExiting) {
      this.controller.cancelClicked = false;
      if (!isExiting) return;
      // WARNING: rollbackAttributes() will remove the model from the store,
      // which will mutate relationships as we loop over them.
      // To avoid looping on a mutating list, make a copy of the array first,
      // and then loop over the copy to delete the records.
      const model = controller.get('model');
      let toDelete = model.subscriptionFilters.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.subscribers.toArray();
      toDelete.forEach(sub => {
        const notificationMethods = sub.notificationMethods.toArray();
        notificationMethods.forEach(nm => nm.rollbackAttributes())
      });
      toDelete.forEach(m => m.rollbackAttributes());

      toDelete = model.schedules.toArray();
      toDelete.forEach(m => m.rollbackAttributes());

      model.rollbackAttributes();
    }
  }
);
