import Component from '@ember/component';
import { computed } from '@ember/object';
import groupBy from 'ember-group-by';
import { findValueInGroupBy } from '../../../helpers/find-value-in-group-by';

export default Component.extend({
  classNames: ['site-filters-card_channel-list-item'],
  classNameBindings: ['isHighlighted:is-highlighted'],
  channel: null,
  channelFilters: null,
  highlightedFiltersByChannel: null,
  allowEdit: false,
  filtersByPtz: groupBy('channelFilters', 'ptzPreset.id'),
  hasPtz: computed('filtersByPtz.[]', function() {
    return (
      this.filtersByPtz.length > 0 &&
      this.filtersByPtz.firstObject.value != null
    );
  }),
  highlightedFiltersOnThisChannel: computed(
    'highlightedFiltersByChannel.[]',
    'channel',
    function() {
      return findValueInGroupBy([
        this.highlightedFiltersByChannel,
        this.channel.get('id')
      ]);
    }
  ),

  isAllChannelFiltersHighlighted: computed(
    'highlightedFiltersOnThisChannel.[]',
    'channelFilters.[]',
    function() {
      return (
        this.highlightedFiltersOnThisChannel.length ===
        this.channelFilters.length
      );
    }
  ),
  isHighlighted: computed('highlightedFiltersOnThisChannel.[]', function() {
    return this.highlightedFiltersOnThisChannel.any(
      filter => filter.channel.get('id') === this.channel.get('id')
    );
  }),
  init() {
    this._super(...arguments);
    this.highlightedFiltersByChannel = this.highlightedFiltersByChannel || [];
    this.channelFilters = this.channelFilters || [];
  },
  actions: {
    toggleFilterTag(filter, tag) {
      this.toggleFilterTag(filter, tag);
    },
    highlightPtzFilter(filter, addToList) {
      this.highlightFilter(filter, addToList);
    },
    highlightChannelFilter() {
      if (!this.hasPtz) {
        this.highlightFilter(
          this.channelFilters.firstObject,
          !this.isHighlighted
        );
      } else {
        this.bulkHighlightFilters(this.channelFilters, !this.isHighlighted);
      }
    }
  }
});
