import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import {t} from 'ember-intl';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    infinity: service(),
    titleToken: t('navbar.user-admin'),
    currentUser: service(),
    can: service(),
    setupController() {
      this.set('breadcrumbs', [{
        name: this.intl.t('navbar.user-admin')
      }]);

      this._super(...arguments);
    },
    model() {
      //if the user does not have permissions to edit user permission, send them away
      if (this.can.cannot('siteAccess user', this.currentUser)) {
        this.transitionTo('index');
        return;
      }

      return this.infinity.model('site-user',
        {
          startingPage: 1,
          perPage: 1000,
          pageParam: 'page_number',
          perPageParam: 'page_size',
          totalPagesParam: 'meta.pagination.pages'
        });
    },
    actions: {
      loadUserProfile() {

      }
    }
  });
