import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  intl: service(),
  classNames: ['webhooks-list-item'],
  attributeBindings: ['disabled:disabled'],
  webhookSubscriber: null,
  testWebhookSubscriber: true,
  showDialog: false,
  disabled: false,
  isCurrentUserUser: computed('user', 'currentUser', function() {
    if (!this.currentUser || !this.currentUser.vhiUser) {
      return false;
    }
    return (
      this.currentUser.vhiUser.get('id') === this.user.user.get('id')
    );
  }),
  actions: {
    toggleShowDialog() {
      this.toggleProperty('showDialog');
    },
    removeWebhookFromList(webhook) {
      this.removeWebhookFromList(webhook);
    },
    toggleWebhooksStatus(webhook) {
      this.toggleWebhooksStatus(webhook);
    }
  }
});
