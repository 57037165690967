import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ErrorHandler from '../../mixins/inspection-error-handler';

export default Controller.extend(ErrorHandler, {
  currentUser: service(),
  store: service(),
  allUsers: service(),
  errors: null,
  showSitePicker: false,
  isSaving: false,
  cancelClicked: false,
  queryParams: ['page', 'page_size'],
  pagination: computed('allSites.{meta}', function() {
    return this.allSites.meta.pagination;
  }),
  init() {
    this._super(...arguments);
    this.errors = this.errors || [];
    this.get('allUsers')
      .loadAllUsers();
  },
  actions: {
    saveInspection() {
      const clientErrors = this.getErrorsOnInspection(this.model);
      if (clientErrors && clientErrors.length > 0) {
        this.errors.pushObjects(clientErrors);
        return;
      }

      this.set('isSaving', true);

      this.model.save({
        include: [
          'subscription_filters',
          'schedules',
          'subscribers',
          'subscribers.notification_methods',
          'subscribers.notification_methods.notification_method_type',
        ].join(','),
        adapterOptions: {saveRelationships: true, queryParams: {context: 'inspection'}}})
        .then(() => {
          this.transitionToRoute('inspection.manage');
        })
        .catch(error => {
          const translatedErrors = this.translateServerErrorsToClient(error);
          this.errors.pushObjects(translatedErrors);
        })
        .finally(() => this.set('isSaving', false));
    },
    generateInspectionReport() {
      //todo after backend hookup
    },
    resetDirtyInspection() {
      this.cancelClicked = true;

      // NOTE: uncommitted dirty model is cleaned up in route.resetController()
      this.transitionToRoute('inspection.manage');
    }
  }
});
