import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import { ALERT_VIEWED } from '../../services/analytics';

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    titleToken: function(model) {
      return model.get('subscription.name');
    },
    intl: service(),
    infinity: service(),
    analytics: service(),
    breadcrumbs: null,
    queryParams: {
      source: {},
      pageSize: {
        refreshModel: true
      }
    },

    model(params) {

      return this.store.find('subscription/event', params.alert_id);
    },

    setupController(controller, model) {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.alerts'),
          link: 'alerts'
        },
        {
          name: model.get('subscription.name')
        }
      ]);
      this.set('breadcrumbButtons', [
        {
          route: 'alert.manage',
          graphic: 'settings-icon',
          title: this.intl.t('alerts.manage')
        }
      ]);
      this.set(
        'navTitleText',
        this.intl.t('navbar.' + this.navTitle, {
          alertName: model.get('subscription.name')
        })
      );

      this._super(...arguments);
      controller.viewEvent();
      controller.set(
        'eventItems',
        this.infinity.model('subscription/event-item-reference', {
          pageParam: 'page_number',
          //startingPage: 1,
          event_id: model.id,
          perPage: controller.pageSize,
          countParam: 'meta.pagination.count',
          perPageParam: 'page_size',
          totalPagesParam: 'meta.pagination.pages',
        })
      );
      this.store.query('channel', {
        page: 1,
        page_size: 1000,
        site: model.site.get('id')
      })


      this.analytics.trackMultiple(ALERT_VIEWED, {
        subscriptionId: model.get('subscription.id'),
        alertId: model.get('id'),
        subscriptionName: model.get('subscription.name'),
        source: controller.source
      });
    }
  }
);
