import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { on } from '@ember/object/evented';
import { inject as service } from '@ember/service';
import { EKMixin as KeyboardMixin, keyDown } from 'ember-keyboard';
import constants from '../../utils/ui-constants';
import AlertManipulator from '../../mixins/alert-manipulator';
import { INSPECTION_IMAGE_ENLARGED } from '../../services/analytics';

const { UI_DATE_FORMAT } = constants;

export default Controller.extend(
  AlertManipulator,
  KeyboardMixin,
  {
    analytics: service(),
    tenant: service(),
    errors: null,
    selected: null,
    dateFormat: UI_DATE_FORMAT,
    showZoomedImage: false,

    // we need to indicate when a selected item should be forced to scroll into view
    hasToScroll: false,

    subscriptionCreatorName: computed('model.subscription.{creator.first_name,creator.last_name}', function() {
      return this.get('model.subscription.creator.first_name') + ' ' + this.get('model.subscription.creator.last_name');
    }),

    selectedIndex: computed('selected', function() {
      return this.get('items')
        .indexOf(this.selected);
    }),

    currentNumber: computed('selected', function() {
      return this.get('items')
        .indexOf(this.selected) + 1;
    }),

    total: computed('items', function() {
      return this.get('items').length;
    }),

    init() {
      this._super(...arguments);
      this.errors = this.errors || [];
    },

    // called from AlertManipulator
    removeFromList() {
      return;
    },

    arrowKeyDownHook: on(keyDown('ArrowLeft'), keyDown('ArrowRight'), function(e) {
      const modifier = e.key === 'ArrowLeft' ? -1 : 1;
      const items = this.get('items');
      const index = items.indexOf(this.selected);
      let newIndex = index + modifier;

      // prevent arrow keys from wrapping around
      if (newIndex < 0) {
        newIndex = 0;
      } else if (newIndex > items.length - 1) {
        newIndex = items.length - 1;
      }

      // on arrow key presses, force scroll to make sure the current item is viewable
      this.set('hasToScroll', true);
      this.set('selected', items.objectAt(newIndex));

      const isZoomModalOpen = !!document.querySelector('#paper-wormhole .report-zoom-image');
      if (isZoomModalOpen && newIndex !== index) {
        this.trackSelectedImageEnlarged();
      }
    }),

    trackSelectedImageEnlarged() {
      const event = this.model;
      const subscription = event.get('subscription');
      const eventItem = this.selected;

      this.analytics.trackMultiple(INSPECTION_IMAGE_ENLARGED, {
        tenant: this.tenant.subdomain,
        subscriptionId: subscription.get('id'),
        inspectionId: event.get('id'),
        eventItemId: eventItem.get('id'),
        subscriptionName: subscription.get('name'),
      });
    },
    
    selectIndex(index) {
      this.set('hasToScroll', true);
      this.set('selected', this.get('items')
        .objectAt(index));
    },

    actions: {
      /**
       * This action is triggered on click
       * If you clicked on this item, there is no need to scroll it into the viewable area
       * NOTE: image is NOT enlarged
       * @param item the item that was clicked on
       */
      selectItem(item) {
        this.set('hasToScroll', false);
        this.set('selected', item);
      },

      /**
       * This action is triggered when the selected item is changed using the stepper control
       * When using stepper control, we may need to scroll to keep this item in view
       * NOTE: image is NOT enlarged
       * @param index
       */
      selectIndex(index) {
        this.selectIndex(index);
      },

      clearErrors() {
        this.set('errors', []);
      },

      setErrors(errors) {
        this.set('errors', errors);
      },

      openImageInModal(image) {
        if (image) {
          this.trackSelectedImageEnlarged();
          this.toggleProperty('showZoomedImage');
        }
      },

      // swipeNext is only called from inside the zoom dialog (therefore track enlarged image)
      swipeNext() {
        const newIndex = this.get('selectedIndex') + 1;
        if (newIndex > this.get('items').length - 1) {
          return;
        }
        this.selectIndex(newIndex);
        this.trackSelectedImageEnlarged();
      },

      // swipePrev is only called from inside the zoom dialog (therefore track enlarged image)
      swipePrev() {
        const newIndex = this.get('selectedIndex') - 1;
        if (newIndex < 0) {
          return;
        }
        this.selectIndex(newIndex);
        this.trackSelectedImageEnlarged();
      },
    }
  });
