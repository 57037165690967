import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

const hasDirty = item => item.hasDirtyAttributes;

export default DS.Model.extend({
  intl: service(),
  currentUser: service(),

  name: DS.attr(),
  creator: DS.attr(),
  created_by: computed('creator', function() {
    return this.creator && this.creator.email ? this.creator.email.split('@')[0] : '-';
  }),
  enabled: DS.attr(),
  scheduled: DS.attr(),
  creationInProgress: DS.attr(),
  created: DS.attr(),
  modified: DS.attr(),

  latestEventTimestamp: DS.attr(),
  lastInspectionTimestamp: DS.attr(),
  nextInspectionTimestamp: DS.attr(),
  eventsPerDay: DS.attr(),
  permissions: DS.attr(),
  events: DS.hasMany('subscription/event'),
  subscribers: DS.hasMany('subscription/subscriber'),
  schedules: DS.hasMany('subscription/schedule'),
  type: DS.belongsTo('subscription/subscription-type'),
  subscriptionFilters: DS.hasMany('subscription/subscription-filter', { polymorphic: true }),
  activeSubscriptionFilters: computed.filterBy(
    'subscriptionFilters',
    'isDeleted',
    false
  ),
  webhookSubscribers: DS.hasMany('subscription/subscription-webhook-subscriber'),
  activeWebhookSubscribers: computed.filterBy(
    'webhookSubscribers',
    'isDeleted',
    false
  ),
  activeSubscribers: computed.filterBy('subscribers', 'isDeleted', false),
  activeSchedules: computed.filterBy('schedules', 'isDeleted', false),
  currentUserCanEdit: computed(
    'currentUser.vhiUser',
    'subscribers.@each.user',
    function() {
      if (!this.subscribers || !this.currentUser || !this.currentUser.vhiUser) {
        return false;
      }
      const currentUserAsSub = this.subscribers.find(
        subscriber =>
          this.currentUser.vhiUser.get('id') === subscriber.user.get('id')
      );

      return currentUserAsSub ? currentUserAsSub.allowEdit : false;
    }
  ),
  hasDirtyRelationships: (model) => {
    return model.hasDirtyAttributes
      || model.subscriptionFilters.any(hasDirty)
      || model.webhookSubscribers.any(hasDirty)
      || model.subscribers.any(hasDirty)
      || model.subscribers.reduce((acc, sub) => acc || sub.notificationMethods.any(hasDirty), false)
      || model.schedules.any(hasDirty);
  }
});
