import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';

import BreadcrumbRoute from '../mixins/breadcrumb-route';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import {inject as service} from '@ember/service';

export default Route.extend(AuthenticatedRouteMixin,
  BreadcrumbRoute,
  NavBarTitleMixin,
  {
    featureFlags: service(),
    model() {
      return this.store.findAll('tenant');
    }
  });
