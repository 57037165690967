import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['reach-pagination-bar'],
  pages: '',
  paginatedRoute: 'sites',
  paginatedData: null,
  computedPages: computed('pages', function() {
    let list = [];
    let pages = this.pages;
    if (!pages) {
      return list;
    }
    if (typeof pages === 'string') {
      pages = JSON.parse(pages);
    }
    const lastPage = pages.pages ? pages.pages : pages.last.page;
    for (let i = 1; i <= lastPage; i++) {
      let active = false;
      if (pages.self && pages.self.page === i) {
        active = true;
      } else if (pages.page && pages.page === i) {
        active = true;
      }
      list.push({
        id: i,
        style: 'page-item' + (active ? ' active' : ''),
      });
    }
    return list;
  })
});
