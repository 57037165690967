import Component from '@ember/component';

export default Component.extend({
  actions: {
    newPassword() {
      this.newPassword();
      return false;
    }
  }
});
