import ApplicationSerializer from './application';
import { underscore } from '@ember/string';
export default ApplicationSerializer.extend({
  serialize(snapshot, options) {
    if (!(snapshot.adapterOptions && snapshot.adapterOptions.localStorage) && !(options && options.localStorage)) {
      return this._super(...arguments);
    }
    let json = {
      id: snapshot.id,
      type: snapshot.type,
    }
    snapshot.eachAttribute((key, attribute) => {
      this.serializeAttribute(snapshot, json, key, attribute);
    });
    snapshot.eachRelationship((key, relationship) => {
      if (relationship.kind === 'belongsTo') {
        this.serializeBelongsTo(snapshot, json, relationship);
      } else if (relationship.kind === 'hasMany') {
        this.serializeHasMany(snapshot, json, relationship);
      }
    });

    return json;
  },
  keyForAttribute(key) {
    return underscore(key);
  },
  serializeAttribute(snapshot, json, key) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      return this._super(...arguments);
    }

    if (key === 'images') {
      let value = snapshot.attr(key);
      if (Array.isArray(value)) {
        value = '["' + value.join('","') + '"]';
      }
      json[underscore(key)] = value;
      json['displayImages'] = snapshot.attr(key);
    } else {
      return this._super(...arguments);
    }

  },
});
