define("reach-web-client/components/uic-form-element", ["exports", "ember-ui-components/components/uic-form-element"], function (_exports, _uicFormElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uicFormElement.default;
    }
  });
});
