import { Factory, faker, association, trait } from 'ember-cli-mirage';

export default Factory.extend({
  name() {
    return faker.random.word();
  },
  order: 0,
  latestImage: association('channelTest'),
  mute: false,
  maintenanceMode: false,
  dataloggerTest: trait({
    mute: false,
    maintenanceMode: false
  }),
  reviewStream: trait({
    cloudbridge: 'something'
  }),
  deployState: 'ACTIVE',
  cvFeatures() {
    return [
      server.create('tag-type-group', {name: 'vehicle'}),
      server.create('tag-type-group', {name: 'person'}),
      server.create('tag-type-group', {name: 'animal'}),
    ];
  },
  isOffline: false,
});

