import ApplicationSerializer from './application';
import { CustomRelationshipKeyMixin } from '../mixins/subscription/serializer';

const RELATIONSHIP_ALIASES = {
  tag_type_group: 'tag-type-group'
};

export default ApplicationSerializer.extend(CustomRelationshipKeyMixin, {
  relationship_key_aliases: RELATIONSHIP_ALIASES,
});
