import Component from '@ember/component';
import { observer } from '@ember/object';
import { debounce } from '@ember/runloop';


import moment from 'moment';

export default Component.extend({
  classNames: ['reach-filter-date-time-picker'],
  datetime: null,
  selectedDate: null,
  selectedTime: null,
  times: null,
  init() {
    this._super(...arguments);
    let start = moment().subtract(1, 'days')
      .startOf('day');
    let times = [];
    while (times.length < 24) {
      start = start.add(1, 'hour');
      times.push(start.format('hh:mm A'));
    }
    this.set('times', times);
    this.convertDateToControls();
  },
  didInsertElement() {
    this.$('.timepicker').wickedpicker({timeSeparator: ':'});
  },
  watchTime: observer('selectedTime', function() {
    if (this.selectedDate) {
      debounce(this, this.sendChange, 1000);
    }
  }),
  sendChange() {
    this.onChange(this.generateTimeString());
  },
  convertDateToControls() {
    if (this.datetime) {
      const date = moment(this.datetime);
      this.set('selectedDate', date.tz(this.site.timezone).format('YYYY-MM-DD'));
      this.set('selectedTime', date.tz(this.site.timezone).format('hh:mm A'));
    }
  },
  watchExternalDate: observer('datetime', function() {
    this.convertDateToControls();
  }),
  generateTimeString() {
    let selectedDate = this.selectedDate;
    if (!selectedDate) {
      selectedDate = moment();
    }
    const date = moment(selectedDate).format('YYYY-MM-DD');

    const time = this.selectedTime;
    if (!time) {
      return null;
    }
    // Using format will render the datetime as ISO format
    const datetime = moment.tz(`${date}T${time}`, 'YYYY-MM-DDThh:mm A', this.site.timezone);
    const result = datetime.format();
    return result;
  },
  actions: {
    dateChanged(date) {
      if (!this.selectedTime) {
        this.set('selectedTime', moment(date).format('hh:mm A'));
      } else {
        this.sendChange();
      }
    },
    clear() {
      this.set('selectedDate', null);
      this.set('selectedTime', null);
      this.sendChange();
    }
  }
});
