import Mixin from '@ember/object/mixin';
import fetch from 'fetch';
import ENV from '../config/environment';
import { inject as service } from '@ember/service';

import {
  VIDEO_STREAM_REQUESTED,
  VIDEO_STREAM_STOPPED } from '../services/analytics';

const JSON_HEADERS = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //'cache-control': 'no-cache'
};

export default Mixin.create({
  intl: service(),
  analytics: service(),
  session: service(),
  tenant: service(),

  /**
   * NOTE: This function allows the reach-snapshot-modal.js to play live video directly.
   * The modal has no concept of a play/stop toggle video button.
   */
  playLiveVideo(streamingVar) {
    if (!streamingVar) {
      streamingVar = 'streaming';
    }
    this.set(streamingVar, true);
    this.set('warning', '');
    this.set('error', '');
    this.set('loadingVideo', true);

    this.analytics.trackMultiple(VIDEO_STREAM_REQUESTED, {
      siteName: this.get('site.name'),
      siteId: this.get('site.id'),
      channelId: this.get('channel.id'),
      channelSlug: this.get('channel.slug'),
      channelName: this.get('channel.name'),
      livestream: true
    });

    this.resolveStreamUrl()
      .then((result)=>{
        this.set('streamingUrl', result);
        this.set('loadingVideo', false);
      })
      .catch((error)=>{
        this.set('error', error);
        this.set('loadingVideo', false);
      })
  },

  toggleLiveVideo(streamingVar) {
    if (!streamingVar) {
      streamingVar = 'streaming';
    }
    this.set('warning', '');
    this.set('error', '');
    if (!this.get(streamingVar)) {
      this.playLiveVideo(streamingVar);
    } else {
      this.set('streaming', false);
      this.set('streamingUrl', '');
      this.analytics.trackMultiple(VIDEO_STREAM_STOPPED, {
        siteName: this.get('site.name'),
        siteId: this.get('site.id'),
        channelId: this.get('channel.id'),
        channelSlug: this.get('channel.slug'),
        channelName: this.get('channel.name'),
        livestream: true
      });
    }
  },
  resolveStreamUrl() {
    if (ENV.TEST_LOCAL_VIDEOSTREAM_URL) {
      return new Promise(resolve => {
        resolve(ENV.TEST_LOCAL_VIDEOSTREAM_URL)
      })
    }

    const channel = this.channel ? this.channel : this.get('image.channel');
    return new Promise((resolve, reject) => {
      if (channel.get('hlsUrl').indexOf('amazons3') > 0) {
        // For stored videos on s3 resolve directly since we don't have to
        // manually start the rtsp stream
        resolve(channel.get('hlsUrl'));
      } else {
        let { access_token } = this.get('session.data.authenticated');
        let headers = JSON_HEADERS;
        headers['Authorization'] = `Bearer ${access_token}`;
        let subdomain = this.get('tenant.subdomain')
        const host = ENV.vhiProtocol + '://' + subdomain + '.' + ENV.vhiServer;
        let formData = 'slug=' + channel.get('slug');
        fetch(`${host}/api/legacy/video/stream/start`, {
          method: 'POST',
          body: formData,
          headers: headers,
        })
          .then((response) => {
            if (response.ok) {
              resolve(channel.get('hlsUrl'));
            } else {
              reject(this.intl.t('video.unable-to-start'));
            }
          })
          .catch(()=>{
            reject(this.intl.t('video.unable-to-start'));
          });
      }
    });
  },
});
