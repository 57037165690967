import { belongsTo } from 'ember-cli-mirage';

import SumbscriptionFiltres from './subscription-subscription-filter';

export default SumbscriptionFiltres.extend({
  typeKey: 'subscription/query-doc-filter',
  site: belongsTo('site'),
  channel: belongsTo('channel'),
  subscription: belongsTo('subscriptionSubscription', { inverse: 'subscriptionFilters' })
});
