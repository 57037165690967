import PaperButton from 'ember-paper/components/paper-button';
import Ember from 'ember';

export default PaperButton.extend({
  classNames: 'reach-primary-btn',
  raised: true,
  primary: true,
  accent: false,
  attributeBindings: ['type'],
  type: 'submit',
  noink: false,

  init() {
    this._super(...arguments)
    if (Ember.testing) {
      this.set('noink', true)
    }
  },
});
