/**
 * WARNING: this code was copied from https://github.com/emberjs/ember.js/issues/14174#issuecomment-385511943
 * It allows arrays (of string & number primitives) to be serialized (and deserialized) from the query string
 * using traditional ajax style (used by VHI request.query_params.getlist())
 *
 * aka myparam = [1, 2, 3]
 *
 * becomes in the URL: &myparam[]=1&myparam[]=2&myparam[]=3
 */
import Mixin from '@ember/object/mixin';
import { typeOf } from '@ember/utils';

function basicArray(array) {
  if (typeOf(array) !== 'array') {
    return false;
  }

  return array.every((value) => {
    return ['string', 'number'].includes(typeOf(value));
  });
}

export default Mixin.create({
  serializeQueryParam(value, urlKey, defaultValueType) {
    if (defaultValueType === 'array' && basicArray(value)) {
      return value.slice();
    }

    return this._super(value, urlKey, defaultValueType);
  },
  deserializeQueryParam(value, urlKey, defaultValueType) {
    if (defaultValueType === 'array' && basicArray(value)) {
      return value.slice();
    }

    return this._super(value, urlKey, defaultValueType);
  },
});
