import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['inspection_action-bar'],
  intl: service(),
  showHelpMessage: true,
  disabled: false,
  init() {
    this._super(...arguments);
    this.set(
      'showHelpMessage',
      !localStorage.getItem('inspections-help-popup-seen')
    );
  },
  statusText: computed('inspection.enabled', function() {
    return this.inspection.enabled
      ? this.intl.t('inspections.active-status')
      : this.intl.t('inspections.inactive-status');
  }),
  actions: {
    closeHelpPopup() {
      localStorage.setItem('inspections-help-popup-seen', true);
      this.set('showHelpMessage', false);
    },
    toggleEnabled() {
      this.toggleProperty('inspection.enabled');
    }
  }
});
