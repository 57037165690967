import PaperButton from 'ember-paper/components/paper-button';
import Ember from 'ember';

export default PaperButton.extend({
  classNames: 'reach-secondary-btn',
  noink: false,
  init() {
    this._super(...arguments)
    if (Ember.testing) {
      this.set('noink', true)
    }
  },
});
