import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { ALERT_SUBSCRIPTION_ENABLED, } from '../services/analytics';

export default Component.extend({
  enabled: false,
  analytics: service(),
  actions: {
    doEnable() {
      this.toggleProperty('enabled');
      if (this.parent) {
        this.analytics.trackMultiple(ALERT_SUBSCRIPTION_ENABLED, {
          subscriptionId: this.get('parent.id'),
          enabled: this.enabled,
        });
        this.parent.save();

      }
    }
  }
});
