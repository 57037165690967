define("reach-web-client/components/tt-calendar-weekrow-day", ["exports", "ember-time-tools/components/tt-calendar-weekrow-day"], function (_exports, _ttCalendarWeekrowDay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttCalendarWeekrowDay.default;
    }
  });
});
