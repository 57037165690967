import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  name() {
    return faker.company.companyName();
  },
  subdomain() {
    return faker.internet.domainWord();
  }

});
