import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['subscriptions-site-filters-card'],
  showSitePicker: false,
  allowEdit: false,
  subscriptionFilters: null,
  highlightedFilters: null,
  subscriptionFiltersLength: computed('subscriptionFilters.[]', function() {
    return this.subscriptionFilters.length;
  }),
  init() {
    this._super(...arguments);

    this.subscriptionFilters = this.subscriptionFilters || [];
    this.highlightedFilters = this.highlightedFilters || [];
  },
  actions: {
    removeFilters() {
      this.removeFilters(this.highlightedFilters);
      this.set('highlightedFilters', []);
    },
    onAddSitesClick() {
      this.onAddSitesClick();
    },
    highlightFilter(filter, addToList) {
      if (addToList) {
        this.highlightedFilters.pushObject(filter);
      } else {
        this.highlightedFilters.removeObject(filter);
      }
    },
    bulkHighlightFilters(filters, addToList) {
      if (addToList) {
        this.highlightedFilters.pushObjects(filters);
      } else {
        this.highlightedFilters.removeObjects(filters);
      }
    },
    toggleFilterTag(filter, tag) {
      if (this.allowEdit) {
        if (filter.tags.includes(tag)) {
          filter.set('tags', filter.tags.removeObject(tag));
        } else {
          filter.set('tags', filter.tags.addObject(tag));
        }
      }
    }
  }
});
