export default function geo() {
  return true;
}
/**
* Generates number of random geolocation points given a center and a radius.
* @param  {Object} center A JS object with lat and lng attributes.
* @param  {number} radius Radius in meters.
* @param {number} count Number of points to generate.
* @return {array} Array of Objects with lat and lng attributes.
*/
export function generateRandomPoints(center, radius, count) {
  var points = [];
  for (var i = 0; i < count; i++) {
    points.push(generateRandomPoint(center, radius));
  }
  return points;
}


/**
* Generates number of random geolocation points given a center and a radius.
* Reference URL: http://goo.gl/KWcPE.
* @param  {Object} center A JS object with lat and lng attributes.
* @param  {number} radius Radius in meters.
* @return {Object} The generated random points as JS object with lat and lng attributes.
*/
export function generateRandomPoint(center, radius) {
  var x0 = center.lng;
  var y0 = center.lat;
  // Convert Radius from meters to degrees.
  var rd = radius / 111300;

  var u = Math.random();
  var v = Math.random();

  var w = rd * Math.sqrt(u);
  var t = 2 * Math.PI * v;
  var x = w * Math.cos(t);
  var y = w * Math.sin(t);

  var xp = x / Math.cos(y0);

  // Resulting point.
  return {'lat': y + y0, 'lng': xp + x0};
}

function rad2degr(rad) { return rad * 180 / Math.PI; }
function degr2rad(degr) { return degr * Math.PI / 180; }

/**
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
export function getLatLngCenter(latLngInDegr) {
  let numberOfCoordinates = latLngInDegr.length;
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;


  for (var i = 0; i < latLngInDegr.length; i++) {
    if (!latLngInDegr[i].lat || !latLngInDegr[i].lng) {
      numberOfCoordinates -= 1;
      continue;
    }
    const lat = degr2rad(latLngInDegr[i].lat);
    const lng = degr2rad(latLngInDegr[i].lng);

    
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  if (numberOfCoordinates === 0) {
    // to avoid dividing by zero below return calgary if no points are passed
    return { lat: 51.048615, lng: -114.07084 };
  }
  
  var avgX = sumX / numberOfCoordinates;
  var avgY = sumY / numberOfCoordinates;
  var avgZ = sumZ / numberOfCoordinates;

  // convert average x, y, z coordinate to latitude and longtitude
  var lng = Math.atan2(avgY, avgX); 
  var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  var lat = Math.atan2(avgZ, hyp);

  return {lat: rad2degr(lat), lng: rad2degr(lng)};
}
