import Service from '@ember/service';
import {inject as service} from '@ember/service';
import ObjectProxy from '@ember/object/proxy';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
let ObjectPromiseProxy = ObjectProxy.extend(PromiseProxyMixin);

export default Service.extend({
  store: service(),
  channelsForTenant(tenant) {
    const proxy =  ObjectPromiseProxy.create({
      promise: this.store.query('channel', {tenant: tenant}).catch(()=>{return [];})
    });

    return proxy;
  }
});
