import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const { alias } = computed;

export default Component.extend({
  classNames: ['inspections-board-selector'],
  selected: null,
  eventReport: service(),
  featureFlags: service(),
  reportIsLoading: false,
  isDownloadPdfEnabled: alias('featureFlags.isDownloadPdfEnabled'),

  selectedIndex: computed('selected', function() {
    return this.model.indexOf(this.selected);
  }),

  isPending: computed('model', 'eventReport.pendingReports', function() {
    return this.eventReport.pendingReports && this.event
      ? this.eventReport.isPending(this.event.id)
      : false;
  }),

  actions: {
    selectIndex(index) {
      this.selectIndex(index);
    },

    selectItem(item) {
      this.selectItem(item);
    },
    closePdfModal() {
      this.set('reportIsLoading', false);
    },

    downloadReport() {
      if (this.event.isInProgress) {
        this.set('reportIsLoading', true);
      } else {
        this.eventReport.getReportUrl(this.event.id);
      }
    },

    enlargeImage(image) {
      this.enlargeImage(image);
    }
  }
});
