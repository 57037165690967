QUnit.module('ESLint | app');

QUnit.test('app/controllers/freestyle.js', function(assert) {
  assert.expect(1);
  assert.ok(true, 'app/controllers/freestyle.js should pass ESLint\n\n');
});

QUnit.test('app/routes/freestyle.js', function(assert) {
  assert.expect(1);
  assert.ok(true, 'app/routes/freestyle.js should pass ESLint\n\n');
});

