import Component from '@ember/component';
import { get, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { ParentMixin, ChildMixin } from 'ember-composability-tools';
import leafletHelper from '../../utils/leaflet-helper';

/**
 * NOTE: its really useful to read this: https://github.com/miguelcobain/ember-composability-tools
 * to understand the approach to integrating leaflet with Ember
 */
export default Component.extend(ParentMixin, ChildMixin, {
  L: leafletHelper.L,
  intl: service(),

  watchIsAerial: observer('isAerial', function() {
    if (this.get('isAerial')) {
      this.L.DomUtil.addClass(this.get('_container'), 'isRoadView');
      this.get('modeControl').getContainer().innerText = this.intl.t('map.map-mode');
    } else {
      this.L.DomUtil.removeClass(this.get('_container'), 'isRoadView');
      this.get('modeControl').getContainer().innerText = this.intl.t('map.satellite-mode');
    }
  }),

  createModeControl: function() {
    const intl = this.intl;
    const leaf = this.L;
    return this.L.Control.extend({
      onAdd: function() {
        const text = leaf.DomUtil.create('div');
        text.id = 'mode-indicator';
        text.innerText = intl.t('map.satellite-mode');
        return text;
      },
      onRemove: function() {
        // nothing to do here
      }
    });
  },

  didInsertParent() {
    const parent = get(this, 'parentComponent');
    if (!parent) return;
    const ModeControl = this.createModeControl();

    const options = {
      width: 75,
      height: 75,
      position: 'bottomleft',

      // zoom out just enough for the mini-map to reflect current viewable area on main map
      zoomLevelOffset: -5,

      // remove orange box
      aimingRectOptions: { opacity: 0, fill: false },

      // remove shadow box w/outline on drag
      shadowRectOptions: { opacity: 0, fill: false, stroke: false },
    };

    // create a new mini-map object (its a separate map), and add it to the main map
    // create a default layer here and use it in the mini-map
    // if there are any mini-map-layers, they will add themselves to the miniMap by calling miniMap.changeLayer()
    this._mainMap = parent._layer;
    this._miniMap = new this.L.Control.MiniMap(this.createLayer(), options).addTo(this._mainMap);

    // turn off double-click to zoom, as we only want clicks to toggle layers
    const realMiniMap = this._miniMap._miniMap;
    realMiniMap.doubleClickZoom.disable();

    // bind click action to the mini-map
    this.set('_container', this.get('_miniMap._container'));
    if (this.get('_container')) {
      this.get('_container').addEventListener('click', this.get('onClick'));
    }

    // add control to display mini-map mode
    const control = new ModeControl({ position: 'bottomleft' });
    control.addTo(realMiniMap);
    this.set('modeControl', control);
  },

  createLayer() {
    return leafletHelper.createBingLayer();
  },

  willDestroyElementParent() {
    this._super(...arguments);

    // clean up
    if (this.get('_container')) {
      this.get('_container').removeEventListener('click', this.get('onClick'));
    }
  }
});
