import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import { copy } from 'ember-copy'
import { makeFinder } from '../../utils/inspection-filter-finder';

/**
 * WARNING: this component will directly mutate the sorting list.
 * Therefore computed properties ARE NOT compatible with it.
 * see: https://github.com/mharris717/ember-drag-drop/issues/120
 * and: https://github.com/mharris717/ember-drag-drop/issues/115
 */
export default Component.extend({
  intl: service(),
  classNames: ['inspections_inspections-board'],
  filters: null,
  // NOTE: filtersForSort is an internal mutable list that is used only for sorting
  // If enableSort=false, then filtersForSort is just another reference to the filters property
  // (we do this to avoid needing extra #if statements inside our template to deal with copies vs originals).
  filtersForSort: null,
  selectedFilters: null,
  enableSort: true,
  disabled: false,
  syncSelected: observer('filters.[]', function() {
    // loop through the list of selected, removing any selected that are no longer in filters
    let toRemove = this.selectedFilters.filter(selected => !this.filters.find(makeFinder(selected)));
    // NOTE: use removeObjects() method to ensure we trigger removal events
    this.selectedFilters.removeObjects(toRemove);

    // also remove copies
    if (this.enableSort) {
      let toRemove = this.filtersForSort.filter(filterCopy => !this.filters.find(makeFinder(filterCopy)));
      this.filtersForSort.removeObjects(toRemove);
    }
  }),
  total: computed('filters.[]', function() {
    return this.filters.get('total') ? this.filters.get('total') : this.filters.length;
  }),
  selectedFilterLabel: computed('filters.[]', 'selectedFilters.[]', function() {
    return this.selectedFilters.length > 0
      ? `(${this.selectedFilters.length}/${this.total})`
      : `${this.intl.t('inspections.inspection-board.all')} (${this.total})`;
  }),
  isAnyFilterSelected: computed('selectedFilters.[]', function() {
    return this.selectedFilters.length > 0;
  }),
  isAllFiltersSelected: computed(
    'selectedFilters.[]',
    'filters.[]',
    function() {
      return this.selectedFilters.length === this.filters.length;
    }
  ),
  showDeleteDialog: false,
  init() {
    this._super(...arguments);
    this.filters = this.filters || [];
    this.selectedFilters = this.selectedFilters || [];
  },
  didReceiveAttrs() {
    this._super(...arguments);

    // NOTE: we need to initialize our internal mutable sorting list from a computed property
    // this is needed so the ember drag-drop AddOn can mutate the list
    // (we cannot directly mutate a computed property, otherwise drag-drop will become flaky (aka sometimes fail))
    let theFilters = [];
    try {
      theFilters = this.filters.map(f => copy(f)).sortBy('order');
    } catch (e) {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.warn('Unable to set the inspections filters, not a copyable object.')
    }

    this.set('filtersForSort', theFilters);
  },
  actions: {
    toggleFilter: function(filter) {
      // make sure this.selectedFilters only contains original filter objects (and never copies)
      // this must be consistent with how toggleSelectAllFilters works.
      const originalFilter = this.filters.find(makeFinder(filter));

      if (this.selectedFilters.includes(originalFilter)) {
        this.selectedFilters.removeObject(originalFilter);
      } else {
        this.selectedFilters.pushObject(originalFilter);
      }
    },
    toggleSelectAllFilters: function() {
      if (this.selectedFilters.length > 0) {
        this.set('selectedFilters', []);
      } else {
        if (this.filters.loadAll) {
          this.set('loadingAll', true);
          this.filters.loadAll()
            .then(() => this.selectedFilters.addObjects(this.filters))
            .finally(() => this.set('loadingAll', false));
        } else {
          this.selectedFilters.addObjects(this.filters);
        }
      }
    },
    sortEndAction: function() {
      // apply order changes from clone back into original filters
      this.filtersForSort.forEach((f, index) => {
        const orig = this.filters.find(makeFinder(f));
        if (orig) {
          orig.set('order', index);
        }
      });
    },
    toggleDeleteDialog() {
      this.toggleProperty('showDeleteDialog');
    },
    removeFilters() {
      // delete all selected filters
      this.selectedFilters.forEach(item => {
        item.deleteRecord();
      });
      // if removeFilters property is set, then fire that now to give parent component a chance to handle removal
      if (this.removeFilters) {
        this.removeFilters(this.selectedFilters);
      }
      // NOTE: removing filters from this.filters will trigger observer "syncSelected", automatically cleaning
      // this.selectedFilters and this.filtersForSort
      // ALSO NOTE: when saving an inspection, everything (including filters) are saved at once in a single API call.
      // Therefore any objects that are not in a relationship to the inspection will be automatically deleted by the backend
      // Which means there is no need to explicitly .save() deleted filters
      this.filters.removeObjects(this.selectedFilters);
      this.set('selectedFilters', []);
    },
    toggleShowSitePicker() {
      if (this.filters.loadAll) {
        this.set('loadingAll', true);
        this.filters.loadAll()
          .then(() => this.toggleShowSitePicker())
          .finally(() => this.set('loadingAll', false));
      } else {
        this.toggleShowSitePicker();
      }
    }
  }
});
