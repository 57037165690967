import LocalStorageSerializer from './local-storage';

export default LocalStorageSerializer.extend({
  serialize(snapshot, options) {
    if ((snapshot.adapterOptions && snapshot.adapterOptions.localStorage) || (options && options.localStorage)) {
      return this._super(...arguments);
    }
    let json = this._super(...arguments);
    json.type = json.segment_type;
    delete json.segment_type;
    return json;
  }
});
