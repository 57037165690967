import Route from '@ember/routing/route';

export default Route.extend({
  model: function() {
    return this.modelFor('site');
  },
  setupController(controller) {
    this._super(...arguments);
    controller.set('channels', this.controllerFor('site').get('channels'));
  }
});
