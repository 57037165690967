import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import UserPermissionCheck from '../../mixins/user-permission-check';

import { MULTIPLE_ALERT_SUBSCRIPTIONS_DELETED } from '../../services/analytics';

export default Controller.extend(UserPermissionCheck, {
  queryParams: ['page_size', 'page', 'ordering', 'reverse', 'search'],
  page: 1,
  page_size: 5,
  reverse: null,
  search: '',
  searchValue: '',
  analytics: service(),
  currentUser: service(),
  allSelected: computed('model.@each.selected', function() {
    let allSelected = true;
    this.model.forEach(alert => {
      if (allSelected && !alert.get('selected')) {
        allSelected = false;
      }
    });
    return allSelected;
  }),
  someSelected: computed('model.@each.selected', function() {
    let someSelected = false;
    this.model.forEach(alert => {
      if (!someSelected && alert.get('selected')) {
        someSelected = true;
      }
    });
    return someSelected;
  }),
  someDisabled: computed('model.@each.{selected,enabled}', function() {
    let someSelected = false;
    this.model.forEach(alert => {
      if (!someSelected && alert.get('selected') && !alert.get('enabled')) {
        someSelected = true;
      }
    });
    return someSelected;
  }),
  toggleDirection() {
    this.set('reverse', this.reverse ? null : 1);
  },
  actions: {
    checkAll() {
      this.model.forEach(alert => {
        alert.set('selected', true);
      });
    },
    deleteSelectedSubscriptions() {
      let deletedSubs = [];
      this.model.forEach(sub => {
        if (sub.get('selected')) {
          sub.destroyRecord({adapterOptions: {queryParams: {context: 'alert'}}});
          deletedSubs.push(sub);
        }
      });

      this.model.removeObjects(deletedSubs);
      this.analytics.trackMultiple(MULTIPLE_ALERT_SUBSCRIPTIONS_DELETED, {
        subscriptionId: this.get('model.id'),
        subscriptionCount: deletedSubs.length
      });
    },

    createSubscription() {
      this.transitionToRoute('alert.create');
    }
  }
});
