import Controller from '@ember/controller';
import AlertManipulator from '../../mixins/alert-manipulator';
import $ from 'jquery';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import {
  OPEN_ALERT_VIDEO_PLAYER,
  DEFAULT_TRACKING_SOURCE
} from '../../services/analytics';
import { later, cancel } from '@ember/runloop';

const STATE_SUCCESS = 'success';
const STATE_INFO = 'info';

const CHART_OPTIONS = {
  global: {
    useUTC: false
  },
  time: {
    timezone: moment.tz.guess()
  },
  chart: {
    type: 'line',
    resetZoomButton: {
      theme: {
        fill: 'white',
        states: {
          hover: {
            fill: 'white'
          }
        }
      }
    },
    // Zoom on pinch
    zoomType: 'x',
    time: {
      timezone: moment.tz.guess()
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Date ( ' + moment.tz.guess() + ' )'
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%l:%M:%S',
      minute: '%l:%M %p',
      hour: '%l:%M %p',
      day: '%e. %b',
      week: '%e. %b',
      month: '%b \'%y',
      year: '%Y'
    }
  },
  yAxis: {
    title: {
      text: 'Readings'
    }
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    }
  },
  legend: {
    enabled: true
  },
  navigator: {
    enabled: false
  },
  tooltip: {
    valueDecimals: 2
  }
};

export default Controller.extend(AlertManipulator, {
  queryParams: ['source', 'pageSize'],
  currentUser: service(),
  analytics: service(),
  report: service(),
  modalImage: null,
  selectedImage: null,
  cameraPanelVisible: false,
  chartOptions: CHART_OPTIONS,
  success: '',
  info: '',
  error: '',
  laterRunId: null,
  source: DEFAULT_TRACKING_SOURCE,
  pageSize: 9,
  isCurrentUserStaff: computed('currentUser.vhiUser.isStaff', function() {
    return this.currentUser.vhiUser.isStaff;
  }),
  eventObj: computed('model.event', function() {
    return this.get('model.event');
  }),
  contentType: computed('eventItems.{@each.content,[]}', function() {
    if (!this.eventItems) {
      return null;
    }
    const firstItem = this.eventItems.objectAt(0);
    let type = null;
    if (firstItem && firstItem.get('content')) {
      type = firstItem.get('content._internalModel.modelName')
    }
    return type ? type : 'image';

  }),
  allImages: computed('eventItems.{@each.content,[]}', function() {
    let allImages = [];
    this.eventItems.forEach(item => {
      allImages.push(item.content);
    })
    return allImages;
  }),
  currentSite: computed('eventItems.@each.content', function() {
    if (this.eventItems) {

      let siteId = this.get('eventItems.firstObject.content.siteId');
      if (siteId) {
        return this.store.peekRecord('site', siteId);
      }
    }
    return null;
  }),
  clearMessages() {
    this.set('success', '');
    this.set('info', '');
    this.set('error', '');
  },
  cleanup() {
    $('.reach-snapshot-modal').modal('hide');
  },
  openImageInReviewStream(site, to, channels) {
    this.transitionToRoute('site.review', site, {
      queryParams: {
        to: to,
        channel: channels
      }
    });
  },
  openDataLoggerInReviewStream(site, datalogger, to, from) {
    this.transitionToRoute('site.datalogger', site, datalogger, {
      queryParams: {
        to: to,
        from: from
      }
    });
  },
  chartData: computed('eventItems.[]', function() {
    if (this.contentType !== 'datapointvalue') {
      return;
    }
    let registers = {};

    this.eventItems.forEach(item => {
      const dpv = item.content;
      let register = dpv.get('register');
      if (!registers[register.get('id')]) {
        registers[register.get('id')] = {
          name: register.get('name') + ' (' + register.get('units') + ')',
          data: []
        };
      }
      registers[register.get('id')].data.push([
        dpv.get('timestamp'),
        dpv.get('truncatedValue')
      ]);
    });
    let data = Object.values(registers);
    return data;
  }),

  sendStatus(msg, state) {
    this.clearMessages();
    cancel(this.laterRunId);
    if (state === STATE_SUCCESS) {
      this.set('success', msg);
    } else if (state === STATE_INFO) {
      this.set('info', msg);
    } else {
      this.set('error', msg);
    }
    this.set(
      'laterRunId',
      later(
        this,
        function() {
          this.clearMessages();
        },
        5000
      )
    );
  },

  removeFromList() {
    return;
  },

  actions: {
    openImageInModal(image) {
      this.set('selectedImage', image);
      if (image) {
        $('.reach-snapshot-modal').modal('show');
      }
    },


    toggleCameraPanel() {
      this.toggleProperty('showCameraPanel');
      if (this.showCameraPanel) {
        this.analytics.trackMultiple(OPEN_ALERT_VIDEO_PLAYER, {
          subscriptionId: this.get('model.subscription.id'),
          alertId: this.get('model.id'),
          subscriptionName: this.get('model.subscription.name')
        });
      }
    }
  }
});
