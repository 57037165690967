import Controller from '@ember/controller';
import { groupByPath } from 'ember-cli-group-by/macros';
import { later, cancel } from '@ember/runloop';
import { inject as service } from '@ember/service';
import {observer} from '@ember/object';
import moment from 'moment';
import { ALERT_LIST_VIEW_FILTERED } from '../services/analytics';
import UserPermissionsMixin from '../mixins/user-permission-check';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';
import move from 'ember-animated/motions/move';
import fade from 'ember-animated/transitions/fade';


const STATE_SUCCESS = 'success';
const STATE_INFO = 'info';

export default Controller.extend(UserPermissionsMixin, {
  fade,
  infinity: service(),
  analytics: service(),
  newAlerts: service('subscription'),
  queryParams: [
    'page_size',
    'notified',
    'state',
    'before_date',
    'subscription',
    'source',
    'report_id',
    'subscription',
    'remove_sub_type'
  ],
  state: null,
  page: 1,
  page_size: 10,
  notified: null,
  before_date: null,
  subscription: null,
  source: null,
  report_id: null,
  eventsGrouped: groupByPath('model', 'day'),
  success: '',
  info: '',
  error: '',
  laterRunId: null,
  showFilters: false,
  loadingAlerts: false,
  loadingLaterAlerts: false,
  noLaterItems: false,

  transition({ removedSprites, insertedSprites }) {
    removedSprites.forEach(sprite => {
      sprite.endAtPixel({ x: window.innerWidth });
      move(sprite, { easing: easeOut });
    });
    insertedSprites.forEach(sprite => {
      sprite.startAtPixel({ x: 0 });
      move(sprite, { easing: easeIn });
    });
  },
  clearMessages() {
    this.set('success', '');
    this.set('info', '');
    this.set('error', '');
    this.set('noLaterItems', false);
  },
  /**
   * When an event arrives via websocket, determine if / how we
   * display it in the list
   **/
  processNewEvent(eventId) {
    if (!this.newAlerts.lastUpdatedAlert) {
      return;
    }
    //Do not show the new alerts if you're looking at an older point in time
    if (this.after_date) {
      return;
    }
    //don't show new alerts if on archived or flagged
    if (this.state && this.state != this.newAlerts.newState.id) {
      return
    }
    //if the event is already loaded we don't need to load it again.
    //but we do want to refresh the event items
    const event = this.store.peekRecord('subscription/event', eventId)
    if (event) {
      this.store.query('subscription/event-item-reference', {
        page_number: 1,
        page_size: 5,
        event_id: event.id
      }).then(items => {
        event.set('items', items);
        event.set('totalEventItems', items.meta && items.meta.pagination ? items.meta.pagination.count : items.length)
        return items;
      });
      return;
    }
    this.store.find('subscription/event', eventId)
      .then(result => {
        //if there is a subscription filter selected, don't show the
        //event if it doesn't match the subscription
        if (this.subscription) {
          if (this.subscription != result.get('subscripton.id')) {
            return;
          }
        }
        this.store.query('subscription/event-item-reference', {
          page_number: 1,
          page_size: 5,
          event_id: result.id
        }).then(items => {
          result.get('items').addObjects(items);
          result.set('totalEventItems', items.meta && items.meta.pagination ? items.meta.pagination.count : items.length)
          return items;
        });
        this.infinity.unshiftObjects(this.get('model'), [result]);
      });
  },

  /**
   * Listen for a new event from the websocket, and load it to the list of alerts
   **/
  newEventAdded: observer('newAlerts.lastUpdatedAlert', function() {

    let newEventId = this.newAlerts.lastUpdatedAlert;
    this.processNewEvent(newEventId);

  }),
  actions: {
    dateChanged(date) {
      this.clearMessages();
      let isoDate = moment(date)
        .add(1439, 'minutes')
        .toISOString();
      this.analytics.trackMultiple(ALERT_LIST_VIEW_FILTERED, {
        filterValue: isoDate,
        by: 'date'
      });
      this.set('before_date', isoDate);
      this.send('refreshData');
    },
    clearDate() {
      if (this.before_date) {
        this.clearMessages();
        this.set('before_date', null);
        this.send('refreshData');
      }
    },
    siteSelected(site) {
      this.clearMessages();
      if (site) {
        this.analytics.trackMultiple(ALERT_LIST_VIEW_FILTERED, {
          filterValue: site.get('id'),
          by: 'subscription'
        });
        this.set('subscription', site.get('id'));
      } else {
        this.set('subscription', null);
      }
    },
    hidePopup() {
      this.set('showFilters', false);
    },
    hideCalendar() {
      this.set('showCalendar', false);
    },
    setState(state) {
      this.analytics.trackMultiple(ALERT_LIST_VIEW_FILTERED, {
        filterValue: state,
        by: 'state'
      });
      this.set('state', state);
      this.set('showFilters', false);
    },
    openFilters() {
      this.toggleProperty('showFilters');
    },
    openCalendar() {
      this.toggleProperty('showCalendar');
    },
    loadLater() {
      let events = this.get('model');
      let latestTime = null;
      this.set('noLaterItems', false);
      this.set('loadingLaterAlerts', true);
      events.forEach(event => {
        if (!latestTime) {
          latestTime = event.get('lastTimestamp');
        } else {
          if (moment(latestTime).isBefore(moment(event.get('lastTimestamp')))) {
            latestTime = event.get('lastTimestamp');
          }
        }
      });
      this.store
        .query('subscription/event', {
          page: 1,
          page_size: this.page_size,
          state: this.state,
          after_date: latestTime,
          sort: 'first_timestamp',
          subscription: this.subscription
        })
        .then(results => {
          if (results.get('length') <= 0) {
            this.set('noLaterItems', true);
            later(
              this,
              function() {
                this.set('noLaterItems', false);
              },
              5000
            );
          }
          this.set('loadingLaterAlerts', false);
          results.forEach(event => {
            this.store
              .query('subscription/event-item-reference', {
                page: 1,
                page_size: 5,
                event_id: event.id
              }).then(items => {
                event.set('totalEventItems', items.meta && items.meta.pagination ? items.meta.pagination.count : items.length)
              });
          });
          let reverseResults = [];
          results.forEach(item => {
            reverseResults.unshift(item);
          });
          if (reverseResults.objectAt(0)) {
            let isoDate = moment(
              reverseResults.objectAt(0).get('lastTimestamp')
            )
              .startOf('day')
              .add(1439, 'minutes')
              .toISOString();
            this.set('before_date', isoDate);
          }
          this.infinity.unshiftObjects(
            this.get('model'),
            reverseResults
          );
        });
    },
    loadEarlier() {
      this.set('loadingAlerts', true);
      this.infinity.infinityLoad(this.get('model')).then(() => {
        this.set('loadingAlerts', false);
      });
    },
    showMessage(msg, state) {
      this.clearMessages();
      cancel(this.laterRunId);
      if (state === STATE_SUCCESS) {
        this.set('success', msg);
      } else if (state === STATE_INFO) {
        this.set('info', msg);
      } else {
        this.set('error', msg);
      }
      this.set(
        'laterRunId',
        later(
          this,
          function() {
            this.clearMessages();
          },
          5000
        )
      );
    },
    removeFromList(event) {
      if (this.state === '2' && event.get('currentStatus.status.id') !== '2') {
        //remove if the event is not flagged and on flagged view
        this.model.removeObject(event);
      } else if (this.state === '4' && event.get('currentStatus.status.id') !== '4') {
        //remove if the event is not archived and on archive view
        this.model.removeObject(event);
      } else if (this.state === '1' && event.get('currentStatus.status.id') !== '1') {
        //remove if the event is not new and on new view
        this.model.removeObject(event);
      } else if (this.state !== '4' && event.get('currentStatus.status.id') === '4') {
        //remove if the event is archived and not on archived view
        this.model.removeObject(event);
      }
    },
    openImageInReviewStream(site, to, channels) {
      this.transitionToRoute('site.review', site, {
        queryParams: {
          to: to,
          channel: channels,
          highlight: true
        }
      });
    },
    openDataLoggerInReviewStream(site, datalogger, to, from) {
      this.transitionToRoute('site.datalogger', site, datalogger, {
        queryParams: {
          to: to,
          from: from
        }
      });
    }
  }
});
