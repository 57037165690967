import Component from '@ember/component';
import { computed } from '@ember/object';
import PerfectScrollController from 'ember-perfect-scroll/mixins/perfect-scroll-controller';

export default Component.extend(PerfectScrollController, {
  classNames: ['site-picker_site-picker'],
  sites: null,
  filtersBySite: null,
  sitesLoading: false,
  paginationText: computed('sites', function() {
    if (!this.sites.query || !this.sites.meta) {
      return '';
    }
    const pageSize = this.sites.query.page_size;
    const pageNumber = this.sites.query.page;
    const totalCount = this.sites.meta.count;
    const from = `${pageSize * (pageNumber - 1) + 1}`;
    const to = `${Math.min(pageSize * pageNumber, totalCount)}`;

    return `${from} - ${to} of ${totalCount}`;
  }),
  activelySelectedSite: null,
  init() {
    this._super(...arguments);
    this.sites = this.sites || [];
    this.filtersBySite = this.filtersBySite || [];
  },
  didRender() {
    this.updatePerfectScroll();
  },
  actions: {
    selectSite(site) {
      this.selectSite(site);
    }
  }
});
