import { Model, trait, faker } from 'ember-cli-mirage';

export default Model.extend({
  dataloggerTest: trait({
    bitToRead: faker.random.number(),
    dataType: faker.random.word(),
    last_reading: faker.date.past(),
    name: faker.hacker.noun(),
    registerAddress: faker.random.number(),
    units: faker.company.bsNoun()
  })
});
