import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';

export default Controller.extend({
  store: service(),

  showSitePicker: false,
  frozenEntitlements: null,

  refreshModel() {
    this.store.unloadAll('subscription/inspection-entitlement');
    this.send('refresh');
  },

  actions: {
    onSaveChanges(entitlements) {
      return Promise.all(entitlements.map(e => e.save()))
        .then(() => {
          // clean out deleted records from the model
          this.set('model', this.model.filterBy('isDeleted', false));
        });
    },

    createEntitlement(channel) {
      return this.store.createRecord('subscription/inspection-entitlement', {
        channel: channel,
      });
    },

    toggleShowSitePicker() {
      this.toggleProperty('showSitePicker');
      this.set('frozenEntitlements', this.showSitePicker ?
        this.get('model')
          .map(item => copy(item))
        : null);

      // when toggling off the site picker, force entitlements to reload
      // so they are presented in the correct server order
      if (!this.showSitePicker) {
        this.refreshModel();
      }
    },
  },
});
