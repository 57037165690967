define("reach-web-client/components/svg-square", ["exports", "ember-svg-shapes/components/svg-square"], function (_exports, _svgSquare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _svgSquare.default;
    }
  });
});
