import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  placeholderText: computed('placeholder', function() {
    return this.placeholder
      ? this.placeholder
      : this.intl.t('sites.search-placeholder');
  })
});
