import Component from '@ember/component';

export default Component.extend({
  actions: {
    removeChannel(channelId) {
      this.removeChannel(channelId);
    },
    addChannel(channelId) {
      this.addChannel(channelId);
    }
  }
});
