import DS from 'ember-data';
import { dasherize } from '@ember/string';

export default DS.JSONAPISerializer.extend({
  /**
   * Need to remove the singularize method from here as it changes data to datum
   * @param key
   * @returns {String|*}
   */
  modelNameFromPayloadKey(key) {
    return dasherize(key);
  },
  keyForAttribute(key) {
    return dasherize(key);
  }
});
