import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  notificationTypes: null,
  classNames: ['webhooks-card'],
  allowEdit: false,
  webhookSubscribers: null,
  urlIsValid: false,
  urlValidation: null,
  isTouched: null,
  webhookUrl: null,

  toggleWebhooksStatus: function(user, type, shouldToggleOn) {
    this.toggleWebhooksStatus(user, type, shouldToggleOn)
  },

  init() {
    this._super(...arguments);
    this.webhookSubscribers = this.webhookSubscribers || [];

    this.urlValidation = [{
      'message': 'Please provide url in a valid format',
      'isValid': true,
      'validate': (inputValue) => {
        let urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        this.set('urlIsValid', urlPattern.test(inputValue));
        return this.urlIsValid;
      }
    }];

  },

  actions: {
    toggleWebhooksStatus(user, type, shouldToggleOn) {
      this.toggleWebhooksStatus(user, type, shouldToggleOn);
    },

    removeWebhookFromList(webhook) {
      this.removeWebhookFromList(webhook);
    },

    addWebhookToList() {
      if (this.webhookUrl && this.urlIsValid) {
        this.addWebhookToList(this.webhookUrl);
        this.set('webhookUrl', '');
        this.set('isTouched', false)
      }

    },

  }
});
