import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, observer } from '@ember/object';
import groupBy from 'ember-group-by';

export default Component.extend({
  currentUser: service(),
  intl: service(),
  store: service(),
  classNames: ['user-management-list-item'],
  attributeBindings: ['disabled:disabled'],
  user: null,
  sites: null,
  filters: null,
  showDialog: false,
  disabled: false,
  site: 'site.id',
  init() {
    this._super(...arguments);
    this.checkViewStatus();
  },
  filtersBySite: groupBy('computedFilters', 'site'),
  computedFilters: computed('filters.[]', function() {
    return this.filters;
  }),
  isCurrentUserUser: computed('user', 'currentUser', function() {
    if (!this.currentUser || !this.currentUser.vhiUser) {
      return false;
    }
    return (
      this.currentUser.vhiUser.get('id') === this.user.user.get('id')
    );
  }),
  canUserView: true,
  watchUserView: observer('user.user', 'filters', function() {
    this.checkViewStatus()
  }),
  checkViewStatus() {
    let inspectionSites = [];
    inspectionSites.push(this.filtersBySite);
    const filters = this.filters;
    if (!this.user.user.get('id') || !this.filters) {
      return false;
    }
    this.store.findRecord('site-user', this.user.user.get('id')).then (user => {
      const result = filters.any(filter => {
        return user.sites.any(site => {
          return filter.site.get('id') === site.get('id')
        })
      })
      this.set('canUserView', result);
    })
  },
  actions: {
    toggleShowDialog() {
      if (!this.user.creator && !this.isCurrentUserUser) {
        this.toggleProperty('showDialog');
      }
    },
    removeUserFromList(user) {
      this.removeUserFromList(user);
    },
    toggleAllowEdit() {
      this.user.toggleProperty('allowEdit');
    },
    toggleNotificationMethod(user, type, shouldToggleOn) {
      this.toggleNotificationMethod(user, type, shouldToggleOn);
    },
    toggleShowWarning() {
      this.user.toggleProperty('canUserView');
    }
  }
});
