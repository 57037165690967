import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  can: service(),
  classNames: ['manage-list_container'],
  model: null,
  init() {
    this._super(...arguments);
    this.model = this.model || [];
  },
  totalCount: computed('this.model.{[],meta.pagination.count}', function() {
    if (
      !this.model.meta ||
      !this.model.meta.pagination ||
      !this.model.meta.pagination.count
    ) {
      return (
        this.model.length
      );
    }
    return this.model.meta.pagination.count;
  }),
  showDeleteDialog: false,
  selectedItems: computed.filterBy('model', 'selected', true),
  selectedCount: computed('selectedItems.[]', function() {
    return this.selectedItems.length;
  }),
  allSelected: computed('selectedCount', 'totalCount', function() {
    return this.totalCount === this.selectedCount;
  }),
  someSelected: computed('selectedItems.[]', 'selectedCount', function() {
    return this.selectedCount > 0;
  }),
  selectAllLabelText: computed('selectedCount', 'totalCount', function() {
    if (this.selectedCount > 0) {
      return `(${this.selectedCount}/${this.totalCount})`;
    }
    return `All (${this.totalCount})`;
  }),
  actions: {
    toggleShowDeleteDialog() {
      this.toggleProperty('showDeleteDialog')
    },
    selectAllInspections() {
      const deselectAll = this.someSelected;
      if (deselectAll) {
        this.model.forEach(inspection => inspection.set('selected', false));
        return;
      }
      const selectable = this.model.filter(inspection => {
        return this.can.can('edit inspection', inspection)
      });
      selectable.forEach(inspection => inspection.set('selected', true));
    },
    deleteSelectedInspections() {
      this.deleteSelectedInspections();
    }
  }
});
