import ApplicationAdapter from './application';
import CacheBuster from '../mixins/cachebuster-adapter';


export default ApplicationAdapter.extend(CacheBuster, {
  alwaysReload: true,
  buildURL(modelName, id, snapshot, action) {
    const url = this._super(...arguments);
    if (action === 'updateRecord') {
      return url + 'read/';
    }
    return url;
  },
  /**
   Called by the store when an existing record is saved
   via the `save` method on a model record instance.
   The `updateRecord` method serializes the record and makes an Ajax (HTTP PUT) request
   to a URL computed by `buildURL`.
   See `serialize` for information on how to customize the serialized form
   of a record.
   @method updateRecord
   @param {DS.Store} store
   @param {DS.Model} type
   @param {DS.Snapshot} snapshot
   @return {Promise} promise
 */
  updateRecord(store, type, snapshot) {
    let data = {};
    let id = snapshot.id;
    let url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

    return this.ajax(url, 'POST', { data: data });
  }
});
