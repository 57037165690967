import {Response} from 'ember-cli-mirage';

export default function () {
  this.passthrough();
  this.passthrough('/write-coverage');
  this.passthrough('https://youtu.be/**')
  this.passthrough('https://api.ipstack.com/**');
  this.passthrough('https://cognito-idp.us-west-2.amazonaws.com/');
  this.passthrough('https://cognito-idp.us-west-2.amazonaws.com/**');
}

export function testConfig() {
  // test-only config, does not apply to development
  this.urlPrefix = 'https://echo.stage.ospr.in'; // make this `http://localhost:8080`, for example, if your API is on a different server
  this.namespace = '/api'; // make this `/api`, for example, if your API is namespaced

  this.get('/tenant', schema => {
    const existing = schema.tenant.first();
    if (existing) {
      return existing;
    }
    return schema.tenant.create();
  });
  this.get('/messages/', schema => {
    return schema.messages.all();
  });
  this.post('/messages/:id/read/', (schema, request) => {
    return schema.messages.create({id: request.params.id});
  });
  this.get('/users/:id', (schema, request) => {
    const existing = schema.users.find(request.params.id);
    if (request.params.id === 'whoami') {
      return schema.users.find('1')
    }
    return existing
      ? existing
      : schema.users.create({
        id: request.params.id,
        username: 'ember-tester'
      });
  });
  this.patch('/users/:id', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.users.find(request.params.id)
      .update(attrs);
  });
  this.post('/users/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.users.create(attrs);
  });

  /*this.get('legacy/v3/users/:id', (schema, request) => {
    const existing = schema.users.find(request.params.id);
    if (request.params.id === 'whoami') {
      if (schema.users.all().length > 0) {
        return schema.users.first();
      }
    }
    return existing ? existing : schema.users.create({id: request.params.id, username: 'ember-tester'});
  });*/
  this.get('/users/', (schema, request) => {
    let queryParams = request.queryParams;
    let users = schema.users.all();
    if (queryParams && queryParams['email']) {
      users = users.filter((user) => user.email.includes(queryParams['email']));
    }

    return users;
  });
  this.get('/sitetypes/', (schema) => {
    let types = schema.siteTypes.all();
    return types;
  });
  this.get('/v1/manage/users/', (schema) => {
    let users = schema.siteUsers.all();
    return users;
  });
  this.get('/v1/manage/users/:id', (schema, request) => {
    const existing = schema.siteUsers.find(request.params.id);
    return existing
  });
  this.patch('/v1/manage/users/:id', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.siteUsers.find(request.params.id)
      .update(attrs);
  });
  this.get('/v1/manage/sites/', (schema) => {
    let users = schema.accessSites.all();
    return users;
  });

  this.get('/v1/subscriptions/users/:id/', (schema, request) => {
    const existing = schema.basicUsers.find(request.params.id);
    if (existing) {
      return existing;
    }
    return schema.basicUsers.all();
  });
  this.get('/features/', schema => {
    return schema.featureFlags.all();
  });
  this.get('/v1/cameras/', schema => {
    return schema.channels.all();
  });
  this.get('/v1/dashboard/channels', schema => {
    return schema.channels.all();
  });

  this.get('/channels/', schema => {
    return schema.channels.all();
  });
  this.get('/channels/:id/', (schema, request) => {
    const existing = schema.channels.find(request.params.id);
    return existing
      ? existing
      : schema.channels.create({id: request.params.id});
  });

  this.get('/cloudbridges/', schema => {
    return schema.cloudbridges.all();
  });
  this.get('/cloudbridges/:id/', (schema, request) => {
    const existing = schema.cloudbridges.find(request.params.id);
    return existing
      ? existing
      : schema.cloudbridges.create({id: request.params.id});
  });

  this.get('/v1/cv/tag_type_group', schema => {
    return schema.tagTypeGroups.all();
  });

  this.get('/v1/cv/tag_type', schema => {
    return schema.tagTypes.all();
  });

  this.get('/v1/cv/tag_type_group/:id/', (schema, request) => {
    return schema.tagTypeGroups.find(request.params.id);
  });
  this.get('/sites', schema => {
    return schema.sites.all();
  });
  this.get('/sites/:id/', (schema, request) => {
    const existing = schema.sites.find(request.params.id);
    return existing ? existing : schema.sites.create({id: request.params.id});
  });
  this.get('https://notexist.staging.ospr.in/**', () => {
    return new Response(
      404,
      {some: 'header'},
      {error: 'Tenant does not exist'}
    );
  });
  this.get('/channels/:slug_id/take-picture', (schema, request) => {
    return schema.images.find(request.params.slug_id);
  });
  this.get('/sites/:site_id/actions', schema => {
    return schema.actions.all();
  });
  this.get('/channels/:slug_id/actions', schema => {
    return schema.actions.all();
  });
  this.patch('/sites/:site_id/actions', (schema, request) => {
    return schema.actions.find(request.params.site_id);
  });
  this.patch('/channels/:slug_id/actions', (schema, request) => {
    return schema.actions.find(request.params.slug_id);
  });
  this.get('/actions/', schema => {
    return schema.actions.all();
  });
  this.post('/legacy/video/stream/start', () => {
    return new Response(
      200,
      {some: 'header'},
      {msg: 'happy happy joy joy'}
    );
  });
  this.get('/dataloggers', schema => {
    return schema.dataloggers.all();
  });
  this.get('/registers/:reg_id', (schema, request) => {
    return schema.registers.find(request.params.reg_id);
  });
  this.get('/images/:id/', (schema, request) => {
    return schema.images.find(request.params.id);
  });
  this.get('/datapoints/:id/', (schema, request) => {
    return schema.datapointvalues.find(request.params.id);
  });
  this.get('/images', (schema, request) => {
    if (request.queryParams.tag) {
      let tagImage = schema.images.create({
        tagSet: [{labels: [request.queryParams.tag], confidence: 100}]
      });
      return schema.images.where({id: tagImage.id});
    }
    if (request.queryParams.images) {
      return schema.images.where({reason: 'new-image'});
    }
    if (request.queryParams.reason) {
      let reasonImage = schema.images.create({
        reason: request.queryParams.reason
      });
      return schema.images.where({id: reasonImage.id});
    }
    if (request.queryParams.preset) {
      let ptzImage = schema.images.create({
        presetId: request.queryParams.preset
      });
      return schema.images.where({id: ptzImage.id});
    }
  });
  this.get('/media', (schema, request) => {
    if (request.queryParams.tag) {
      let tagImage = schema.images.create({
        tagSet: [{labels: [request.queryParams.tag], confidence: 100}]
      });
      return schema.images.where({id: tagImage.id});
    }
    if (request.queryParams.images) {
      return schema.images.where({reason: 'new-image'});
    }
    if (request.queryParams.reason) {
      let reasonImage = schema.images.create({
        reason: request.queryParams.reason
      });
      return schema.images.where({id: reasonImage.id});
    }
    if (request.queryParams.to) {
      let toImage = schema.images.create({timestamp: request.queryParams.to});
      return schema.images.where({id: toImage.id});
    }
    if (request.queryParams.from) {
      let fromImage = schema.images.create({
        timestamp: request.queryParams.from
      });
      return schema.images.where({id: fromImage.id});
    }

    let channels = [];
    const params = request.url.split('?')[1].split('&');
    params.forEach(param => {
      let item = param.split('=');
      if (item[0] === 'channel') {
        channels.push(item[1]);
      }
    });
    if (channels.length === 0) {
      return schema.images.all();
    }
    return schema.images.all()
      .filter(imageModel => {
        if (!imageModel.channel) {
          return false;
        }
        return channels.indexOf(imageModel.channel.id) >= 0;
      });
  });
  this.post('/cloudbridges/:cloudbridge-id/fetch-video/', schema => {
    return schema.videos.all();
  });
  this.get('/legacy/v3/reports/', schema => {
    return schema.reports.all();
  });
  this.post('/legacy/v3/reports/', schema => {
    return schema.reports.first();
  });
  this.get('/legacy/v3/report_types/', schema => {
    schema.reportTypes.create();
    return schema.reportTypes.all();
  });
  this.get('/legacy/v3/report_segment_types/', schema => {
    schema.reportSegmentTypes.create();
    return schema.reportSegmentTypes.all();
  });
  this.put('/legacy/v3/reports/:id', (schema, request) => {
    const attrs = JSON.parse(request.requestBody);
    let report = schema.reports.find(request.params.id);
    if (report) {
      report.attrs = attrs;
      report.save();
    } else {
      report = schema.reports.first();
    }
    return report;
  });
  this.get('/legacy/v3/report/:id/segments', schema => {
    return schema.reportSegments.all();
  });
  this.post('/legacy/v3/reports/:id/generate-pdf/', () => {
    return '';
  });
  this.delete('/legacy/v3/reports/:id', (schema, request) => {
    schema.db.reports.remove(request.params.id);
    return new Response(204);
  });
  this.get('/v1/subscriptions/subscriptions/:id/', (schema, request) => {
    let sub = schema.subscriptionSubscriptions.find(request.params.id);
    sub.subscriptionFilters = schema.subscriptionQueryDocFilters.where({subscriptionId: sub.id});
    return sub;
  });
  this.patch('/v1/subscriptions/event_state_types/:id', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    let alert = schema.subscriptionEventStateTypes.where({
      id: request.params.id
    });
    alert.update(attrs);
    return schema.subscriptionEventStateTypes
      .find(request.params.id)
      .update(attrs);
  });
  this.get('/v1/subscriptions/event_state_types/', schema => {
    return schema.subscriptionEventStateTypes.all();
  });
  this.get('/v1/subscriptions/subscription_types/', schema => {
    return schema.subscriptionSubscriptionTypes.all();
  });
  this.get('/v1/subscriptions/notification_method_types/', schema => {
    return schema.subscriptionNotificationMethodTypes.all();
  });
  this.get('/v1/subscriptions/notification_methods/', schema => {
    return schema.subscriptionNotificationMethods.all();
  });
  this.post('/v1/subscriptions/event_subscriber_status/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    attrs.data.statusId = attrs.data.relationships.status.data.id;
    let status = schema.subscriptionEventSubscriberStatuses.find(attrs.data.id);
    if (!status) {
      status = schema.subscriptionEventSubscriberStatuses.create(attrs.data);
    }

    return status.update(attrs.data);
  });
  this.get(
    '/v1/subscriptions/event_subscriber_status/:id/',
    (schema, request) => {
      return schema.subscriptionEventSubscriberStatuses.find(request.params.id);
    }
  );
  this.patch(
    '/v1/subscriptions/event_subscriber_status/:id',
    (schema, request) => {
      let attrs = JSON.parse(request.requestBody);
      attrs.data.statusId = attrs.data.relationships.status.data.id;
      let status = schema.subscriptionEventSubscriberStatuses.find(
        attrs.data.id
      );
      if (!status) {
        status = schema.subscriptionEventSubscriberStatuses.create(attrs.data);
      }
      return status.update(attrs.data);
    }
  );
  this.get('/v1/subscriptions/subscribers/:id/', (schema, request) => {
    return schema.subscriptionSubscribers.find(request.params.id);
  });

  this.get('/v1/subscriptions/webhook_subscribers/:id/', (schema, request) => {
    return schema.subscriptionWebhookSubscribers.find(request.params.id);
  });
  this.get('/v1/dashboard/events/', (schema) => {
    return schema.subscriptionEvents.all();
  });

  this.get('/v1/subscriptions/events/', (schema, request) => {
    let state = request.queryParams['filter[subscriber_states.status]'];
    const dateFilter = request.queryParams['filter[first_timestamp.lte]'];
    if (dateFilter) {
      const events = schema.subscriptionEvents.all();
      return events.filter(model => new Date(model.created) <= new Date(dateFilter));
    } else if (request.queryParams['filter[subscription]']) {
      return schema.subscriptionEvents.where({
        subscriptionId: request.queryParams['filter[subscription]']
      });
    } else if (state) {
      let statuses = schema.subscriptionEventSubscriberStatuses.where({
        statusId: state
      });
      let events = null;
      statuses.models.forEach(status => {
        if (events) {
          events.models = events.models.concat(
            schema.subscriptionEvents.where({id: status.eventId}).models
          );
        } else {
          events = schema.subscriptionEvents.where({id: status.eventId});
        }
      });
      if (!events) {
        events = schema.subscriptionEvents.none;
      }
      return events;
    } else if (request.queryParams['filter[subscriber_states.status.in]']) {
      return schema.subscriptionEvents.all();
    }
    return schema.subscriptionEvents.all();
  });
  this.get('/v1/subscriptions/events/:id/', (schema, request) => {
    let results = schema.subscriptionEvents.find(request.params.id);
    if (!results) {
      return new Response(
        404,
        {some: 'header'},
        {error: 'Event does not exist'}
      );
    }
    return results;
  });
  this.patch('/v1/subscriptions/events/:id/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    let alert = schema.subscriptionEvents.where({id: request.params.id});
    alert.update({state: attrs.state});
    return schema.subscriptionEvents.find(request.params.id)
      .update(attrs);
  });
  this.patch('/v1/subscriptions/subscriptions/:id/', (schema, request) => {
    let attrs = {enabled: JSON.parse(request.requestBody).enabled};
    return schema.subscriptionSubscriptions
      .find(request.params.id)
      .update(attrs);
  });
  this.delete('/v1/subscriptions/subscriptions/:id/', (schema, request) => {
    schema.db.subscriptionSubscriptions.remove(request.params.id);
    return new Response(204);
  });
  this.post('/v1/subscriptions/events/', schema => {
    return schema.subscriptionEvents.first();
  });

  this.get('/v1/subscriptions/subscriptions/', (schema, request) => {
    let typeId = request.queryParams['filter[type]'];
    let context = request.queryParams['context'];
    typeId = context == 'inspection' ? 2 : context == 'alert' ? 1 : typeId;

    if (typeId) {
      return schema.subscriptionSubscriptions.where({
        typeId: typeId
      });
    } else {
      return schema.subscriptionSubscriptions.all();
    }
  });
  this.get('/v1/subscriptions/event_items/:id', (schema, request) => {
    //works around mirage requesting models by id.
    if (!request.queryParams['page_size']) {
      return schema.subscriptionEventItemReferences.find(request.params.id)
    }
    return schema.subscriptionEventItemReferences.where({
      eventId: request.params.id
    });
  });

  this.post('/v1/subscriptions/subscriptions/', (schema, request) => {
    let context = request.queryParams['context'];
    let typeId = context == 'inspection' ? 2 : context == 'alert' ? 1 : null;
    let data = JSON.parse(request.requestBody).data.attributes;

    if (typeId) {
      data['typeId'] = typeId;
    } else {
      throw 'type is not specified'
    }

    return schema.subscriptionSubscriptions.create(data);
  });
  this.get('/v1/subscriptions/querydoc_filters/', schema => {
    return schema.subscriptionQueryDocFilters.all();
  });
  this.post('/v1/subscriptions/querydoc_filters/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.subscriptionQueryDocFilters.create(attrs);
  });
  this.post('/v1/subscriptions/subscribers/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.subscriptionSubscribers.create(attrs);
  });
  this.post('/v1/subscriptions/webhook_subscribers/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.subscriptionWebhookSubscribers.create(attrs);
  });
  this.post(
    '/v1/subscriptions/notification_method_types/',
    (schema, request) => {
      let attrs = JSON.parse(request.requestBody);
      return schema.subscriptionNotificationMethodTypes.create(attrs);
    }
  );
  this.post('/v1/subscriptions/notification_methods/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.subscriptionNotificationMethods.create(attrs);
  });
  this.post('/v1/subscriptions/schedules/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.subscriptionSchedules.create(attrs);
  });
  this.get('/v1/subscriptions/daily_report/', schema => {
    return schema.subscriptionDailyReports.all();
  });
  this.post('/v1/subscriptions/daily_report/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    attrs.id = 42;
    return schema.subscriptionDailyReports.create(attrs);
  });
  this.patch('/v1/subscriptions/daily_report/:id/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    schema.db.subscriptionDailyReports.update(request.params.id, attrs.data.attributes);
    return schema.subscriptionDailyReports.find(request.params.id);
  });

  this.get('ptz-positions/:id/', (schema, request) => {
    return schema.ptzPresets.find(request.params.id);
  });
  this.post('channels/:slug/move-to-preset/', () => {
    return new Response(
      200,
      {some: 'header'},
      {msg: 'happy happy joy joy'}
    );
  });

  this.get('/v1/subscriptions/inspection_entitlements');
  this.get('/v1/subscriptions/inspection_entitlements/:id/', (schema, request) =>
    schema.inspectionEntitlements.find(request.params.id));
  this.patch('/v1/subscriptions/inspection_entitlements/:id/', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    schema.db.inspectionEntitlements.update(request.params.id, attrs.data.attributes);
    return schema.inspectionEntitlements.find(request.params.id);
  });
  this.post('/v1/subscriptions/inspection_entitlements', (schema, request) => {
    let attrs = JSON.parse(request.requestBody);
    return schema.inspectionEntitlements.create(attrs);
  });
  this.delete('/v1/subscriptions/inspection_entitlements/:id/', (schema, request) => {
    schema.db.inspectionEntitlements.remove(request.params.id);
    new Response(204)
  });

  this.get('/v1/subscriptions/inspections', schema => {
    return schema.subscriptionInspections.all();
  });
  this.get('/v1/subscriptions/inspections/:id/', (schema, request) => {
    return schema.subscriptionInspections.find(request.params.id);
  });
  this.post('/v1/subscriptions/inspections', (schema, request) => {
    let attrs = {enabled: JSON.parse(request.requestBody).enabled};
    return schema.subscriptionInspections.create(attrs);
  });
  this.patch('/v1/subscriptions/inspections/:id/', (schema, request) => {
    let attrs = {enabled: JSON.parse(request.requestBody).enabled};
    return schema.subscriptionInspections
      .find(request.params.id)
      .update(attrs);
  });
  this.delete('/v1/subscriptions/inspections/:id/', (schema, request) => {
    schema.db.subscriptionInspections.remove(request.params.id);
    return new Response(204);
  });
  this.patch('/channels/ptz/move/relative/1234/zoom/', () => {
    return new Response(
      200,
      {some: 'header'},
      {msg: 'happy happy joy joy'}
    );
  });
  this.patch('/channels/ptz/move/continuous/1234/', () => {
    return new Response(
      200,
      {some: 'header'},
      {msg: 'happy happy joy joy'}
    );
  });

  this.passthrough('https://api.ipstack.com/**');
  this.passthrough('https://cognito-idp.us-west-2.amazonaws.com/**');
  this.passthrough('http://lorempixel.com/** ');
}
