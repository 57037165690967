import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import { v1 } from 'ember-uuid';

export default ApplicationAdapter.extend({
  storage: service(),
  namespace: 'api/legacy/v3/report/',
  query(store, type, query) {
    //remove null values from query
    Object.keys(query).forEach((key) => (query[key] == null) && delete query[key]);
    let url = this.buildURL(type.modelName, null, null, 'query', query);
    url = url.replace('//report-segments', '/' + query.id + '/segments');
    delete query.id;

    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }

    return this.ajax(url, 'GET', { data: query });

  },
  createRecord(store, type, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      snapshot.id = snapshot.id ? snapshot.id : v1();
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      data.type = 'report-segment';
      this.set('storage.' + snapshot.id, data);
      return new Promise(resolve => { resolve(data);});
    } else {
      return this._super(...arguments);
    }
  },
  deleteRecord(store, type, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      return new Promise((resolve) => {
        this.set('storage.' + snapshot.id, null);
        resolve();
      });
    } else {
      this._super(...arguments);
    }
  },
});
