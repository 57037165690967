import ApplicationAdapter from './application';
import ENV from '../config/environment';
import fetch from 'fetch';
import { reject } from 'rsvp';


export default ApplicationAdapter.extend({
  //overriding host on tenant as this is the main entry point to
  //setting up our api urls, everything else should be the same.
  host: ENV.vhiProtocol + '://' + window.location.host.split('.')[0] + '.' + ENV.vhiServer,
  /**
   * If we want a list of all tenants we must have admin access and
   * query the admin..../organizations endpoint
   * @param modelName
   * @param id
   * @param snapshot
   * @param requestType
   */
  buildURL(modelName, id, snapshot, requestType) {
    if (requestType !== 'findAll') {
      return this._super(...arguments);
    }

    let path = 'api/organizations';
    let url = [];
    let host = ENV.vhiProtocol + '://' + 'admin.' + ENV.vhiServer
    url.push(host);
    url.push(path);
    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }
    return url + '/';
  },
  queryRecord() {
    return fetch(this.host + '/api/tenant/' , {
      redirect: 'follow'
    })
      .then(result => {
        if (!result.ok) {
          return reject(result.status);
        }
        return result.json();
      });
  }
});
