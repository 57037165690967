import Component from '@ember/component';
import {inject as service} from '@ember/service';
import { computed } from '@ember/object';
import {filter} from '@ember/object/computed';

export default Component.extend({

  store: service(),
  cloudbridges: null,
  offlineCloudbridges: filter('cloudbridges', function(cb) {
    return !cb.active
  }),
  alertsCloudbridgePresent: computed('offlineCloudbridges', function() {
    return this.offlineCloudbridges && this.offlineCloudbridges.length > 0;
  }),
});
