import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import ENV from '../config/environment';


const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
const MEDIA_ENDPOINT = 'media';
const IMAGES_ENDPOINT = 'images';
const CLOUDBRIDGE_ENDPOINT = 'cloudbridges'
const CLOUDBRIDGE_FETCH_IMAGES = 'fetch-images'
const FIND_ENDPOINT = 'findRecord';
/**
 * refresh camera returns an image from
 * https://echo.ospreystaging.com/api/channels/180104-ch-80d0/take-picture/
 **/
export default ApplicationAdapter.extend({
  websockets: service(),
  session: service(),
  vhiProtocol: ENV.vhiProtocol,
  vhiWsProtocol: ENV.vhiWsProtocol,
  pathForType() {
    return MEDIA_ENDPOINT;
  },
  buildURL(modelName, id, snapshot, requestType, query, socket) {
    let path;
    let url = [];
    let host = this.host;
    let prefix = this.urlPrefix();

    if (socket) {
      prefix = prefix.replace(this.vhiProtocol, this.vhiWsProtocol);
      prefix = prefix.replace('/api', '');
    }
    if (requestType === FIND_ENDPOINT) {
      path = IMAGES_ENDPOINT;
      url.push(path);
    } else {
      if (!socket) {
        if (query.cloudbridge) {
          path = CLOUDBRIDGE_ENDPOINT;
          url.push(path);
          url.push(query.id);
          url.push(CLOUDBRIDGE_FETCH_IMAGES);
        } else if (query.media !== 'video' && (query.images || (query.tag && query.tag.length > 0) || (query.reason && query.reason.length > 0))) {
          path = IMAGES_ENDPOINT;
          url.push(path);
        } else if (modelName) {
          path = this.pathForType(modelName);
          if (path) {
            url.push(path);
          }
        }
      }
    }

    if (!socket && id) {
      url.push(encodeURIComponent(id));
    }
    if (prefix) {
      url.unshift(prefix);
    }

    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    return url + '/';
  },

  query(store, type, query) {
    //remove null values from query
    Object.keys(query).forEach((key) => (query[key] == null) && delete query[key]);
    let url = this.buildURL(type.modelName, null, null, 'query', query);


    if (this.sortQueryParams) {
      query = this.sortQueryParams(query);
    }
    delete query.media;

    if (query.cloudbridge) {
      delete query.cloudbridge;
      return this.ajax(url, 'POST', { data: query, traditional: true })
        .then((cbResponse) => {
          //we can't mock websockets from mirage
          if (Ember.testing) {
            return cbResponse;
          }
          return this.loadFromWebsocket(cbResponse.socket, query);
        });
    } else {
      return this.ajax(url, 'GET', { data: query, traditional: true });
    }
  },
  loadFromWebsocket(socketPath, query) {
    return new Promise ((resolve, reject) => {
      function onMessage(message) {
        const messageData = JSON.parse(message.data);
        if (messageData.status) {
          if (messageData.status === 'failed') {
            reject(messageData.message);
          }
        } else {
          socket.close();
          resolve(messageData);
        }
      }

      if (!socketPath) {
        return null;
      }
      if (socketPath.startsWith('/')) {
        socketPath = socketPath.substr(1);
      }
      let url = this.buildURL('image', null, null, 'query', query, true);

      let { access_token } = this.get('session.data.authenticated');
      url += `${socketPath}?access_token=${access_token}`;

      const socket = this.websockets.socketFor(url);
      socket.on('message', onMessage, this);
    });
  },
  queryRecord(store, type, query) {
    if (query.channelSlug) {
      const url = this.host + '/api/channels/' + query.channelSlug + '/take-picture/';
      const options = {headers: JSON_HEADERS};
      return this.ajax(url, 'GET', options);
    }

    return this._super(...arguments);

  }
});
