import CacheBuster from '../../mixins/cachebuster-adapter';
import SubscriptionAdapter from './subscription';

export default SubscriptionAdapter.extend(CacheBuster, {
  pathForType() {
    return 'v1/subscriptions/event_items'
  },

  query(store, type, query) {
    let url = this.buildURL();
    const endOfPath = url.lastIndexOf('/');
    const path = this.pathForType();
    url = [url.slice(0, endOfPath), `/${path}/${query.event_id}`, url.slice(endOfPath)].join('');
    url += `&page_size=${query.page_size}&page_number=${query.page_number}`;
    if (query.include) {
      url += `&include=${query.include}`;
    }
    if (query.sort) {
      url += `&sort=${query.sort}`;
    }
    const options = {headers: this.headers, redirect: 'follow'};
    return this.ajax(url, 'GET', options);
  }
});
