import {BaseSubscriptionSerializer} from '../../mixins/subscription/serializer';

export default BaseSubscriptionSerializer.extend({
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (!payload) {
      payload = {};
    }
    let newPayload = payload;
    if (Array.isArray(newPayload.data)) {
      newPayload.data.forEach((item) => {
        if (item.attributes.content) {
          delete item.attributes.content;
        }
      })
    } else {
      delete payload.data.content;
    }
    return this._super(store, primaryModelClass, newPayload, id, requestType);
  }
});
