define("reach-web-client/components/uic-modal-confirm", ["exports", "ember-ui-components/components/uic-modal-confirm"], function (_exports, _uicModalConfirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uicModalConfirm.default;
    }
  });
});
