import {belongsTo } from 'ember-cli-mirage';
import SumbscriptionFiltres from './subscription-subscription-filter';

export default SumbscriptionFiltres.extend({
  typeKey: 'subscription/inspection-filter',
  site: belongsTo('site'),
  channel: belongsTo('channel'),
  subscription: belongsTo('subscription-subscriptions', { inverse: 'subscriptionFilters' }),
  ptzPreset: belongsTo('ptz-preset')
});
