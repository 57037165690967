import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  name () {
    return faker.company.bs();
  },
  enabled () {
    return true;
  },
  scheduled () {
    return true;
  },
  creationInProgress () {
    return false;
  },
  created() {
    return faker.date.past();
  },
  modified() {
    return faker.date.past();
  },
  latestEventTimestamp () {
    return faker.date.past();
  },
  eventsPerDay() {
    return faker.random.number();
  }

});
