import {BaseSubscriptionSerializer} from '../../mixins/subscription/serializer';

export default BaseSubscriptionSerializer.extend({
  /**
   * We do get old json style output from vhi for some user requests
   **/
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (!payload) {
      payload = {};
    }
    let newPayload = payload;
    if (!payload.data) {
      newPayload = {
        data: [],
      };

      if (Array.isArray(payload.results)) {
        payload.results.forEach(item => {
          newPayload.data.push(
            this.normalizeItem(primaryModelClass.modelName, item, newPayload)
          );
        });
      } else {
        newPayload.data = this.normalizeItem(
          primaryModelClass.modelName,
          payload,
          newPayload
        );
      }
    }
    newPayload.meta = { count: payload.count };
    newPayload.links = {
      prev: payload.previous,
      next: payload.next,
      first: 0,
      last: payload.count
    };
    return this._super(store, primaryModelClass, newPayload, id, requestType);
  },
  /**
   * Simplified version from ApplicationSerializer
   **/
  normalizeItem(modelName, item) {
    let newItem = {
      id: item.id,
      type: item._type ? item._type : modelName,
      attributes: {},
      relationships: {}
    };

    for (var key in item) {
      newItem.attributes[key] = item[key];
    }

    delete newItem.attributes['id'];
    delete newItem.attributes['data'];

    return newItem;
  },

});
