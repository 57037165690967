import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  channel: DS.belongsTo('channel'),
  images: DS.hasMany('image', { inverse: 'ptzPreset' }),
  latestImage: computed('images',function() {
    return this.images.toArray().sort((a, b) => b.timestamp - a.timestamp)[0]
  }),
  delay: DS.attr(),
  label: DS.attr(),
  number: DS.attr(),
  order: DS.attr()
});
