/**
 * Commonly used constants when making API calls are gathered here.
 */
export default {
  // used by route: /inspection/index
  // This is how many images (or errors) displayed on an inspection-report-details page
  DEFAULT_EVENT_ITEMS_PAGE_SIZE: 50,

  // used by route: /inspections
  // NOTE: the table content can fit 11 rows on the display with appropriate margin-bottom
  // However, due to using infinite scroll we need 11 + 1 rows (aka 12 rows loaded) so that we
  // do not immediately trigger another fetch to the backend after the page is loaded.
  DEFAULT_INSPECTIONS_PAGE_SIZE: 12,
};
