/**
 * Helper functions for dealing with Leaflet can go in here.
 */

/* global L */
const leaf = typeof L === 'undefined' ? {} : L;

export default {
  L: leaf,

  createBingLayer: function() {
    const BING_KEY = 'AkQLkLEt5D8WbKusPrIvyJjc0pJZncGOGoeZoTCS7V6uxwyeQe3SiFXxh2WB8SWs';
    return this.L.tileLayer.bing(BING_KEY);
  }
};
