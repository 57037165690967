import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['site-filters-card_ptz-list-item'],
  classNameBindings: ['isHighlighted:is-highlighted'],
  ptzFilter: null,
  ptz: null,
  store: service(),
  allowEdit: false,
  highlightedFilters: null,
  isHighlighted: computed('highlightedFilters.[]', 'ptzFilter', function() {
    return this.highlightedFilters.includes(this.ptzFilter);
  }),
  init() {
    this._super(...arguments);
    this.ptzFilter = this.ptzFilter || [];
    this.highlightedFilters = this.highlightedFilters || [];
  },
  actions: {
    highlightFilter() {
      this.highlightFilter(this.ptzFilter, !this.isHighlighted);
    },
    toggleFilterTag(filter, tag) {
      this.toggleFilterTag(filter, tag);
    }
  }
});
