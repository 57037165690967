import TourManagerService from 'ember-site-tour/services/tour-manager';
import Tour from 'ember-site-tour/utils/tour';
import { typeOf as getTypeOf } from '@ember/utils';
import { get } from '@ember/object';
import { A as array } from '@ember/array';

export default TourManagerService.extend({
  _t(str) {
    // this function can be used to implement translation.
    return str;//this.get('i18n').t(str);
  },
  includeStepCount: false,
  /**
   * Setup a tour object.
   *
   * @method _setupTour
   * @param tourId
   * @param model
   * @returns {EmberHopscotch.Object.Tour}
   * @public
   */
  setupManualTour(tourId, mSteps, model) {
    let steps = this._loadManualTour(tourId, mSteps,  model);
    let owner = this.owner;

    let tour = Tour.create(
      owner.ownerInjection(), {
        tourId,
        steps,
        model
      });

    return tour;
  },


  /**
   * Load & prepare a tour.
   *
   * @method _loadTour
   * @param {string} tourId The unique id of this tour
   * @param {tourData} the steps object that is normally in a tour file
   * @param {*} model An optional model that is passed to the steps condition functions
   * @return {Object[]}
   * @private
   */
  _loadManualTour(tourId, tourData) {
    tourData = tourData ? tourData : [];

    let tourArray = [];
    let tourInstance;
    switch (getTypeOf(tourData)) {
      case 'array':
        tourArray = tourData;
        break;
      case 'function':
        tourArray = tourData.class(tourId);
        break;
      case 'class':
        tourInstance = tourData.create({ tourId });
        tourArray = get(tourInstance, 'tour') || [];
    }

    tourData = array(tourArray);

    return tourData.map((step) => {
      return {
        condition: get(step, 'condition'),
        target: get(step, 'target'),
        placement: get(step, 'placement') || 'top',
        title: this._t(get(step, 'title')),
        content: this._t(get(step, 'content'))
      };
    });
  },
  _configureHopscotch() {
    this.hopscotch.configure({
      bubbleWidth: '400',
    });
    this._super(...arguments);
  }


});
