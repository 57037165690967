import { helper } from '@ember/component/helper';
import moment from 'moment';
import { htmlSafe } from '@ember/string';

const format = 'HH:mm A';

export function subscriptionsCalculateSchedulePillHtml(params /*, hash*/) {
  const [day, scheduleCount, color, start, end] = params;
  return calculateSchedulePillHtml(day, scheduleCount, color, start, end);
}

export function calculateSchedulePillHtml(
  day,
  scheduleCount,
  color,
  start,
  end
) {
  const startTime = toMoment(start);
  const endTime = toMoment(end);

  if (isStartAfterEnd(startTime, endTime)) {
    const styleStart = createSchedulePillStyle(
      getDayOffset(day),
      scheduleCount,
      color,
      startTime,
      getEndOfDay(startTime)
    );

    const styleEnd = createSchedulePillStyle(
      (getDayOffset(day) + 1) % 7,
      scheduleCount,
      color,
      getStartOfDay(endTime),
      endTime
    );

    return htmlSafe(
      createSchedulePillHtmlContent(styleStart) +
        createSchedulePillHtmlContent(styleEnd)
    );
  }

  const style = createSchedulePillStyle(
    getDayOffset(day),
    scheduleCount,
    color,
    startTime,
    endTime
  );

  return htmlSafe(createSchedulePillHtmlContent(style));
}

const createSchedulePillStyle = (
  dayOffset,
  scheduleCount,
  color,
  startTime,
  endTime
) => `
  height: calc(${getDuration(startTime, endTime)} / 24 * 100%);
  top: calc(${getHourValue(startTime)} / 24 * 100%);
  left: calc(${dayOffset} / 7 * 100% + 6px * ${scheduleCount});
  background-color: ${color};
`;

export const createSchedulePillHtmlContent = style =>
  `<div class="schedule-pill" style="${style}"></div>`;

export const toMoment = time => moment(time, format);

const isStartAfterEnd = (start, end) => start.isAfter(end);

const getEndOfDay = time => time.clone().endOf('day');

const getStartOfDay = time => time.clone().startOf('day');

const getHourValue = time => time.hours() + time.minutes() / 60;

const getDuration = (startTime, endTime) =>
  endTime.diff(startTime, 'minutes') / 60;

const dayValue = {
  MO: 0,
  TU: 1,
  WE: 2,
  TH: 3,
  FR: 4,
  SA: 5,
  SU: 6
};

const getDayOffset = day => dayValue[day];

export default helper(subscriptionsCalculateSchedulePillHtml);
