import DS from 'ember-data';
import EventItemContent from './event-item-content'
import { computed } from '@ember/object';

export default EventItemContent.extend({
  event_item_error_type: DS.belongsTo('subscription/event-item-error-type', { inverse: null, async: true }),
  message: DS.attr(),
  details: DS.attr(),
  channel: DS.belongsTo('channel', { inverse: null, async: true }),
  ptzPreset: DS.belongsTo('ptz-preset', { inverse: null, async: true }),

  /* These 'peek' functions are copied from the image.js file, so that the event-item-error looks
  * enough like a regular Image for the reach-snapshot code to display it.
  * Theoretically it already has the required methods to do this, but we still need changes at the VHI.
  * */
  peekChannel: computed('channel', 'channelId', function() {
    if (this.channel.get('id')) {
      return this.channel;
    }
    if (!this.channelId) {
      return null;
    }
    return this.store.peekRecord('channel', this.channelId)
  }),
});
