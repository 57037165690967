import Component from '@ember/component';
import Ember from 'ember';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  currentUser: service(),
  classNames: ['report-header', 'col-12'],

  actions: {
    generatePDF() {
      this.generatePDF();
    },
    deleteReport() {
      if (Ember.testing ||
        window.confirm(this.intl.t('report.delete-confirmation', {'report-name': this.get('reportData.name')}))) {
        this.deleteReport();
      }
    }
  }
});
