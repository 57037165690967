import PaperButton from 'ember-paper/components/paper-button';
import Ember from 'ember';

export default PaperButton.extend({
  tagName: 'button',
  classNames: 'reach-link-btn',
  attributeBindings: ['type'],
  type: 'button',
  noink: false,
  disabled: false,

  init() {
    this._super(...arguments)
    if (Ember.testing) {
      this.set('noink', true)
    }
  },
});
