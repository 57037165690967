import {BaseSubscriptionSerializer} from '../../mixins/subscription/serializer';

export default BaseSubscriptionSerializer.extend({

  serializeAttribute(snapshot, json, key, attribute) {

    // NOTE: this is here for inspection.schedule {start, end} to prevent the end date serialized as "Invalid date"
    const value = snapshot.attr(key);

    if (value !== null && value !== undefined &&
      !(attribute.type === 'schedule-time' && attribute.name === 'end' && value === 'Invalid date')) {
      this._super(...arguments);
    }
  }
});
