import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import NextSiteButtonMixin from '../../mixins/next-site-button-route';

export default Route.extend(BreadcrumbRoute, NextSiteButtonMixin, {
  titleToken: function(model) {
    return model.get('name');
  },
  intl: service(),
  siteManager: service(),

  model: function(_, transition) {
    return this.siteManager.loadSite(transition.params.site.site_id);
  },

  setupController() {
    this.set('breadcrumbs', [
      {
        name: this.intl.t('navbar.sites'),
        link: 'sites'
      },
      {
        name: this.controllerFor('site').get('model.name')
      }
    ]);

    this.set(
      'navTitleText',
      this.intl.t('navbar.site.index', { siteName: this.siteManager.site.name })
    );
    this._super(...arguments);
  }
});
