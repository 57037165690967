import DS from 'ember-data';

export default DS.Model.extend({
  site: DS.belongsTo('site'),
  name: DS.attr(),
  //type
  simIccid: DS.attr(),
  serviceProvider: DS.attr(),
  phoneNumber: DS.attr(),
  ipAddress: DS.attr(),
  webApiPort: DS.attr(),
  deployState: DS.attr(),
  disabled: DS.attr(),
  jasperSimId: DS.attr(),
  powerControllers: DS.attr(),
  //channels: DS.hasMany('channel'),
  //cloudbridges: DS.hasMany('cloudbridge')
});
