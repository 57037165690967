import Route from '@ember/routing/route';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import Ember from 'ember';
import { later, cancel } from '@ember/runloop';


export default Route.extend(BreadcrumbRoute, {
  titleToken: function (model) {
    return model.get('name') + ' | ' + this.modelFor('site').name;
  },
  intl: service(),
  rootBreadcrumbName: '',
  breadcrumbs: null,

  model(params) {
    const siteParams = this.paramsFor('site');
    return this.store.query('datalogger', {
      page: 1,
      site: siteParams.site_id,
      page_size: 400
    })
      .then(result => {
        let dl = result.find(item => {
          return (item.get('id') === params.datalogger_id);
        });
        if (dl.isV2Temperature) {
          this.store.query('dataloggerdata', {
            page_number: 1,
            page_size: 50,
            sort: '-timestamp',
            dl_id: dl.id
          })
        }
        return dl;
      });
  },

  deactivate() {
    cancel(this.runToken);
    this.set('breadcrumbs', []);
    this.controllerFor('application').set('navTitle', '');
  },

  setupController(controller, model) {
    this.set('breadcrumbs', [
      {
        name: this.intl.t('navbar.sites'),
        link: 'sites'
      },
      {
        name: this.modelFor('site').get('name'),
        link: 'site.dataloggers',
        data: this.modelFor('site')
      },
      {
        name: model.get('name')
      }
    ]);
    controller.set('model', model);
    this.set('dataloggerId', model.id);
    this._super(...arguments);
    controller.reloadData();
    this.set('navTitleText', this.intl.t('navbar.datalogger',
      {siteId: this.modelFor('site').get('id'),
        siteName: this.modelFor('site').get('name'),
        loggerName: model.get('name')}));
    this.controllerFor('application').set('navTitle', this.navTitleText);
    this.scheduleDataUpdate();
  },


  scheduleDataUpdate() {
    //you can't do continuous runloops with ember testing!
    if (Ember.testing) {
      return;
    }
    let timeout = this.controllerFor('datalogger').get('model.pollingFrequency');
    if (!timeout) {
      timeout = 60000;
    } else {
      //convert seconds to ms.
      timeout = timeout * 1000;
    }
    this.set('runToken', later(this, function() {
      this.backgroundUpdateSite().then(() => {
        this.scheduleDataUpdate();
      })
    }, timeout));

  },

  backgroundUpdateSite() {
    const dlId = this.controllerFor('site/datalogger').get('model.id');
    const siteId = this.controllerFor('site').get('model.id');
    if (this.controllerFor('site').get('model.isV2Temperature')) {
      this.store.query('dataloggerdata', {
        page_number: 1,
        page_size: 500,
        sort: 'timestamp',
        dl_id: dlId
      });
      return true;
    } else {
      return this.store.query('datalogger', {
        page: 1,
        site: siteId,
        page_size: 1,
        reload: true
      })
        .then((result) => {
          if (result) {
            let dataloggerId = this.controllerFor('site/datalogger').get('model.id');
            let logger = result.find(item => {
              return (item.get('id') === dataloggerId);
            });

            if (logger) {
              this.controllerFor('site/datalogger').set('model', logger);
              this.controllerFor('site/datalogger').reloadData();
            }
          }

        });
    }

  },
  actions: {
    willTransition() {
      if (this.runToken) {
        cancel(this.runToken);
      }
      return this._super(...arguments);
    },
  }
});
