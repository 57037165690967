import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['cv-features-select'],
  channel: null,
  filter: null,
  allowEdit: false,
  onSelectedChanged: function(values) {
    if (this.allowEdit) {
      this.filter.set('tags', values);
    }
  },

  selected: computed('filter', 'filter.tags.[]', function() {
    if (!this.filter || !this.filter.tags) {
      return [];
    }
    return this.filter.tags;
  }),

  actions: {
    customSearch(tag, term) {
      return tag.indexOf(term);
    },
    selectedChanged(values) {
      this.onSelectedChanged(values)
    }
  }
});
