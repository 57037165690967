import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  user: DS.belongsTo('basic-user'),
  subscription: DS.belongsTo('subscription/subscription'),
  allowEdit: DS.attr(),
  created: DS.attr(),
  modified: DS.attr(),
  notificationMethods: DS.hasMany('subscription/notification-method'),
  notificationMethodEnabled(type) {
    let found = this.notificationMethods.find(item => {
      if (item.get('notificationMethodType.id') === type.id) {
        return item;
      }
    });
    if (found) {
      return found.enabled;
    }
    return false;
  },
  typeEnabled: computed('notificationMethods.{[],@each.enabled}', function() {
    const finalEnabled = this.notificationMethods.reduce(
      (enabled, currentMethod) => {
        enabled[currentMethod.get('notificationMethodType.id')] =
          currentMethod.enabled;
        return enabled;
      },
      {}
    );
    return finalEnabled;
  }),
  creator: DS.attr()
});
