import Component from '@ember/component';
import Ember from 'ember';

export default Component.extend({
  classNames: ['reach-checkbox'],
  classNameBindings: ['whiteOutline:white-outline:','isIndeterminate:is-indeterminate'],
  isIndeterminate: false,
  disabled: false,
  whiteOutline: false,
  checked: false,
  noink: false,
  init() {
    this._super(...arguments)
    if (Ember.testing) {
      this.set('noink', true)
    }
  },
  actions: {
    onChange() {
      this.onChange()
    }
  }
});
