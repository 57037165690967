import DS from 'ember-data';
import { underscore } from '@ember/string';

export default DS.JSONAPISerializer.extend({
  keyForAttribute(key) {
    if (key === 'last_reading') {
      return 'last_reading';
    }
    return key;
  },
  keyForRelationship(key) {
    if (key === 'data_logger') {
      return 'data_logger';
    }
    return underscore(key);
  },
});
