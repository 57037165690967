import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  featureFlags: service(),
  store: service(),
  intl: service(),
  errors: null,
  init() {
    this._super(...arguments);
    this.errors = this.errors || [];
  },

  /**
   * NOTE: When serializers are refactored to JSON API we should
   * handle server errors properly here and make sure we display a nice message to the user.
   * JIRA: https://ospreyinformatics.atlassian.net/browse/REACH-10407
   * Related Front-End task: https://ospreyinformatics.atlassian.net/browse/REACH-10681
   * @returns {{resolve: *, values: null, title: *}}
   */
  makeUnknownError() {
    return {
      title: this.intl.t('errors.unknown-error-title'),
      values: null,
      resolve: this.intl.t('errors.unknown-error-resolve')
    };
  },
  actions: {
    deleteSelectedInspections: async function() {
      /**
       * NOTE: what is selected and what is deleted may not be the same.
       * What if you try to delete something and it fails?
       */
      let deletedInspections = [];
      const selected = this.model.filterBy('selected', true);

      /**
       * NOTE: in this section of code we have an await or promise inside of a loop.
       * This requires special handling and the simplest structure of
       * code to read is a straight-forward indexed loop (we cannot use .forEach)
       */
      for (let i = 0; i < selected.length; i++) {
        const inspection = selected.objectAt(i);
        try {
          await inspection.destroyRecord({adapterOptions: {queryParams: {context: 'inspection'}}});
          deletedInspections.push(inspection);
        } catch (err) {
          // restore the status of the item we failed to delete, so that the user can try to delete it again later.
          inspection.rollbackAttributes();

          // if there are several errors, show them all together at once in a single dialog.
          this.get('errors')
            .pushObject(this.makeUnknownError());
        }
      }

      // only remove inspections that were actually deleted (no failure)
      this.model.removeObjects(deletedInspections);
    },
    createInspection: function() {
      this.transitionToRoute('inspection.create');
    },
    clearErrors() {
      this.set('errors', []);
    },
  }
});
