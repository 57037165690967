import DS from 'ember-data';
import {singularize} from 'ember-inflector';

export default DS.JSONAPISerializer.extend({
  modelNameFromPayloadKey(key) {
    if (key === 'Site') {
      return 'access-site';
    }
    return singularize(key)
  },
});
