import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  action_object() {return { displayName: 'Nakusp',
    id: 6,
    objectType: 'site'}},
  actor() {return {displayName: 'ember-test', id: 9, objectType: 'user'}},
  data() {return {duration: 0}},
  timestamp() {
    return faker.date.past();
  },
  verb: 'unmuted'
});
