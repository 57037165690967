import Component from '@ember/component';

export default Component.extend({
  classNames: ['inspections-page_no-content-container'],
  actions: {
    createInspection: function() {
      this.createInspection();
    }
  }
});
