import Component from '@ember/component';
import { observer } from '@ember/object';

export default Component.extend({
  visible: false,
  selectedDuration: 2,
  availableDurations: null,
  classNames: ['mute-event-alerts'],
  init() {
    this._super(...arguments);
    let durations = [];
    for (let i = 1; i <= 48; i++) {
      durations.push(i);
    }
    this.set('availableDurations', durations);
  },
  didRender() {
    this.$('.modal').on('hide.bs.modal', () => {
      this.set('visible', false);
    })
  },
  watchVisible: observer('visible', function() {
    if (this.visible) {
      this.$('.modal').modal('show');
    }
  }),
  actions: {
    processDuration() {
      this.setDuration(this.selectedDuration);
    },
    durationUpdated(duration) {
      this.set('selectedDuration', duration);
    }

  }
});
