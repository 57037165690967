import DS from 'ember-data';
import {singularize} from 'ember-inflector';
import {underscore} from '@ember/string';

export default DS.JSONAPISerializer.extend({
  modelNameFromPayloadKey(key) {
    return singularize(key)
  },
  keyForAttribute(attr) {
    return underscore(attr);
  },
});
