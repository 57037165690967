import ApplicationAdapter from './application';
const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export default ApplicationAdapter.extend({
  headers: JSON_HEADERS,
  namespace: 'api',
  buildURL(modelName, id, snapshot, requestType) {
    if (requestType !== 'queryRecord') {
      return this._super(...arguments);
    }

    let path = 'api/users/whoami';
    let url = [];
    let host = this.host;
    url.push(host);
    url.push(path);
    url = url.join('/');
    if (!host && url && url.charAt(0) !== '/') {
      url = '/' + url;
    }

    return url + '/';
  },
  handleResponse(status, type, detail, request) {
    if (400 === status) {
      //malformed detail message in user VHIendpoint
      return this._super(status, type, {
        errors: [
          {
            detail: detail.message
          }
        ]
      }, request);
    }
    return this._super(status, type, detail, request);
  },

});
