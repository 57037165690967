import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import RSVP from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    can: service(),
    model() {
      //if the user does not have permissions to edit user permission, send them away
      if (this.can.cannot('siteAccess user', this.currentUser)) {
        this.transitionTo('index');
        return;
      }

      return RSVP.hash({
        users: this.store.query('site-user', ('site-user',
        {
          page: 1,
          page_size: 1000,
        })
        ),
        sites: this.store.query('site', {
          page: 1,
          page_size: 500
        })
      });
    }
  });
