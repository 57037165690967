import ApplicationAdapter from './application';
import CacheBuster from '../mixins/cachebuster-adapter';

const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export default ApplicationAdapter.extend(CacheBuster, {
  query(store, type, query) {
    if (query.site) {
      const url = `${this.host}/api/dataloggers/?site=${query.site}&page_size=${query.page_size}&page=${query.page}`;
      const options = {headers: JSON_HEADERS};
      return this.ajax(url, 'GET', options);
    }

    return this._super(...arguments);

  }
});
