import { helper } from '@ember/component/helper';

const slugify = str => str.replace(/\s+/g, '-')
  .toLowerCase();

export function translate([intl, prefix, str]/*, hash*/) {
  return intl.t(`${prefix}.${slugify(str)}`);
}

export default helper(translate);
