import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  ALERT_SUBSCRIPTION_ENABLED
} from '../../../services/analytics';
import constants from '../../../utils/ui-constants';

const { UI_DATE_FORMAT } = constants;

export default Component.extend({
  classNames: ['manage-list-item'],
  classNameBindings: ['isSelected:selected', 'isDisabled:disabled'],
  analytics: service(),
  subscription: null,
  listDateFormat: UI_DATE_FORMAT,
  isSelected: computed('subscription.selected', function() {
    if (this.subscription) {
      return this.subscription.selected;
    }
    return false;
  }),
  isDisabled: computed('subscription', function() {
    return !this.subscription.currentUserCanEdit;
  }),
  actions: {
    toggleSubscriptionSelected() {
      this.subscription.toggleProperty('selected');
    },
    toggleEnabled() {
      this.subscription.toggleProperty('enabled');
      this.subscription.save({adapterOptions: {queryParams: {context: 'alert'}}});
      this.analytics.trackMultiple(ALERT_SUBSCRIPTION_ENABLED, {
        subscriptionId: this.subscription.id,
        enabled: this.subscription.enabled
      });
    }
  }
});
