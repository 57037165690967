import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { makeFinder } from '../../utils/inspection-filter-finder';

export default Component.extend({
  store: service(),
  classNames: ['inspections-board-item_main-container'],
  classNameBindings: ['selected:selected-item', 'enableSort:show-grab-cursor'],
  attributeBindings: ['disabled:disabled'],
  enableSort: true,
  disabled: false,
  selected: computed('filter', 'selectedFilters.[]', function() {
    return this.selectedFilters
      ? this.selectedFilters.find(makeFinder(this.filter))
      : false;
  }),
  latestImage: computed('filter.{channel.latestImage,ptzPreset.latestImage}', function() {
    // NOTE: the VHI serializer can return {data: null} for a relationship. This creates an empty object which we can check for, by checking the id
    if (this.filter.get('ptzPreset.id')) {
      return this.filter.get('ptzPreset.latestImage')
    }
    return this.filter.get('channel.latestImage');
  }),
  actions: {
    toggleFilter: function() {
      this.toggleFilter(this.filter);
    }
  }
});
