import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import REPORT_PDF_REQUESTED from '../../services/analytics';

export default Controller.extend({
  analytics: service(),
  report: service(),
  intl: service(),
  pdfSuccess: null,
  pdfFailure: null,
  cleanup() {
    this.set('pdfSuccess', null);
    this.set('pdfFailure', null);
  },
  actions: {
    generatePDF() {
      this.analytics.trackMultiple(REPORT_PDF_REQUESTED, {
        reportId: this.get('reportData.id'),
      });
      this.set('pdfSuccess', null);
      this.set('pdfFailure', null);
      this.report.generateReportPdf(this.get('model.id'))
        .then(() => {
          this.set('pdfSuccess', this.intl.t('report.pdf-success'));
        })
        .catch(() => {
          this.set('pdfFailure', this.intl.t('report.pdf-failure'));
        })

    },
    deleteReport() {
      this.model.destroyRecord()
        .then(()=>{
          this.model.unloadRecord();
          this.transitionToRoute('reports');
        })
        .catch(() => {
          this.set('errors.delete', true);
        });

    }
  }
});
