import DS from 'ember-data';

export default DS.Model.extend({
  name: DS.attr(),
  timezone: DS.attr(),
  lsd: DS.attr(),
  centroid: DS.attr(),
  latestImage: DS.belongsTo('image'),
  locationPolygons: DS.attr(),
  siteType: DS.hasMany('site-type'),
  channels: DS.attr(),
  cloudbridges: DS.attr(),
  dataloggers: DS.attr(),
  mute: DS.attr(),
  maintenanceMode: DS.attr(),
  duration: DS.attr()

});
