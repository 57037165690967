import Controller from '@ember/controller';
import {inject as service} from '@ember/service';
import { sort } from '@ember/object/computed';


export default Controller.extend({
  store: service(),
  isSaving: false,
  loadAllSites() {
    return this.store.query('site', {
      page: 1,
      page_size: 1000
    });
  },
  usersSorting: Object.freeze(['stale', 'lastName', 'firstName']),
  sortedUsers: sort('model', 'usersSorting'),
  actions: {
    generateCSV() {
      let csv = 'data:text/csv;charset=utf-8,';
      let rows = [['username', 'email', 'first name', 'last name', 'is staff', 'last login']]
      this.model.forEach(user => {
        if (!user.isStaff) {
          rows.push([user.username, user.email, user.firstName, user.lastName, user.isStaff, user.lastLoginString]);
        }
      });
      csv = csv + rows.map(e => e.join(',')).join('\n');
      const encodedUri = encodeURI(csv);
      window.open(encodedUri);
    }
  }
});
