import { computed } from '@ember/object';
import Component from '@ember/component';
import { htmlSafe } from '@ember/template'

export default Component.extend({
  classNames: ['federated-signin-button'],
  iconUrl: null,

  iconStyle: computed('iconUrl', function() {
    return htmlSafe('background-image: url(' + this.iconUrl + ')')
  }),

  init() {
    this._super(...arguments);
  }
});
