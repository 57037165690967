import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';

// NOTE: the table content can fit 11 rows on the display with appropriate margin-bottom
// However, due to using infinite scroll we need 11 + 1 rows (aka 12 rows loaded) so that we\
// do not immediately trigger another fetch to the backend after the page is loaded.
const PAGE_SIZE = 12;

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    titleToken: t('alerts.manage'),
    analytics: service(),
    intl: service(),
    currentUser: service(),
    infinity: service(),
    subscription: service(),

    queryParams: {
      page: {
        refreshModel: true
      },
      page_size: {
        refreshModel: true
      },
      ordering: {
        refreshModel: true
      },
      reverse: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    model() {
      return this.subscription.ready.then(() => {
        return this.infinity.model('subscription/subscription', {
          perPage: PAGE_SIZE,
          perPageParam: 'page_size',
          pageParam: 'page_number',
          totalPagesParam: 'meta.pagination.pages',
          include: 'subscribers',
          context: 'alert'
        });
      });
    },

    setupController() {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.alerts'),
          link: 'alerts'
        },
        {
          name: this.intl.t('navbar.subscriptions')
        }
      ]);
      this.set('navTitleText', this.intl.t('navbar.' + this.navTitle));

      this._super(...arguments);
    }
  }
);
