import { inject as service } from '@ember/service';
import FreestyleController from 'ember-freestyle/controllers/freestyle';



export default FreestyleController.extend({
  emberFreestyle: service(),
  intl: service(),
  title: '',
  subtitle: '',

  init() {
    this._super(...arguments);
    this.set('title', this.get('intl').t('documentation.title'));
    this.set('subtitle', this.get('intl').t('documentation.subtitle'));
  }

});
