import Route from '@ember/routing/route';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import NextSiteButtonMixin from '../../mixins/next-site-button-route';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { computed } from '@ember/object';
import { t } from 'ember-intl';
import { isArray } from '@ember/array';
import VHIQueryParms from '../../mixins/vhi-query-params';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';

export default Route.extend(
  BreadcrumbRoute,
  NextSiteButtonMixin,
  VHIQueryParms,
  NavBarTitleMixin,
  {
    titleToken: t('review-stream.breadcrumb-title'),
    intl: service(),
    rootBreadcrumbName: '',
    breadcrumbs: null,
    infinity: service(),
    siteManager: service(),

    queryParams: {
      channel: {
        refreshModel: true,
        type: 'array'
      },
      to: {
        refreshModel: true
      },
      from: {
        refreshModel: true
      },
      tag: {
        refreshModel: true,
        type: 'array'
      },
      reason: {
        refreshModel: true,
        type: 'array'
      },
      media: {
        refreshModel: true
      },
      pageSize: {
        refreshModel: true
      },
      modalImage: {
        replace: true,
        type: 'string'
      }
    },

    model: function(params) {
      let channels = params.channel;
      if (channels === null || channels.length === 0) {
        params.channel = [-1];
      } else if (!isArray(channels)) {
        channels = channels.replace(new RegExp('"|\\\\', 'g'), '');
        params.channel = JSON.parse(channels);
        //this is failing if there is only a single channel
        if (!isArray(params.channel)) {
          params.channel = [channels];
        }
      }

      if (!params.tag || params.tag.length === 0) {
        params.tag = null;
      } else if (!isArray(params.tag)) {
        params.tag = JSON.parse(params.tag);
      }
      if (!params.reason || params.reason.length === 0) {
        params.reason = null;
      } else if (!isArray(params.reason)) {
        params.reason = JSON.parse(params.reason);
      }
      let currentModel = this.controllerFor('site.review').get('model');
      if (!isEmpty(currentModel)) {
        currentModel.set('reachedInfinity', false);
        this.infinity.flush(currentModel);
      }

      if (
        params.media === 'video' &&
        !(params.tag && params.tag.length > 0) &&
        !params.reason
      ) {
        return this.infinity.model('video', {
          startingPage: 1,
          channel: params.channel,
          to: params.to,
          from: params.from,
          perPage: params.pageSize,
          countParam: 'meta.count',
          pageParam: 'page',
          perPageParam: 'page_size'
        });
      } else {
        return this.infinity.model('media', {
          startingPage: 1,
          channel: params.channel,
          to: params.to,
          from: params.from,
          tag: params.tag,
          reason: params.reason,
          perPage: params.pageSize,
          countParam: 'meta.count',
          pageParam: 'page',
          perPageParam: 'page_size',
          media: params.media
        });
      }
    },

    setupController(controller) {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.sites'),
          link: 'sites'
        },
        {
          name: this.siteManager.site.name,
          link: 'site',
          data: this.siteManager.site
        },
        {
          name: this.intl.t('review-stream.breadcrumb-title')
        }
      ]);
      this.set(
        'navTitleText',
        this.intl.t('navbar.site.index', {
          siteName: this.modelFor('site').get('name')
        })
      );
      this._super(...arguments);

      controller.initChannels();
    },
    watchSiteChange: computed('siteManager.site', function() {
      this.reload();
    }),

    deactivate() {
      this._super(...arguments);
      this.controllerFor('site.review').setProperties({
        page: 1,
        channel: null,
        to: null,
        from: null,
        tag: null,
        reason: null
      });
      this.controllerFor('site.review').cleanup();
    }
  }
);
