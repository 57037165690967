import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import { htmlSafe } from '@ember/string';
import Hammer from 'hammerjs';
import moment from 'moment';
import $ from 'jquery';
import LiveVideoMixin from '../mixins/live-video-mixin';
import ImageZoomCardMixin from '../mixins/image-zoom-card';
import PeekChannelSitePropertiesWorkaroundMixin from '../mixins/site-channel-image-properties-workaround-mixin';
import {
  VIDEO_STREAM_REQUESTED,
  VIDEO_STREAM_STARTED,
  VIDEO_STREAM_STOPPED
} from '../services/analytics';
import { createHammer, restrictZoom } from '../utils/touch';
import { isMobile } from '../utils/responsive';

export default Component.extend(LiveVideoMixin, ImageZoomCardMixin, PeekChannelSitePropertiesWorkaroundMixin, {
  analytics: service(),
  store: service(),

  intl: service(),
  report: service(),
  ptz: service(),
  featureFlags: service(),
  showAdminLink: false,
  error: '',
  message: '',
  refreshing: false,
  scrollLeft: 0,
  scrollTop: 0,
  lastZoomPercentage: ImageZoomCardMixin.defaultZoom,
  livePtzEdit: false,
  lastPtzInteraction: Date.now(),

  init() {
    this._super(...arguments);
    // to call an inherited action from inside script (rather than invoked from template), we need to bind it first
    this.zoomReset = this.actions.zoomReset.bind(this);
  },
  totalImageCount: computed('allImages.[]', function() {
    return this.get('allImages.length') - 1;
  }),
  isVideo: observer('image', function() {
    let selectedImage = this.image;
    return selectedImage && selectedImage.constructor.modelName === 'video';
  }),

  watchImage: observer('image', function() {
    let selectedImage = this.image;
    if (selectedImage && selectedImage.constructor.modelName === 'video') {
      this.set('loadingVideo', false);
      this.set('streamingPlaylist', [selectedImage]);
      this.set('streamingLive', false);
    }
  }),

  showNavLeft: computed('allImages.[]', 'image', 'liveVideo', 'streaming', function() {
    if (this.liveVideo && this.streaming) {
      return false;
    }
    if (!this.allImages || this.get('allImages.length') <= 0) {
      return false;
    }
    if (this.get('image.id') === this.allImages.objectAt(0).get('id')) {
      return false;
    }
    return true;
  }),

  showNavRight: computed('allImages.[]', 'image', 'liveVideo', 'streaming', function() {
    if (this.liveVideo && this.streaming) {
      return false;
    }
    if (!this.allImages || this.get('allImages.length') <= 0) {
      return false;
    }
    if (
      this.get('image.id') ===
      this.allImages.objectAt(this.totalImageCount).get('id')
    ) {
      return false;
    }
    return true;
  }),

  cleanup(saveImage) {
    if (!saveImage) {
      this.selectImage(null);
    }
    this.set('loadingVideo', false);
    this.set('streaming', false);
    this.set('zoomedIn', false);
    this.set('streamingPlaylist', null);
    this.set('streamingUrl', null);
    this.set('error', null);
    this.set('warning', null);
    this.set('reportSuccess', null);
    this.set('reportFailed', null);
    this.set('message', null);
    this.zoomReset();
    this.dragReset();
    this.pinchReset();
  },

  dragStart() {
    // if user has started to drag, record current scroll positions
    this.set('lastScrollLeft', this.scrollLeft);
    this.set('lastScrollTop', this.scrollTop);
  },

  drag(ev) {
    this.set('scrollLeft', this.restrictLeft(ev.deltaX + this.lastScrollLeft));
    this.set('scrollTop', this.restrictTop(ev.deltaY + this.lastScrollTop));
  },

  pinchStart() {
    this.set('lastZoomPercentage', this.get('zoomPercentage'));
  },

  pinch(ev) {
    const newZoom = restrictZoom(Math.ceil(this.get('lastZoomPercentage') * ev.scale));
    this.updateZoom(newZoom)
  },

  pinchEnd(ev) {
    const newZoom = restrictZoom(Math.ceil(this.get('lastZoomPercentage') * ev.scale));
    this.updateZoom(newZoom)
  },

  dragReset() {
    this.set('scrollLeft', 0);
    this.set('scrollTop', 0);
  },

  pinchReset() {
    this.set('lastZoomPercentage', ImageZoomCardMixin.defaultZoom);
  },
  ptzInteraction() {
    if (!(this.isDestroyed || this.isDestroying)) {
      this.set('lastPtzInteraction', Date.now());
    }
  },

  actions: {
    didInsert() {
      this.set('livePtzEdit', false);
      const modalBody = this.element.querySelector('.modal-body');
      const containerManager = createHammer(modalBody);

      const pan = new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0, enable: () => !this.get('notZoomed') });
      const pinch = new Hammer.Pinch();

      pan.recognizeWith(pinch);

      containerManager.add(pan);
      containerManager.add(pinch);

      containerManager
        .on('panstart', run.bind(this, this.dragStart))
        .on('panmove', run.bind(this, this.drag))
        .on('pinchstart', run.bind(this, this.pinchStart))
        .on('pinch', run.bind(this, this.pinch))
        .on('pinchend', run.bind(this, this.pinchEnd));

      this.hammer = containerManager;

      this.$().on('hidden.bs.modal', () => {
        this.cleanup();
      });
    },
    willDestroy() {
      this.hammer.destroy();
    },
    changePosition(newPtzPoz) {
      this.set('refreshing', true);
      this.set('error', null);
      this.ptz
        .movePtz(newPtzPoz)
        .then(result => {
          this.set('image', result);
          this.set('refreshing', false);
        })
        .catch(() => {
          this.set('refreshing', false);
          this.set('error', this.intl.t('site.details.select-ptz-failed'));
        });
    },
    download() {
      function downloadImage(image) {
        image.download().catch(() => {
          if (this.isVideo) {
            this.set('error', this.intl.t('video.error-loading'));
          } else {
            this.set('error', this.intl.t('errors.image-download'));
          }
        });
      }
      if (this.image.download) {
        downloadImage(this.image);
      } else {
        this.image.then(data => {
          downloadImage(data);
        });
      }
    },
    navLeft() {
      this.cleanup(true);
      const currentImage = this.get('image.id');
      this.allImages.find((item, index) => {
        if (item.get('id') === currentImage) {
          if (index > 0) {
            this.set('image', this.allImages.objectAt(index - 1));
          }
          return true;
        }
      });
    },
    navRight() {
      this.cleanup(true);
      const currentImage = this.get('image.id');
      this.allImages.find((item, index) => {
        if (item.get('id') === currentImage) {
          if (index < this.totalImageCount) {
            this.set('image', this.allImages.objectAt(index + 1));
          }
          return true;
        }
      });
    },
    close() {
      this.cleanup();
    },

    normalResolution() {
      this.set('zoomedIn', false);
    },
    fullResolution() {
      this.zoomReset();

      // By keeping the zoom component height sync'd to the normal view, the dialog will not shift when switching modes.
      const limitHeight = this.element.querySelector('.reach-snapshot-modal_normal-image-container').clientHeight;
      this.set('zoomContainerStyle', htmlSafe(`height: ${limitHeight}px;`));

      this.set('zoomedIn', true);
    },
    toggleLivePtz() {
      this.toggleProperty('livePtzEdit');
      if (!this.streaming) {
        this.playLiveVideo();
      }

      if (this.livePtzEdit) {

        this.set('message', this.intl.t('video.ptz-edit-instructions'));
      }


    },

    stopVideo() {
      this.analytics.trackMultiple(VIDEO_STREAM_STOPPED, {
        channelId: this.get('image.channel.id'),
        channelSlug: this.get('image.channel.slug'),
        channelName: this.get('channel.name'),
        siteName: this.get('image.site.name'),
        siteId: this.get('image.site.id'),
        livestream: false
      });
      this.set('stopVideo', true);
    },
    playVideo() {
      this.analytics.trackMultiple(VIDEO_STREAM_STARTED, {
        channelId: this.get('image.channel.id'),
        channelSlug: this.get('image.channel.slug'),
        channelName: this.get('channel.name'),
        siteName: this.get('image.site.name'),
        siteId: this.get('image.site.id'),
        livestream: false
      });
      this.set('stopVideo', false);
    },
    loadVideo() {
      this.set('warning', false);
      this.set('error', false);
      if (this.liveVideo) {
        this.playLiveVideo();
      } else {
        this.analytics.trackMultiple(VIDEO_STREAM_REQUESTED, {
          channelId: this.get('image.channel.id'),
          channelSlug: this.get('image.channel.slug'),
          channelName: this.get('channel.name'),
          siteName: this.get('image.site.name'),
          siteId: this.get('image.site.id'),
          livestream: false
        });
        this.set('loadingVideo', true);
        this.set('error', null);

        this.store
          .query('video', {
            cloudbridge: true,
            id: this.get('image.channel.cloudbridge'),
            channel: this.get('image.channel.slug'),
            multiple: true,
            save: true,
            start: this.get('image.isoTimestamp'),
            end: moment(this.get('image.timestamp'))
              .add(3, 'minutes')
              .toISOString()
          })

          .then(result => {
            let offsetTime = moment(result.firstObject.start).diff(
              this.image.momenttimestamp,
              'seconds'
            );
            offsetTime = Math.abs(offsetTime);
            this.set('offsetTime', offsetTime);
            this.set('streamingPlaylist', result.slice(0, 9));
            this.set('loadingVideo', false);
            this.set('streamingLive', false);
          })
          .catch(e => {
            this.set('loadingVideo', false);
            if (e.status === 404) {
              this.set('error', this.intl.t('video.dne'));
            } else {
              this.set('error', this.intl.t('video.error-loading'));
            }
          });
      }
    },
    streamingStopped(warning) {
      this.set('livePtzEdit', false);
      this.set('warning', warning);
      this.set('streaming', false);
      this.set('streamingUrl', '');
      this.$('.alert-stream-stopped').alert();
    },
    addImageToReport(image) {
      this.set('reportSuccess', false);
      this.set('reportFailed', false);
      this.report
        .addImageToReport(image)
        .then(() => {
          this.set('reportSuccess', this.intl.t('report.image-added'));
        })
        .catch(() => {
          this.set('reportFailed', this.intl.t('report.image-add-failed'));
        });
    },
    detectIsMobile() {
      // the reach-snapshot-dialog doesn't look nice in mobile mode. For now, just disable the dialog.
      if (isMobile(true)) {
        $('.reach-snapshot-modal')
          .modal('hide');
      }
    },
  }
});
