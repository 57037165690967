import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'li',
  currentState: null,
  itemState: null,
  classNames: ['filter-state-list-item'],
  classNameBindings: ['isActive:active-filter:'],
  isActive: computed('currentState', 'itemState', function() {
    return this.currentState == this.itemState;
  }),
  attributeBindings: ['role'],
  role: 'button',
  actions: {
    setState(state) {
      this.setState(state)
    }
  },
  click() {
    this.setState(this.itemState);
  },
});
