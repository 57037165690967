import Component from '@ember/component';
import { computed } from '@ember/object';
/**
 * http://google.github.io/material-design-icons/
 **/
export default Component.extend({
  tagName: 'span',
  layer: '',
  prefix: 'fas',
  size: 'md',
  classNames: ['reach-icon'],
  classNameBindings: ['layers:fa-layers', 'layers:fa-fw'],
  layers: computed('layer', function() {
    if (this.layer) {
      return true;
    }
    return false;
  })

});
