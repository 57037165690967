import Component from '@ember/component';

export default Component.extend({
  classNames: ['no-sites-content-card'],
  isAddSitesDisabled: false,
  actions: {
    addSites() {
      this.onAddSitesClick();
    }
  }
});
