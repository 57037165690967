import DS from 'ember-data';
import EmberObject from '@ember/object';
import {Copyable} from 'ember-copy';
/**
 * NOTE: The logic for the inspection-board is somewhat complex
 * as it operates in 2 different modes (for inspection-entitlements
 * on customer-settings page, and inspection-filters on the
 * inspection create & edit pages).
 *
 * Also the inspection-board can display normal sortable items and
 * 'frozen' channels which appear when the user opens the channel-picker.
 *
 * In the case where the channel-picker is opened, the 'frozen' copies
 * will also be copied by the inspection-board.
 *
 * The inspection-board must copy the filters passed into it because
 * internally it mutates the list during a sort.
 *
 * However the list of filters is a computed property which is
 * read-only and not compatible with drag-drop mutations.
 *
 * By applying sort changes to a copy and then applying the changes
 * made on the clone back to the original the inspection-board can
 * get around the problem of drag-drop sorting not working.
 */
const CopyableChannel = EmberObject.extend(Copyable, {
  copy() {
    return EmberObject.create({
      ptzPreset: this.ptzPreset,
      channel: this.channel,
      inspection: this.inspection,
      created: this.created,
      modified: this.modified,
      order: this.order,
      enabled: this.enabled,
      timeout: this.timeout,
      site: this.get('channel.site'),
    });
  }
});

export default DS.Model.extend(Copyable, {
  subscription: DS.belongsTo('subscription/subscription', { inverse: 'subscriptionFilters' }),
  ptzPreset: DS.belongsTo('ptz-preset'),
  channel: DS.belongsTo('channel'),
  created: DS.attr(),
  timestamp: DS.attr(),
  modified: DS.attr(),
  enabled: DS.attr('boolean', { defaultValue: true }),
  copy() {
    return CopyableChannel.create({
      ptzPreset: this.ptzPreset,
      channel: this.channel,
      inspection: this.inspection,
      created: this.created,
      modified: this.modified,
      order: this.order,
      enabled: this.enabled,
      timeout: this.timeout,
      site: this.get('channel.site'),
    })
  }
});
