import Component from '@ember/component';

export default Component.extend({
  actions: {
    login() {
      this.login();
      return false;
    },
    resetPassword() {
      this.resetPassword();
      return false;
    }
  }
});
