define("reach-web-client/components/uic-check-box", ["exports", "ember-ui-components/components/uic-check-box"], function (_exports, _uicCheckBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _uicCheckBox.default;
    }
  });
});
