import ApplicationAdapter from '../application';
import CacheBuster from '../../mixins/cachebuster-adapter';

const JSON_HEADERS = {
  'Accept': 'application/vnd.api+json, application/json',
  'Content-Type': 'application/vnd.api+json'
};

export default ApplicationAdapter.extend(CacheBuster, {
  headers: JSON_HEADERS,
  pathForType() {
    return 'v1/subscriptions/inspection_filters'
  },
});
