import DS from 'ember-data';
import MediaModel from './media';
import { computed } from '@ember/object';
import moment from 'moment'

const SECONDS = 'seconds';
const VIDEO_HEADERS = {
  'Cache-Control': 'no-cache',
  'Accept': '*/*'
};
const logger = console;

export default MediaModel.extend({
  end: DS.attr(),
  hlsUrl: DS.attr(),
  rtmpUrl: DS.attr(),
  thumbnail: DS.attr(),
  video: DS.attr(),
  hlsStream: DS.attr(),
  rtmpStream: DS.attr(),
  start: DS.attr(),
  mediaType: 'video/mp4',
  userFriendlyDownloadName: computed('isoTimestamp', 'channel.name', function() {
    let cameraName = this.get('channel.name');
    cameraName = cameraName ? cameraName : '';
    cameraName = cameraName.toLowerCase()
      .replace(new RegExp(' ', 'g'), '_');
    const timestamp = this.isoTimestamp;
    let fileName = `${cameraName}_${timestamp}.mp4`;
    return fileName.replace(new RegExp(':', 'g'),'_');

  }),
  thumbnailBlob: computed('thumbnail', function() {
    const url = this.get('thumbnail');
    if (!url) {
      return null;
    }
    return fetch(url)
      .then(response => {
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read()
                .then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
            }
          }
        })
      })
      .then(stream =>
        new Response(stream)
      )
      .then(response =>
        response.blob()
      )
      .then(blob => URL.createObjectURL(blob))
      .catch(reason =>
        logger.log(reason)
      )

  }),
  downloadUrl: computed('video', function() {
    return this.video;
  }),
  download() {
    // Set headers to the request
    let headers = VIDEO_HEADERS;
    const url = this.downloadUrl;
    return fetch(url,
      {
        method: 'GET',
        cache: 'no-cache',
        headers,
        responseType: 'arraybuffer'
      })
      .then((data) => {
        if (!data.ok) {
          throw ('Error downloading');
        }

        // Create the blob for and set the type depending on the media type

        //ie
        if (data._bodyBlob) {
          this.saveBlob(data._bodyBlob);
          return;
        }
        if (data.body.constructor.name === 'ReadableStream') {
          this.readAllChunks(data.body)
            .then(chunks => {
              const blob = new Blob(chunks, { type: this.mediaType });
              this.saveBlob(blob);
            })
            .catch(() => {throw ('Error downloading')});
        }
      })
  },
  offsetTime: computed('start', 'momenttimestamp', function() {
    let offsetTime = this.momenttimestamp.diff(moment(this.start), SECONDS);
    return offsetTime;
  })


});
