import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { MAINTENANCE_MODE_TOGGLED, MUTE_TOGGLED } from '../../services/analytics';
import { computed, observer } from '@ember/object';

import moment from 'moment';
import $ from 'jquery';
import { isMobile } from '../../utils/responsive';
import UserPermCheck from '../../mixins/user-permission-check';

export default Controller.extend(UserPermCheck, {
  analytics: service(),
  siteManager: service(),
  ptz: service(),
  intl: service(),
  maintenanceAction: '',
  muteAction: '',
  currentTimezone: moment.tz.guess(),
  init() {
    this._super(...arguments);
    this.updateMaintenanceAction();
    this.updateMuteAction();
  },

  allPreviews: computed('siteManager.channels.[]', function() {
    let previews = [];
    if (this.siteManager.channels) {
      this.siteManager.channels.forEach(channel => {
        previews.push(channel.get('latestImage'));
      });
    }
    return previews;
  }),

  muteUntilTimestamp: computed('muteAction.timestamp', function() {
    let timestamp = moment(this.get('muteAction.timestamp'));
    timestamp = timestamp.add(this.get('muteAction.dataDuration'), 's');
    return timestamp.tz(this.currentTimezone)
      .format('ll LTS z');
  }),
  watchMaintenanceMode: observer(
    'siteManager.site.maintenanceMode',
    function() {
      if (!this.get('siteManager.site.hasDirtyAttributes')) {
        this.updateMaintenanceAction();
      }
    }
  ),
  watchMuteChannel: observer('siteManager.site.mute', function() {
    if (!this.get('siteManager.site.hasDirtyAttributes')) {
      this.updateMuteAction();
    }
  }),

  updateMaintenanceAction() {
    if (this.get('siteManager.site.maintenanceMode')) {
      this.store
        .query('action', {
          siteId: this.get('siteManager.site.id'),
          verb: 'enabled maintenance mode'
        })
        .then(result => {
          this.set('maintenanceAction', result.objectAt(0));
        });
    }
  },
  updateMuteAction() {
    if (this.get('siteManager.site.mute')) {
      this.store
        .query('action', {
          siteId: this.get('siteManager.site.id'),
          verb: 'muted'
        })
        .then(result => {
          this.set('muteAction', result.objectAt(0));
        });
    }
  },
  nextSite(nextSite) {
    if (nextSite) {
      this.transitionToRoute('site', nextSite);
    }
  },

  actions: {
    toggleMaintenanceMode() {
      this.toggleProperty('siteManager.site.maintenanceMode');
      const analyticsAction = this.get('siteManager.site.maintenanceMode')
        ? 'Turned On'
        : 'Turned Off';
      this.analytics.trackMultiple(MAINTENANCE_MODE_TOGGLED, {
        siteToggle: true,
        channelToggle: false,
        siteName: this.get('siteManager.site.name'),
        siteId: this.get('siteManager.site.id'),
        action: analyticsAction
      });
      this.siteManager.site.save()
        .then(() => {
          this.send('routeReloadData');
          this.updateMaintenanceAction();
        });
    },
    muteAlerts() {
      this.set('showEventModal', true);
    },
    updateMuteStatus(duration) {
      this.toggleProperty('siteManager.site.mute');
      this.set('siteManager.site.duration', duration * 3600);
      this.set('showEventModal', false);

      const analyticsAction = this.get('siteManager.site.mute')
        ? 'Turned On'
        : 'Turned Off';

      this.analytics.trackMultiple(MUTE_TOGGLED, {
        siteToggle: true,
        channelToggle: false,
        siteName: this.get('siteManager.site.name'),
        siteId: this.get('siteManager.site.id'),
        action: analyticsAction
      });
      this.siteManager.site
        .save()
        .then(() => {
          this.send('routeReloadData');
          this.updateMuteAction();
        })
        .catch(() => {
          this.set('error', this.intl.t('site.details.mute-failed'));
        });
    },
    reloadData() {
      this.send('routeReloadData');
    },
    showRepositionCameraModal(channel) {
      this.set('showPTZModal', true);
      this.set('selectedChannelPTZ', channel.ptzPresets);
    },
    selectPTZ(view) {
      view.set('channel.refreshing', true);
      view.set('channel.error', null);
      this.ptz
        .movePtz(view)
        .then(() => {
          view.set('channel.refreshing', false);
        })
        .catch(() => {
          view.set('channel.refreshing', false);
          view.set(
            'channel.error',
            this.intl.t('site.details.select-ptz-failed')
          );
        });
    },
    openImageInModal(image) {
      if (isMobile(true)) {
        return;
      }
      this.set('selectedImage', image);
      if (this.selectedImage) {
        this.set('selectedChannel', image.get('channel'));
        $('.reach-snapshot-modal').modal('show');
        return;
      }
    }
  }
});
