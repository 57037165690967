import DS from 'ember-data';
import { computed } from '@ember/object';

export default DS.Model.extend({
  name: DS.attr(),
  lat: DS.attr(),
  long: DS.attr(),
  imageUrl: DS.attr(),
  
  centroid: computed('lat', function () {
    return {
      lat: this.lat,
      lng: this.long,
    };
  }),
});
