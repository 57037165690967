import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import PerfectScrollController from 'ember-perfect-scroll/mixins/perfect-scroll-controller';
import groupBy from 'ember-group-by';

export default Component.extend(PerfectScrollController, {
  intl: service(),
  classNames: ['site-picker_channel-picker'],
  channels: null,
  filtersOnSite: null,
  ptzAsOneFilter: false,
  siteFiltersByChannel: groupBy('filtersOnSite', 'channel.id'),
  selectedChannelLength: computed('siteFiltersByChannel', function() {
    return this.siteFiltersByChannel.length;
  }),
  isAllSelected: computed('siteFiltersByChannel.[]', 'channels.[]', function() {
    if (!this.channels || !this.selectedChannelLength === 0) {
      return false;
    }

    return this.selectedChannelLength === this.channels.length;
  }),
  selectAllText: computed(
    'siteFiltersByChannel.[]',
    'channels.[]',
    'isAllSelected',
    function() {
      if (!this.channels) {
        return '';
      }
      if (this.selectedChannelLength === 0) {
        return `All (${this.channels.length})`;
      }
      if (this.isAllSelected) {
        return 'Select all';
      }
      return `(${this.selectedChannelLength}/${this.channels.length})`;
    }
  ),
  paginationText: computed('channels.[]', function() {
    if (!this.channels || !this.channels.query || !this.channels.meta) {
      return '';
    }

    const totalCount = this.channels.meta.count;

    return `${totalCount} ${this.intl.t('subscriptions.cameras')}`;
  }),
  selectAllChannels(shouldSelectAll) {
    this.channels && this.channels.forEach(channel =>
      this.selectChannel(channel, shouldSelectAll)
    );
  },
  init() {
    this._super(...arguments);
    this.filtersOnSite = this.filtersOnSite || [];
    this.channels = this.channels || [];
  },
  didRender() {
    this.updatePerfectScroll();
  },
  actions: {
    selectChannel(channel, shouldAddChannelToSelection) {
      this.selectChannel(channel, shouldAddChannelToSelection);
    },
    selectPtz(channel, ptz, shouldAddChannelToSelection) {
      if (this.ptzAsOneFilter) {
        this.selectChannel(channel, shouldAddChannelToSelection);
      } else {
        this.selectPtz(channel, ptz, shouldAddChannelToSelection);
      }
    },
    toggleSelectAllChannels() {
      this.selectAllChannels(this.filtersOnSite.length === 0);
    },
    getNextChannelsPage() {
      if (this.channelsHasNextPage) {
        this.loadNewChannelsPage(true);
      }
    },
    getPreviousChannelsPage() {
      if (this.channelsHasPrevPage) {
        this.loadNewChannelsPage(false);
      }
    }
  }
});
