import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  intl: service(),
  classNames: ['action-row-alert-item-toggle_container'],
  totalUsers: 0,
  toggledCounts: null,
  labelText: computed('toggledCounts.@each', function() {
    if (!this.toggledCounts) {
      return '';
    }
    if (this.toggledCounts[this.notificationType.id] === 0) {
      return this.intl.t('user-permissions-card.select-all', {
        count: this.totalUsers
      });
    } else {
      return this.intl.t('user-permissions-card.select-some', {
        count: this.toggledCounts[this.notificationType.id]
      });
    }
  }),
  actions: {
    toggleAllNotificationMethod(id) {
      this.toggleAllNotificationMethod(id);
    }
  }
});
