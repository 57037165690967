import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import {t} from 'ember-intl';
import RSVP from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin,
  TenantRouteMixin, {
    infinity: service(),
    tenant: service(),
    subscription: service(),
    titleToken: t('navbar.dashboard'),
    activate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideBreadcrumb', true);
    },

    deactivate() {
      this._super(...arguments);
      this.controllerFor('application')
        .set('hideBreadcrumb', false);
    },
    beforeModel() {
      this.subscription.ready;
      return this._super(...arguments);

    },

    model() {
      return RSVP.hash({
        sites: this.store.query('site', {
          page: 1,
          page_size: 1000
        }),
        channels: this.store.query('dashboard-channel', {
          page: 1,
          page_size: 1000
        }),
        cloudbridges: this.store.query('cloudbridge', {
          page: 1,
          page_size: 1000
        }),
        alerts: this.store.query('subscription/event', {
          'filter[subscription.type]': this.subscription.alertType.id,
          page: 1,
          page_size: 100,
          dashboard: true
        })
      })
    },
  });
