import Mixin from '@ember/object/mixin';
import moment from 'moment';
import { inject as service } from '@ember/service';
const format = 'hh:mm:ss a';

export default Mixin.create({
  intl: service(),

  makeNameError() {
    return {
      title: this.intl.t('subscription-errors.no-name-title'),
      values: null,
      resolve: this.intl.t('subscription-errors.no-name-resolve')
    };
  },
  makeNoScheduleError() {
    return {
      title: this.intl.t('subscription-errors.no-schedule-title'),
      values: null,
      resolve: this.intl.t('subscription-errors.no-schedule-resolve')
    };
  },
  makeNoFilterError() {
    return {
      title: this.intl.t('subscription-errors.no-filter-title'),
      values: null,
      resolve: this.intl.t('subscription-errors.no-filter-title')
    };
  },
  makeUnknownErrorOnSave() {
    return {
      title: this.intl.t('errors.unknown-error-title'),
      values: null,
      resolve: this.intl.t('errors.unknown-error-resolve')
    };
  },
  makeMidnightCrossoverError() {
    return {
      title: this.intl.t(
        'subscription-errors.schedule-midnight-crossover-title'
      ),
      values: null,
      resolve: this.intl.t(
        'subscription-errors.schedule-midnight-crossover-resolve'
      )
    };
  },
  getErrorsOnSubscription(subscription) {
    const errors = [];
    const doesSubscriptionHaveFilters =
      subscription.activeSubscriptionFilters &&
      subscription.activeSubscriptionFilters.length > 0;
    if (!doesSubscriptionHaveFilters) {
      errors.push(this.makeNoFilterError());
    }

    const doesSubscriptionHaveSchedules =
      subscription.activeSchedules && subscription.activeSchedules.length > 0;
    if (!doesSubscriptionHaveSchedules) {
      errors.push(this.makeNoScheduleError());
    }

    const doesSubscriptionHaveAName =
      typeof subscription.name === 'string' && subscription.name !== '';
    if (!doesSubscriptionHaveAName) {
      errors.push(this.makeNameError());
    }

    const doAnySchedulesCrossMidnight = doesSubscriptionHaveSchedules
      ? subscription.activeSchedules.some(schedule => {
        const endTime = moment(schedule.end, format);
        const startTime = moment(schedule.start, format);
        return moment(endTime).isBefore(moment(startTime));
      })
      : false;

    if (doAnySchedulesCrossMidnight) {
      errors.push(this.makeMidnightCrossoverError());
    }
    return errors;
  },
  serverErrorToClientError(error) {
    if (!error) {
      return this.makeUnknownErrorOnSave();
    }
    // todo make the server give codes so we can go code -> make client error function
    if (error.code === '') {
      //this will actually match to something
      return this.makeUnknownErrorOnSave();
    } else {
      return this.makeUnknownErrorOnSave();
    }
  },
  translateServerErrorsToClient(response) {
    const errors = [];
    if (!response || !response.errors || !Array.isArray(response.errors)) {
      errors.push(this.makeUnknownErrorOnSave());
      return errors;
    }
    response.errors.forEach(error => {
      const newError = this.serverErrorToClientError(error);
      errors.push(newError);
    });
    return errors;
  }
});
