import Route from '@ember/routing/route';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';
import * as jose from 'jose'
import ENV from '../config/environment';


export default Route.extend(UnauthenticatedRouteMixin, {
  async beforeModel() {
    var url = window.location.href;
    const stateIndex = url.indexOf('state');
    let endIndex = url.indexOf('&', stateIndex);
    if (endIndex < 0) {
      endIndex = url.length;
    }
    let state = '';
    /***
     * for some providers (Okta and Crusoe) it doesn't retain the state so we need
     * to override that here, there must be a better way to do this, but the username
     * contains the provider in it so we can use that to determine the Okta tenant.
     */
    if (stateIndex < 0) {
      let access_token_index = url.indexOf('access_token')
      endIndex = url.indexOf('&', access_token_index);
      if (endIndex < 0) {
        endIndex = url.length;
      }
      let jwt = url.substring(access_token_index + 13, endIndex);
      const publicKeyUrl = `https://cognito-idp.us-west-2.amazonaws.com/${ENV.COGNITO_POOL_ID}/.well-known/jwks.json`;
      const JWKS = await jose.createRemoteJWKSet(new URL(publicKeyUrl));
      const { payload } = await jose.jwtVerify(jwt, JWKS, {
        issuer: `https://cognito-idp.us-west-2.amazonaws.com/${ENV.COGNITO_POOL_ID}`
      })
      if (payload.username.indexOf('osprey-okta') >= 0) {
        state = 'richard';
        url += `&state=${state}`;
      } else if (payload.username.indexOf('crusoe') >= 0) {
        state = 'crusoe';
        url += `&state=${state}`;
      }
    } else {
      state = url.substring(stateIndex + 6, endIndex);
    }
    if (state) {
      let newLocation = url.replace('login', state);
      // the redirect url has to specify the tenant, in the provider/app config in the url
      // the replace below ensures users are sent to the correct page
      // this is because we believe cognito doesn't support redirect to *.ospreyreach.com/stuff
      newLocation = newLocation.replace('cognito-redirect', 'torii/redirect.html');
      window.location.replace(newLocation);
    }
  }
});
