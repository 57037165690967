import { association, Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  eventItemErrorType: association('event-item-error-type'),
  message() {
    return faker.lorem.sentence();
  },
  details() {
    return faker.lorem.sentence();
  },
  timestamp() {
    return faker.date.past();
  },
  channel: association('channel'),
  ptzPreset: association('ptz-preset'),
});
