import Component from '@ember/component';
import { inject as service } from '@ember/service';
import {
  USER_GUIDE_OPENED
} from '../services/analytics';

const STATES_SHOW_SITE_SEARCH = 'show-site-search';
const STATES_SHOW_TITLE = 'show-title';

export default Component.extend({
  tenant: service(),
  featureFlags: service(),
  currentUser: service(),
  report: service(),
  subscription: service(),
  analytics: service(),
  classNames: ['reach-navbar'],
  state: STATES_SHOW_TITLE,
  actions: {
    navigateToSite(site) {
      this.navigateToSite(site);
      this.set('state', STATES_SHOW_TITLE);
    },
    showSearchBar() {
      if (this.state !== STATES_SHOW_SITE_SEARCH) {
        this.set('state', STATES_SHOW_SITE_SEARCH);
      } else {
        this.set('state', STATES_SHOW_TITLE);
      }
    },
    hideNavbar() {
      this.$('.collapse').collapse('hide');
    },
    openUserGuide() {
      this.analytics.trackMultiple(USER_GUIDE_OPENED, {
        'currentPage': window.location.pathname,
      });
      window.open('https://ospreyinformatics.atlassian.net/wiki/spaces/PUBLIC/pages/83034141/Osprey+Reach+User+Guide');
    }
  }

});
