define("reach-web-client/helpers/point", ["exports", "ember-leaflet/helpers/point"], function (_exports, _point) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _point.default;
    }
  });
  Object.defineProperty(_exports, "point", {
    enumerable: true,
    get: function get() {
      return _point.point;
    }
  });
});
