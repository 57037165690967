import Service from '@ember/service';
import { REPORT_IMAGE_ADDED } from '../services/analytics';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Ember from 'ember';
import fetch from 'fetch';
import ENV from '../config/environment';

const REPORT_PDF_URL = '/api/legacy/v3/reports/:id/generate-pdf/';
const USER_REPORT = 'user_report';
let JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export default Service.extend({
  currentUser: service(),
  analytics: service(),
  store: service(),
  session: service(),
  storage: service(),
  tenant: service(),
  intl: service(),
  currentReport: null,
  init() {
    this._super();
    if (!Ember.testing) {
      this.store.findAll('report-type').then(result => {
        result = result.filter(report => report.name === USER_REPORT);
        this.set('reportType', result.objectAt(0));
      });
      this.store.findAll('report-segment-type').then(result => {
        this.set('reportSegmentType', result.objectAt(0));
      });

    }
  },

  isReportInProgress: computed('currentUser.vhiUser',
    'currentReport',
    'currentReport.segments[]', function() {
      let data = this.get('storage.' + this.get('currentUser.reportLocalStorageId'));
      return !!data;

    }),

  clearLocalReport() {
    this.storage.clear();
  },

  addImagesToReport(images) {
    let store = this.store;
    return new Promise((resolve) => {
      this.analytics.trackMultiple(REPORT_IMAGE_ADDED, {
        channelId: images.get('firstObject.channelId'),
        siteId: images.get('firstObject.siteId'),
      });
      this.loadReportInProgress()
        .then(report => {

          let segments = [];
          images.forEach(image => {
            let segment = store.createRecord('report-segment',
              {
                site: image.get('peekSite'),
                channel: image.get('peekChannel'),
                images: [image.get('fullImageUrl')],
                ptzLabel: image.get('ptzPreset.label'),
                startTime: image.get('timestamp'),
                silent: true,
                segmentType: this.reportSegmentType
              });
            segment.save({adapterOptions: {localStorage: true}})
              .then(() => {
                report.get('segments').addObject(segment);
                report.save({adapterOptions: {localStorage: true}}).then(()=> {resolve(report);});
                this.set('currentReport', report);
              });
            segments.push(segment);
          });
          report.get('segments').addObjects(segments);
          report.save({adapterOptions: {localStorage: true}})
            .then(()=> {resolve(report);});
          this.set('currentReport', report);
        });
    });
  },

  addImageToReport(image) {
    let store = this.store;
    return new Promise((resolve, reject) => {
      this.analytics.trackMultiple(REPORT_IMAGE_ADDED, {
        channelId: image.get('channelId'),
        siteId: image.get('siteId'),
      });
      this.loadReportInProgress()
        .then(report => {
          let found = false;
          report.get('segments').forEach(segment => {
            if (segment.get('images') && segment.get('images')[0] === image.get('fullImageUrl')) {
              found = true;
            }
          });
          if (found) {
            reject(this.intl.t('report.image-add-failed'));
            return;
          }
          let segment = store.createRecord('report-segment',
            {
              site: image.get('peekSite'),
              channel: image.get('peekChannel'),
              images: [image.get('fullImageUrl')],
              ptzLabel: image.get('ptzPreset.label'),
              startTime: image.get('timestamp'),
              silent: true,
              segmentType: this.reportSegmentType
            });
          segment.save({adapterOptions: {localStorage: true}})
            .then(() => {
              report.get('segments').addObject(segment);
              report.save({adapterOptions: {localStorage: true}}).then(()=> {resolve(report);});
              this.set('currentReport', report);
            });
        });
    });
  },

  createEmptyReport() {
    let result = this.store.createRecord('report' , {
      creator: this.get('currentUser.vhiUser.id'),
      reportType: this.reportType,
      segments: []

    });
    return result.save({adapterOptions: {localStorage: true}});
  },

  loadReportInProgress() {
    return new Promise((resolve) => {
      this.store.queryRecord('report', {localStorage: true})
        .then(result => {
          resolve(result);
        })
        .catch(() => {
          this.createEmptyReport().then(report => {
            resolve(report);
          });
        });
    });
  },

  generateReportPdf(reportId) {
    let subdomain = this.get('tenant.subdomain');
    const host = ENV.vhiProtocol + '://' + subdomain + '.' + ENV.vhiServer;
    let url = `${host}` + REPORT_PDF_URL.replace(':id', reportId);
    let { access_token } = this.get('session.data.authenticated');
    JSON_HEADERS['Authorization'] =  `Bearer ${access_token}`;
    return fetch(url, {
      method: 'POST',
      headers: JSON_HEADERS,
      body: 'id=' + reportId
    })
      .then(result => {
        if (!result.ok) {
          throw (result.status);
        }
        return true;
      });
  }
});
