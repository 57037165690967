define("reach-web-client/components/tt-year-option", ["exports", "ember-time-tools/components/tt-year-option"], function (_exports, _ttYearOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _ttYearOption.default;
    }
  });
});
