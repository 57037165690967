import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include: function() {
    return [
      'subscription-subscriber',
      'subscription-event-state-type',
      'user'
    ];
  }
});
