import { Factory, association, faker } from 'ember-cli-mirage';

export default Factory.extend({
  lastPolled: faker.date.past(),
  lastSuccessfulPoll: faker.date.past(),
  name: faker.random.word(),
  slug: faker.random.word(),
  type: faker.company.bsNoun(),
  channel: association('dataloggerTest'),
  inSync: true,
  enabled: true
});
