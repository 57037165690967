import Component from '@ember/component';

export default Component.extend({
  actions: {
    clickAllFilter() {
      this.onClickAll();
    },
    clickMotionFilter() {
      this.onClickMotion();
    },
    clickUserRequestedFilter() {
      this.onClickUserRequested();
    },
    clickTimeLapseFilter() {
      this.onClickTimeLapse();
    },
    clickInspectionFilter() {
      this.onClickInspection();
    },
    clickVideoFilter() {
      this.onClickVideo();
    }
  }
});
