import { Factory, faker, association, trait } from 'ember-cli-mirage';

export default Factory.extend({
  coordinates() {
    return [faker.address.lattitude, faker.address.longitude]
  },
  description() {
    return faker.lorem.sentence();
  },
  fullImageUrl() {
    return '/assets/images/osprey-icon.png?id=' + faker.random.uuid();
  },
  originalName() {
    return faker.internet.domainWord();
  },
  ptzPreset: association('ptz-preset'),
  reason () {
    return 'motion';
  },
  resizableImageUrl() {
    return '/assets/images/osprey-icon.png';
  },
  size() {
    return faker.random.number();
  },
  //source: DS.attr(),
  //tagSet: DS.attr(),
  timestamp() {
    return faker.date.past();
  },
  user() {
    return faker.random.number();
  },

  channelTest: trait({
    reason: 'activity',
    timestamp: faker.date.past(),
  })
});
