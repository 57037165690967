import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { debounce } from '@ember/runloop';

export default Component.extend({
  store: service(),
  notificationTypes: null,
  classNames: ['user-permissions-card'],
  allowEdit: false,
  users: null,
  allUsers: null,
  userService: null,
  userSearchText: null,
  selectedUser: null,
  toggledCounts: null,
  isInspection: false,
  availableUsers: computed('allUsers', 'users', function() {
    if (!this.get('allUsers')) {
      return [];
    }
    if (!this.get('users')) {
      return this.get('allUsers');
    }
    // return all-users not already subscribed
    return this.get('allUsers')
      .filter(user => !this.get('users')
        .find(subscriber => subscriber.get('user.id') === user.get('id')));
  }),
  // uses observer instead of computed property because we can only watch one level deep (users.@each) and
  // have no way of knowing when a specific user has toggled a notification on or off
  updateToggleCounts: observer(
    'users.@each.notificationMethods',
    'notificationTypes.[]',
    function() {
      this.calculateToggledCounts();
    }
  ),
  calculateToggledCounts: function() {
    const finalCount = this.users.reduce((count, currentSub) => {
      this.notificationTypes.forEach(type => {
        const prevCount = count[type.id] || 0;
        count[type.id] = currentSub.notificationMethodEnabled(type)
          ? prevCount + 1
          : prevCount;
      });
      return count;
    }, {});
    this.set('toggledCounts', finalCount);
    return finalCount;
  },
  toggleNotificationMethod: function(user, type, shouldToggleOn) {
    this.toggleNotificationMethod(user, type, shouldToggleOn)
  },
  init() {
    this._super(...arguments);
    this.notificationTypes = this.notificationTypes || [];
    this.users = this.users || [];
    this.allUsers = this.allUsers || [];
    this.calculateToggledCounts();
  },
  onSearchUser: function() {
    if (this.userService) {
      return this.userService.loadAllUsers(this.userSearchText);
    }
  },

  actions: {
    toggleAllNotificationMethod(methodType) {
      if (!this.users) {
        return;
      }
      const isAnyToggled = this.toggledCounts[methodType.id] > 0;
      this.users.map(user => {
        this.toggleNotificationMethod(user, methodType, !isAnyToggled);
      });
      this.calculateToggledCounts();
    },
    toggleNotificationMethod(user, type, shouldToggleOn) {
      this.toggleNotificationMethod(user, type, shouldToggleOn);
      this.calculateToggledCounts();
    },
    highlightFirstMatch(param) {
      if (param.results.length > 0) {
        return (param.highlighted = param.results[0]);
      }
    },
    removeUserFromList(user) {
      this.removeUserFromList(user);
    },
    addUserToList(user) {
      this.set('selectedUser', null);
      this.set('userSearchText', '');
      this.addUserToList(user);
    },

    searchUser() {
      return new Promise(() => debounce(this, this.onSearchUser, 500));
    },

    searchTextChange(email) {
      this.set('userSearchText', email)

      if (!email) {
        return new Promise(() => debounce(this, this.onSearchUser, 500));
      }
    },

  }
});
