import { BaseSubscriptionSerializer, SaveRelationshipsMixin } from '../../mixins/subscription/serializer';
import Ember from 'ember';

export default BaseSubscriptionSerializer.extend(SaveRelationshipsMixin, {
  saveRelationships: false,
  attrs: {
    subscribers: { serialize: true },
    schedules: { serialize: true },
    type: { serialize: true },
    subscriptionFilters: { serialize: true },
  },

  /**
   * NOTE: this function must have a custom override to apply a patch to the ember-data-save-relationship Mixin.
   * The override is to address the 3rd party code NOT calling the modelNameFromPayloadKey() base function
   * Here we call it and ensure that our logic for translating the modelName is executed.
   * Other than the single line changed below, this method is a direct copy paste from the add-on.
   * @param json
   * @param store
   * @returns {*}
   */
  updateRecord(json, store) {
    if (json.attributes !== undefined && json.attributes.__id__ !== undefined)
    {
      // NOTE: Before we lookup the type in the store, we must convert the payload json.type to a model name we expect
      json.type = this.modelNameFromPayloadKey(json.type);

      const record = store.peekAll(json.type)
        .filterBy('currentState.stateName', 'root.loaded.created.uncommitted')
        .findBy('_internalModel.' + Ember.GUID_KEY, json.attributes.__id__);

      if (record) {
        // record.unloadRecord();
        record.set('id', json.id);
        record._internalModel.id = json.id;
        record._internalModel.adapterWillCommit();
        store.didSaveRecord(record._internalModel);
        // store.push({ data: json });
      }
    }
    return json;
  }
});
