import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import moment from 'moment';
import { USER_PROPERTY_EDIT_SAVED } from '../services/analytics';
import { later } from '@ember/runloop';
import fade from 'ember-animated/transitions/fade';

export default Controller.extend({
  fade: fade,
  currentUser: service(),
  analytics: service(),
  timezones: moment.tz.names(),
  error: false,
  saved: false,
  clearMessages() {
    this.set('saved', '');
    this.set('error', '');
    this.set('error', '');
  },
  actions: {
    save() {
      const isFormDirty =
        this.currentUser.vhiUser.hasDirtyAttributes ||
        this.model.dailyReport.hasDirtyAttributes;
      if (!isFormDirty) {
        return;
      }
      this.analytics.trackMultiple(USER_PROPERTY_EDIT_SAVED, {
        userId: this.currentUser.vhiUser.id
      });
      this.set('saved', false);
      this.set('error', false);
      this.currentUser.vhiUser
        .save()
        .then(() => {
          this.model.dailyReport
            .save({ adapterOptions: { userId: this.currentUser.vhiUser.id } })
            .then(() => {
              this.set('saved', true);
              this.set(
                'laterRunId',
                later(
                  this,
                  function() {
                    this.clearMessages();
                  },
                  5000
                )
              );
            })
            .catch(() => {
              this.set('error', true);
              this.set(
                'laterRunId',
                later(
                  this,
                  function() {
                    this.clearMessages();
                  },
                  5000
                )
              );
            });
        })
        .catch(e => {
          this.set('error', true);
          this.set('errorMessage', e.errors[0].detail);
          this.set(
            'laterRunId',
            later(
              this,
              function() {
                this.clearMessages();
              },
              5000
            )
          );
        });
    },
    timezoneChanged(timezone) {
      this.set('model.dailyReport.timezone', timezone);
    }
  }
});
