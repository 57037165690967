import Route from '@ember/routing/route';
import { makeArray } from '@ember/array';
import ENV from '../config/environment';
import { inject as service } from '@ember/service';
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import { LOGIN_SUCCEEDED, LOGOUT } from '../services/analytics';
import $ from 'jquery';

import moment from 'moment';

export default Route.extend(ApplicationRouteMixin, {
  title: function(tokens) {
    tokens = makeArray(tokens);
    tokens.unshift('Osperity');
    return tokens.reverse().join(' | ');
  },
  session: service(),
  tenant: service(),
  currentUser: service(),
  analytics: service(),
  intl: service(),
  moment: service(),
  reachTour: service(),

  init() {
    window.mixpanel.init(ENV.MIXPANEL_KEY);
    this._super(...arguments);
    this.moment.setTimeZone(moment.tz.guess());
  },
  beforeModel() {
    return this.intl.setLocale(['en-us']);
  },

  sessionAuthenticated() {
    this._super(...arguments);
    this.analytics.trackMultiple(LOGIN_SUCCEEDED,
      {clientIp: this.get('analytics.clientIp')}
    );
  },

  sessionInvalidated() {
    this._super(...arguments);
    this.analytics.trackMultiple(LOGOUT,
      {clientIp: this.get('analytics.clientIp')}
    );
  },

  actions: {
    invalidateSession() {
      this.session.invalidate();
    },
    error(error, transition) {
      if (error.status === '403') {
        let previousTransition = transition
        this.session.authenticate('authenticator:cognito', {state: {name: 'refresh'}})
          .then(() => {
            previousTransition.retry()
          })
          .catch(() => {
            this.replaceWith('login');
          });
      } else {
        return true;
      }
    },

    willTransition() {
      $('.reach-snapshot-modal').modal('hide');
      return true;
    }

  }
});
