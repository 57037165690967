import ApplicationSerializer from './application';

export default ApplicationSerializer.extend({
  include: function() {
    return [
      'subscription-subscription',
      'site',
      'subscription-subscriber',
      'subscription-event-subscriber-status'
    ];
  }
});
