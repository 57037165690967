import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import groupBy from 'ember-group-by';

export default Component.extend({
  siteManager: service('base-site-manager'),
  store: service(),
  infinity: service(),

  classNames: ['site-picker-card'],
  channelPageSize: 1000,
  currentChannelPage: 1,
  // NOTE: filters property must be a mutable array.
  // If using a RecordArray, call .toArray() on it first before setting it here.
  // example: store.findAll().then(res){return res.toArray()}
  filters: null,
  channelFilters: null,
  sitesLoading: false,
  sites: null,
  activelySelectedSite: null,
  channels: null,
  showResetDialog: false,
  loadingChannels: false,
  ptzAsOneFilter: false,
  filtersByChannel: groupBy('computedFilters', 'channel.id'),
  filtersBySite: groupBy('computedFilters', 'site.id'),
  filtersByPtz: groupBy('computedFilters', 'ptzPreset.id'),
  // see: https://github.com/ember-cli/eslint-plugin-ember/issues/105 (still open as of Dec. 5, 2019)
  // eslint-disable-next-line ember/use-brace-expansion
  computedFilters: computed('filters.[]', 'filters.@each.isDeleted', function() {
    return this.filters.filterBy('isDeleted', false);
  }),
  pagination: computed('sites.meta.pagination', function() {
    return this.sites.meta.pagination;
  }),
  selectedCount: computed('filtersByChannel.[]', function() {
    return this.filtersByChannel.length;
  }),
  siteChanged: observer('activelySelectedSite', function() {
    if (this.activelySelectedSite) {
      this.loadChannels(this.activelySelectedSite, 1, this.channelPageSize);
      this.set('currentChannelPage', 1);
    } else {
      this.set('channels', null);
    }
  }),
  loadChannels(site, page, pageSize) {
    this.set('loadingChannels', true);
    return this.siteManager.loadChannels(site, page, pageSize, this.channelFilters)
      .then(() => {
        this.set('channels', this.siteManager.channels);
        this.set('loadingChannels', false);
      });
  },
  init() {
    this._super(...arguments);
    this.filters = this.filters || [];
    this.sites = this.sites || [];
    this.set('sitesLoading', true);
    this.infinity
      .model('site', {
        startingPage: 1,
        include: 'latest_image',
        perPage: 10,
        countParam: 'meta.count',
        pageParam: 'page',
        perPageParam: 'page_size'
      })
      .then(result => {
        this.set('sitesLoading', false);
        this.set('sites', result);
      })
      .catch(() => {
        this.set('sitesLoading', false);
      });
  },
  willRender() {
    if (this.sites && !this.activelySelectedSite) {
      this.set('activelySelectedSite', this.sites.objectAt(0));
    }
  },

  createFiltersOnSelection(channel, ptz) {
    const newFilters = [];

    const selectAllPtzOnChannel = channel.ptzEnabled && !ptz;
    if (selectAllPtzOnChannel) {
      channel.ptzPresets.forEach(ptz =>
        newFilters.push(this.createFilters(channel, ptz))
      );
    } else {
      const newFilter = this.createFilters(channel, ptz);
      newFilters.push(newFilter);
    }
    return newFilters;
  },
  isChannelSelected(channel) {
    return this.filtersByChannel.any(
      filter => filter.value === channel.get('id')
    );
  },
  isPtzSelected(ptz) {
    return this.filtersByPtz.any(
      filter => filter.value === ptz.get('id')
    );
  },
  actions: {
    addSitesToList() {
      // NOTE: parameter to addSiteSelection is the original list of filter records.
      // This will ensure any record.deletedRecord() will be saved() and removed from the back-end.
      this.addSiteSelection(this.filters)
        .then(() => {
          // Make sure to wait for saving complete before toggling off.
          this.toggleShowSitePicker();
        });
    },
    resetFilters() {
      this.resetFilters();
    },
    toggleShowResetDialog() {
      this.toggleProperty('showResetDialog');
    },

    loadNewChannelsPage(getNext) {
      const newPageNumber = getNext
        ? this.currentChannelPage + 1
        : this.currentChannelPage - 1;
      this.loadChannels(
        this.activelySelectedSite,
        newPageNumber,
        this.channelPageSize
      );
      this.set('currentChannelPage', newPageNumber);
    },
    selectSite(site) {
      this.set('activelySelectedSite', site);
    },
    selectPtz(channel, ptz, shouldAddPtzToSelection) {
      const isPtzSelected = this.isPtzSelected(ptz);
      const isAddPtzBoolUndef = shouldAddPtzToSelection == null;

      const shouldAddPtz = isAddPtzBoolUndef
        ? !isPtzSelected
        : shouldAddPtzToSelection;

      if (shouldAddPtz) {
        let newFilters = this.createFiltersOnSelection(channel, ptz);
        this.filters.addObjects(newFilters);
      } else {
        const presets = this.filters.filterBy(
          'ptzPreset.id',
          ptz.get('id')
        );
        presets.forEach(preset => {
          if (preset.isNew) {
            preset.rollbackAttributes();
            this.filters.removeObject(preset);
          } else {
            preset.deleteRecord();
          }
        });
      }
    },
    selectChannel(channel, shouldAddChannelToSelection) {
      const isChannelSelected = this.isChannelSelected(channel);
      const isAddChannelBoolUndef = shouldAddChannelToSelection == null;

      const shouldAddChannel = isAddChannelBoolUndef
        ? !isChannelSelected
        : shouldAddChannelToSelection;

      if (shouldAddChannel) {
        let newFilters = [];
        if (this.ptzAsOneFilter) {
          newFilters = [this.createFilters(channel)];
        } else {
          newFilters = this.createFiltersOnSelection(channel);
        }
        this.filters.addObjects(newFilters);
      } else {
        const filters = this.filters.filterBy(
          'channel.id',
          channel.get('id')
        );
        filters.forEach(filter => {
          if (filter.isNew) {
            filter.rollbackAttributes();
            this.filters.removeObject(filter);
          } else {
            filter.deleteRecord();
          }
        });
      }
    }
  }
});
