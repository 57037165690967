import { helper } from '@ember/component/helper';

export function alertsPerDay(params/*, hash*/) {
  const value = params[0];
  if (value < 1) {
    return '<1'
  }
  return Math.round(value);
}

export default helper(alertsPerDay);
