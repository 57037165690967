import Component from '@ember/component';
import moment from 'moment';
import { inject as service } from '@ember/service';

const generateCalendarPickerTimes = () => {
  let start = moment().startOf('day');

  let times = [start.format('h A')];
  while (times.length < 9) {
    start = start.add(3, 'hour');
    times.push(start.format('h A'));
  }
  return times;
};

export default Component.extend({
  intl: service(),
  classNames: ['schedule-picker-calendar_container'],
  weekDays: null,
  hoursLabel: null,
  schedules: null,
  init() {
    this._super(...arguments);
    this.weekDays = [
      { day: 'MO', displayText: this.intl.t('weekday.monday-double') },
      { day: 'TU', displayText: this.intl.t('weekday.tuesday-double') },
      { day: 'WE', displayText: this.intl.t('weekday.wednesday-double') },
      { day: 'TH', displayText: this.intl.t('weekday.thursday-double') },
      { day: 'FR', displayText: this.intl.t('weekday.friday-double') },
      { day: 'SA', displayText: this.intl.t('weekday.saturday-double') },
      { day: 'SU', displayText: this.intl.t('weekday.sunday-double') }
    ];
    this.hoursLabel = generateCalendarPickerTimes();
  }
});
