import { underscore } from '@ember/string';
import { singularize } from 'ember-inflector'
import Mixin from '@ember/object/mixin';
import DS from 'ember-data';
import SaveRelationships from 'ember-data-save-relationships';

const SUB_TYPES = [
  'inspection',
  'subscription',
  'subscriber',
  'schedule',
  'user',
  'notificationmethod',
  'notificationmethodtype',
  'notification-method',
  'notification-method-type',
  'subscriptiontype',
  'subscription-type',
  'eventitemerror',
  'eventitemerrortype',
  'inspectionentitlement',
  'inspection-entitlement',
  'subscriptionfilter',
  'subscription-filter',
  'inspectionfilter',
  'inspection-filter',
  'daily-report',
  'dailyreport',
  'event',
  'event-item-reference',
  'event-item-error',
  'event-item-error-type',
  'querydocfilter',
  'query-doc-filter',
  'eventitemreference',
  'eventsubscriberstatus',
  'event-subscriber-status',
  'eventstatetype',
  'event-state-type',
  'subscriptionwebhooksubscriber',
  'subscription-webhook-subscriber',
];

export const PrefixSerializerModelMixin = Mixin.create({
  prefix: 'subscription',
  divider: '/',
  concatenatedProperties: ['sub_types'],
  sub_types: SUB_TYPES,

  keyForAttribute(attr) {
    return underscore(attr);
  },

  keyForRelationship(key) {
    return underscore(key);
  },

  modelNameFromPayloadKey(key) {
    const prefix = this.prefix + this.divider;
    const tempKey = singularize(key);
    if (this.sub_types.indexOf(tempKey.toLowerCase()) >= 0) {
      key = prefix + tempKey;
    }

    return this._super(key)
  },


  payloadKeyFromModelName(modelName) {
    let prefix = this.prefix + this.divider;
    if (this.sub_types.indexOf(modelName.substring(prefix.length)) >= 0) {
      modelName = modelName.replace(prefix, '');
      modelName = singularize(modelName);
    }
    modelName = modelName.camelize();
    modelName = modelName.capitalize();
    return modelName;
  },

  serialize() {
    // @TODO check if we still need it
    let json = this._super(...arguments);
    let prefix = this.prefix ? this.prefix + this.divider : '';
    json.data.type = singularize(json.data.type.replace(prefix, ''))
      .capitalize();
    return json
  }
});

export const AliasSerializerMixin = Mixin.create({
  aliases: false,
  getAliasesFromSnapshot: function(snapshot) {
    if (!this.aliases) {
      this.aliases = snapshot.aliases ? snapshot.aliases :
        snapshot.adapterOptions ? snapshot.adapterOptions.aliases : false;
    }

    return this.aliases
  },

  serializeRelationship: function serializeRelationship(snapshot) {
    this.getAliasesFromSnapshot(snapshot);
    this._super.apply(this, arguments);
  },

  serializeRecord: function serializeRecord(obj) {
    if (!obj) {
      return null;
    }

    obj.aliases = this.aliases;
    return this._super.apply(this, arguments);
  },

  serialize(snapshot, options) {
    this.getAliasesFromSnapshot(snapshot);
    return this._super(snapshot, options);
  },

  modelNameFromPayloadKey(key) {
    if (this.aliases) {
      let item = this.aliases.find(rec => rec.key === key);

      if (item) {
        key = item.alias
      }
    }

    return this._super(key);
  },

  payloadKeyFromModelName(modelName) {
    modelName = this._super(modelName);
    if (this.aliases) {
      let item = this.aliases.find(rec => rec.alias === modelName);

      if (item) {
        modelName = item.key
      }
    }

    return modelName
  }
});

export const BaseSubscriptionSerializer = DS.JSONAPISerializer.extend(
  PrefixSerializerModelMixin, AliasSerializerMixin, {
    sub_types: SUB_TYPES,
  });

export const SaveRelationshipsMixin = Mixin.create(SaveRelationships, {


  relationshipSavingStrategy: function(snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('saveRelationships')) {
      this.saveRelationships = snapshot.adapterOptions.saveRelationships
    } else if (!this.saveRelationships) {
      this.saveRelationships = !!snapshot.saveRelationships
    }

    return this.saveRelationships
  },

  serializeRelationship: function serializeRelationship(snapshot) {
    this.relationshipSavingStrategy(snapshot) && this._super.apply(this, arguments);
  },

  serializeRecord: function serializeRecord(obj) {
    if (!obj) {
      return null;
    }

    // @TODO it should be in AliasSerializerMixin.serializeRecord
    //  but it doesn't work because of inheritance issue
    obj.aliases = this.aliases;
    obj.saveRelationships = !!this.saveRelationships;
    return this._super.apply(this, arguments);
  },
});

export const CustomRelationshipKeyMixin = Mixin.create({
  relationship_key_aliases: null,
  extractRelationships: function(modelClass, resourceHash) {
    if (resourceHash && resourceHash.relationships) {
      this.assignRelationshipKeyAliases(resourceHash.relationships)
    }
    return this._super(...arguments);
  },

  assignRelationshipKeyAliases: function(relationships) {
    let applyRelationshipAlias = (name, alias) => {
      if (relationships.hasOwnProperty(name)) {
        relationships[alias] = relationships[name];
        delete relationships[name]
      }
    };

    let aliases = this.relationship_key_aliases;
    if (relationships && aliases) {
      Object.keys(aliases).forEach(key => applyRelationshipAlias(key, aliases[key]));
    }
  }
});


