import Ember from 'ember';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import {
  VEHICLE_GROUP_NAME,
  PEOPLE_GROUP_NAME,
  ANIMAL_GROUP_NAME
} from '../../models/channel';
import { computed } from '@ember/object';

const logger = console;
const CHANNEL_FILTER_MAP = {
  [VEHICLE_GROUP_NAME]: { iconText: 'car', tag: VEHICLE_GROUP_NAME },
  [PEOPLE_GROUP_NAME]: { iconText: 'users', tag: PEOPLE_GROUP_NAME },
  [ANIMAL_GROUP_NAME]: { iconText: 'paw', tag: ANIMAL_GROUP_NAME }
};

export default Component.extend({
  intl: service(),

  classNames: ['channel-list-item_filter-button'],
  tag: null,
  enabledTagsOnFilter: null,
  enabled: computed('tag', 'enabledTagsOnFilter.[]', function() {
    return this.enabledTagsOnFilter.includes(this.tag);
  }),
  buttonText: null,
  iconName: null,
  init() {
    if (Ember.testing) {
      this.set('noink', true);
    }
    this._super(...arguments);

    this.enabledTagsOnFilter = this.enabledTagsOnFilter || [];

    if (CHANNEL_FILTER_MAP[this.tag]) {
      const filterText = this.intl.t(`camera-filter-text.${this.tag}`);
      this.set('buttonText', filterText);
      this.set('iconName', CHANNEL_FILTER_MAP[this.tag].iconText);
    } else {
      logger.log(`No button mapping exists for: ${this.tag}`);
    }
  },
  actions: {
    onClick() {
      this.onClick();
    }
  }
});
