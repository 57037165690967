import DS from 'ember-data';

export default DS.Model.extend({
  subscription: DS.belongsTo('subscription/subscription'),
  user: DS.belongsTo('basic-user'),
  event: DS.attr(),
  url: DS.attr(),
  isActive: DS.attr(),
  hmacSecret: DS.attr(),
  hmacDigest: DS.attr(),
  created: DS.attr(),
  updated: DS.attr(),
});
