import Component from '@ember/component';

export default Component.extend({
  actions: {
    cancel() {
      this.cancel();
      return false;
    },
    changePassword() {
      this.changePassword();
      return false;
    }
  }
});
