import { helper } from '@ember/component/helper';

export function getIconForCvTag(params/*, hash*/) {
  const value = params[0];
  const filter_map = params[1];

  return filter_map && filter_map[value] ? filter_map[value].iconText : 'cog';
}

export default helper(getIconForCvTag);
