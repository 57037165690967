import { Factory, faker, association } from 'ember-cli-mirage';

export default Factory.extend({
  firstTimestamp () {
    return faker.date.past();
  },
  lastTimestamp () {
    return faker.date.past();
  },
  state() {
    return 'viewed'
  },
  subscription: association(),
  event: association(),
  subscribableType () {
    return 'image'
  },

});
