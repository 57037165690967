import DS from 'ember-data';
import { computed } from '@ember/object';
import SubscriptionFilter from './subscription-filter';

export default SubscriptionFilter.extend({
  order: DS.attr(),
  timeout: DS.attr(),
  site: computed('channel.site', function() {
    return this.get('channel.site');
  }),

});
