define("reach-web-client/helpers/confirm", ["exports", "ember-ui-components/helpers/confirm"], function (_exports, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "confirm", {
    enumerable: true,
    get: function get() {
      return _confirm.confirm;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _confirm.default;
    }
  });
});
