import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  site() {
    return faker.random.number();
  },
  channel() {
    return faker.random.number();
  },
  images() {
    return '["' + faker.image.imageUrl() + '"]';
  },
  startTime() {
    return faker.date.past();
  },
  notes() {
    return faker.lorem.paragraph();
  },
  reportType: 1
});
