import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import { v1 } from 'ember-uuid';
import CacheBuster from '../mixins/cachebuster-adapter';


export default ApplicationAdapter.extend(CacheBuster, {
  storage: service(),
  namespace: 'api/legacy/v3',
  currentUser: service(),
  //This is to fix IE on win10 doing super aggressive caching
  alwaysReload: true,
  queryRecord(store, type, query) {
    if (query.localStorage) {
      return new Promise((resolve, reject) => {
        let response = {data: this.get('storage.' + this.get('currentUser.reportLocalStorageId')),
          included: []
        };
        if (response.data && response.data.relationships) {
          let segments = response.data.relationships.segments.data;
          if (segments) {
            segments.forEach((item)=>{
              response.included.push(this.get('storage.' + item.id));
            })
          }
        }
        if (!response.data) {
          reject('no report in progress');
        }
        resolve(response);
      })
    } else {
      return this._super(...arguments);
    }
  },
  createRecord(store, type, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      return this.saveToLocalStorage(store, type, snapshot);
    } else {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: false });
      return this.ajax(url, 'POST', { data: data });
    }

  },
  updateRecord(store, type, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      return this.saveToLocalStorage(store, type, snapshot);
    } else {
      let data = {};
      let serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
      let url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', { data: data });

    }
  },
  deleteRecord(store, type, snapshot) {
    if (snapshot.adapterOptions && snapshot.adapterOptions.localStorage) {
      return new Promise((resolve) => {
        this.storage.clear();
        resolve();
      });
    } else {
      return this._super(...arguments);
    }
  },
  saveToLocalStorage(store, type, snapshot) {
    let data = {};
    let serializer = store.serializerFor(type.modelName);
    snapshot.id = snapshot.id ? snapshot.id : v1();
    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });
    data.type = 'report';
    this.set('storage.' + this.get('currentUser.reportLocalStorageId'), data);
    return new Promise((resolve) => {
      resolve(this.get('storage.' + this.get('currentUser.reportLocalStorageId')));
    })
  }
});
