import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  firstTimestamp() {
    return faker.date.past();
  },
  lastTimestamp() {
    return faker.date.past();
  },

});
