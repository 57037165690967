import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  channel() {
    return faker.random.uuid();
  },
  channelId() {
    return faker.random.word();
  },
  coordinates() {
    return [faker.address.lattitude, faker.address.longitude]
  },
  description() {
    return faker.lorem.sentence();
  },
  reason () {
    return 'motion';
  },
  resizableImageUrl() {
    return '/assets/images/osprey-icon.png';
  },
  siteId() {
    return faker.random.uuid();
  },
  size() {
    return faker.random.number();
  },
  timestamp() {
    return faker.date.past();
  },
  user() {
    return faker.random.number();
  },
  end() {
    return faker.date.past();
  },
  hlsUrl() {
    return '/assets/images/osprey-icon.png';
  },
  rtmpUrl() {
    return '/assets/images/osprey-icon.png';
  },
  thumbnail() {
    return '/assets/images/osprey-icon.png';
  },
  video() {
    return '/assets/images/osprey-icon.png';
  },
  hlsStream() {
    return '/assets/images/osprey-icon.png';
  },
  rtmpStream() {
    return '/assets/images/osprey-icon.png';
  },
  start() {
    return faker.date.past();
  },

});
