import DS from 'ember-data';
import moment from 'moment';

export default DS.Transform.extend({
  deserialize(serialized) {
    return moment(serialized, 'HH:mm')
      .format('hh:mm A');
  },

  serialize(deserialized) {
    return moment(deserialized, 'hh:mm A').format('HH:mm')
  }
});
