import Ember from 'ember';
import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { run } from '@ember/runloop';
import Hammer from 'hammerjs';
import ImageZoomCardMixin from '../../../mixins/image-zoom-card';
import constants from '../../../utils/ui-constants';
import { createHammer, restrictZoom } from '../../../utils/touch';
import { isPaperDialogFullscreen, isIos } from '../../../utils/responsive';

const { CAMERA_DATE_FORMAT, CAMERA_DATE_ONLY_FORMAT, CAMERA_TIME_ONLY_FORMAT } = constants;

// background on Ember.testing and how to use: https://github.com/emberjs/ember.js/issues/16006
const { testing } = Ember;

const { alias } = computed;

// we need to grab the image card from inside the dialog wormhole (testing mode check required for ember-paper)
// see: https://github.com/miguelcobain/ember-paper/issues/630
// and related commit: https://github.com/miguelcobain/ember-paper/commit/26fd088a4d9525feea58f924e4e6f857a1dbc736
const dialogSelector = (selector) => document
  .querySelector(`#${testing ? 'ember-testing' : 'paper-wormhole'}`)
  .querySelector(selector);

export default Component.extend(ImageZoomCardMixin, {
  hideIcons: true,
  dateFormat: CAMERA_DATE_FORMAT,
  dateOnlyFormat: CAMERA_DATE_ONLY_FORMAT,
  timeOnlyFormat: CAMERA_TIME_ONLY_FORMAT,
  scrollLeft: 0,
  scrollTop: 0,
  lastZoomPercentage: ImageZoomCardMixin.defaultZoom,
  showCameraDetails: false,

  mediaType: computed('model.content', function() {
    return this.get('model.content.mediaType');
  }),

  image: computed('mediaType', function() {
    return this.get('mediaType') === 'image/jpeg' ? this.get('model.content') : undefined;
  }),

  noImage: computed('image', function() {
    return !this.image;
  }),

  hasLightBackground: alias('noImage'),

  site: computed('model.content.channel.site', function() {
    return this.get('image') ? this.get('model.content.peekSite') : this.get('model.content.channel.site');
  }),

  channel: computed('model.content.channel', function() {
    return this.get('image') ? this.get('model.content.peekChannel') : this.get('model.content.channel');
  }),

  ptzPreset: computed('model.content.ptzPreset', function() {
    return this.get('model.content.ptzPreset');
  }),

  modelWatcher: observer('model', function() {
    this.reset();
  }),

  init() {
    this._super(...arguments);
    this.set('isIos', isIos());

    // to call an inherited action from inside script (rather than invoked from template), we need to bind it first
    this.zoomReset = this.actions.zoomReset.bind(this);
  },

  dragStart() {
    // if user has started to drag, record current scroll positions
    this.set('lastScrollLeft', this.scrollLeft);
    this.set('lastScrollTop', this.scrollTop);
  },

  drag(ev) {
    this.set('scrollLeft', this.restrictLeft(ev.deltaX + this.lastScrollLeft));
    this.set('scrollTop', this.restrictTop(ev.deltaY + this.lastScrollTop));
  },

  swipeRightDetect() {
    this.stopPan();
    this.swipeRight();
  },

  swipeLeftDetect() {
    this.stopPan();
    this.swipeLeft();
  },

  pinchStart() {
    this.set('lastZoomPercentage', this.get('zoomPercentage'));
  },

  pinch(ev) {
    const newZoom = restrictZoom(Math.ceil(this.get('lastZoomPercentage') * ev.scale));
    this.updateZoom(newZoom)
  },

  pinchEnd(ev) {
    const newZoom = restrictZoom(Math.ceil(this.get('lastZoomPercentage') * ev.scale));
    this.updateZoom(newZoom)
  },

  /*
   * When we detect a swipe we need to
   * abort pan detection immediately.
   */
  stopPan() {
    this.hammer.stop(true);
  },

  dragReset() {
    this.set('scrollLeft', 0);
    this.set('scrollTop', 0);
  },

  pinchReset() {
    this.set('lastZoomPercentage', ImageZoomCardMixin.defaultZoom);
  },

  reset() {
    this.zoomReset();
    this.dragReset();
    this.pinchReset();
  },

  tap() {
    this.toggleProperty('hideIcons');
  },

  doubleTap() {
    this.reset();
  },

  actions: {
    closeDialog() {
      this.reset();
      this.close();
    },
    reset() {
      this.reset();
    },
    async downloadImage() {
      // this.image is an ObjectProxy/Promise, resolve the promise to get the actual object.
      // see: https://discuss.emberjs.com/t/convert-a-proxy-object-to-ember-data-before-calling-save/16206/3
      if (this.image) {
        const content = await this.image;
        content.download();
      }
    },
    showCameraDetails() {
      this.toggleProperty('showCameraDetails');
    },
    detectFullscreen() {
      this.set('isDialogFullscreen', isPaperDialogFullscreen());
    },
    didInsert() {
      // setup hammer
      const dialog = dialogSelector('.report-zoom-image .md-dialog-content');
      const containerManager = createHammer(dialog);

      const swipe = new Hammer.Swipe({ enable: () => this.get('notZoomed') });
      const tap = new Hammer.Tap();
      const doubleTap = new Hammer.Tap({event: 'doubletap', taps: 2 });
      const pan = new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0, enable: () => !this.get('notZoomed') });
      const pinch = new Hammer.Pinch();

      swipe.recognizeWith(doubleTap);
      swipe.recognizeWith(tap);
      swipe.recognizeWith(pan);
      swipe.recognizeWith(pinch);

      containerManager.add([swipe, doubleTap, tap, pan, pinch]);
      tap.requireFailure([doubleTap]);

      containerManager
        .on('doubletap', run.bind(this, this.doubleTap))
        .on('tap', run.bind(this, this.tap))
        .on('swiperight', run.bind(this, this.swipeRightDetect))
        .on('swipeleft', run.bind(this, this.swipeLeftDetect))
        .on('panstart', run.bind(this, this.dragStart))
        .on('panmove', run.bind(this, this.drag))
        .on('pinchstart', run.bind(this, this.pinchStart))
        .on('pinch', run.bind(this, this.pinch))
        .on('pinchend', run.bind(this, this.pinchEnd));

      this.hammer = containerManager;
    },
    willDestroy() {
      this.hammer.destroy();
    },
  }
});
