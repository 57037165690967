import Service from '@ember/service';
import { inject as service } from '@ember/service';
import ENV from '../config/environment';
import fetch from 'fetch';
import { observer } from '@ember/object';

export const PAGE_VIEWED = 'Page Viewed';
export const CHANNEL_REFRESHED = 'Channel Refreshed';
export const REPORT_IMAGE_ADDED = 'Report Image Added';
export const VIDEO_STREAM_REQUESTED = 'Video Stream Requested';
export const VIDEO_STREAM_STARTED = 'Video Stream Started';
export const VIDEO_STREAM_STOPPED = 'Video Stream Stopped';
export const REVIEWSTREAM_FILTER_APPLIED = 'Reviewstream Filter Applied';
export const MEDIA_MODAL_OPENED = 'Media Modal Opened';
export const MAINTENANCE_MODE_TOGGLED = 'Maintenance Mode Toggled';
export const MUTE_TOGGLED = 'Mute Toggled';
export const MUTE_MODAL_OPENED = 'Mute Modal Opened';
// note Alert has not been changed to Event Report below for data consistency
// the event will be removed when automated reports are removed
export const IRRELEVANT_REPORT_EVENT = 'Report Marked Irrelevant';
export const IRRELEVANT_ALERT_EVENT = 'Alert Marked Irrelevant';
export const ALERT_VIEWED = 'Alert Viewed';
export const ALERT_LIST_VIEW_FILTERED = 'Alert List View Filtered';
export const ALERT_STATE_UPDATED = 'Alert State Updated';
export const ALERT_EDITOR_OPENED = 'Alert Editor Opened';
export const ALERT_EDIT_SAVED = 'Alert Edit Saved';
export const OPEN_ALERT_VIDEO_PLAYER = 'Alert Video Player Opened';
export const USER_PROPERTY_EDITOR_OPENED = 'User Property Editor Opened';
export const USER_PROPERTY_EDIT_SAVED = 'User Property Edit Saved';
export const ALERT_SUBSCRIPTION_ENABLED = 'Alert Subscription Enabled/Disabled';
export const ALERT_SUBSCRIPTION_DELETED = 'Alert Subscription Deleted';
export const ALERT_SUBSCRIPTION_ADDED_TO_DAILY_REPORT = 'Alert Subscription Added/Removed From Daily Report';
export const MULTIPLE_ALERT_SUBSCRIPTIONS_ENABLED = 'Multiple Alert Subscriptions Enabled/Disabled';
export const MULTIPLE_ALERT_SUBSCRIPTIONS_DELETED = 'Multiple Alert Subscriptions Deleted';
export const MULTIPLE_ALERT_SUBSCRIPTIONS_ADDED_TO_DAILY_REPORT = 'Multiple Alert Subscriptions Added/Removed from Daily Report';
export const ALERT_SUBSCRIPTION_CREATOR_OPENED = 'Alert Subscriptions Creator Opened';
export const NEW_ALERT_SUBSCRIPTION_SAVED = 'New Alert Subscription Saved';
export const DAILY_REPORT_EDITOR_OPENED = 'Daily Report Editor Opened';
export const DAILY_REPORT_CHANGES_SAVED = 'Daily Report Changes Saved';
export const DAILY_REPORT_OPENED = 'Daily Report Opened';
export const LOGIN_ATTEMPTED = 'User Login Attempted';
export const LOGIN_SUCCEEDED = 'User Login Succeeded';
export const LOGOUT = 'User Logged Out';
export const REPORT_PDF_REQUESTED = 'Report PDF Requested';
export const INSPECTION_REPORT_DOWNLOADED = 'Inspection Report Downloaded';
export const MAP_VIEWED = 'Map Opened';
export const MAP_MODE_CHANGED = 'Map Mode Changed';
export const HELP_URL_CLICKED = 'Support Url Opened';
export const PTZ_CHANGED = 'PTZ position change requested';
export const USER_GUIDE_OPENED = 'User Guide visited';
export const INSPECTION_LIST_PAGE_VIEWED = 'Inspection List Page Viewed';
export const INSPECTION_REPORT_VIEWED = 'Inspection Report Viewed';
export const INSPECTION_IMAGE_ENLARGED = 'Inspection Image Enlarged';
export const INSPECTION_LIST_PAGE_FILTERED = 'Inspection List Page Filtered';
export const DEFAULT_TRACKING_SOURCE = 'web';
export const EMAIL_TRACKING_SOURCE = 'email';
const logger = console;

export default Service.extend({
  tenant: service(),
  currentUser: service(),
  ipAddress: '',
  intl: service(),
  init() {
    this.findIpAddress();
    this._super(...arguments);
    if (!window.mixpanel.people) {
      window.mixpanel.people = {
        set: function(json) {
          logger.debug(json);
        }
      }
    }
    if (!window.mixpanel.register) {
      window.mixpanel.register = function(json) {
        logger.debug(json);
      }
    }
    this.identifyUser();
    this.setSuperProperties({
      'environment': ENV.environment,
      'appVersion': 3
    })
  },
  setSuperProperties(props) {
    window.mixpanel.register(props);

  },
  identifyUser: observer (
    'currentUser.email', function() {
      if (this.get('currentUser.email')) {
        window.mixpanel.identify(this.get('currentUser.email'));
      }
    }
  ),
  track(eventName, propertyName, propertyValue) {
    var props = {
      [propertyName]: propertyValue
    };
    window.mixpanel.track(eventName, props);
  },
  trackMultiple(eventName, props) {
    window.mixpanel.track(eventName, props)
  },
  findIpAddress() {
    if (!ENV.mixpanel.mpKey) {
      this.set('clientIp', this.intl.t('errors.unknown-ip'));
      return;
    }
    const ipServiceUrl = ENV.ipService.url;
    fetch(ipServiceUrl)
      .then(response => {
        response.json()
          .then(json => {
            if (!this.isDestroyed && !this.isDetroying) {
              this.set('clientIp', json.ip);
            }
          })
      })
      .catch(() => {
        if (!this.isDestroyed && !this.isDetroying) {
          this.set('clientIp', this.intl.t('errors.unknown-ip'));
        }
      });
  },
});
