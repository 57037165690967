import { Model, belongsTo, hasMany } from 'ember-cli-mirage';

export default Model.extend({
  typeKey: 'subscription/subscription',
  subscribers: hasMany('subscription-subscriber'),
  type: belongsTo('subscription-subscription-type'),
  subscriptionFilters: hasMany({polymorphic: true}),
  webhookSubscribers: hasMany('subscription-webhook-subscriber'),
  schedules: hasMany('subscription-schedules')
});
