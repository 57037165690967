import Controller from '@ember/controller';
import { computed } from '@ember/object';
import moment from 'moment';
const CHART_OPTIONS = {
  global: {
    useUTC: false
  },
  time: {
    timezone: moment.tz.guess()
  },
  chart: {
    type: 'line',
    resetZoomButton: {
      theme: {
        fill: 'white',
        states: {
          hover: {
            fill: 'white'
          }
        }
      }
    },
    // Zoom on pinch
    zoomType: 'x',
    time: {
      timezone: moment.tz.guess()
    }
  },
  title: {
    text: ''
  },
  xAxis: {
    type: 'datetime',
    title: {
      text: 'Date ( ' + moment.tz.guess() + ' )'
    },
    dateTimeLabelFormats: {
      millisecond: '%H:%M:%S.%L',
      second: '%l:%M:%S',
      minute: '%l:%M %p',
      hour: '%l:%M %p',
      day: '%e. %b',
      week: '%e. %b',
      month: '%b \'%y',
      year: '%Y'
    }
  },
  yAxis: {
    title: {
      text: 'Readings'
    },
    plotLines: []
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    }
  },
  legend: {
    enabled: true
  },
  navigator: {
    enabled: false
  },
  tooltip: {
    valueDecimals: 2
  }
};

export default Controller.extend({
  queryParams: ['datalogger', 'to', 'from'],
  datalogger: '',
  chartData: null,
  chart: null,
  zones: null,
  min: computed('from', function() {
    return moment(this.from).format('x');
  }),
  max: computed('to', function() {
    return moment(this.to).format('x');
  }),
  to: moment()
    .add(25, 'hour')
    .format(),
  from: moment()
    .subtract(2, 'day')
    .format(),
  toDate: computed('to', function() {
    return moment(this.to).format('YYYY-MM-DD');
  }),
  fromDate: computed('from', function() {
    return moment(this.from).format('YYYY-MM-DD');
  }),
  chartOptions: CHART_OPTIONS,
  init() {
    const controller = this;
    this.chartOptions.plotOptions.series = {
      point: { events: { click: controller.clickChart.bind(controller) } }
    };

    this._super(...arguments);
  },
  clickChart(event) {
    if (this.model && this.model.channel) {
      // Get the point that was clicked
      const point = event.point;
      // Get the time
      const datetime = moment(point.x).format();

      this.transitionToRoute('site.review', this.get('model.site.id'), {
        queryParams: {
          channel: this.get('model.channel.id'),
          to: datetime
        }
      });
    }
  },
  reloadChart() {
    let data = [];
    const min = this.min;
    const max = this.max;
    this.get('model.registers').forEach(register => {
      data.push({
        name: register.get('name') + ' (' + register.get('units') + ')',
        data: register
          .get('datapoints')
          .map(item => {
            const timestamp = item.get('timestamp');
            if (timestamp < min || timestamp > max) {
              return false;
            }
            return [item.get('timestamp'), item.get('truncatedValue')];
          })
          .filter(obj => obj != false)
          .sort((obj1, obj2) => {
            return obj1[0] - obj2[0];
          })
      });
    });
    this.set('chartData', data);
  },
  reloadV2Chart() {
    this.store.query('camera-shadow', {'channel_id': this.model.get('channel.slug')}).then(((result) => {
      this.zones = result.objectAt(0).shadow.zones;
      const zones = this.zones;
      let plots = []
      let min = false;
      let max = false;
      for (const zone in zones) {
        if (zones.hasOwnProperty(zone)) {
          if (!min) {
            min = Number(zones[zone].temperature_threshold);
          }
          if (!max) {
            min = Number(zones[zone].temperature_threshold);
          }
          if (min > Number(zones[zone].temperature_threshold)) {
            min = Number(zones[zone].temperature_threshold);
          }
          if (max < Number(zones[zone].temperature_threshold)) {
            max = Number(zones[zone].temperature_threshold);
          }
          let line = {
            value: Number(zones[zone].temperature_threshold),
            color: 'red',
            dashStyle: 'shortdash',
            width: 2,
            label: {
              text: `${zones[zone].name} alert ${zones[zone].detection_type} ${zones[zone].temperature_threshold}`
            }
          }
          plots.push(line);
        }
      }
      this.chart.update({yAxis: {plotLines: plots, softMin: min, softMax: max}});
      const data = this.parseThermalData(this.model.dataloggerdata);
      this.set('chartData', data);
    }))
  },
  parseThermalData(thermalData) {
    let data = [];
    let updatedChartData = {};
    const min = this.min;
    const max = this.max;
    thermalData.forEach((item) => {
      let name = null;
      if (item.values.zone_id) {
        name = this.zones[item.values.zone_id].name;
      }
      if (!name) {
        name = '0';
      }

      if (!updatedChartData[name]) {
        updatedChartData[name] = [];
        //updatedChartData[name + '-min'] = [];
        //updatedChartData[name + '-max'] = [];
      }
      let timestamp = item.momenttimestamp.unix() * 1000;
      if (timestamp < min || timestamp > max) {
        return;
      }
      updatedChartData[name].push([timestamp, parseFloat(item.values.avg)]);
      //updatedChartData[name + '-min'].push([timestamp, parseFloat(item.values.min)]);
      //updatedChartData[name + '-max'].push([timestamp, parseFloat(item.values.max)]);
    });
    for (let key in updatedChartData) {
      data.push({
        name: 'Zone ' + key,
        data: updatedChartData[key].filter(obj => obj != false)
          .sort((obj1, obj2) => {
            return obj1[0] - obj2[0];
          }),
        lineWidth: 2,
        boostThreshold: 1,
        turboThreshold: 1
      });
    }
    return data;
  },
  reloadData() {
    if (this.model.isV2Temperature) {
      this.store.query('dataloggerdata', {
        page_number: 1,
        page_size: 50,
        sort: 'timestamp',
        to: moment(this.to).toISOString(),
        from: moment(this.from).toISOString(),
        dl_id: this.model.id
      }).then(data => {
        this.reloadV2Chart(data);
      });
    } else {
      this.store
        .query('datapointvalue', {
          slug: this.get('model.slug'),
          to: this.to,
          from: this.from,
          output: 'datalist'
        })
        .then(() => {
          this.reloadChart();
        });

    }
  },
  actions: {
    changeToDate(date) {
      this.set(
        'to',
        moment(date)
          .utc()
          .format()
      );
      this.reloadData();
    },
    clearToDate() {
      this.set(
        'to',
        moment()
          .utc()
          .format()
      );
      this.reloadData();
    },
    changeFromDate(date) {
      this.set(
        'from',
        moment(date)
          .utc()
          .format()
      );
      this.reloadData();
    },
    clearFromDate() {
      this.set(
        'from',
        moment()
          .subtract(1, 'day')
          .utc()
          .format()
      );
      this.reloadData();
    }
  }
});
