import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Service.extend({
  analytics: service(),
  store: service(),
  currentTenant: null,
  isInspectionsEnabled: computed('currentTenant.subdomain', function() {
    return this.get('currentTenant.inspection')
  }),
  subdomain: computed('currentTenant.subdomain', function() {
    const subdomain = this.get('currentTenant.subdomain');
    if (subdomain) {
      return subdomain;
    } else {
      let host = window.location.host.split('.')[0];
      if (host.search('localhost') >= 0) {
        host = 'echo';
      }
      return host;
    }
  }),
  init() {
    this._super(...arguments);

  },
  load() {
    if (this.currentTenant) {
      return new Promise(resolve => {
        resolve(this.currentTenant);
      }, 'Find Current Tenant');
    }
    return this.store.queryRecord('tenant', 'current')
      .then(data => {
        this.set('currentTenant', data);
        this.analytics.setSuperProperties({
          'Tenant Name': this.get('currentTenant.name'),
          'Tenant Subdomain': this.get('currentTenant.subdomain')
        });
      })
  }
});
