import Component from '@ember/component';

export default Component.extend({
  classNames: ['subscriptions-error-dialog'],
  actions: {
    clearErrors() {
      this.clearErrors();
    }
  }
});
