define("reach-web-client/components/svg-filters", ["exports", "ember-svg-shapes/components/svg-filters"], function (_exports, _svgFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _svgFilters.default;
    }
  });
});
