import DS from 'ember-data';
import { computed } from '@ember/object';
import BasicUser from './basic-user';
import moment from 'moment';
import constants from '../utils/ui-constants';
import {inject as service} from '@ember/service';

const { UI_DATE_FORMAT } = constants;

export default BasicUser.extend({
  intl: service(),
  dateJoined: DS.attr(),
  intercon: DS.attr(),
  isStaff: DS.attr(),
  isSuperuser: DS.attr(),
  lastLogin: DS.attr(),
  permissions: DS.attr(),
  phoneNumber: DS.attr(),
  positionTitle: DS.attr(),
  groups: DS.attr(),

  hasPermission(requestedPermission) {
    const permissions = this.permissions;
    if (!permissions) {
      return false;
    }
    return permissions.indexOf(requestedPermission) > -1;
  },
  lastLoginString: computed('lastLogin', function() {
    const date = moment(this.lastLogin).tz(moment.tz.guess());

    if (!date.isValid()) {
      return this.intl.t('user-admin.never');
    } else if (date.isSameOrBefore(moment().subtract(1, 'month'))) {
      return this.intl.t('user-admin.old-login');
    } else {
      return date.format(UI_DATE_FORMAT);
    }
  }),
  stale: computed('lastLogin', function() {
    const date = moment(this.lastLogin).tz(moment.tz.guess());

    if (!date.isValid() || date.isSameOrBefore(moment().subtract(1, 'month'))) {
      return true;
    }
    return false;
  })
});
