import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import { t } from 'ember-intl';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import makeExtendedInfinityModel from '../utils/MakeExtendedInfinityModel';

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    can: service(),
    store: service(),
    infinity: service(),

    titleToken: t('navbar.customer-settings'),
    breadcrumbs: null,

    model() {
      /**
       * NOTE: we patch a custom prop ('isInfiniteScroll') onto the model to hint to the inspections-board component
       * that it needs to display a infinite scroll loader.
       * If the model does not have this prop, the infinite scroll loader won't appear.
       * This allows the inspections-board to operate with or without infinite scroll.
       */
      return this.infinity.model('subscription/inspection-entitlement', {
        perPage: 5,
        perPageParam: 'page_size',
        pageParam: 'page_number',
        totalPagesParam: 'meta.pagination.pages',
      }, makeExtendedInfinityModel(
        'subscription/inspection-entitlement',
        { isInfiniteScroll: true }
      ));
    },
    setupController() {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.customer-settings')
        }
      ]);

      this._super(...arguments);
    },
    /**
     * NOTE: this is a special hook from the TenantRouteMixin which will fire only after the vhiUser is loaded.
     * We need to make sure the vhiUser is loaded first, so we can check whether he/she isStaff
     * @param transition - the beforeModel() transition object passed along from TenantRouteMixin
     */
    afterUserLoaded(transition) {
      if (this.can.cannot('edit customer-setting')) {
        // send you back from whence you came
        if (transition.from) {
          this.transitionTo(transition.from.name);
        } else {
          // if you got here via a bookmark, then push you to home page
          this.transitionTo('index');
        }
      }
    },
    actions: {
      refresh() {
        this.refresh();
      }
    }
  });
