import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export const PERMIT_SITE_MUTE = 'hardware.permit_site_mute';
export const PERMIT_MAINTENANCE_MODE = 'hardware.permit_site_maintenance_mode';
export const ALERTS_ADD_SUBSCRIPTION = 'subs.add_subscription';
export const ALERTS_CHANGE_SUBSCRIPTION = 'subs.edit_subscription';
export const ALERTS_DELETE_SUBSCRIPTION = 'subs.delete_subscription';
export const AUTH_EDIT_OTHERS_SUBS = 'auth.edit_others_subs';

export default Mixin.create({
  currentUser: service(),
  showSiteMute: computed('currentUser.vhiUser.permissions.[]', function() {
    if (!this.get('currentUser.vhiUser')) {
      return false;
    }
    return this.get('currentUser.vhiUser').hasPermission(PERMIT_SITE_MUTE);
  }),
  showMaintenanceMode: computed('currentUser.vhiUser.permissions.[]', function() {
    if (!this.get('currentUser.vhiUser')) {
      return false;
    }
    return this.get('currentUser.vhiUser').hasPermission(PERMIT_MAINTENANCE_MODE);
  }),
  showManageAlerts: computed('currentUser.vhiUser.permissions.[]', function() {
    if (!this.get('currentUser.vhiUser')) {
      return false;
    }
    return (
      this.get('currentUser.vhiUser').hasPermission(ALERTS_ADD_SUBSCRIPTION) ||
      this.get('currentUser.vhiUser').hasPermission(ALERTS_DELETE_SUBSCRIPTION) ||
      this.get('currentUser.vhiUser').hasPermission(ALERTS_CHANGE_SUBSCRIPTION)
    );
  }),
  editOtherUserSubscriptions: computed('currentUser.vhiUser.permissions.[]', function() {
    if (!this.get('currentUser.vhiUser')) {
      return false;
    }
    return this.get('currentUser.vhiUser').hasPermission(AUTH_EDIT_OTHERS_SUBS);
  })

});
