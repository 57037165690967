import Component from '@ember/component';
import AlertManipulator from '../mixins/alert-manipulator';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';


export default Component.extend(AlertManipulator, {
  store: service(),
  classNameBindings: ['topLevelClass', 'alertId'],
  topLevelClass: 'subscription-event-preview',
  alertId: computed('model.id', function() {
    return 'alert-id-' + this.model.id;
  }),
  click () {
    this.viewEvent();
    return true;
  },
  contextMenu() {
    this.viewEvent();
    return true;
  },
  isThermalAlert: computed('model.items.{@each.content,[]}', function() {
    let isThermal = false;
    if (!this.model || !this.model.items) {
      return isThermal;
    }
    this.model.items.any(item => {
      if (item && item.content) {
        if (item.isDestroyed || item.isDestroying) {
          return false;
        }
        if (item.get('content._internalModel.modelName') === 'image' && item.get('content.channelId')) {
          const channel = this.store.peekRecord('channel', item.get('content.channelId'));
          isThermal = channel.thermalCamera;
          return isThermal;
        }
      }
    });
    return isThermal;
  })

});
