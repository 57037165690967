import Controller from '@ember/controller';
import $ from 'jquery';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { sort } from '@ember/object/computed';
import { computed, observer } from '@ember/object';

export default Controller.extend({
  queryParams: [
    'channel',
    'from',
    'to',
    'tag',
    'reason',
    'media',
    'highlight',
    'pageSize',
    'preserveScrollPosition',
    { modalImage: { type: 'string' } }
  ],
  channel: null,
  from: null,
  to: null,
  tag: null,
  reason: null,
  media: null,
  modalImage: null,
  pageSize: 30,
  selectAllChannelsOnInit: true,
  preserveScrollPosition: false,
  highlight: null,
  infinity: service(),
  siteManager: service(),
  currentUser: service(),

  watchSelectedImage: observer('selectedImage', function() {
    this.set('preserveScrollPosition', true);
    this.set('modalImage', this.get('selectedImage.id'));
  }),
  isCurrentUserStaff: computed('currentUser.vhiUser.isStaff', function() {
    return this.currentUser.vhiUser.isStaff
  }),
  images: sort('model', function(itemA, itemB) {
    //these are reversed since we want to show in desc order
    if (itemA.get('momenttimestamp').isBefore(itemB.get('momenttimestamp'))) {
      return 1;
    }
    if (itemA.get('momenttimestamp').isAfter(itemB.get('momenttimestamp'))) {
      return -1;
    }
    //same value
    return 0;
  }),
  init() {
    this._super(...arguments);
    later(this, this.updateModalState, 3500);
  },
  cleanup() {
    this.set('preserveScrollPosition', false);
    $('.reach-snapshot-modal').modal('hide');
  },
  imageSelected: computed('selectedImage', 'modalImage', function() {
    this.updateModalState();
  }),
  updateModalState() {
    if (this.selectedImage) {
      $('.reach-snapshot-modal').modal('show');
      return;
    }
    if (this.modalImage) {
      const found = this.images.findBy('id', this.modalImage);
      if (found) {
        this.set('selectedImage', found);
        $('.reach-snapshot-modal').modal('show');
      } else {
        this.store.find('media', this.modalImage).then(result => {
          this.set('selectedImage', result);
          $('.reach-snapshot-modal').modal('show');
        });
      }
    }
  },
  initChannels() {
    this.set('model.reachedInfinity', false);
    let channels = this.channel;
    if (channels === null && this.selectAllChannelsOnInit) {
      channels = [];
      this.siteManager.channels.forEach(item => {
        channels.pushObject(item.id);
      });
      later(
        this,
        function() {
          this.set('channel', channels);
        },
        100
      );
    } else {
      this.set('model.reachedInfinity', true);
    }
    this.set('selectAllChannelsOnInit', true);
    return true;
  },
  nextSite(nextSite) {
    if (nextSite) {
      this.set('channel', null);
      this.set('selectAllChannelsOnInit', true);
      this.transitionToRoute('site.review', nextSite.id, {
        queryParams: { channel: null }
      });
    }
  },
  actions: {
    updateChannel(id) {
      let channels = this.channel;
      if (!channels) {
        channels = [];
      }
      if (!Array.isArray(channels)) {
        channels = [channels];
      }
      const found = channels.indexOf(id);
      if (found >= 0) {
        channels.removeAt(found, 1);
      } else {
        channels.pushObject(id);
      }
      if (channels.length === 0) {
        channels = null;
      }
      this.set('channel', channels);
    },
    fromDateChanged(date) {
      this.set('from', date);
    },
    toDateChanged(date) {
      this.set('to', date);
    },
    clearDate() {
      this.set('from', null);
      this.set('to', null);
    },
    openImageInModal(image) {
      this.set('selectedImage', image);
      this.updateModalState();
    },
    addMoreImages(images) {
      this.infinity.pushObjects(this.model, images);
    },
    hideFilter(e) {
      if ($(e.target).parents('.wickedpicker').length > 0) {
        return;
      }
      $('.reach-image-filter').collapse('hide');
    }
  }
});
