import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

const SCHEDULE_COLORS = [
  '#19dcb2',
  '#ffa73b',
  '#4363d8',
  '#f032e6',
  '#f44336',
  '#ffe119',
  '#911eb4',
  '#29b6f6',
  '#469990',
  '#000075',
  '#800000',
  '#9a6324',
  '#808000',
  '#bfef45',
  '#3cb44b'
];

export default Component.extend({
  classNames: ['schedule-picker_main-container'],
  store: service(),
  schedules: null,
  allowEdit: false,
  disableAddMoreButton: computed('schedules.[]', function() {
    return this.schedules.length >= 15;
  }),
  scheduleColors: null,
  init() {
    this._super(...arguments);
    this.scheduleColors = SCHEDULE_COLORS;
  },
  actions: {
    addNewSchedule() {
      const newSchedule = this.store.createRecord('subscription/schedule', {
        recurrence: ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'],
        start: '06:00 pm',
        end: '11:59 pm',
        timezone: moment.tz.guess(),
        subscription: this.subscription
      });
      this.schedules.pushObject(newSchedule);
    },
    removeSchedule(schedule, color) {
      schedule.deleteRecord();
      //pop the color to the end of the color array
      this.scheduleColors.removeObject(color);
      this.scheduleColors.pushObject(color)
    }
  }
});
