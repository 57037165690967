import { Factory, faker } from 'ember-cli-mirage';

export default Factory.extend({
  id() {
    faker.random.number()
  },
  name() {
    return faker.hacker.noun();
  },
  contextNote() {
    return faker.lorem.paragraph();
  },
  creator() {
    return {
      first_name: faker.name.firstName(),
      last_name: faker.name.lastName(),
      id: faker.random.number(),
      username: faker.internet.userName()
    }
  },
  dateTime() {
    return faker.date.past();
  },
  pdfFile: null,

  reportType: 1
});
