import Service, { inject as service } from '@ember/service';

//TODO: the list of allowed users should be restricted by who can view the site.
// This requires a dynamic API call to re-fetch the list of permitted users based on which
// cameras are selected.  Since this is complex, we will defer this for now, and in Phase I
// will settle on users who do not have permission to a site, will never receive an email
// for an inspection that contains a camera they cannot view.

export default Service.extend({
  store: service(),
  allUsers: null,

  // NOTE: for now, we assume you are calling loadAllUsers() from a route,
  // therefore we DO NOT CACHE the list of users as this list could change.
  loadAllUsers(email, clearList) {
    if (clearList) {
      this.set('allUsers', null);
    }

    return this.store.query('subscription/user', {
      page: 1,
      page_size: 20,
      email: email
    })
      .then(allUsers => {
        // NOTE: to allow unit tests to function correctly, avoid using promise proxies.
        // aka - AVOID setting result of query onto controller as a property.
        // example: this.controller.set('allUsers', this.store.query('myModel'));
        this.set('allUsers', allUsers.toArray());
        return this.get('allUsers');
      });
  }
});
