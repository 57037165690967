/**
 * This class implements authentication against google
 * using the OAuth2 authorization flow in a popup window.
 */

import Oauth2 from 'torii/providers/oauth2-code';
import { configurable } from 'torii/configuration';
import ENV from '../config/environment';
import { inject as service } from '@ember/service';


var CognitoOauth2 = Oauth2.extend({
  tenant: service(),
  name: 'cognito-oauth2',
  clientId: configurable('clientId', ENV.COGNITO_CLIENT_ID),
  poolId: configurable('poolId', ENV.COGNITO_POOL_ID),
  responseType: 'token',
  identityProvider: configurable('identityProvider'),
  redirectUri: configurable('redirectUri', ENV.torii.providers['cognito-oauth2'].redirectUri),
  baseUrl: configurable('baseUrl', ENV.torii.providers['cognito-oauth2'].baseUrl),
  requiredUrlParams: Object.freeze(
    ['response_type', 'client_id', 'redirect_uri', 'state', 'identity_provider']
  ),
  // additional params that this provider requires
  optionalUrlParams: Object.freeze([
    'idp_identifier',
    'scope',
    'code_challenge_method',
    'code_challenge']),

  requestVisibleActions: configurable('requestVisibleActions', ''),

  accessType: configurable('accessType', ''),

  responseParams: Object.freeze(['access_token', 'token_type', 'expires_in', 'state']),

  scope: configurable('scope', 'openid aws.cognito.signin.user.admin'),

  approvalPrompt: configurable('approvalPrompt', 'auto'),

  hd: configurable('hd', ''),
  open: function open(options) {
    this.set('state', this.get('tenant.currentTenant.subdomain'));
    this.set('identityProvider', options['identityProvider'])
    let name = this.get('name'),
      url = this.buildUrl(),
      responseParams = this.get('responseParams'),
      redirectUri = this.get('redirectUri'),
      state = this.get('state'),
      shouldCheckState = responseParams.indexOf('state') !== -1;

    return this.get('popup').open(url, responseParams, options)
      .then(function (authData) {
        var missingResponseParams = [];
        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });
        if (missingResponseParams.length) {
          throw new Error('The response from the provider is missing ' + 'these required response params: ' + missingResponseParams.join(', '));
        }
        if (shouldCheckState && authData.state !== state) {
          throw new Error('The response from the provider has an incorrect ' + 'session state param: should be "' + state + '", ' + 'but is "' + authData.state + '"');
        }
        authData.provider = name;
        authData.redirectUri = redirectUri;
        return authData;
      });
  }
});

export default CognitoOauth2;
