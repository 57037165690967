import EmberHighChartsComponent from 'ember-highcharts/components/high-charts';
import { observer } from '@ember/object';
import { later } from '@ember/runloop'
import ResponsiveBootstrap from '../mixins/responsive-bootstrap';

export default EmberHighChartsComponent.extend(ResponsiveBootstrap, {
  didInsertElement() {
    this._super(...arguments);
    later(this, this.adjustChartLegend,100);
  },
  adjustChartLegend() {
    const size = this.currentScreenSize;
    let chart = this.chart;
    if (!chart) {
      return;
    }
    if (size === 'xs' || size === 'sm' || size === 'md') {
      chart.legend.update({
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        alignColumns: false,
        //y: 0,
      });
      chart.setSize(null, null, true);

    } else {
      chart.legend.update({
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        alignColumns: true,
      });
      chart.setSize(null, null, true);
    }
    chart.redraw();
  },
  watchScreenSize: observer('currentScreenSize', function() {
    this.adjustChartLegend();
  })
})
