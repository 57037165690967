import DS from 'ember-data';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

const IMAGE_HEADERS = {
  'Accept': 'image/jpeg',
  'Content-Type': 'image/jpeg'
};

const logger = console;

export default DS.Model.extend({
  session: service(),
  site: DS.belongsTo('site'),
  channel: DS.belongsTo('channel'),
  images: DS.attr(),
  startTime: DS.attr(),
  notes: DS.attr(),
  segmentType: DS.belongsTo('report-segment-type'),
  ptzLabel: DS.attr(),
  formattedDateTime: computed('dateTime', 'site.timezone', function() {
    let timezone = this.get('site.timezone');
    return moment(this.startTime)
      .tz(timezone ? timezone : moment.tz.guess())
      .format('ll LTS z');
  }),

  imagePreview: computed('images.[]', function() {
    if (!this.images) {
      return null;
    }
    if (Array.isArray(this.images)) {
      return this.images.objectAt(0);
    } else {
      return JSON.parse(this.images).objectAt(0);
    }
  }),

  /**
   * Retrieve an image from the image server, sized as specified by the size argument.
   *
   * @type {string}
   */
  imagePreviewBlob: computed('images.[]', function() {


    const url = this.imagePreview;

    if (!url) {
      return null;
    }

    let { access_token } = this.get('session.data.authenticated');
    let headers = IMAGE_HEADERS;
    if (url.indexOf('images.ospreyreach.com') <= 0) {
      headers['Authorization'] = `Bearer ${access_token}`;
    }
    url.replace('/vhi-staging-snapshot', '');
    url.replace('/ospreydatastore', '');

    return fetch(url, { headers: headers })
      .then(response => {
        const reader = response.body.getReader();

        return new ReadableStream({
          start(controller) {
            return pump();

            function pump() {
              return reader.read()
                .then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
            }
          }
        })
      })
      .then(stream =>
        new Response(stream)
      )
      .then(response =>
        response.blob()
      )
      .then(blob => URL.createObjectURL(blob))
      .catch(reason =>
        logger.log(reason)
      )
  })
});
