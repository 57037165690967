import Mixin from '@ember/object/mixin';
import { inject as service } from '@ember/service';

export default Mixin.create({
  store: service(),
  siteManager: service(),
  setupController(controller) {
    let model = this.siteManager.site;
    this._super(...arguments);
    this.store.query('site', {
      page: 1,
      page_size: 1000
    }).then(sites => {
      let found = -1;
      sites.forEach((site, index) => {
        if (site.id === model.id) {
          found = index;
        }
      });
      if (found < 0) { return; }
      let nextSite = {};

      if (found < sites.length - 1) {
        nextSite = sites.objectAt(found + 1);
      } else {
        nextSite = sites.objectAt(0);
      }

      this.set('breadcrumbButtons', [
        {
          action: 'callCallback',
          callback: controller.nextSite.bind(controller, nextSite),
          title: this.intl.t('site.details.next', {nextSite: nextSite.name})
        }
      ]);
      this.controllerFor('application').set('breadcrumbButtons', this.breadcrumbButtons);

    });
  },
});
