const match = (filter, target) => (
  filter.get('channel.id') === target.get('channel.id')
    ? filter.get('ptzPreset')
      ? filter.get('ptzPreset.id') === target.get('ptzPreset.id')
      : true
    : false
);

/**
 * Returns a function that compares a provided inspection-filter vs a target inspection-filter we are looking for.
 * @param target an inspection filter
 * @returns {function(*=): boolean} a function for comparing a filter argument vs a target object we are looking for.
 */
export function makeFinder(target) {
  return filter => match(filter, target)
}
