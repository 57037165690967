define("reach-web-client/utils/get-days-in-month", ["exports", "ember-time-tools/utils/get-days-in-month"], function (_exports, _getDaysInMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getDaysInMonth.default;
    }
  });
});
