import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import ENV from '../config/environment';
import { run } from '@ember/runloop';
import { computed } from '@ember/object';

const PENDING_REPORT_SUFFIX = '-pending-report';

export default Service.extend({
  session: service(),
  cognito: service(),
  store: service(),
  intl: service(),
  cognitoUser: readOnly('cognito.user'),
  vhiUser: null,
  username: readOnly('cognitoUser.username'),
  tempUser: '',

  reportLocalStorageId: computed('vhiUser.username', function() {
    let username = this.get('vhiUser.username');
    username = username ? username : 'temp';
    username = username.split('.')
      .join('_dot_');
    return username + PENDING_REPORT_SUFFIX;
  }),

  load() {
    return new Promise((resolve, reject) => {
      if (this.get('session.isAuthenticated')) {
        if (this.vhiUser) {
          resolve(this.vhiUser);
        } else {
          if (!this.cognitoUser) {
            this.findVHIUser(resolve, reject);
          } else {
            return this.cognitoUser.getUserAttributes()
              .then(userAttributes => {
                userAttributes.forEach(attr => {
                  this.set(attr.getName(), attr.getValue());
                });
                this.findVHIUser(resolve, reject);
              })
              .catch(err => reject(err)); // NOTE: 2020-Feb-25 saw an uncaught exception on expired Cognito token
          }
        }
      } else {
        reject(this.intl.t('errors.not-authenticated'));
      }
    }, 'Check for Tenant and Auth');
  },
  findVHIUser(resolve, reject) {
    //This is needed to be wrapped in a run to help the tests run
    run(() => {
      this.store
        .queryRecord('user', {})
        .then(result => {
          this.set('vhiUser', result);
          resolve(result);
        })
        .catch(e => {
          if (
            e.errors &&
            e.errors[0].detail === 'Login denied for inactive user.'
          ) {
            reject(this.intl.t('errors.user-inactive'));
          }
          reject(this.intl.t('errors.not-authenticated-vhi'));
        });
    });
  },
  findCognitoUser() {
    const poolId = ENV.cognito.poolId;
    const clientId = ENV.cognito.clientId;
    let pool = this.createPool(clientId, poolId);
    let cognitoUser = pool.getCurrentUser();
    return cognitoUser;
  },

  createUser(username, pool) {
    let user = new CognitoUser({
      Username: username,
      Pool: pool
    });
    return user;
  },

  createPool(clientId, poolId) {
    let pool = new CognitoUserPool({
      UserPoolId: poolId,
      ClientId: clientId
    });
    return pool;
  },

  forgotPassword(username) {
    const poolId = ENV.cognito.poolId;
    const clientId = ENV.cognito.clientId;
    return new Promise((resolve, reject) => {
      let pool = this.createPool(clientId, poolId);
      let user = this.createUser(username, pool);

      this.set('tempUser', user);

      const translator = this.intl;
      user.forgotPassword({
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          if (err && err.code && err.code === 'NotAuthorizedException') {
            reject(translator.t('login-form.reset-temporary-password-error'));
          }
          reject(err.message);
        },
        inputVerificationCode() {
          resolve('enter-verification-code');
        }
      });
    }, 'Forgot Password Promise');
  },

  verifyResetPasswordCode(verificationCode, newPassword) {
    verificationCode = verificationCode.trim();
    return new Promise((resolve, reject) => {
      let user = this.tempUser;
      if (!user) {
        reject(this.intl.t('login-form.user-error'));
      }
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve();
        },
        onFailure(error) {
          reject(error.message);
        }
      });
    }, 'Verify Pwd code');
  },

  signOut() {
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
    }
    this.session.invalidate();
    this.set('vhiUser', null);
  }
});
