import Component from '@ember/component';
import moment from 'moment';

import { computed } from '@ember/object';
const CHART_OPTIONS = {
  chart: {
    type: 'column',
    height: '200',
  },
  title: {
    text: ''
  },
  xAxis: {
    categories: ['Last Reading']
  },
  yAxis: {
    title: {
      text: ''
    }
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      dataLabels: {
        enabled: true
      }
    },

  },
  legend: {
    useHTML: true,
    align: 'left',
    verticalAlign: 'top',
    layout: 'vertical'
  },
  tooltip: {
    formatter: function () {
      return this.point.series.name;
    }
  },
};
export default Component.extend({
  classNames: ['datalogger-summary', 'col-md-4'],
  currentTimezone: moment.tz.guess(),
  chartOptions: CHART_OPTIONS,
  chartData: computed('logger.registers.@each.{truncatedLastReading,name}', function() {
    let data = [];
    this.get('logger.registers').forEach((register) => {
      data.push({
        name: register.get('name') + ' : ' + register.get('truncatedLastReading') + ' ' + register.get('units'),
        data: [register.get('truncatedLastReading')]
      });
    });
    return data;
  }),

});
