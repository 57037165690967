import Component from '@ember/component';
import {observer} from '@ember/object';

export default Component.extend({
  classNames: ['subscribable-item-container'],
  content: null,
  init() {
    /**
    Not sure what's going on here, but this promise is not getting
    resolved in the template
    **/
    this._super(...arguments);
    if (this.item) {
      this.item.get('content').then(result => {
        this.set('content', result);
      })
    }
  },
  resolveContent: observer('item.content', function() {
    /**
    Not sure what's going on here, but this promise is not getting
    resolved in the template
    **/
    this.get('item.content').then(result => {
      this.set('content', result);
    })
  })
});
