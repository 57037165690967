import Component from '@ember/component';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import SearchBarMixin from '../mixins/search-bar-scroll-fixer-mixin';

export default Component.extend(SearchBarMixin, {
  store: service(),
  classNames: 'channel-search-bar mobile-bounce-left',
  selected: null,
  site: null,

  selectedChanged: observer('selected', function() {
    let channel = this.selected;
    this.channelSelected(channel);
  }),
  init() {
    this._super(...arguments);
    this.set('channels', this.loadChannels());
  },
  siteChanged: observer('site', function() {
    this.loadChannes();
  }),
  loadChannels() {
    if (!this.site) {
      return;
    }
    return this.store.query('channel', {
      page: 1,
      page_size: 1000,
      site: this.get('site.id'),
      include: 'latest_image'
    });
  }
});
