import ApplicationAdapter from './application';
import CacheBuster from '../mixins/cachebuster-adapter';
import {AdapterIncludeParamMixin} from '../mixins/subscription/adapter';

const JSON_HEADERS = {
  'Accept': 'application/vnd.api+json, application/json',
  'Content-Type': 'application/vnd.api+json'
};
export default ApplicationAdapter.extend(CacheBuster, AdapterIncludeParamMixin, {
  headers: JSON_HEADERS,
  pathForType() {
    return 'v1/cv/tag_type_group';
  }
});
