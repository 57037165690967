define("reach-web-client/services/window-resize", ["exports", "ember-window-resize/services/window-resize"], function (_exports, _windowResize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _windowResize.default;
    }
  });
});
