import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../mixins/navbar-title-mixin';
import TenantRouteMixin from '../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import {
  DAILY_REPORT_OPENED,
  EMAIL_TRACKING_SOURCE
} from '../services/analytics';
import { t } from 'ember-intl';

const AlertInfinityModel = InfinityModel.extend({
  store: service(),
  subscription: service(),

  afterInfinityModel(events) {
    events.forEach(event => {
      this.store
        .query('subscription/event-item-reference', {
          page_number: 1,
          page_size: 5,
          event_id: event.id,
          'filter[subscription.type]': this.get('subscription.alertType.id'),
        })
        .then(items => {
          event.items.addObjects(items);
          event.set('totalEventItems', items.meta && items.meta.pagination ? items.meta.pagination.count : items.length);
          return items;
        });
    });
  }
});

export default Route.extend(
  AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute,
  {
    titleToken: t('navbar.alerts'),
    intl: service(),
    infinity: service(),
    analytics: service(),
    currentUser: service(),
    subscription: service(),
    breadcrumbs: null,

    queryParams: {
      state: {
        refreshModel: true
      },
      subscription: {
        refreshModel: true
      },
      source: {},
      report_id: {}
    },
    beforeModel() {
      this.subscription.ready;
      return this._super(...arguments);

    },

    model(params) {
      return this.infinity.model(
        'subscription/event',
        {
          before_date: params.before_date,
          startingPage: 1,
          perPage: params.page_size,
          pageParam: 'page_number',
          perPageParam: 'page_size',
          totalPagesParam: 'meta.pagination.pages',
          state: params.state,
          subscription: params.subscription,
          'filter[subscription.type]': this.get('subscription.alertType.id')
        },
        AlertInfinityModel
      );
    },

    setupController(controller) {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.alerts')
        }
      ]);
      this.set('breadcrumbButtons', [
        {
          route: 'alert.manage',
          graphic: 'settings-icon',
          title: this.intl.t('alerts.manage')
        }
      ]);

      this._super(...arguments);

      if (controller.source === EMAIL_TRACKING_SOURCE && controller.report_id) {
        this.analytics.trackMultiple(DAILY_REPORT_OPENED, {
          source: controller.source,
          reportId: controller.report_id
        });
      }
    },

    actions: {
      activate() {
        this.set('breadcrumbButtons', [
          {
            route: 'alert.manage',
            icon: 'cogs',
            title: this.intl.t('alerts.manage')
          }
        ]);
      },
      refreshData() {
        this.refresh();
      }
    },
  }
);
