import DS from 'ember-data';
import Copyable from 'ember-data-copyable';
import { computed } from '@ember/object';
import moment from 'moment';

export default DS.Model.extend(Copyable, {
  contextNote: DS.attr(),
  creator: DS.attr(),
  dateTime: DS.attr(),
  name: DS.attr(),
  pdfFile: DS.attr(),
  segments: DS.hasMany('report-segment'),
  reportType: DS.belongsTo('report-type'),
  formattedDateTime: computed('dateTime', function() {
    return moment(this.dateTime)
      .tz(moment.tz.guess())
      .format('ll LTS z');
  }),
  creatorName: computed('creator', function() {
    return this.get('creator.first_name') + ' ' + this.get('creator.last_name');
  })

});
