import DS from 'ember-data';
import './custom-inflector-rules';
import { computed } from '@ember/object';
import {inject as service} from '@ember/service';

import ENV from '../config/environment';
const DEFAULT_TOPLOGO = '/assets/images/osperity-white.svg';
const DEFAULT_MAINLOGO = '/assets/images/osperity-blue.svg';
export default DS.Model.extend({
  operations: service(),
  name: DS.attr(),
  subdomain: DS.attr(),
  topLogo: DS.attr(),
  mainLogo: DS.attr(),
  reportLogo: DS.attr(),
  idProviders: DS.attr(),
  inspection: DS.attr(),
  liveVideoStreamTimeLimit: DS.attr(),
  adminUrl: computed('subdomain', function() {
    return ENV.vhiProtocol + '://' + this.subdomain + '.' + ENV.vhiServer + '/admin';
  }),
  navbarLogo: computed('topLogo', function() {
    if (this.topLogo) {
      return this.topLogo;
    }
    return DEFAULT_TOPLOGO;
  }),
  frontLogo: computed('mainLogo', function() {
    if (this.mainLogo) {
      return this.mainLogo;
    }
    return DEFAULT_MAINLOGO;
  }),
  channels: computed('subdomain', function() {
    return this.operations.channelsForTenant(this.subdomain);
  })

});
