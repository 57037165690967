import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {hash} from 'rsvp';

export default Route.extend({
  store: service(),
  model() {
    return hash({
      channels: this.store.findAll('channel'),
      cloudbridges: this.store.findAll('cloudbridge'),
      gateways: this.store.findAll('gateway')
    });
  },
});
