/**
 *
 * @param fromPoint
 * @param toPoint
 * @param canvas
 * This function draws an arrow head at the end of a line segment
 */
export function drawArrowhead(fromPoint,toPoint,canvas) {
  const dx = toPoint.x - fromPoint.x;
  const dy = toPoint.y - fromPoint.y;

  // normalize
  const length = Math.sqrt(dx * dx + dy * dy);
  const unitDx = dx / length;
  const unitDy = dy / length;

  // increase this to get a larger arrow head
  const arrowHeadBoxSize = 10;

  let arrowPoint1X = toPoint.x - unitDx * arrowHeadBoxSize - unitDy * arrowHeadBoxSize;
  let arrowPoint1Y = toPoint.y - unitDy * arrowHeadBoxSize + unitDx * arrowHeadBoxSize;

  let arrowPoint2X = toPoint.x - unitDx * arrowHeadBoxSize + unitDy * arrowHeadBoxSize;
  let arrowPoint2Y = toPoint.y - unitDy * arrowHeadBoxSize - unitDx * arrowHeadBoxSize;

  canvas.lineStyle(3, 0x3fa9f5);
  canvas.moveTo(fromPoint.x, fromPoint.y);
  canvas.lineTo(toPoint.x, toPoint.y);
  canvas.lineTo(arrowPoint1X, arrowPoint1Y);
  canvas.moveTo(toPoint.x, toPoint.y);
  canvas.lineTo(arrowPoint2X, arrowPoint2Y);

}

/**
 * draws the center of a circle for the live ptz edit interface
 */
export function drawCenterCircle(graphics, pt, radius, highlight = false) {
  graphics.clear();
  graphics.lineStyle(2, 0x3fa9f5, 1);
  if (highlight) {
    graphics.beginFill(0xFFFFFF, 0.25);
  }
  graphics.drawCircle(pt.x, pt.y, radius);
  graphics.endFill();

  graphics.lineStyle(0);
  graphics.beginFill(0x3fa9f5)
  graphics.drawCircle(pt.x, pt.y,5);
  graphics.endFill();
}

/**
 * Finds the length of a line segment
 * @param fromPoint
 * @param toPoint
 * @returns {{dx: number, length: null, dy: number}}
 */
export function findLengthOfLineSegment(fromPoint, toPoint) {
  const dx = toPoint.x - fromPoint.x;
  const dy = toPoint.y - fromPoint.y;

  // normalize
  const length = Math.sqrt(dx * dx + dy * dy);
  const unitDx = dx;
  const unitDy = dy;
  return {dx: unitDx, dy: unitDy, length: length}

}

/**
 * this will snap a line to a 45 degree angle from the origin
 * @param fromPoint
 * @param toPoint
 * @returns {{x: *, y: *}}
 */
export function snapToAngle(snapAngle, fromPoint, toPoint) {
  //let m = (toPoint.y - fromPoint.y) / (toPoint.x - fromPoint.x);
  let theta = Math.atan2(toPoint.y - fromPoint.y,toPoint.x - fromPoint.x);
  theta = theta * (180 / Math.PI);

  let newDegree = Math.round(theta / snapAngle) * snapAngle;

  let difference = newDegree - theta;

  let resultPoint = {x: toPoint.x,y: toPoint.y};
  resultPoint = rotate(difference, fromPoint, toPoint);
  return resultPoint;
}
export function rotate(angle, center, point) {
  angle = angle * (Math.PI / 180); // Convert to radians
  const rotatedX = Math.cos(angle) * (point.x - center.x) - Math.sin(angle) * (point.y - center.y) + center.x;
  const rotatedY = Math.sin(angle) * (point.x - center.x) + Math.cos(angle) * (point.y - center.y) + center.y;
  return {x: rotatedX, y: rotatedY};
}
