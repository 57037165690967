import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['report-segment'],
  showDelete: computed('reportData.segments.[]', function() {
    return this.get('reportData.segments.length') > 1;
  }),

  actions: {
    removeImage() {
      let segments = this.get('reportData.segments');
      if (segments && segments.get('length') > 1) {
        segments.removeObject(this.segment)
      }
      
      this.segment.deleteRecord();
      this.segment.save({adapterOptions: {localStorage: true}})
        .then(() => {
          this.reportData.save({adapterOptions: {localStorage: true}});
        });
    }
  }
});
