import moment from 'moment';

import Component from '@ember/component';

const format = 'h:mm a';
const momentFormat = 'h:mm:s a';

const defaultOptions = {
  interval: 30,
  dynamic: false,
  dropdown: true,
  scrollbar: true,
  minTime: '12:00 am',
  maxTime: '11:59:59 pm',
  startTime: '12:00 am',
  maxHour: 24,
  timeFormat: format,
  momentFormat: momentFormat
};

export default Component.extend({
  classNames: ['input-time-picker'],
  selectedValue: null,
  options: null,
  overrideOptions: null,
  init() {
    this._super(...arguments);
    this.options = { ...defaultOptions, ...this.overrideOptions };
  },
  actions: {
    onChange(valueAsDateTime) {
      if (!valueAsDateTime) {
        this.set('selectedValue', this.lastValidSelection);
        return;
      }
      
      const formattedValue = moment(valueAsDateTime).format(momentFormat);
      if (this.onChange) {
        this.onChange(formattedValue);
      }
    }
  }
});
