import {
  BaseSubscriptionSerializer,
  SaveRelationshipsMixin
} from '../../mixins/subscription/serializer';

export default BaseSubscriptionSerializer.extend(SaveRelationshipsMixin, {
  attrs: {
    notificationMethods: { serialize: true },
  }

});
