/**
 * NOTE: to test pinch gesture in Chrome Dev Tools using Hammer.js
 * Install the Hammer.js TouchEmulator: http://hammerjs.github.io/touch-emulator/
 * e.g. npm install hammer-touchemulator (then copy node_modules/../touch-emulator.js to public/assets)
 * Then add the following 2 script tags to app/index.html at top part of body:
 *
 *   <script src="{{rootURL}}assets/touch-emulator.js"></script>
 *   <script> TouchEmulator(); </script>
 *
 *   NOTE: copy the node_modules/hammer-touchemulator/touch-emulator.js to public/assets/
 */
import Hammer from 'hammerjs';

const MIN_ZOOM = 100;
const MAX_ZOOM = 2000;

/**
 * Creates the hammer manager.
 * NOTE: In order to simulate pinch gesture in Chrome Dev Tools for Hammer.js (using shift-left-click drag),
 * we need to adjust manager options to add: { inputClass: Hammer.TouchInput }
 * see: https://github.com/hammerjs/touchemulator/issues/22
 * @returns {Manager}
 */
export const createHammer = (el) => {
  // You must uncomment the line below if using touch-emulator to simulate pinch.
  // WARNING: Regular mouse movements will not work with this option enabled.
  // DONT FORGET TO DISABLE THIS LINE IF YOU ARE NOT DEBUGGING MULTI-TOUCH
  // return new Hammer.Manager(el, { inputClass: Hammer.TouchInput });
  return new Hammer.Manager(el);
};

export const restrictZoom = (zoom) => {
  if (zoom < MIN_ZOOM) {
    zoom = MIN_ZOOM;
  } else if (zoom > MAX_ZOOM) {
    zoom = MAX_ZOOM;
  }
  return zoom;
};
