import DS from 'ember-data';

export default DS.Model.extend({
  lastSuccessfulConnection: DS.attr(),
  slug: DS.attr(),
  ip_address: DS.attr(),
  port: DS.attr(),
  localIpAddress: DS.attr(),
  clientName: DS.attr(),
  notes: DS.attr(),
  active: DS.attr(),
  minionId: DS.attr(),
  legacy_cloudbridge: DS.attr(),
  gateway: DS.attr(),
  site: DS.belongsTo('site')
});
