import DS from 'ember-data';
import { computed } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import Ember from 'ember';

const EVENT_MIN_DIFFERENCE = 300;
export default DS.Model.extend({
  currentUser: service(),
  firstTimestamp: DS.attr(),
  lastTimestamp: DS.attr(),
  lastUpdate: DS.attr(),
  created: DS.attr(),
  modified: DS.attr(),
  subscription: DS.belongsTo('subscription/subscription'),
  currentTime: null,
  tags: DS.attr(),
  site: DS.belongsTo('site'),
  states: DS.hasMany('subscription/event-subscriber-status'),
  items: DS.hasMany('subscription/event-item-reference', { async: true }),
  totalEventItems: 0,
  isInProgress: DS.attr(),
  eventContentType: computed('items.{@each.content,[]}', function() {
    let theType = null;
    if (!this.items) {
      return theType;
    }
    this.items.any(item => {
      if (item && item.content) {
        if (item.isDestroyed || item.isDestroying) {
          return false;
        }
        theType = item.get('content._internalModel.modelName');
        return true;
      }
    })
    return theType;
  }),
  day: computed('firstTimestamp', function() {
    let timestamp = moment(this.firstTimestamp);
    if (timestamp.isSame(moment(), 'day')) return 'Today';
    if (timestamp.isSame(moment().subtract(1, 'day'), 'day'))
      return 'Yesterday';
    return timestamp.format('MMMM D, YYYY');
  }),
  updateCurrentTime() {
    this.set('currentTime', moment());
  },

  live: computed('lastTimestamp', 'modified', 'currentTime', function() {
    if (!this.isLoaded) {
      return false;
    }
    let lastTimestamp = this.lastTimestamp;
    if (!lastTimestamp) {
      return true;
    }
    let elapsed = moment().isBefore(
      moment(this.lastTimestamp).add(EVENT_MIN_DIFFERENCE, 'seconds')
    );
    if (elapsed && !Ember.testing) {
      later(
        this,
        function() {
          if (!this.isDetroyed || this.isDestoying) {
            this.updateCurrentTime();
          }
        },
        moment().diff(this.lastTimestamp, 'seconds') * 1000
      );
    }
    return elapsed;
  }),
  currentStatus: computed(
    'states.{@each.status,@each.subscriber,@each.subscriber.user}',
    function() {
      let currentState = null;
      this.get('states').forEach(state => {
        if (state.get('subscriber.user.id') === this.currentUser.vhiUser.id) {
          currentState = state;
        }
      });
      //Mirage has an issue with including three levels deep of includes,
      //was far easier to add this check here to help testing versus
      //spending hours hacking Mirage --Richard Aug2019
      if (Ember.testing && !currentState && this.get('states.length') === 1) {
        return this.get('states').objectAt(0);
      }
      return currentState;
    }
  ),
  displayTime: computed('firstTimestamp', function() {
    let timestamp = moment(this.firstTimestamp);
    let timezone = this.site.get('timezone') || moment.tz.guess();
    let format = 'HH:mm z MMM. D, YYYY';
    return moment(timestamp)
      .tz(timezone)
      .format(format);
  }),
  detailTime: computed('firstTimestamp', 'lastTimestamp', function() {
    let firstTimestamp = moment(this.firstTimestamp);
    let lastTimestamp = moment(this.lastTimestamp);
    let timezone = this.site.get('timezone') || moment.tz.guess();
    let format = 'HH:mm z MMM. D, YYYY';
    return (
      firstTimestamp.tz(timezone).format('HH:mm') +
      ' to ' +
      lastTimestamp.tz(timezone).format(format)
    );
  }),

});
