import { Factory, faker, association } from 'ember-cli-mirage';

export default Factory.extend({
  allowEdit () {
    return true;
  },
  created() {
    return faker.date.past();
  },
  modified() {
    return faker.date.past();
  },
  subscription: association(),
  user: association(),
});
