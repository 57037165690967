import DS from 'ember-data';
import { computed } from '@ember/object';
import moment from 'moment';

export default DS.Model.extend({
  timestamp: DS.attr(),
  eventitemreference: DS.hasMany('subscription/event-item-reference',
    { inverse: 'content' }),
  isoTimestamp: computed('timestamp', function() {
    return moment(this.timestamp)
      .toISOString();
  }),
  momenttimestamp: computed('timestamp', function() {
    return moment(this.timestamp);
  })
});
