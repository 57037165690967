define("reach-web-client/utils/get-mouse-position", ["exports", "ember-ui-components/utils/get-mouse-position"], function (_exports, _getMousePosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _getMousePosition.default;
    }
  });
});
