import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  report: service(),
  errors: null,
  init() {
    this._super(...arguments);
    this.set('errors', {
      creation: null,
    });
  },
  actions: {
    addMoreImages() {
      this.model.save({adapterOptions: { localStorage: true}})
        .then((result)=>{
          this.set('report.currentReport', result);
          this.transitionToRoute('sites');
        });

    },
    createReport() {
      this.model.copy(true)
        .then(copy =>{
          copy.save({adapterOptions: {
            includeId: false
          }})
            .then(()=>{
              this.model.deleteRecord();
              this.model.save({adapterOptions: { localStorage: true}});
              this.set('report.currentReport', null);
              this.transitionToRoute('reports');
            })
            .catch(() => {
              this.set('errors.creation', true);
            });
        });

    },
    cancelReport() {
      this.model.deleteRecord();
      this.model.save({adapterOptions: { localStorage: true}})
        .then(() => {
          this.set('report.currentReport', null);
          this.transitionToRoute('reports');
        });
    }
  }
});
