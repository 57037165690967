import Route from '@ember/routing/route';
import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import NavBarTitleMixin from '../../mixins/navbar-title-mixin';
import TenantRouteMixin from '../../mixins/tenant-route-mixin';
import BreadcrumbRoute from '../../mixins/breadcrumb-route';
import { inject as service } from '@ember/service';
import { t } from 'ember-intl';

export default Route.extend(AuthenticatedRouteMixin,
  NavBarTitleMixin,
  TenantRouteMixin,
  BreadcrumbRoute, {
    titleToken: t('report.create-report.breadcrumb'),
    intl: service(),
    report: service(),

    model() {
      return this.report.loadReportInProgress();
    },

    setupController() {
      this.set('breadcrumbs', [
        {
          name: this.intl.t('navbar.reports'),
          link: 'reports'
        },
        {
          name: this.intl.t('report.create-report.breadcrumb')
        }
      ]);

      this._super(...arguments);
    }
  });
