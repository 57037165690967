import ApplicationAdapter from '../application';
const JSON_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};
export default ApplicationAdapter.extend({
  pathForType() {
    return 'v1/subs/events'
  },
  query(store, type, query) {
    const url = `${this.host}/api/v1/subs/events/${query.event_id}/items/?page_size=${query.page_size}&page=${query.page}`;
    const options = {headers: JSON_HEADERS};
    return this.ajax(url, 'GET', options);

  }
});
