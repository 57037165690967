import Service from '@ember/service';
import fetch from 'fetch';
import ENV from '../config/environment';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { later } from '@ember/runloop';
import { PTZ_CHANGED } from '../services/analytics';

export default Service.extend({
  analytics: service(),
  tenant: service(),
  session: service(),
  store: service(),
  namespace: 'api',
  host: computed('tenant.subdomain', function() {
    let subdomain = this.get('tenant.subdomain')
    return ENV.vhiProtocol + '://' + subdomain + '.' + ENV.vhiServer;
  }),
  buildUrl (slug) {
    return this.host + `/api/channels/${slug}/move-to-preset/`;
  },
  movePtz(ptz) {
    this.analytics.trackMultiple(PTZ_CHANGED, {
      channelId: ptz.get('channel.id'),
      channelSlug: ptz.get('channel.slug'),
      channelName: ptz.get('channel.name'),
      ptzName: ptz.label,
      ptzId: ptz.number,
    });
    return new Promise((resolve, reject) => {
      if (!ptz || !ptz.channel) {
        reject();
      }
      let url = this.buildUrl(ptz.get('channel.slug'));
      let data = {preset_num: ptz.number, slug: ptz.get('channel.slug')};

      let { access_token } = this.get('session.data.authenticated');

      fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${access_token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      }).then((result) => {
        if (result.ok) {
          later(this, function() {
            this.store.queryRecord('media', {channelSlug: ptz.get('channel.slug')})
              .then(result => {
                ptz.set('channel.latestImage', result);
                resolve(result);
              }, reject);
          }, ptz.delay * 1000);
        } else {
          reject();
        }
      }, reject)
        .catch(() => reject);
    });
  }
});
