import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';

const { readOnly } = computed;
const DEFAULT_ZOOM_PERCENTAGE = 100;

/**
 * This mixin adds zoom actions to your component which will manipulate a zoomPercentage property that
 * controls the zoomPercentage on a child zoom component, aka {{common/image-zoom-card}}
 */
export default Mixin.create({
  defaultZoom: readOnly(function() {
    return DEFAULT_ZOOM_PERCENTAGE;
  }),
  zoomPercentage: DEFAULT_ZOOM_PERCENTAGE,
  maxScrollLeft: 0,
  maxScrollTop: 0,

  updateZoom(value) {
    this.set('zoomPercentage', value);
  },

  notZoomed: computed('zoomPercentage', function() {
    return this.zoomPercentage === DEFAULT_ZOOM_PERCENTAGE;
  }),

  restrictLeft(value) {
    const sign = value < 0 ? -1 : 1;
    if (Math.abs(value) > this.get('maxScrollLeft')) {
      return sign * this.get('maxScrollLeft');
    }
    return value;
  },

  restrictTop(value) {
    const sign = value < 0 ? -1 : 1;
    if (Math.abs(value) > this.get('maxScrollTop')) {
      return sign * this.get('maxScrollTop');
    }
    return value;
  },

  actions: {
    zoomReset() {
      this.updateZoom(DEFAULT_ZOOM_PERCENTAGE);
    },
    zoomIn() {
      this.updateZoom(this.zoomPercentage + 10)
    },
    zoomOut() {
      this.updateZoom(this.zoomPercentage - 10)
    },
    scrollLimit({ left, top }) {
      if (left) {
        this.set('maxScrollLeft', left);
      }
      if (top) {
        this.set('maxScrollTop', top);
      }
    }
  }
});
