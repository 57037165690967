define("reach-web-client/utils/date", ["exports", "ember-time-tools/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _date.default;
    }
  });
});
